import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { faEdit, faXmark, faCheckCircle, faEye, faEyeSlash, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { UtilService } from '../../services/util/util.service';
import { ModalService } from '../../services/modal/modal.service';
import { ShopService } from '../../services/shop/shop.service';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {

  faEdit = faEdit;
  faXmark = faXmark;
  faCheckCircle = faCheckCircle;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faSignOut = faSignOut

  system_id = environment.system_id

  resetPasswordForm: FormGroup;

  textAlert = '';

  eyeActive: any = {}

  @ViewChild('callAPIDialogChangeImage')
  callAPIDialogChangeImage!: TemplateRef<any>;
  @ViewChild('callAPIDialogChangePassword')
  callAPIDialogChangePassword!: TemplateRef<any>;

  profileSelected = '';

  profileImage = [
    'https://storage.maxxgadget.com/warehouse/avatar/user2.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user3.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user4.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user5.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user6.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user7.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user8.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user9.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user10.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user11.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user12.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user13.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user14.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user15.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user16.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user17.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user18.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user19.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user20.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user21.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user22.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user23.png',
    'https://storage.maxxgadget.com/warehouse/avatar/user24.png',
  ]

  userInfo: any;

  processing = false

  constructor(
    public authService: AuthService,
    public util: UtilService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private fb: FormBuilder,
    private shopService: ShopService,
    private notificationService: NotificationService
  ) {
    this.resetPasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.getUserInfo();
    await this.checkAccessShop()
    console.log(this.userInfo)
  }

  changeImage() {
    this.profileSelected = this.authService.user.image;
    const dialogRef = this.dialog.open(this.callAPIDialogChangeImage, { disableClose: true })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const info = {
          username: this.authService.user.uid,
          info: {
            image: this.profileSelected
          }
        }
        this.authService.updateCredential(info).then((result: any) => {
          console.log(result)
          if (result.success) {
            const user = {
              ...this.authService.user,
              image: this.profileSelected
            }
            localStorage.setItem('user', JSON.stringify(user));

            this.authService.getAccessToken(this.authService.user.uid, 'credential', result.data.info)
            this.authService.user.image = this.profileSelected;
            this.openModal('success', 'เปลี่ยนรูปโปรไฟล์สำเร็จ', '')
          } else {
            this.openModal('fail', 'Ooop!', 'ไม่สามารถเปลี่ยนรูปได้')
          }
        });
      }
    })
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      this.authService.getUserInfo().then((result: any) => {
        console.log(result)
        if (result.success && result.data) {
          resolve(result.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  changePassword() {
    this.resetDataInput()
    this.dialog.open(this.callAPIDialogChangePassword, { disableClose: true })
  }

  confirmChangePassword() {
    if (!this.resetPasswordForm.valid) return

    const { oldPassword, newPassword, confirmPassword } = this.resetPasswordForm.getRawValue()

    console.log(this.isAlphanumeric(newPassword))
    if (!this.isAlphanumeric(newPassword) || !this.isAlphanumeric(confirmPassword)) {
      this.textAlert = 'รหัสผ่านต้องมีเฉพาะ a-z A-Z และ 0-9 เท่านั้น'
    }

    if (newPassword === confirmPassword) {
      const body = {
        username: this.authService.user.uid,
        old_password: oldPassword,
        new_password: newPassword
      }
      this.authService.changePassword(body).then((response: any) => {
        console.log(response)
        this.dialog.closeAll()
        if (response.success) {
          this.openModal('success', 'เปลี่ยนรหัสผ่านสำเร็จ', '').then((result: any) => {
            console.log(result)
          })
        } else {
          let messageError = 'ไม่สามารถเปลี่ยนรหัสผ่านได้';
          if (response.message === 'Username not found') {
            messageError = 'ไม่พบผู้ใช้ในระบบ'
          } else if (response.message === 'Incorrect password') {
            messageError = 'รหัสผ่านไม่ถูกต้อง'
          }

          this.openModal('fail', 'Ooop!', messageError).then((result: any) => {
            console.log(result)
          })
        }
      })
    } else {
      this.textAlert = 'รหัสผ่านไม่ตรงกัน'
    }
  }

  isAlphanumeric(inputString: string) {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    return alphanumericRegex.test(inputString);
  }

  showPassword(elementId: string) {
    const x: any = document.getElementById(elementId);
    if (x.type === "password") {
      x.type = "text";
      this.eyeActive[elementId] = true
    } else {
      x.type = "password";
      delete this.eyeActive[elementId]
    }
  }

  resetDataInput() {
    this.eyeActive = {}

    this.textAlert = ''

    this.resetPasswordForm.controls['oldPassword'].setValue('')
    this.resetPasswordForm.controls['newPassword'].setValue('')
    this.resetPasswordForm.controls['confirmPassword'].setValue('')
  }

  openModal(type: any, title: string, subtitle: string) {
    return this.modalService.openModal(type, title, subtitle)
  }

  selectedPermission(role: string) {
    this.processing = true;
    const body = {
      role,
      system_id: this.system_id
    }

    console.log(body)
    this.authService.updateRoleSelected(body).then((result: any) => {
      this.processing = false;
      console.log(result)
      if (result.success) {
        this.userInfo.permissions = result.data.permissions.filter((item: any) => item.system_id === this.system_id)
        this.authService.user.role = role;

        const user: any = localStorage.getItem('user')
        localStorage.setItem('user', JSON.stringify({ ...JSON.parse(user), role }));

        const roleName = this.authService.roleOptions.find(item => item.id === this.authService.user.role)?.name || ''
        this.modalService.openModal('success', 'เปลี่ยนสิทธิ์สำเร็จ', `กำลังใช้งานในสิทธิ์ ${roleName} `)
      } else {
        console.log('ERROR')
        this.openModal('fail', 'Ooop!', 'ไม่สามารถเปลี่ยนสิทธิ์ได้ในขณะนี้')
      }
    })
  }

  async checkAccessShop() {
    const shops: any = await this.authService.getAccessShopUser()
    this.authService.shops = shops?.data?.sort((a: any, b: any) => a.shop_id - b.shop_id) || []

    if (this.authService.shops.length > 0) {
      const shopIds = this.authService.shops.map(item => item.shop_id);
      const [shopDetails, providerDetails]: any = await Promise.all([
        this.shopService.getAllShop({ shop_id: shopIds.toString() }),
        this.shopService.getAllProvider({})
      ])

      this.authService.shops.forEach(shop => {
        const index = shopDetails.data.findIndex((item: any) => item.shop_id === shop.shop_id)
        shop.name = shopDetails.data[index]?.name || '';
        shop.line_id = shopDetails.data[index]?.line?.line_id || '';
        shop.liff_id = shopDetails.data[index]?.line?.liff_id || '';
        shop.provider_id = shopDetails.data[index]?.provider_id || '';
      })

      const selectedShop = this.authService.shops.find(item => item.selected === true)
      if (selectedShop) {
        const selectedProvider = providerDetails.data.find((e: any) => e.provider_id === selectedShop.provider_id)
        this.authService.selectedShop = {
          shop_id: selectedShop.shop_id,
          name: selectedShop.name,
          line_id: selectedShop.line_id,
          liff_id: selectedShop.liff_id,
          provider_id: selectedShop.provider_id,
          type: selectedProvider.type
        }
      } else {
        const shop = this.authService.shops[0]
        const result: any = await this.authService.selectShop(shop.shop_id)
        const selectedProvider = providerDetails.data.find((e: any) => e.provider_id === shop.provider_id)
        if (result.success) {
          this.authService.selectedShop = {
            shop_id: shop.shop_id,
            name: shop.name,
            line_id: shop.line_id,
            liff_id: shop.liff_id,
            provider_id: shop.provider_id,
            type: selectedProvider.type
          }
        } else {
          this.authService.selectedShop = undefined
        }
      }
    }
  }

  async selectShop(shopId: string) {
    this.processing = true;
    const result: any = await this.authService.selectShop(shopId)
    if (result.success) {
      await this.checkAccessShop()
      await this.notificationService.loadNotification();
      this.modalService.openModal('success', 'เปลี่ยนช็อปสำเร็จ', `กำลังใช้งานช็อป ${this.authService.selectedShop?.name} `)
    } else {
      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', '')
    }
    this.processing = false;
    console.log(result)
  }

  getNameRole(id: string) {
    return this.authService.roleOptions.find(e => e.id === id)?.name;
  }

  checkRoleActive(role: string, permission: any[]) {
    if (permission) {
      const index = permission.findIndex(item => item.role === role && item.active && item.system_id === this.system_id)
      return index > -1 ? true : false
    } else {
      return false
    }
  }

  checkSelectedRole(role: string, permission: any[]) {
    if (permission) {
      const index = permission.findIndex(item => item.role === role && item.selected && item.active && item.system_id === this.system_id)
      return index > -1 ? true : false
    } else {
      return false
    }
  }

  logout() {
    this.modalService.openModal('confirm', 'ออกจากระบบ', 'คุณต้องการออกจากระบบหรือไม่').then((result) => {
      console.log(result);
      if (result) {
        this.authService.logout()
      }
    })
  }


}
