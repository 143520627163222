<div class="p-4 w-full space-y-2 text-sm lg:text-base relative">
    <div class="font-bold text-base lg:text-lg"> รายการ Provider</div>
    <div class="grid grid-cols-1 gap-2 lg:flex lg:justify-end">
        <div class="w-full lg:w-fit">
            <div class="flex flex-row justify-between items-center border bg-white rounded-lg h-[46px]">
                <input type="text" id="keyword" class="py-2 px-4 !pr-8 w-full outline-none rounded-l-lg"
                    placeholder="ค้นหาชื่อ/รหัส provider" [(ngModel)]="searchText" (keydown.enter)="search(searchText)"
                    autocomplete="off">
                <button
                    class="flex-none h-full aspect-square bg-yellow-500 rounded-r-lg flex items-center justify-center"
                    (click)="search(searchText)">
                    <fa-icon [icon]="faMagnifyingGlass" class="text-xl"></fa-icon></button>
            </div>
        </div>
        <div class="w-full lg:w-fit flex justify-end">
            <button class="btn-light !border flex space-x-2 items-center" (click)="clickCreateProvider()">
                <fa-icon [icon]="faPlus" class=""></fa-icon>
                <div class="">เพิ่ม Provider</div>
            </button>
        </div>
    </div>
    @if (isLoading) {
    <div class="flex justify-center pt-40">
        <app-loader></app-loader>
    </div>
    }@else {
    <!-- For Mobile -->
    <div class="lg:hidden grid gap-1">
        @for (provider of filteredData; track $index) {
        <button class="border bg-white rounded-lg p-3 text-left" (click)="clickDetailProvider(provider)">
            <div class="flex justify-between">
                <div class="font-bold ">
                    {{provider.name}}
                </div>
                <div class="font-bold ">
                    {{provider.provider_id}}
                </div>
            </div>
            <div class="flex justify-between items-center">
                <div class="">
                    @if(provider.type === 'INHOUSE'){
                    <div class="px-2 py-0.5 rounded bg-yellow-500">#{{provider.type}}</div>
                    }@else {
                    <div class="px-2 py-0.5 rounded bg-gray-200">#{{provider.type}}</div>
                    }
                </div>
                <div class=" text-blue-500">
                    ร้านค้าที่เข้าร่วม {{countShopProvider(provider.provider_id)}} ร้านค้า
                </div>
            </div>
        </button>
        }
    </div>

    <!-- For Desktop -->
    <div class="hidden lg:grid grid-cols-1 gap-2">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                Provider ID
                            </th>
                            <th class="text-left">
                                ชื่อ
                            </th>
                            <th class="text-left">
                                ประเภท
                            </th>
                            <th class="text-center">
                                ร้านค้าที่เข้าร่วม
                            </th>
                        </tr>
                    </thead>
                    @if(!filteredData || filteredData.length === 0){
                    <tbody>
                        <tr class="tbody-tr">
                            <td colspan="100" class="text-center py-10">
                                <div class="flex flex-col justify-center items-center w-full">
                                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                    <div class="text-center pt-4">ไม่พบรายการ Provider</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }
                    @else {
                    <tbody>
                        @for (provider of filteredData; track $index) {
                        <tr class="tbody-tr cursor-pointer" (click)="clickDetailProvider(provider)">
                            <td>
                                <div class="h-12 content-center">
                                    {{provider.provider_id}}
                                </div>
                            </td>
                            <td>
                                <div class="h-12 content-center">
                                    {{provider.name}}
                                </div>
                            </td>
                            <td>
                                @if(provider.type === 'INHOUSE'){
                                <div class="w-fit px-2 py-0.5 rounded bg-yellow-500">#{{provider.type}}</div>
                                }@else {
                                <div class="w-fit px-2 py-0.5 rounded bg-gray-200">#{{provider.type}}</div>
                                }
                            </td>
                            <td class="text-center">
                                {{countShopProvider(provider.provider_id)}} ร้านค้า
                            </td>
                        </tr>
                        }
                    </tbody>
                    }
                </table>
            </div>
        </div>
    </div>
    }
</div>

<div>
    <app-provider-detail-dialog [open]="showDetail" [provider]="providerDetail"
        (callBackProviderDetail)="callBackProviderDetail($event)"></app-provider-detail-dialog>
</div>