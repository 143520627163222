import { Component } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { Router } from '@angular/router';
import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-receipt-history-list',
  templateUrl: './receipt-history-list.component.html',
  styleUrl: './receipt-history-list.component.css'
})
export class ReceiptHistoryListComponent {

  faChevronLeft = faChevronLeft
  faChevronRight = faChevronRight
  faClock = faClock
  faUser = faUser

  receiptHistory = [
    {
      id: "65d7258c4c7572142482b5c4",
      "total_serial": 50,
      "status": "completed",
      "created": {
        "date": 1711681200000,
        "by": "U0d3f1a4ae069a5e9"
      },
      "updated": {
        "date": 1708918405135,
        "by": "U0d3f1a4ae069a5e9"
      },
      "confirm": {
        "date": 1708599600000,
        "by": "U0d3f1a4ae069a5e9"
      },
    },
    {
      id: "65d7258c4c7572142482b5c4",
      "total": 50,
      "status": "completed",
      "created": {
        "date": 1708598668719,
        "by": "U0d3f1a4ae069a5e9"
      },
      "updated": {
        "date": 1708918405135,
        "by": "U0d3f1a4ae069a5e9"
      },
      "confirm": {
        "date": 1708599600000,
        "by": "U0d3f1a4ae069a5e9"
      },
    },
  ]

  dataSource: any[] = []
  isLoaded = false;

  users = [
    {
      "user_id": "U0d3f1a4ae069a5e9",
      "name": "ทดสอบ ทดสอบ"
    }
  ]


  constructor(
    public utilService: UtilService,
    private router: Router
  ) { }


  ngOnInit(): void {
    setTimeout(() => {
      this.reloadData();
      this.isLoaded = true;
    }, 300);
  }

  reloadData() {
    this.dataSource = this.receiptHistory
  }

  backToReceiptLits() {
    this.router.navigate(['/receipts/receipt-list']);
  }

  routeToReceiptHistoryDetial(id: string) {
    this.router.navigate(['/receipts/receipt-history-detail'], { queryParams: { id } });
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).fromNow()
  }

  getUserDetail(userId: string) {
    return this.users.find(item => item.user_id === userId)
  }
}
