import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SaleApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getOneOrder(orderNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/orders/${orderNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllOrder(filters: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/orders/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOrderList(shop_id: string, offset: number, limit: number, status: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/orders`, {
        headers,
        params: {
          shop_id,
          offset,
          limit,
          status
        }
      }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateOrderDiscount(orderNo: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/orders/${orderNo}/discounts`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateOrderRedemptionDiscount(orderNo: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/orders/${orderNo}/discounts/redemption`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateOrderDiscountNote(orderNo: string, discountType: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/orders/${orderNo}/discounts/type/${discountType}/note`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  removeOrderDiscount(orderNo: string, discountType: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/orders/${orderNo}/discounts/type/${discountType}/remove`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOneSerial(serialNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/serial/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductShopBySku(shop_id: string, sku: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product-shop/shop-id/${shop_id}/sku/${sku}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductBySKu(sku: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product/sku/${sku}`, { headers }).subscribe((data) => {
        console.log(data)
        resolve(data);
      });
    })
  }

  createOrder(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/orders/new`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  addOrderItem(orderNo: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/orders/${orderNo}/products`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    })
  }

  deleteItems(orderNo: string, serialNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.delete(`${endPoint}/orders/${orderNo}/products/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerByPhoneNumber(phoneNumber: string, shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/phone/${phoneNumber}/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerByUserId(userId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/users/${userId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerPointBalace(userId: string, shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/points/balance/${userId}/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerTotalBill(userId: string, shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/bills/total/${userId}/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  confirmOrder(order_no: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/confirm`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  confirmOrderCredit(order_no: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/confirm-credit`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  confirmOrderPromptay(order_no: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/confirm-promtpay`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  createQrPayment(order_no: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/qr-payment`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  updateOrderPriceConfig(orderNo: string, priceConfigId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${orderNo}/price`, {
        price_config_id: priceConfigId
      }, { headers }).subscribe((data) => {
        resolve(data);
      })
    })
  }

  updateItemPriceConfig(orderNo: string, updateBody: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${orderNo}/products/price`, updateBody, { headers }).subscribe((data) => {
        resolve(data);
      })
    })
  }

  getPriceConfig(shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.get(`${this.endpoint}/products/price-config/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePaymentMethod(order_no: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/payment-method`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  addCustomerToOrder(order_no: string, user_id: string, name: string) {
    const headers = this.apiService.getAPIHeader();
    const body = {
      user_id,
      name
    }
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/customer`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  removeCustomerFromOrder(order_no: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/customer/remove`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  checkSatusQrPayment(order_no: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.post(`${this.endpoint}/orders/${order_no}/status-qr-payment`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  cancelOrder(orderNo: string, note?: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${orderNo}/cancel`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  updateOrderStatus(orderNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${orderNo}/status`, { status: 'completed' }, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  getAllPromotion(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/promotion/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }


  getShopByShopId(shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getShopPaymentConfig() {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/payment-config`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateStatusCounterService(order_no: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/orders/${order_no}/status-counter-service`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  getCalculatePointRedemtion(points: number) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/points/redemption/calculate/${points}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createRedemption(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.post(`${this.endpoint}/points/redemption`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  confirmRedemption(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.post(`${this.endpoint}/points/redemption/confirm`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  getAllApprovedRedemptionByUser(user_id: string, shop_id: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/points/redemption/approved/${user_id}/${shop_id}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  sentSlipMessage(orderNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/orders/${orderNo}/sending/slips`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getRedemptionByRedemptionId(redemptionId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/points/redemption/${redemptionId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  printReceipt(shopId: string, orderNo: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.put(`${endPoint}/printer/order/${shopId}/${orderNo}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}

