import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-serial-out-dailog',
  templateUrl: './serial-out-dailog.component.html',
  styleUrl: './serial-out-dailog.component.css'
})
export class SerialOutDailogComponent {
  faChevronLeft = faChevronLeft;

  @Input('open')
  open: boolean = false;
  @Input('serials')
  serials: any;
  @Output('callBackClose')
  callBackClose: EventEmitter<object> = new EventEmitter<object>();


  close() {
    this.callBackClose.emit({ confirm: false })
  }

  // constructor(
  //   @Inject(MAT_DIALOG_DATA) public data: any,
  //   private ref: MatDialogRef<SerialOutDailogComponent>
  // ) {

  // }

}
