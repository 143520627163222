export const environment = {
    endpoint: 'https://mservice-dev.maxxgadget.com/maxx-pos',
    websocket: 'wss://mservice-dev.maxxgadget.com',
    SYSTEM_ID: 'maxx-pos',
    AUTH_END_POINT: 'https://mservice-dev.maxxgadget.com/authen',
    liff_url_claim: 'https://liff.line.me/1653990310-p3MwX8Pd',
    system_id: 'maxx-pos',
    auth_endpoint: 'https://mservice-dev.maxxgadget.com/authen',
    firebase: {
        apiKey: "AIzaSyDUSkrjIJbPpQOGGFzjDzo8LI2COaD1L5M",
        authDomain: "power-workers.firebaseapp.com",
        databaseURL: "https://power-workers-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "power-workers",
        storageBucket: "power-workers.appspot.com",
        messagingSenderId: "957067658543",
        appId: "1:957067658543:web:d946dfb678ec56b2360dda"
    },
    firebaseAuthCRM: {
        apiKey: "AIzaSyBrjRukidiizMzedCQtkgEgXWEUsY17XZ4",
        authDomain: "happy-crm-platform.firebaseapp.com",
        projectId: "happy-crm-platform",
        storageBucket: "happy-crm-platform.appspot.com",
        messagingSenderId: "351937093775",
        appId: "1:351937093775:web:915f79aacc4893efd350fa"
    },
    inventory_endpoint: 'https://inventory.powerbolt.workers.dev',
    inventory_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI4VkRQWlFHTEtDRFRWSlhIIiwibmFtZSI6IlJlbWF4IiwiY3JlYXRlRGF0ZSI6IjIwMjQtMDYtMTVUMDM6MjU6NDMuMTg0WiJ9.3Y/rihrA919DERP0ktl7gKJiXQfcx8kM8L3Znul+B5c='
};