<div class="p-4 pb-32 relative" id="inventory-counting">
    <div
        class="fixed md:sticky top-[55px] md:-top-0.5 bg-gray-100 z-10 py-4 md:pb-4 w-full md:w-auto right-0 md:right-px px-4 md:px-0">
        <div class="relative">
            <input #barcodeInput [(ngModel)]="barcodeValue" type="text" id="barcode" class="input !pr-12"
                placeholder="บาร์โค้ดสินค้า" autocomplete="off" (keyup.enter)="scanBarcode()">
            <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
        </div>
        <div
            class="bg-gray-100 w-full text-sm font-medium text-center text-gray-500 border-b border-gray-200 col-span-full my-2">
            <div class="w-full md:w-fit flex flex-wrap -mb-px">
                <button class="flex-1 me-2" (click)="state='shop-product'">
                    <p [ngClass]="{'text-black w-full md:w-fit font-semibold border-yellow-500':state==='shop-product' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='shop-product'}"
                        class="inline-block whitespace-nowrap p-2 border-b-2 rounded-t-lg">
                        สินค้าของร้าน</p>
                </button>
                <button class="flex-1 me-2" (click)="state='new-product'">
                    <p [ngClass]="{'text-black w-full md:w-fit font-semibold border-yellow-500':state==='new-product' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='new-product'}"
                        class="inline-block whitespace-nowrap p-2 border-b-2 rounded-t-lg">
                        สินค้าใหม่</p>
                </button>
            </div>
        </div>
    </div>
    <div class="mt-20 md:mt-4 grid grid-cols-1 lg:grid-cols-3 lg:grid-flow-dense gap-4">
        <div class="col-span-1 grid h-fit gap-4 order-first lg:order-last">

            @if (scanning) {
            <div class="card !bg-blue-500 text-white">
                <div class="border-b pb-4">
                    <span class="text-lg font-bold">สินค้าที่กำลังตรวจนับ</span>
                </div>
                @if(productCounting) {
                <div class="pt-4 relative">
                    <div class="flex gap-4">
                        <div class="">
                            <div class="w-20 aspect-square rounded-md">
                                <img [src]="'https://storage.remaxthailand.co.th/img/product/' + productCounting.sku + '/1.jpg'"
                                    [alt]="productCounting.sku" class="rounded-md" loading="lazy"
                                    onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                            </div>
                        </div>
                        <div>
                            <div>
                                {{productCounting.name || 'ไม่พบข้อมูลสินค้า'}}
                            </div>
                            <div>
                                {{productCounting.sku || '-'}}
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-2 items-center w-full mt-4">
                        @if (productCounting.qty) {
                        <mat-progress-bar mode="determinate"
                            [ngClass]="{'progress-success': progressProductCounting() >= 100}"
                            [value]="progressProductCounting()"></mat-progress-bar>
                        <div>
                            {{productCounting.counted || 0 | number}}/{{ productCounting.qty || 0 | number}}
                        </div>
                        }@else {
                        <div class="w-full justify-center text-center">--สินค้าใหม่--</div>
                        }
                    </div>
                </div>
                }@else {
                <div class="animate-pulse mt-4 space-y-4">
                    <div class="flex space-x-4">
                        <div class=" bg-gray-200 w-20 aspect-square rounded-md"></div>
                        <div class="flex-1 space-y-3 py-1">
                            <div class="h-3 bg-gray-200 rounded"></div>
                            <div class="h-3 bg-gray-200 rounded"></div>
                            <div class="space-y-2">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-3 bg-gray-200 rounded col-span-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-2 bg-gray-200 rounded"></div>
                </div>
                }
            </div>
            }

            <div class="card lg:h-56">
                <div class="border-b pb-4">
                    <span class="text-lg font-bold">ความคืบหน้าการนับสต๊อก</span>
                </div>
                <div class="space-y-2 pt-4">
                    <div class="flex justify-between">
                        <div>
                            <span class="pr-2">นับแล้ว</span>
                            <span class="text-blue-500 font-medium">
                                {{counted |number}}/{{stock|number}}
                            </span>
                        </div>
                        <div class="font-bold"
                            [ngClass]="{'text-lime-500 ':calcuatePercent(counted,stock) >= 100,'text-red-500 ':calcuatePercent(counted,stock) < 100}">
                            {{calcuatePercent(counted,stock)}}%</div>
                    </div>
                    <mat-progress-bar mode="determinate"
                        [ngClass]="{'progress-success': calcuatePercent(counted,stock) >= 100}"
                        [value]="calcuatePercent(counted,stock)"></mat-progress-bar>
                </div>
                <div class="flex justify-start space-x-4 pt-6">
                    <button class="btn-danger w-1/2" (click)="deleteInventory()">
                        ยกเลิกการนับ
                    </button>
                    <button class="btn-secondary w-1/2" (click)="confirmInventory()">
                        ยืนยันสต๊อกสินค้า
                    </button>
                </div>
            </div>
        </div>

        @if (loading) {
        <div class="flex lg:grid lg:col-span-2 card w-full justify-center items-center h-56">
            <app-loader></app-loader>
        </div>
        } @else {

        @if(state === 'shop-product') {
        <!-- Mobile View -->
        <div class="space-y-2 pb-4 mb-4 border-b block lg:hidden">
            @for (product of inventory; track $index) {
            <div class="grid grid-cols-6 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full lg:w-16 aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                            [alt]="product.sku" class="rounded-sm" loading="lazy"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-5 xl:col-span-9 h-[56px]">
                    <div class="line-clamp-3 text-sm text-gray-600">{{ product.name }}</div>
                </div>
                <div class="col-span-2 text-right pr-1 flex flex-col justify-between">
                    <div class="font-medium text-sm md:text-base">
                        x {{ product.qty | number}}
                    </div>
                    <div class="space-x-1">
                        @if (product.counted === product.qty) {
                        <span class="text-lime-500 font-medium text-sm md:text-base">นับครบแล้ว</span>
                        } @else {
                        <span class="text-sm md:text-base text-gray-400">ยังไม่นับ:</span>
                        <span class="text-red-500 font-medium text-sm md:text-base">{{(product.qty -
                            product.counted)
                            | number}}</span>
                        }
                    </div>
                </div>
            </div>
            }
        </div>

        <!-- Desktop View -->
        <div class="col-span-2 hidden lg:grid gap-4">
            <div class="card divide-y">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-right">
                                จำนวนทั้งหมด
                            </th>
                            <th class="text-right">
                                นับแล้ว/ทั้งหมด
                            </th>
                        </tr>
                    </thead>
                    <tbody class="">
                        @for (product of inventory; track $index) {
                        <tr>
                            <td>
                                <div class="flex w-full space-x-2">
                                    <div class="">
                                        <div class="w-full lg:w-16 aspect-square">
                                            <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                                                [alt]="product.sku" class="rounded-sm" loading="lazy"
                                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="line-clamp-2 text-sm text-gray-600">{{ product.name }}</div>
                                        <div class="text-primary-500">{{ product.sku }}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-sm font-medium">
                                    x{{ product.qty}}
                                </div>
                            </td>
                            <td class="relative">
                                <div class="text-right text-sm font-medium">
                                    {{product.counted}}/{{ product.qty}}
                                </div>
                                <div class="!absolute bottom-5 right-0 w-full xl:pl-6 2xl:pl-10">
                                    <mat-progress-bar mode="determinate"
                                        [ngClass]="{'progress-success': calcuatePercent(product.counted,product.qty) >= 100}"
                                        [value]="calcuatePercent(product.counted,product.qty)"></mat-progress-bar>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        }

        @if (state === 'new-product') {

        <!-- Mobile View -->
        <div class="space-y-2 pb-4 mb-4 border-b block lg:hidden">
            <div class="text-sm text-gray-500 text-center py-4" *ngIf="inventoryNew?.length <= 0">-
                ไม่มีรายการสินค้าใหม่ -</div>
            @for (product of inventoryNew; track $index) {
            <div class="grid grid-cols-6 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full lg:w-16 aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product?.sku + '/1.jpg'"
                            [alt]="product?.sku" class="rounded-sm" loading="lazy"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-5 xl:col-span-9 h-[56px]">
                    <div class="line-clamp-3 text-sm text-gray-600">{{ product.name ||
                        'ไม่พบข้อมูลสินค้า' }}</div>
                </div>
            </div>
            }
        </div>

        <!-- Desktop View -->
        <div class="col-span-2 hidden lg:grid gap-4">
            <div class="card divide-y">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สินค้า
                            </th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <div class="text-sm text-gray-500 pt-4" *ngIf="inventoryNew?.length <= 0">-
                            ไม่มีรายการสินค้าใหม่ -
                        </div>
                        @for (product of inventoryNew; track $index) {
                        <tr>
                            <td>
                                <div class="flex w-full space-x-2">
                                    <div class="">
                                        <div class="w-full lg:w-16 aspect-square">
                                            <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                                                [alt]="product.sku" class="rounded-sm" loading="lazy"
                                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="line-clamp-2 text-sm text-gray-600">{{ product.name ||
                                            'ไม่พบข้อมูลสินค้า' }}</div>
                                        <div class="text-primary-500">{{ product.sku || '-' }}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        }

        }
    </div>
</div>

<app-scroll-button id="inventory-counting"></app-scroll-button>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}