import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProvinceService } from '../../../../core/services/province/province.service';
import { ProviderApiService } from '../../../providers/services/api/provider-api.service';
import { faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { MerchantManagementApiService } from '../../serives/api/merchant-management-api.service';

@Component({
  selector: 'app-merchant-create-dialog',
  templateUrl: './merchant-create-dialog.component.html',
  styleUrl: './merchant-create-dialog.component.css'
})
export class MerchantCreateDialogComponent implements OnInit {

  faEye = faEye
  faEyeSlash = faEyeSlash
  faXmark = faXmark

  providerType: string[] = ['INHOUSE', 'OTHER']

  pricesOption: any[] = [
    {
      id: 4,
      name: "ราคาส่ง 4"
    },
    {
      id: 7,
      name: "ราคาส่ง 4.5"
    },
    {
      id: 5,
      name: "ราคาส่ง 5"
    },
  ]

  form: FormGroup;
  type = ''

  eyeActive: any = {}

  provinces: any = {}
  provinceSelections: string[] = []
  districtSelections: string[] = []
  subdistrictSelections: string[] = []

  providerList: any[] = []
  prodiverOptions: any[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<MerchantCreateDialogComponent>,
    private fb: FormBuilder,
    private provinceService: ProvinceService,
    private providerApiService: ProviderApiService,
    private merchantManagementApiService: MerchantManagementApiService,

  ) {
    console.log(this.data)

    this.form = this.fb.group({
      shopName: ['', [Validators.required]],
      providerId: [''],
      price: [null, [Validators.required]],
      address: this.fb.group({
        address: ['', [Validators.required]],
        province: ['', [Validators.required]],
        district: ['', [Validators.required]],
        subdistrict: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
      }),
      userAccount: this.fb.group({
        username: ['', [Validators.required], [this.UniqueUsernameUpdateValidator()]],
        password: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        confirmPassword: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        email: ['', [Validators.required, Validators.email]],
      },
        {
          validator: this.MustMatch('password', 'confirmPassword')
        }),
      haveProvider: [false],
      addShopToUser: [false],
    });
  }


  async ngOnInit(): Promise<void> {
    await this.loadProvinces();
    this.providerList = await this.getAllProvider()
    this.prodiverOptions = this.formatProviderOption(this.providerList.filter(provider => provider.type === 'OTHER'))

  }

  formatProviderOption(providerList: any[]) {
    return providerList.map(provider => ({ id: provider.provider_id, name: provider.name }))
  }

  async getAllProvider(): Promise<any[]> {
    return this.providerApiService.getAllProvider({}).then((response: any) => {
      if (response.success) {
        return response.data.sort((a: any, b: any) => b.created.date - a.created.date)
      } else {
        return []
      }
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  close() {
    this.ref.close({ confirm: false, data: null });
  }

  onSubmit() {
    if (this.form.invalid) {
      console.log(this.form)
      return;
    }
    this.ref.close({ confirm: true, data: this.form.getRawValue() });
  }

  async loadProvinces(): Promise<void> {
    this.provinceService.getProvincesData().subscribe(response => {
      console.log(response)
      this.provinces = response
      this.setProvinceSelection()
    })
  }

  setProvinceSelection(): void {
    this.provinceSelections = Object.keys(this.provinces);
  }

  setDistrictSelection(province: string): void {
    this.districtSelections = province ? Object.keys(this.provinces[province]) : [];
  }

  setSubdistrictSelection(province: string, district: string): void {
    this.subdistrictSelections = (province && district) ? Object.keys(this.provinces[province][district]) : [];
  }

  setPastalCode(province: string, district: string, subdistrict: string) {
    const postalCode = province && district && subdistrict ? this.provinces[province][district][subdistrict] : ''
    this.form.get('address.postalCode')?.setValue(postalCode)
  }


  onProvinceChange() {
    this.districtSelections = []
    this.subdistrictSelections = []
    this.form.get('address.district')?.reset()
    this.form.get('address.subdistrict')?.reset()
    this.form.get('address.postalCode')?.reset()
    this.setDistrictSelection(this.form.get('address.province')?.value)
  }

  onDistrictChange() {
    this.subdistrictSelections = []
    this.form.get('address.subdistrict')?.reset()
    this.form.get('address.postalCode')?.reset()
    this.setSubdistrictSelection(this.form.get('address.province')?.value, this.form.get('address.district')?.value)
  }

  onSubdistrictChange() {
    this.setPastalCode(this.form.get('address.province')?.value, this.form.get('address.district')?.value, this.form.get('address.subdistrict')?.value,)
  }

  checkHaveProvider() {
    const haveProvider = this.form.get('haveProvider')?.value
    if (haveProvider) {
      this.form.get('providerId')?.setValidators([Validators.required]);
    } else {
      this.form.get('providerId')?.clearValidators();
      this.form.get('providerId')?.reset();
    }
    this.form.get('providerId')?.updateValueAndValidity();

  }

  showPassword(elementId: string) {
    const x: any = document.getElementById(elementId);
    if (x.type === "password") {
      x.type = "text";
      this.eyeActive[elementId] = true
    } else {
      x.type = "password";
      delete this.eyeActive[elementId]
    }
  }

  preventPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      // If matching control has errors, return early
      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      // Set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  UniqueUsernameUpdateValidator(delay: number = 500) {
    let timeout: any;
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      const username = control.value;
      clearTimeout(timeout);
      return new Promise((resolve) => {
        timeout = setTimeout(() => {
          this.merchantManagementApiService.getDealerByUsername(username)
            .then((response: any) => {
              resolve((response.data ? { duplicate: true } : null));
            })
            .catch(() => {
              resolve(null);
            });
        }, delay);
      });
    };
  }
}
