import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { StaffsService } from '../../services/staffs/staffs.service';

@Component({
  selector: 'app-staff-update-dialog',
  templateUrl: './staff-update-dialog.component.html',
  styleUrl: './staff-update-dialog.component.css'
})
export class StaffUpdateDialogComponent {
  @Input('open')
  open: boolean = false;
  @Input('detail')
  detail: any;
  @Input('shops')
  shops: any;
  @Output('callBackStaffUpdate')
  callBackStaffUpdate: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;

  isSelectAll: boolean = false;
  shopSelected: string[] = []

  constructor(
    private staffsService: StaffsService,
  ) { }

  ngOnInit() {
    if (this.detail?.shops) {
      this.shopSelected = this.detail.shops.map((e: any) => e.shop_id)
      this.checkSelectAll();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['open']?.currentValue) {
      this.ngOnInit()
    }
  }

  close() {
    this.callBackStaffUpdate.emit({ confirm: false })
  }

  submit() {
    this.callBackStaffUpdate.emit({ confirm: true, shops: this.shopSelected })
  }

  checkPermissions() {
    const uniqueId = this.detail.permissions.filter((e: any) => e.active).map((e: any) => e.role)
    const result = this.staffsService.roleOptions.filter(e => uniqueId.includes(e.id))
    return result;
  }

  checkSelected(shopId: string) {
    return this.shopSelected.includes(shopId)
  }

  changeValue(event: any, shopId: string) {
    if (event.target.checked) {
      this.shopSelected.push(shopId)
    } else {
      let index = this.shopSelected.indexOf(shopId);
      if (index !== -1) {
        this.shopSelected.splice(index, 1);
      }
    }
    this.checkSelectAll()
  }

  checkSelectAll() {
    if (this.shopSelected.length === this.shops.length) {
      this.isSelectAll = true;
    } else {
      this.isSelectAll = false;
    }
  }

  selectAllChange(event: any) {
    if (event.target.checked) {
      this.shopSelected = this.shops.map((e: any) => e.shop_id)
    } else {
      this.shopSelected = []
    }
  }

}
