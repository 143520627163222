import { Injectable } from '@angular/core';
import { PromotionApiService } from '../api/promotion-api.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private promotionApiService: PromotionApiService
  ) { }

  getAllShop(filters: any) {
    return this.promotionApiService.getAllShops(filters).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getAllPromotion(filters: any) {
    return this.promotionApiService.getAllPromotion(filters).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  createPromotion(body: any) {
    return this.promotionApiService.createPromotion(body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  updatePromotion(promotionId: string, body: any) {
    return this.promotionApiService.updatePromotion(promotionId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }
}
