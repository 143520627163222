<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        ชำระเงินด้วย QR พร้อมเพย์
    </div>

    <div class="border rounded-lg flex flex-col justify-center items-center w-full">
        <img src="https://storage.maxxgadget.com/remaxthailand/payment-method/qr-header.jpg" class="rounded-t-lg" />
        <div class="qrcodeImage">
            <qrcode [qrdata]="qrPromptpayNo" [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'" [imageSrc]="thaiQRLogo"
                [imageHeight]="40" [imageWidth]="40" [margin]="4" [scale]="1" [width]="256"></qrcode>
        </div>
        <div class="flex w-full justify-between px-4 pb-4">
            <div class="text-sm">
                {{data.promptpayName}}
            </div>
            <div class="text-right">
                <div class="text-xl">
                    {{data.amount | number: '.2'}}
                </div>
                <div class="text-xs">
                    บาท (BAHT)
                </div>
            </div>
        </div>
    </div>

    <button class="btn-secondary" (click)="confirm()">
        ยืนยันการชำระเงิน
    </button>
    <button (click)="close()" class="text-gray-700 underline underline-offset-2">
        เปลี่ยนวิธีการชำระเงิน
    </button>
</div>