import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-show-image-dialog',
  templateUrl: './show-image-dialog.component.html',
  styleUrl: './show-image-dialog.component.css'
})
export class ShowImageDialogComponent {

  faChevronDown = faChevronDown;

  @Input('open')
  open: boolean = false;
  @Input('imageUrl')
  imageUrl = ''
  @Output('callBackShowImage')
  callBackShowImage: EventEmitter<object> = new EventEmitter<object>();

  close() {
    this.callBackShowImage.emit({ confirm: false })
  }

}
