import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faChevronDown, faPercent, faTicket } from '@fortawesome/free-solid-svg-icons';
import { SalesService } from '../../services/sales/sales.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-sale-add-discount-dialog',
  templateUrl: './sale-add-discount-dialog.component.html',
  styleUrl: './sale-add-discount-dialog.component.css'
})
export class SaleAddDiscountDialogComponent implements OnChanges, OnInit {
  @Input('open')
  open: boolean = false;
  @Input('total')
  total: number = 0;
  @Output('callBackAddDiscount')
  callBackAddDiscount: EventEmitter<object> = new EventEmitter<object>();
  @Output('callBackRemoveDiscount')
  callBackRemoveDiscount: EventEmitter<object> = new EventEmitter<object>();

  @Input('defaultValue') defaultValue: any = '';
  @Input('defaultNote') defaultNote: string = '';
  @Input('defaultUnit') defaultUnit: string = 'amount';
  @Input('defaultRef') defaultRef: string = '';
  @Input('defaultType') defaultType: string = 'promotion';

  value = '';
  note = '';
  unit = 'amount';
  ref = '';
  type = 'promotion';
  selected = '';

  faChevronDown = faChevronDown;
  faTicket = faTicket;
  faPercent = faPercent;

  backupDiscount: any = {}


  discountList: any = []

  shopId: string = this.authService.selectedShop?.shop_id || '';
  providerId: string = this.authService.selectedShop?.provider_id || '';

  constructor(
    private authService: AuthService,
    private salesService: SalesService
  ) { }

  ngOnInit(): void {
    const filters = {
      providers : this.providerId,
      active: true,
      shops: [this.shopId]
    }
    this.salesService.getAllPromotion(filters).then((response: any) => {
      console.log(response)
      if (response.success) {
        this.discountList = response.data.sort((a: any, b: any) => {
          // First compare by unit
          if (a.unit === b.unit) {
              // If units are the same, compare by value
              return a.value - b.value;
          } else {
              // If units are different, sort 'amount' before 'percent'
              return a.unit === 'amount' ? -1 : 1;
          }
      });
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['open']) {
      if (this.defaultType === 'promotion') {
        this.selected = this.defaultRef
      }
      if (this.defaultType) {
        this.value = this.defaultValue
        this.note = this.defaultNote
        this.unit = this.defaultUnit
        this.ref = this.defaultRef
        this.type = this.defaultType
      } else {
        this.value = '';
        this.note = '';
        this.unit = 'amount';
        this.ref = '';
        this.type = 'promotion';
        this.selected = '';
      }
    }
  }

  close() {
    this.callBackAddDiscount.emit({ confirm: false, value: null })
  }

  apply() {
    const name = `${this.type === 'promotion' ? 'Promotion ส่วนลด' : 'กรอกส่วนลด'} ${this.value} ${this.unit === 'percent' ? '%' : 'บาท'}`;
    this.ref = this.selected;
    this.callBackAddDiscount.emit({ confirm: true, type: this.type, value: this.value, note: this.note, name: name, unit: this.unit, ref: this.ref })
  }

  selectedNumber(number: string) {
    console.log(number)
    if (number !== 'delete') {
      this.value += number
    } else if (number === 'delete') {
      this.value = this.value.slice(0, this.value.length - 1);
    }
  }

  selectedDiscount(list: any) {
    console.log(list)
    this.selected = list.promotion_id;
    this.value = list.value
    this.unit = list.unit;
  }

  changeState(value: string) {
    this.type = value;
    this.selected = '';
    this.value = '';
  }

  changeUnit(value: string) {
    this.unit = value;
  }

  removeDiscount() {
    this.selected = '';
    this.callBackRemoveDiscount.emit({ confirm: true, type: this.defaultType })
  }

}
