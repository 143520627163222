<div class="p-4">
    <div class="pb-4">
        <button class="btn-secondary w-full" (click)="routeToSaleCreate()">
            สร้างคำสั่งซื้อ
        </button>
    </div>
    <div class="pt-4 border-t space-y-1">

        <div class="font-bold">
            รายการคำสั่งซื้อ
        </div>

        <div class="pb-2">
            <div class="rounded-lg bg-white grid grid-cols-2 divide-x">
                <button class="text-center py-1.5 rounded-l-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : salesService.state === 'ongoing'}"
                    (click)="changeState('ongoing')">
                    รอดำเนินการ ({{countOrder['ongoing']}})
                </button>
                <button class="text-center py-1.5 rounded-r-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : salesService.state !== 'ongoing'}"
                    (click)="changeState('success')">
                    สำเร็จ ({{countOrder['success']}})
                </button>
            </div>
        </div>


        @if (isLoaded) {
        @if(orders.length === 0) {
        <div>
            <div class="flex flex-col justify-center items-center w-full pt-10">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
            </div>
            <div class="text-center pt-4">ไม่พบรายการสั่งซื้อในขณะนี้</div>
        </div>
        }
        @for (order of orders; track $index) {
        <div class="border bg-white rounded-lg px-4 py-3 cursor-pointer" routerLink="sale-detail"
            [queryParams]="{no: order.order_no}">
            <div class="h-full flex justify-between">
                <div class="space-y-1">
                    <div>
                        <span class="font-bold text-sm">{{order.order_no}}</span>
                        <span class="text-sm pl-1" [ngClass]="displayStatusColor(order.status)">
                            ({{ displayStatusOrder(order.status) }})
                        </span>
                    </div>
                    <div class="flex flex-wrap gap-x-2 gap-y-1">
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faClock"></fa-icon>
                            {{ dateFormat(order.order_date) }}
                        </div>
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faUser"></fa-icon>
                            {{ getUserDetail(order.created.by)?.name }}
                        </div>
                    </div>
                    <div class="">
                        <span class="text-gray-600 text-sm">รวมสุทธิ ({{order.items.length | number}} ชิ้น) : </span>
                        <span class="text-gray-600 text-sm font-medium">{{ order.total_price | number }}฿</span>
                    </div>
                </div>

                <div class="content-center">
                    <button>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>


            </div>

        </div>
        }

        @if(loadingMore) {
        <div class="flex justify-center pt-10">
            <app-loader></app-loader>
        </div>
        }
        @if(countOrder[salesService.state] > 10 && countOrder[salesService.state] > orders.length){
        <div class="flex items-center justify-center w-full pt-4 space-x-4">
            <button (click)="loadMore()"
                class="btn-light !py-1 w-full lg:w-fit flex justify-between md:justify-center md:space-x-6 xl:space-x-8 items-center">
                <div class="flex space-x-1 justify-start items-center">
                    <div>แสดง</div>
                    <div class="text-primary-500 font-medium">{{orders.length}}</div>
                    <div>/</div>
                    <div>{{countOrder[salesService.state]}}</div>
                </div>
                <div class="flex space-x-2 justify-end items-center font-medium">
                    <div>แสดงเพิ่มเติม</div>
                    <div><fa-icon [icon]="faArrowDown"></fa-icon></div>
                </div>
            </button>
        </div>
        }

        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }

    </div>

</div>