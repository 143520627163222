import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilService } from '../util/util.service';
import { Router } from '@angular/router';

const SYSTEM_ID = environment.system_id
const AUTH_END_POINT = environment.auth_endpoint

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoading = true;
  isLoggedIn = false;
  token = '';
  user: any = {};
  selectedShop: { shop_id: string, name: string, line_id: string, liff_id: string, provider_id: string, type: string } | undefined = undefined;
  shops: { shop_id: string, name: string, line_id: string, liff_id: string, provider_id: string, selected: boolean }[] = []

  roleOptions = [
    { id: 'admin', name: 'Admin' },
    { id: 'manager', name: 'Manager' },
    { id: 'supervisor', name: 'Supervisor' },
    { id: 'sale', name: 'Sale' }
  ]

  constructor(
    private http: HttpClient,
    private util: UtilService,
    private router: Router,
  ) { }

  login(username: string, password: string) {
    return new Promise((resolve, _reject) => {
      this.http.post(`${AUTH_END_POINT}/credential/login`, { username, password }, { params: { system_id: SYSTEM_ID } }).subscribe({
        next: (result: any) => {
          this.token = result.access_token;
          resolve(result)
        }
      })
    })
  }

  logout() {
    this.isLoggedIn = false;
    this.token = '';
    this.user = {};
    this.selectedShop = undefined;
    this.shops = []
    localStorage.removeItem('expired')
    localStorage.removeItem('user')
    localStorage.removeItem('method')
    localStorage.removeItem('token')
    this.router.navigate(['/login'])
  }

  getAccessToken(user_id: string, channel: string, info: any) {
    return new Promise((resolve, _reject) => {
      this.http.post(`${AUTH_END_POINT}/auth/token`, { user_id, channel, info }).subscribe({
        next: (result: any) => {
          resolve(result)
        }
      })
    })
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      }
      return this.http.get(`${AUTH_END_POINT}/user/info`, { headers, params: { system_id: SYSTEM_ID } }).subscribe({
        next: (result: any) => {
          resolve(result)
        },
        error: (err: any) => {
          console.log(err)
          resolve({ success: false })
        }
      }
      );
    })
  }

  isExpired(timestamp: string) {
    if (parseInt(timestamp, 10) < this.util.dayjs().valueOf()) {
      return false;
    } else {
      return true
    }
  }

  getRole() {
    return new Promise((resolve, reject) => {
      this.getUserInfo().then((result: any) => {
        console.log(result)
        if (result.success && result.data) {
          const index = result.data.permissions.findIndex((item: any) => item.selected && item.active);
          if (index > -1) {
            resolve(result.data.permissions[index].role)
          } else {
            resolve(null)
          }
        } else {
          resolve(null)
        }
      })
    })
  }

  updateCredential(body: any) {
    return new Promise((resolve, _reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      }
      this.http.patch(`${AUTH_END_POINT}/credential`, body, { headers }).subscribe({
        next: (result: any) => {
          resolve(result)
        }
      })
    })
  }

  changePassword(body: any) {
    return new Promise((resolve, _reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      }
      this.http.patch(`${AUTH_END_POINT}/credential/change-password`, body, { headers }).subscribe({
        next: (result: any) => {
          resolve(result)
        }
      })
    })
  }

  resetPassword(username: string) {
    return new Promise((resolve, _reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      }
      this.http.patch(`${AUTH_END_POINT}/credential/reset-password/${username}`, {}, { headers }).subscribe({
        next: (result: any) => {
          resolve(result)
        }
      })
    })
  }

  updateRoleSelected(body: any) {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return new Promise((resolve, reject) => {
      return this.http.patch(`${AUTH_END_POINT}/user/update-role`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAccessShopUser() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return new Promise((resolve, reject) => {
      return this.http.get(`${AUTH_END_POINT}/shop-user/access`, { headers, params: { system_id: SYSTEM_ID } }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  selectShop(shopId: string) {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    const body = {
      shop_id: shopId,
      system_id: SYSTEM_ID
    }
    return new Promise((resolve, reject) => {
      return this.http.patch(`${AUTH_END_POINT}/shop-user/select`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
