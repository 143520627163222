import { Component, ElementRef, ViewChild } from '@angular/core';
import { faBarcode, faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { Router } from '@angular/router';
import { ReceiptApiService } from '../../services/api/receipt-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-receipt-scan',
  templateUrl: './receipt-scan.component.html',
  styleUrl: './receipt-scan.component.css'
})
export class ReceiptScanComponent {

  faBarcode = faBarcode
  faChevronDown = faChevronDown
  faChevronUp = faChevronUp
  faChevronLeft = faChevronLeft

  @ViewChild('barcodeInput') barcodeInputElement: ElementRef | undefined;

  products: { sku: string, name: string, serials: string[] }[] = []

  barcodeValue: string = '';

  receipts: any[] = []

  shopId: string = this.authService.selectedShop?.shop_id || '';

  isUpdating: boolean = false;

  soundEffectSuccess: HTMLAudioElement;
  soundEffectWrong: HTMLAudioElement;
  soundEffectScan: HTMLAudioElement;

  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private router: Router,
    private receiptApi: ReceiptApiService,
    private modalService: ModalService,
  ) {
    this.soundEffectSuccess = new Audio('/assets/sounds/success.mp3');
    this.soundEffectWrong = new Audio('/assets/sounds/wrong.mp3');
    this.soundEffectScan = new Audio('/assets/sounds/blink.mp3')
  }

  async ngOnInit(): Promise<any> {
    setTimeout(() => {
      this.barcodeInputElement?.nativeElement.focus();
    }, 10);
  }

  backToReceiptLits() {
    this.router.navigate(['/receipts/receipt-list']);
  }

  confirmReceipt() {
    this.router.navigate(['/receipts/receipt-list']);
  }

  cancelReceipt() {
    this.router.navigate(['/receipts/receipt-list']);
  }

  async scanBarcode() {
    console.log(this.barcodeValue)

    if (this.isUpdating) {
      this.soundEffectWrong.play();
      console.log('Sorry, Creating stock out');
      return;
    }

    this.isUpdating = true;
    const response: any = await this.receiptApi.receiptOneSerial(this.barcodeValue, this.shopId);
    console.log(response)
    if (response.success) {

      this.soundEffectScan.play();
      await this.addPrduuctHistory(this.barcodeValue)
      this.resetBacodeValue();

    } else {

      this.soundEffectWrong.play();
      let message = ''
      let subMessage = ''
      if (response.message === 'serial already exits') {
        message = 'บาร์โค้ดถูกยิงรับเข้าแล้ว'
      } else if (response.message === 'serial not found in receipt') {
        message = 'ไม่พบบาร์โค้ดสินค้านี้'
        subMessage = 'โปรดตรวจสอบบาร์โค้ดสินค้าหรือนำข้อมูลคำสั่งซื้อเข้าสู่ระบบก่อน'
      } else if (response.message === 'serial not found in this shop') {
        message = 'บาร์โค้ดสินค้าไม่ได้อยู่ shop นี้'
      } else if (response.message === 'serial waiting confirm stock out') {
        message = 'สินค้านี้อยู่ในขั้นตอนการคืนสำนักงานใหญ่ กรุณาต่อเจ้าหน้าที่เพื่อดำเนินการต่อ'
      } else {
        message = 'เกิดข้อผิดพลาด ลองใหม่อีกครั้ง'
      }
      this.modalService.openModal('fail', message, subMessage).then((result) => {
        console.log(result);
        this.resetBacodeValue();
      })
    }
  }

  resetBacodeValue() {
    this.barcodeValue = '';
    this.isUpdating = false;
  }

  async addPrduuctHistory(barcode: string) {
    const existedReceipt = this.receipts.find(receipt => receipt.products.find((receiptProduct: any) => receiptProduct.ordered_serials.includes(barcode)))
    if (existedReceipt) {
      const product = existedReceipt.products.find((product: any) => product.ordered_serials.includes(barcode));
      this.updateProductHistory(barcode, product.sku, product.name)
    } else {
      const receipt: any = await this.receiptApi.getOneReceiptBySerialNo(barcode)
      if (receipt.success && receipt.data) {
        this.receipts.push(receipt.data)
        const product = receipt.data.products.find((product: any) => product.ordered_serials.includes(barcode))
        this.updateProductHistory(barcode, product.sku, product.name)
      }
    }
  }

  updateProductHistory(serialNo: string, sku: string, name: string) {
    const product = this.products.find(product => product.sku === sku)
    if (product) {
      const currentProduct = {
        ...product,
        serials: [
          ...product.serials,
          serialNo
        ]
      }
      const otherProduct = this.products.filter(product => product.sku !== sku);
      this.products = [
        currentProduct,
        ...otherProduct
      ]
    } else {
      this.products = [
        {
          sku,
          name,
          serials: [serialNo]
        },
        ...this.products
      ]
    }
  }

}
