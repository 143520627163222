import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }


  getAllProductShop(shop_id: string, filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product-shop/${shop_id}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductShopBySku(shop_id: string, sku: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product-shop/shop-id/${shop_id}/sku/${sku}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllCategory(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/category/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllBrand(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/brand/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllPriceConfigByShop(shopId: string, filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/price-config/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePriceConfigByShop(shopId: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/products/price-config/${shopId}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductBySKu(sku: string, filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product/sku/${sku}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }


  updateAllPriceProductShop(shopId: string, sku: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.put(`${this.endpoint}/products/product-shop/price/${shopId}/${sku}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePriceProductShop(shopId: string, sku: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/products/product-shop/price/${shopId}/${sku}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createPriceConfig(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/products/price-config`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateMinStockProductShop(shopId: string, sku: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/products/product-shop/stock/${shopId}/${sku}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
