import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimService } from '../../services/claim/claim.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { ProvinceService } from '../../../../core/services/province/province.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-claim-form',
  templateUrl: './claim-form.component.html',
  styleUrl: './claim-form.component.css'
})
export class ClaimFormComponent implements OnInit {
  state = 'shop'

  claimForm: FormGroup;
  isSubmit = false

  shopId: string = this.authService.selectedShop?.shop_id || '';
  serialNumber: any;
  product: any = {};

  isLoading = true;

  provinces: any = {}
  provinceSelections: string[] = []
  districtSelections: string[] = []
  subdistrictSelections: string[] = []

  shopDetail: any;

  processing = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private utilService: UtilService,
    private provinceService: ProvinceService,
    private modalService: ModalService,
    private authService: AuthService
  ) {
    this.claimForm = this.fb.group({
      description: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      nickname: new FormControl('', [Validators.required]),
      tel: new FormControl('', [Validators.required]),
      email: new FormControl(''),
      customerLineId: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      province: new FormControl('', [Validators.required]),
      district: new FormControl('', [Validators.required]),
      subDistrict: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.queryParamMap.subscribe(async params => {
      this.serialNumber = params.get('sn') || '';
      this.getShopByShopId();

      console.log(this.serialNumber)
      if (this.serialNumber) {
        this.product = await this.checkWarranty()
        if (this.product.sku) {

          const validateClaimSerial = await this.validateClaimSerial(this.serialNumber);
          if (validateClaimSerial.success) {
            this.loadProvinces();
            this.isLoading = false;
            this.changeState('shop')
          } else {

            let message = 'ไม่สามารถเคลมสินค้านี้ได้';
            console.log(validateClaimSerial.message)
            if (validateClaimSerial.message === 'this serial_no has already been claimed') {
              message = 'Serial นี้ สถานะอยู่ระหว่างการเคลมอยู่แล้ว'
            } else if (validateClaimSerial.message === 'serial number not found') {
              message = 'ไม่พบ Serial Number'
            } else if (validateClaimSerial.message === 'product not found') {
              message = 'ไม่พบข้อมูลสินค้า'
            }

            this.modalService.openModal('fail', message, 'กรุณาลองใหม่อีกครั้ง')
            this.router.navigate(['/claim/claim-create']);
          }
        } else {
          this.modalService.openModal('fail', 'ไม่สามารถเคลมสินค้านี้ได้', 'กรุณาลองใหม่อีกครั้ง')
          this.router.navigate(['/claim/claim-create']);
        }
      } else {
        this.modalService.openModal('fail', 'ไม่พบ Serial Number', 'กรุณาลองใหม่อีกครั้ง')
        this.router.navigate(['/claim/claim-create']);
      }
    });
  }

  async validateClaimSerial(serialNumber: string) {
    return await this.claimService.validateClaimSerial(serialNumber).then((response: any) => {
      return response
    })
  }

  getShopByShopId() {
    this.claimService.getShopByShopId(this.shopId).then((response: any) => {
      if (response.success) {
        this.shopDetail = response.data
      }
    })
  }

  async checkWarranty() {
    return this.claimService.checkProductWarraty(this.serialNumber).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data
      } else {
        return {}
      }
    })
  }

  async loadProvinces(): Promise<void> {
    this.provinceService.getProvincesData().subscribe(response => {
      this.provinces = response
      this.setProvinceSelection()
    })
  }

  onCancel() {
    this.router.navigate(['/claim']);
  }

  getErrorMessage(value: string) {
    switch (this.claimForm.get(value)?.hasError('required')) {
      case value === 'description':
        return 'กรุณากรอกอาการชำรุด'
      case value === 'firstname':
        return 'กรุณากรอกชื่อ'
      case value === 'lastname':
        return 'กรุณากรอกนามสกุล'
      case value === 'nickname':
        return 'กรุณากรอกชื่อผู้ติดต่อ'
      case value === 'tel':
        return 'กรุณากรอกเบอร์โทรศัพท์'
      case value === 'address':
        return 'กรุณากรอกที่อยู่'
      case value === 'province':
        return 'กรุณาเลือกจังหวัด'
      case value === 'district':
        return 'กรุณาเลือกเขต/อำเภอ'
      case value === 'subDistrict':
        return 'กรุณาเลือกแขวง/ตำบล'
      case value === 'zipcode':
        return 'กรุณากรอกรหัสไปรษณีย์'
      default:
        return 'กรุณากรอกข้อมูลให้ครบ'
    }
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD MMM YYYY')
  }

  onProvinceChange() {
    this.districtSelections = []
    this.subdistrictSelections = []
    this.claimForm.get('district')?.reset()
    this.claimForm.get('subDistrict')?.reset()
    this.claimForm.get('zipcode')?.reset()
    this.setDistrictSelection(this.claimForm.get('province')?.value)
  }

  onDistrictChange() {
    this.subdistrictSelections = []
    this.claimForm.get('subDistrict')?.reset()
    this.claimForm.get('zipcode')?.reset()
    this.setSubdistrictSelection(this.claimForm.get('province')?.value, this.claimForm.get('district')?.value)
  }

  onSubdistrictChange() {
    this.setPastalCode(this.claimForm.get('province')?.value, this.claimForm.get('district')?.value, this.claimForm.get('subDistrict')?.value,)
  }

  setProvinceSelection(): void {
    this.provinceSelections = Object.keys(this.provinces);
  }

  setDistrictSelection(province: string): void {
    this.districtSelections = province ? Object.keys(this.provinces[province]) : [];
  }

  setSubdistrictSelection(province: string, district: string): void {
    this.subdistrictSelections = (province && district) ? Object.keys(this.provinces[province][district]) : [];
  }

  setPastalCode(province: string, district: string, subdistrict: string) {
    const zipcode = province && district && subdistrict ? this.provinces[province][district][subdistrict] : ''
    this.claimForm.get('zipcode')?.setValue(`${zipcode}`)
  }

  onSubmit() {
    this.processing = true;
    this.isSubmit = true;
    console.log(this.claimForm.valid)
    if (!this.claimForm.valid) {
      this.processing = false;
      return
    }
    const data = this.claimForm.getRawValue()
    console.log(data);

    const body = {
      serial_no: this.serialNumber,
      shop_id: this.shopId,
      request: {
        ...data,
        serial: this.serialNumber,
        shop: this.shopId,
      },
      is_delivery: this.state === 'shop' ? false : true
    }

    console.log(body)

    this.claimService.createClaimProduct(body).then((response: any) => {
      console.log(response)
      if (response.success) {
        this.modalService.openModal('success', 'บันทึกข้อมูลสำเร็จ', '').then(() => {
          this.router.navigate(['/claim']);
        })
      } else {
        this.modalService.openModal('fail', 'ไม่สามารถบันทึกรายการเคลมได้', 'กรุณาลองใหม่อีกครั้ง')
      }
      this.processing = false;
    })
  }

  changeState(newState: string) {
    this.state = newState
    console.log(this.state)

    if (this.state === 'shop') {
      this.setShopAddressToForm()
    } else {
      this.resetAddress()
    }
  }

  setShopAddressToForm() {
    console.log(this.shopDetail)
    if (this.shopDetail.address) {
      if (!this.shopDetail.address.address || !this.shopDetail.address.province || !this.shopDetail.address.district || !this.shopDetail.address.subdistrict || !this.shopDetail.address.postal_code) {
        this.modalService.openModal('fail', 'กรุณากรอกที่อยู่ร้านค้าก่อนทำการเคลมสินค้า', 'ต้องกรอกข้อมูลที่อยู่ร้านค้าให้ครบ').then((result: any) => {
          console.log(result)
          this.router.navigate(['/merchant/setting/general']);
        })
      }

      if (this.shopDetail.address.address) {
        this.claimForm.get('address')?.setValue(this.shopDetail.address.address)
      }
      if (this.shopDetail.address.province) {
        this.claimForm.get('province')?.setValue(this.shopDetail.address.province)
      }
      if (this.shopDetail.address.district) {
        this.claimForm.get('district')?.setValue(this.shopDetail.address.district)
      }
      if (this.shopDetail.address.subdistrict) {
        this.claimForm.get('subDistrict')?.setValue(this.shopDetail.address.subdistrict)
      }
      if (this.shopDetail.address.postal_code) {
        this.claimForm.get('zipcode')?.setValue(this.shopDetail.address.postal_code)
      }

      console.log(this.claimForm)
    }
  }

  resetAddress() {
    this.claimForm.get('address')?.reset()
    this.claimForm.get('subDistrict')?.reset()
    this.claimForm.get('district')?.reset()
    this.claimForm.get('province')?.reset()
    this.claimForm.get('zipcode')?.reset()
  }
}
