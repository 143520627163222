import { Component } from '@angular/core';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrl: './example.component.css'
})
export class ExampleComponent {

  faBarcode = faBarcode;

  countDataSource = 100;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  phoneNumber: string = '';
  errorMessage: string = '';


  constructor(
    private modalService: ModalService,
  ) {}

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      // to do something
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
  }

  exampleModal(type: any) {
    this.modalService.openModal(type, 'Info Title', 'Replace info contents here.').then((result) => {
      console.log(result);
    })
  }

}
