import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../services/util/util.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-location-back',
  templateUrl: './location-back.component.html',
  styleUrl: './location-back.component.css'
})
export class LocationBackComponent implements OnInit {
  faChevronLeft = faChevronLeft;

  currentPath: any = {};

  constructor(
    private router: Router,
    private utilService: UtilService,
    private location: Location,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.updateCurrentRoute();
    console.log(this.router.url.split('?')[0])
    // this.currentRoute(this.router.url.split('?')[0]);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentRoute();
      }
    });
  }

  updateCurrentRoute() {
    // let active = this.route.root;
    // const routeStack = [];

    // while (active.firstChild) {
    //   active = active.firstChild;
    //   const routeSnapshot = active.snapshot;
    //   const path = routeSnapshot.routeConfig ? routeSnapshot.routeConfig.path : '';
    //   const parameters = routeSnapshot.params;

    //   routeStack.push({ path, parameters });
    // }

    // const currentRoute = routeStack;

    // console.log(this.utilService.menuList)
    // console.log(currentRoute[0])

    // console.log(this.router.url)

    
    // if (currentRoute[0]){
    //   this.currentRoute(`/${currentRoute[0].path}`)
    // }

    this.currentPath = {}
    const index = this.utilService.menuList.findIndex(item => item.path === `${this.router.url.split('?')[0]}`);
    if (index > -1) {
      this.currentPath = this.utilService.menuList[index]
    }
  }

  // currentRoute(path: any) {
  //   this.currentPath = {}
  //   const index = this.utilService.menuList.findIndex(item => item.path === `${path}`);
  //   if (index > -1) {
  //     this.currentPath = this.utilService.menuList[index]
  //   }
  // }

  back() {
    this.location.back()
  }
}
