import { AfterViewInit, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UtilService } from '../../services/util/util.service';
import { ShopService } from '../../services/shop/shop.service';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements AfterViewInit {

  form: FormGroup;
  textAlert = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public util: UtilService,
    private shopService: ShopService,
    private notificationService: NotificationService
  ) {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {
    if (this.authService.isLoggedIn) {
      this.router.navigate([''])
    }
  }

  async confirmLogin() {
    this.textAlert = ''
    if (!this.form.valid) return

    const { username, password } = this.form.getRawValue()
    const response: any = await this.authService.login(username, password)

    if (response.success) {

      this.authService.isLoading = true;
      await this.getAccessToken(response.data);
      setTimeout(() => {
        this.authService.isLoading = false;
      }, 300);

    } else {
      this.form.reset()
      if (response.message === 'Username not found') {
        this.textAlert = 'username หรือ password ไม่ถูกต้อง'
      } else if (response.message === 'Incorrect password') {
        this.textAlert = 'username หรือ password ไม่ถูกต้อง'
      } else {
        this.textAlert = 'ไม่สามารถเข้าสู่ระบบได้ในขณะนี้'
      }


    }
  }

  async getAccessToken(user: any) {

    const profile = {
      ...user.info,
      uid: user.username
    }
    const EXPIRED_IN_HOUR = 12

    localStorage.setItem('method', 'credential');
    localStorage.setItem('user', JSON.stringify(profile));
    localStorage.setItem('expired', this.util.dayjs().add(EXPIRED_IN_HOUR, 'hour').valueOf().toString());

    const result: any = await this.authService.getAccessToken(user.username, 'credential', user.info)
    if (result.access_token) {
      this.authService.token = result.access_token;
      localStorage.setItem('token', result.access_token);
      this.authService.user = profile;
      this.authService.isLoggedIn = true
      this.authService.user.role = await this.authService.getRole();
      await this.checkAccessShop()
      await this.loadUserShop();
      await this.notificationService.loadNotification()
    }
  }

  async checkAccessShop() {
    const shops: any = await this.authService.getAccessShopUser()
    this.authService.shops = shops?.data?.sort((a: any, b: any) => a.shop_id - b.shop_id) || []

    if (this.authService.shops.length > 0) {
      const shopIds = this.authService.shops.map(item => item.shop_id);
      const [shopDetails, providerDetails]: any = await Promise.all([
        this.shopService.getAllShop({ shop_id: shopIds.toString() }),
        this.shopService.getAllProvider({})
      ])

      this.authService.shops.forEach(shop => {
        const index = shopDetails.data.findIndex((item: any) => item.shop_id === shop.shop_id)
        shop.name = shopDetails.data[index]?.name || '';
        shop.line_id = shopDetails.data[index]?.line?.line_id || '';
        shop.liff_id = shopDetails.data[index]?.line?.liff_id || '';
        shop.provider_id = shopDetails.data[index]?.provider_id || '';
      })

      const selectedShop = this.authService.shops.find(item => item.selected === true)
      if (selectedShop) {
        const selectedProvider = providerDetails.data.find((e: any) => e.provider_id === selectedShop.provider_id)
        this.authService.selectedShop = {
          shop_id: selectedShop.shop_id,
          name: selectedShop.name,
          line_id: selectedShop.line_id,
          liff_id: selectedShop.liff_id,
          provider_id: selectedShop.provider_id,
          type: selectedProvider.type
        }
      } else {
        const shop = this.authService.shops[0]
        const result: any = await this.authService.selectShop(shop.shop_id)
        const selectedProvider = providerDetails.data.find((e: any) => e.provider_id === shop.provider_id)
        if (result.success) {
          this.authService.selectedShop = {
            shop_id: shop.shop_id,
            name: shop.name,
            line_id: shop.line_id,
            liff_id: shop.liff_id,
            provider_id: shop.provider_id,
            type: selectedProvider.type
          }
        } else {
          this.authService.selectedShop = undefined
        }
      }
      this.router.navigate(['/'])
    } else {
      // forbidden
      console.log('select-shop')
      this.router.navigate(['/forbidden'])
    }
  }

  async loadUserShop() {
    if (this.authService.token) {
      await this.shopService.getActiveUserInfo().then((res: any) => {
        if (res.success) {
          this.shopService.shopUsers = res.data;
        }
      })
    }
  }

}
