import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../../services/inventory/inventory.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.css'
})
export class InventoryComponent implements OnInit {
  faClock = faClock;
  state = 'counted'

  shopId: string = this.authService.selectedShop?.shop_id || '';
  // shopId = '00000999'

  processing = false

  inventory: any;
  inventoryCount: any = {
    counted: 0,
    complete: 0
  }

  isLoaded = false;
  date = this.utilService.dayjs().format('DD-MM-YYYY')

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    private utilService: UtilService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isLoaded = false;
    this.getInventoryHistoryCount()
    const fetchInventory = await this.getInventoryHistory(this.state)
    if (fetchInventory.success) {
      this.inventory = fetchInventory.data
      this.inventoryCount[this.state] = fetchInventory.count
      console.log(this.inventoryCount)
    }
    this.isLoaded = true;
  }

  async getInventoryHistoryCount() {
    if (this.state === 'counted') {
      const count = await this.getInventoryHistory('complete')
      console.log(count)
      this.inventoryCount.complete = count.success? count.count : 0;
    } else if (this.state === 'complete') {
      const count = await this.getInventoryHistory('counted')
      this.inventoryCount.counted = count.success? count.count : 0;
    }
  }

  async getInventoryHistory(status: string) {
    const filter = {
      status: status
    }
    return await this.inventoryService.getInventoryHistory(this.shopId, filter).then((response: any) => {
      return response
    })
  }

  inventoryCounting() {
    this.processing = true;
    this.inventoryService.getInventory(this.shopId).then(async (response: any) => {
      if (response.success) {
        // have inventory data
        this.router.navigate(['/inventory/counting'])
        this.processing = false;
      } else {
        // not found inventory data
        await this.modalService.openModal('confirm', 'ไม่มีข้อมูลการนับสต๊อกค้างอยู่', 'ต้องการสร้างรอบในการนับสต๊อกใหม่หรือไม่').then(async (result: any) => {
          console.log(result)
          if (result) {
            return await this.createInventory()
          } else {
            return false
          }
        })

        this.processing = false;
      }
    })
  }

  getActiveSerialByShop() {
    return this.inventoryService.getActiveSerialByShop(this.shopId).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data
      } else {
        return []
      }
    })
  }

  async getProductShopByshop() {
    const filter = {
      limit: 1,
      offset: 0
    }
    return await this.inventoryService.getProductShopByshop(this.shopId, filter).then((response: any) => {
      return response
    })
  }

  async createInventory() {
    const serials = await this.getActiveSerialByShop()
    console.log(serials)
    if (serials.length > 0) {
      const serial = serials.map((item: any) => item.serial_no)
      const body = {
        serial: serial
      }
      console.log(body)
      return this.callApiCreateInventory(body)
    } else {
      const productShop = await this.getProductShopByshop()
      console.log(productShop)
      if (productShop.success && productShop.count === 0) {
        const body = {
          serial: ["ALL"]
        }
        console.log(body)
        return this.callApiCreateInventory(body)
      } else {
        this.processing = false;
        this.modalService.openModal('fail', 'ไม่พบ serial ที่พร้อมขายในร้านค้าของคุณ', 'กรุณาลองใหม่อีกครั้ง')
        return false
      }
    }
  }

  async callApiCreateInventory(body: any) {
    return await this.inventoryService.createInventory(this.shopId, body).then((response: any) => {
      console.log(response)
      if (response.success) {
        this.router.navigate(['/inventory/counting'])
        return true
      } else {
        this.modalService.openModal('fail', 'ขณะนี้ไม่สามารถสร้างรอบการนับสต๊อกได้', 'กรุณาลองใหม่อีกครั้ง')
        return false
      }
    })
  }

  async changeState(value: string) {
    this.isLoaded = false;
    this.state = value
    this.getInventoryHistoryCount()
    const fetchInventory = await this.getInventoryHistory(this.state)
    if (fetchInventory.success) {
      this.inventory = fetchInventory.data
      this.inventoryCount[this.state] = fetchInventory.count
      console.log(this.inventoryCount)
    }
    this.isLoaded = true;
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }


}
