<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">การชำระเงิน</div>
    </div>
    <div class="card">
        <div *ngIf="loading; else loadedContent" class="flex justify-center py-20">
            <app-loader></app-loader>
        </div>
        <ng-template #loadedContent>
            <form class="divide-y" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                        <div class="font-semibold">การชำระเงิน</div>
                        <div class="text-gray-500 text-sm">รูปแบบการชำระเงินของลูกค้า มีผลในหน้าการขาย
                            จะสามารถเลือกได้เฉพาะที่เปิดใช้งาน</div>
                    </div>
                    <div class="grid grid-cols-1 gap-6 divide-y">
                        <div *ngFor="let payment of salePaymentList; trackBy: trackByIndex"
                            class="grid grid-cols-4 pt-6 rounded-lg">
                            <div class="w-full col-span-3 flex items-center">
                                <div class="flex-none w-16">
                                    <img [src]="payment.img" class="w-[40px] h-[40px]" />
                                </div>
                                <div class="flex-1">
                                    <p>{{payment.name}}</p>
                                    <p class="text-xs text-gray-500">{{payment?.description}}</p>
                                </div>
                            </div>
                            <div class="flex justify-end items-center">
                                <mat-slide-toggle [checked]="isPaymentMethodActive(payment.id)"
                                    (change)="togglePaymentMethod($event, payment.id)"></mat-slide-toggle>
                            </div>
                            @if(payment.id === 'promptpay_qr' && isPaymentMethodActive(payment.id)){
                            <div class="pt-4 col-span-4" formGroupName="promptpay">
                                <div class="bg-gray-100 rounded-lg p-4 space-y-1">
                                    <div class="rounded-lg border divide-y">
                                        <input formControlName="name"
                                            class="w-full h-10 p-2.5 rounded-t-lg outline-none" type="string"
                                            placeholder="ชื่อบัญชีพร้อมเพย์">
                                        <input formControlName="number"
                                            class="w-full h-10 p-2.5 rounded-b-lg outline-none" type="string"
                                            placeholder="หมายเลขพร้อมเพย์ (โทรศัพท์/เลขบัตรประชาชน)">
                                    </div>
                                    <div *ngIf="form.get('promptpay.name')?.hasError('required')"
                                        class="text-xs text-red-500">
                                        กรุณากรอก ชื่อบัญชี</div>
                                    <div *ngIf="form.get('promptpay.number')?.hasError('required')"
                                        class="text-xs text-red-500">
                                        กรุณากรอก หมายเลขพร้อมเพย์</div>
                                    <div *ngIf="form.get('promptpay.number')?.hasError('pattern')"
                                        class="text-xs text-red-500">
                                        หมายเลขพร้อมเพย์ต้องเป็นตัวเลขและมีความยาว 10-13 ตัวอักษรเท่านั้น</div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                        <div class="font-semibold">การคืนเงินลูกค้า</div>
                        <div class="text-gray-500 text-sm">รูปแบบการคืนเงินให้ลูกค้า มีผลในหน้าคืนสินค้า
                            จะสามารถเลือกได้เฉพาะที่เปิดใช้งาน</div>
                    </div>
                    <div class="grid grid-cols-1 gap-6 divide-y">
                        <div *ngFor="let payment of refundPaymentList; trackBy: trackByIndex"
                            class="grid grid-cols-4 pt-6 rounded-lg">
                            <div class="w-full col-span-3 flex items-center">
                                <div class="w-16">
                                    <img [src]="payment.img" class="w-[40px] h-[40px]" />
                                </div>
                                <div>
                                    {{payment.name}}
                                </div>
                            </div>
                            <div class="flex justify-end items-center">
                                <mat-slide-toggle [checked]="isRefundPaymentMethodActive(payment.id)"
                                    (change)="toggleRefundPaymentMethod($event, payment.id)"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="formChanged" class="flex justify-end">
                    <div class="grid grid-cols-2 gap-2 pt-4 w-full lg:w-1/2">
                        <button type="button" class="btn-secondary" (click)="onCancel()">ยกเลิก</button>
                        <button type="submit" class="btn-primary">บันทึก</button>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
</div>