<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                รายละเอียด Provider
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            @if(provider){
            <div class="space-y-5">
                <div>
                    <div class="text-gray-500">
                        ชื่อ Provider
                    </div>
                    <div>
                        {{provider.name}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        Provider ID
                    </div>
                    <div>
                        {{provider.provider_id}}
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        ประเภท
                    </div>
                    <div class="">
                        @if(provider.type === 'INHOUSE'){
                        <div class="w-fit px-2 py-0.5 rounded bg-yellow-500">#{{provider.type}}</div>
                        }@else {
                        <div class="w-fit px-2 py-0.5 rounded bg-gray-200">#{{provider.type}}</div>
                        }
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        ร้านค้าที่อยู่ใน Provider
                    </div>
                    <div>
                        <ul class="list-disc pl-5">
                            @for (item of provider.shops; track $index) {
                            <li class="text-sm">{{item.name}}</li>
                            }
                        </ul>
                        @if(provider.shops.length===0){
                        <div class="">- ไม่พบร้านค้า -</div>
                        }
                    </div>
                </div>

                <div class="h-20"></div>
            </div>
            }
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <button class="btn-secondary w-full" (click)="close()">ปิด</button>
        </div>
    </div>
</div>