import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faChevronLeft, faTags, faPercent } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-promotion-detail-dialog',
  templateUrl: './promotion-detail-dialog.component.html',
  styleUrl: './promotion-detail-dialog.component.css'
})
export class PromotionDetailDialogComponent {
  @Input('open')
  open: boolean = false;
  @Input('promotion')
  promotion: any;
  @Input('shops')
  shops: any;
  @Output('callBackPromotionDetail')
  callBackPromotionDetail: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;
  faTags = faTags;
  faPercent = faPercent;

  constructor() { }

  close() {
    this.callBackPromotionDetail.emit({ edit: false })
  }

  edit() {
    this.open = false
    setTimeout(() => {
      this.callBackPromotionDetail.emit({ edit: true })
    }, 500);
  }

  shopName(shopId: string) {
    const shop = this.shops.find((item: any) => item.shop_id === shopId)
    return shop ? shop.name : 'shop ID: '+shopId
  }
}
