<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">ตั้งค่าร้านค้า </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div class="card  flex items-center hover:cursor-pointer" routerLink="setting/general">
            <div class="w-16">
                <img src="../../../../../assets/images/shop-info.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">ข้อมูลทั่วไป</div>
                <div class="text-sm text-gray-600">ชื่อ เบอร์ติดต่อ ที่อยู่ พิกัด</div>
            </div>
        </div>
        <div class="card flex items-center hover:cursor-pointer" routerLink="setting/boardcast">
            <div class="w-16">
                <img src="../../../../../assets/images/boardcast.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">บรอดคาสต์</div>
                <div class="text-sm text-gray-600">ข้อมูลสำหรับการบรอดคาสต์ ใบเสร็จ ใบเคลมสินค้า</div>
            </div>
        </div>
        <div class="card flex items-center hover:cursor-pointer" routerLink="setting/printer">
            <div class="w-16">
                <img src="../../../../../assets/images/printer.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">เครื่องปริ้น</div>
                <div class="text-sm text-gray-600">ตั้งค่าการพิมพ์ใบเสร็จ</div>
            </div>
        </div>
        <div class="card flex items-center hover:cursor-pointer" routerLink="setting/payment">
            <div class="w-16">
                <img src="../../../../../assets/images/payment-method.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">การชำระเงิน</div>
                <div class="text-sm text-gray-600">รูปแบบการชำระเงินและการคืนเงินลูกค้า</div>
            </div>
        </div>
        <div class="card flex items-center hover:cursor-pointer" routerLink="setting/return">
            <div class="w-16">
                <img src="../../../../../assets/images/exchange.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">เปลี่ยน-คืน สินค้า</div>
                <div class="text-sm text-gray-600">ระยะเวลาในการรับปลี่ยน-คืน สินค้า</div>
            </div>
        </div>
        <div class="card flex items-center hover:cursor-pointer" routerLink="setting/stock">
            <div class="w-16">
                <img src="../../../../../assets/images/storage.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">สต๊อกสินค้า</div>
                <div class="text-sm text-gray-600">ค่าตั้งต้นสินค้าขั้นต่ำ</div>
            </div>
        </div>
        <div class="card flex items-center hover:cursor-pointer" routerLink="setting/member">
            <div class="w-16">
                <img src="../../../../../assets/images/membership.png" class="w-[40px] h-[40px]" />
            </div>
            <div>
                <div class="text-base md:text-lg font-bold">ระบบสมาชิก</div>
                <div class="text-sm text-gray-600">ลิงค์ระบบสมาชิก</div>
            </div>
        </div>
    </div>

</div>