import { Injectable } from '@angular/core';
import { MerchantApiService } from '../api/merchant-api.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(
    private merchantApiService: MerchantApiService
  ) { }

  async getOneShop(shopId: string) {
    return this.merchantApiService.getShopByShopId(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  async updateshop(shopId: string, body: any) {
    return this.merchantApiService.updateShop(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  async getShopPaymentConfig() {
    return this.merchantApiService.getShopPaymentConfig().then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  async getOneProvider(providerId: string) {
    return this.merchantApiService.getProvider(providerId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

}
