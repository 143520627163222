import { Injectable } from '@angular/core';
import { SaleApiService } from '../api/sale-api.service';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  state = 'ongoing';  // ongoing, success
  paymentConfig = [];

  constructor(
    private saleApiService: SaleApiService
  ) { }

  getOneOrder(orderNo: string) {
    return this.saleApiService.getOneOrder(orderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  updatePaymentMethod(orderNo: string, body: any) {
    return this.saleApiService.updatePaymentMethod(orderNo, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  generateQrPayment(orderNo: string) {
    return this.saleApiService.createQrPayment(orderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  confirmOrderByClient(orderNo: string) {
    return this.saleApiService.confirmOrder(orderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  confirmOrderPromptay(orderNo: string) {
    return this.saleApiService.confirmOrderPromptay(orderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  checkSatusQrPayment(orderNo: string) {
    return this.saleApiService.checkSatusQrPayment(orderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  updateOrderStatus(orderNo: string) {
    return this.saleApiService.updateOrderStatus(orderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getAllPromotion(filters: any) {
    return this.saleApiService.getAllPromotion(filters).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getPointOrder(shopId: string, orderNo: string) {
    return this.saleApiService.getAllOrder({ shop_id: shopId, order_no: orderNo, fields: 'points' }).then((response: any) => {
      if (response.success) {
        if (response.data.length === 0) return { success: false, message: "order not found" }
        return { success: true, data: response.data[0]?.points || 0 }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

}
