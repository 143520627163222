<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                รายละเอียดโปรโมชั่น
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            @if(promotion){
            <div class="space-y-5">
                <div>
                    <div class="text-gray-500">
                        ชื่อโปรโมชั่น
                    </div>
                    <div>
                        {{promotion.name}}
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        เงื่อนไข
                    </div>
                    <div class=" flex gap-2">
                        <div class="space-x-1">
                            <span>รับส่วนลด</span>
                            <span class="text-lg font-bold">{{promotion.value}}</span>
                            <span>{{promotion.unit === 'amount'? 'บาท' :'%'}}</span>
                        </div>
                        <div class="h-7 content-center">
                            @if (promotion.minimum > 0) {
                            เมื่อซื้อสินค้าครบ <span class="text-lg font-bold">{{promotion.minimum}}</span> บาท
                            }@else {
                            ไม่มีขั้นต่ำ
                            }
                        </div>
                    </div>

                    <div class=" text-gray-500">
                        @if (promotion.maximun) {
                        ลดได้สูงสุด  <span class="text-lg font-bold">{{promotion.maximun}}</span>
                        }
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        ร้านค้าที่สามารถใช้โปรโมชั่นนี้
                    </div>
                    <div>
                        <div class="">
                            @if (promotion.shops.length > 0) {
                            เข้าร่วม {{promotion.shops.length}} ร้านค้า
                            <div class="flex flex-col gap-2 mt-1">
                                @for (shop of promotion.shops; track $index) {
                                <div class="px-4 rounded-full bg-blue-100 w-fit">
                                    {{shopName(shop)}}
                                </div>
                                }
                            </div>
                            }@else {
                            ทุกร้านค้าเข้าร่วม
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        สถานะการใช้งาน
                    </div>
                    <div class="flex items-center gap-1"
                        [ngClass]="{'text-lime-500': promotion.active, 'text-red-500': !promotion.active}">
                        <div class="rounded-full h-3 w-3"
                            [ngClass]="{'bg-lime-500': promotion.active, 'bg-red-500': !promotion.active}"></div>
                        {{promotion.active? 'เปิดใช้งาน': 'ปิดใช้งาน'}}
                    </div>
                </div>
                <div class="h-20"></div>
            </div>
            }
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <button class="btn-secondary w-full" (click)="edit()">แก้ไขโปรโมชั่นนี้</button>
        </div>
    </div>
</div>