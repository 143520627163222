<div class="p-4">
    <div class="pb-4">
        <button class="btn-secondary w-full" (click)="inventoryCounting()">
            <!-- จะเป็นการดึงการนับสต๊อกจาก api พี่เอิบ -->
            นับสต๊อกประจำวันที่ {{date}}
        </button>
    </div>
    <div class="pt-4 border-t space-y-1">
        <div class="flex justify-between">
            <div class="font-bold text-base lg:text-lg">
                ประวัติการนับสต๊อก
            </div>
        </div>
        <div class="pb-2 lg:py-4">
            <div class="rounded-lg bg-white grid grid-cols-2 divide-x">
                <button class="text-center py-1.5 lg:py-2.5 rounded-l-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state === 'counted'}"
                    (click)="changeState('counted')">
                    นับเสร็จแล้ว ({{inventoryCount['counted']| number}})
                </button>
                <button class="text-center py-1.5 lg:py-2.5 rounded-r-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state !== 'counted'}"
                    (click)="changeState('complete')">
                    ชำระแล้ว ({{inventoryCount['complete']| number}})
                </button>
            </div>
        </div>

        @if (isLoaded) {
        @if(inventory.length === 0) {
        <div>
            <div class="flex flex-col justify-center items-center w-full pt-10">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
            </div>
            <div class="text-center pt-4">ไม่พบประวัติการนับสต๊อกในขณะนี้</div>
        </div>
        }@else{

        @for (item of inventory; track $index) {

        <div class="border bg-white rounded-lg px-4 py-3 transition-opacity delay-300 cursor-pointer" routerLink="/inventory/detail" [queryParams]="{id: item.inventory_id}">
            <div class="h-full flex justify-between">
                <div class="space-y-1">
                    <div>
                        <span class="font-bold text-sm">#{{item.inventory_id}}</span>
                    </div>
                    <div class="flex flex-wrap gap-x-2 gap-y-1">
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faClock"></fa-icon>
                            {{ dateFormat(item.inventory_date) }}
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row gap-1 lg:items-center">
                        <div>
                            <span class="text-gray-600 text-sm">สินค้าก่อนนับ
                                <span class="text-blue-500">{{item.counted.length + item.loss.length |
                                    number}}</span>
                                ชิ้น | </span>
                            <span class="text-gray-600 text-sm">นับได้
                                <span class="text-lime-500">{{ item.counted.length | number }}</span>
                                | </span>
                            <span class="text-gray-600 text-sm">ใหม่
                                <span class="text-yellow-500">{{ item.new.length | number }}</span>
                            </span>
                        </div>
                        <div class="hidden lg:block text-gray-600 text-sm">|</div>
                        <div>
                            <span class="text-gray-600 text-sm">หาย <span class="text-red-500">{{ item.loss.length |
                                    number }}</span> | </span>
                            <span class="text-gray-600 text-sm font-medium">ยอดที่ต้องชำระ <span class="text-red-500">{{
                                    item.total_price | number }}</span> ฿</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        }
        }@else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }

    </div>
</div>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}