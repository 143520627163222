import { Component, OnInit } from '@angular/core';
import { faMagnifyingGlass, faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { ProviderApiService } from '../../services/api/provider-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ProviderCreateDialogComponent } from '../../components/provider-create-dialog/provider-create-dialog.component';
import { ModalService } from '../../../../core/services/modal/modal.service';

interface Provider {
  name: string;
  provider_id: string;
  type: string;
  created: any;
}
interface ShopList {
  shop_id: string;
  name: string;
  provider_id: string;
  created: any;
}

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrl: './providers.component.css'
})
export class ProvidersComponent implements OnInit {

  faPlus = faPlus;
  faTags = faTags;
  faMagnifyingGlass = faMagnifyingGlass

  providerList: Provider[] = []
  shopList: ShopList[] = []

  isLoading = false;

  showDetail = false;
  providerDetail: any;

  filteredData: any[] = []
  searchText = ''

  constructor(
    private providerApiService: ProviderApiService,
    private dialog: MatDialog,
    public modalService: ModalService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.providerList = await this.getAllProvider()
    this.shopList = await this.getAllShop()
    this.filteredData = this.providerList
  }

  async getAllProvider(): Promise<any[]> {
    return this.providerApiService.getAllProvider({}).then((response: any) => {
      if (response.success) {
        return response.data.sort((a: any, b: any) => b.created.date - a.created.date)
      } else {
        return []
      }
    })
  }

  async getAllShop(): Promise<any[]> {
    const filter = { fields: 'shop_id,name,provider_id,created' }
    return this.providerApiService.getAllShop(filter).then((response: any) => {
      if (response.success) {
        return response.data.sort((a: any, b: any) => a.shop_id - b.shop_id)
      } else {
        return []
      }
    })
  }

  countShopProvider(providerId: string) {
    return this.shopList.filter(e => e.provider_id === providerId).length;
  }

  clickCreateProvider() {
    const dialogRef = this.dialog.open(ProviderCreateDialogComponent, {
      width: '90%',
      maxWidth: '520px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log(result);
      if (result.confirm) {
        const body = {
          name: result.data.name,
          type: result.data.type,
        }
        this.providerApiService.createProvider(body).then((res: any) => {
          if (res.success) {
            this.providerList.unshift(res.data)
            this.modalService.openModal('success', 'เพิ่ม Provider สำเร็จ', 'คุณสามารถสร้างร้านค้าใน Provider นี้ได้')
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', res.message || 'ไม่สามารถเพิ่ม Provider ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    })
  }

  clickDetailProvider(provider: Provider) {
    this.providerDetail = { ...provider, shops: this.shopList.filter(e => e.provider_id === provider.provider_id) }
    this.showDetail = true;
  }

  callBackProviderDetail = (event: any) => {
    this.showDetail = false;
  }

  search(text: string) {
    this.filteredData = this.providerList.filter(item =>
      item.provider_id === text || item.name.toLowerCase().includes(text.toLowerCase())
    );
  }

}
