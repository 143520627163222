<div class="p-4">
    <div class="pb-4">
        <button class="btn-secondary w-full" (click)="routeToReceiptScan()">
            <span class="pl-4">รับเข้าสินค้า</span>
        </button>
    </div>
    <div class="pt-4 border-t space-y-1">
        <div class="flex justify-between">
            <div class="font-bold">
                รายการสั่งซื้อสินค้า
            </div>
            <button class="text-blue-500" (click)="addReceipt()">
                <fa-icon [icon]="faPlus"></fa-icon>
                <span class="pl-2">นำเข้าคำสั่งซื้อ</span>
            </button>
        </div>
        <div class="pb-2">
            <div class="rounded-lg bg-white grid grid-cols-2 divide-x">
                <button class="text-center py-1.5 rounded-l-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : receiptsService.state === 'ongoing'}"
                    (click)="changeState('ongoing')">
                    ค้างรับ ({{countOrder['ongoing'] | number}})
                </button>
                <button class="text-center py-1.5 rounded-r-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : receiptsService.state === 'success'}"
                    (click)="changeState('success')">
                    รับครบแล้ว ({{countOrder['success'] | number}})
                </button>
            </div>
        </div>
        @if (isLoaded) {
        @if(filteredOrders.length === 0) {
        <div>
            <div class="flex flex-col justify-center items-center w-full pt-10">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
            </div>
            <div class="text-center pt-4">ไม่พบรายการรับเข้าในขณะนี้</div>
        </div>
        }
        @for (order of filteredOrders; track $index) {
        <div class="border bg-white rounded-lg px-4 py-3 transition-opacity delay-300 cursor-pointer"
            (click)="routeToReceiptDetial(order.order_no)">
            <div class="h-full flex justify-between">
                <div class="space-y-1">
                    <div>
                        @if (order.type === 'claim') {
                        <span class="bg-blue-100 px-2 rounded-md mr-2 text-blue-500">
                            <fa-icon [icon]="faShieldHalved" class="text-blue-500 text-sm"></fa-icon>
                            {{order.type}}
                        </span>
                        }
                        <span class="font-bold text-sm">#{{order.order_no}}</span>
                        <span class="text-sm pl-1 font-medium"
                            [ngClass]="{'text-lime-500': order.status === 'complete', 'text-yellow-500' : order.status === 'ongoing' || order.status === 'wait'}">
                            <span *ngIf="order.status === 'complete'">(รับครบแล้ว)</span>
                            <span *ngIf="order.status === 'ongoing' || order.status === 'wait'">
                                (ค้างรับ)</span>
                        </span>
                    </div>
                    <div class="flex flex-wrap gap-x-2 gap-y-1">
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faClock"></fa-icon>
                            {{ order.date }}
                        </div>
                    </div>
                    <div class="">
                        <span class="text-gray-600 text-sm">รวมสุทธิ ({{order.total_ordered | number}} ชิ้น) : </span>
                        <span class="text-gray-600 text-sm font-medium"> {{ order.total | number }}฿</span>
                    </div>
                </div>
                <div class="content-center">
                    <button class="flex items-center">
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        }
        @if(loadingMore) {
        <div class="flex justify-center pt-10">
            <app-loader></app-loader>
        </div>
        }
        @if(filteredOrders.length > 0 && ((countOrder[receiptsService.state] - filteredOrders.length) > 0)){
        <div class="flex items-center justify-center w-full pt-4 space-x-4">
            <button (click)="loadMore()"
                class="btn-light !py-1 w-full lg:w-fit flex justify-between md:justify-center md:space-x-6 xl:space-x-8 items-center">
                <div class="flex space-x-1 justify-start items-center">
                    <div>แสดง</div>
                    <div class="text-primary-500 font-medium">{{filteredOrders.length}}</div>
                    <div>/</div>
                    <div>{{countOrder[receiptsService.state]}}</div>
                </div>
                <div class="flex space-x-2 justify-end items-center font-medium">
                    <div>แสดงเพิ่มเติม</div>
                    <div><fa-icon [icon]="faArrowDown"></fa-icon></div>
                </div>
            </button>
        </div>
        }
        @if(filteredOrders.length > 0 && (countOrder[receiptsService.state] === filteredOrders.length) &&
        (filteredOrders.length >
        limit)){
        <div class="flex items-center justify-center w-full pt-4 space-x-4">
            <div class="h-0.5 w-1/6 bg-gray-200"></div>
            <div class="text-sm md:text-base text-gray-500">สิ้นสุดรายการสั่งซื้อ</div>
            <div class="h-0.5 w-1/6 bg-gray-200"></div>
        </div>
        }
        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }
    </div>
</div>