import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {

  state = 'ongoing';  // ongoing, success

  constructor(
    private http: HttpClient
  ) { }

  async loadBankData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any[]>('assets/data/BankTH.json').subscribe(data => {
        resolve(data)
      });
    });
  }

}
