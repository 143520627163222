<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        เพิ่มโปรโมชั่น
    </div>
    <div class="flex flex-col gap-4 w-full md:w-80 text-sm md:text-base">
        <div>
            <label class="text-sm">ชื่อโปรโมชั่น*</label>
            <input type="text" class="input" placeholder="ระบุชื่อ" autocomplete="off" [(ngModel)]="data.formCreatePromotion.name" #nameInput required>
            @if (errorAlert['name']) {
            <span class="text-xs md:text-sm text-red-500">*{{errorAlert['name']}}</span>
            }
        </div>
        <div>
            <div class="flex items-end justify-between">
                <label class="text-sm">มูลค่า*</label>
                <div class="grid grid-cols-2 mb-2">
                    <button class="text-center py-1 px-2 rounded-l-lg text-gray-600 border border-gray-200"
                        [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : data.formCreatePromotion.unit === 'amount'}"
                        (click)="changeUnit('amount')">
                        บาท
                    </button>
                    <button class="text-center py-1 px-2 rounded-r-lg text-gray-600 border border-gray-200"
                        [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : data.formCreatePromotion.unit === 'percent'}"
                        (click)="changeUnit('percent')">
                        เปอร์เซ็นต์
                    </button>
                </div>
            </div>
            <input type="number" class="input" [placeholder]="data.formCreatePromotion.unit === 'amount'? '฿': '%'"
                autocomplete="off" [(ngModel)]="data.formCreatePromotion.value" #valueInput required>
                @if (errorAlert['value']) {
                    <span class="text-xs md:text-sm text-red-500">*{{errorAlert['value']}}</span>
                    }
        </div>
        <div>
            <label class="text-sm">ยอดสั่งซื้อขั้นต่ำ*</label>
            <input type="number" class="input" placeholder="ขั้นต่ำ" autocomplete="off" #minimumInput
            [(ngModel)]="data.formCreatePromotion.minimum" required>
                @if (errorAlert['minimum']) {
                    <span class="text-xs md:text-sm text-red-500">*{{errorAlert['minimum']}}</span>
                    }
        </div>
        <div>
            <label class="text-sm">ส่วนลดสูงสุด</label>
            <input type="number" class="input" placeholder="ลดสูงสุด" autocomplete="off" #maximumInput
            [(ngModel)]="data.formCreatePromotion.maximum">
        </div>
        <div class="w-full">
            <label class="text-sm">ร้านค้าที่เข้าร่วม*</label>
            <mat-form-field class="w-full">
                <mat-select (selectionChange)="formCreateString('shops', $event.value)" [value]='toggleAll? 0: data.formCreatePromotion.shops' multiple>
                    <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">ทั้งหมด</mat-option>
                    @for (shop of data.shopList; track $index) {
                    <mat-option [disabled]="allSelected.selected" [value]="shop.shop_id">
                        {{shop.name}}
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex items-center space-x-2">
            <app-toggle [id]="'toggle-create-active'" [callbackToggle]="callbackActiveCreate"
                [defaultValue]="this.data.formCreatePromotion.active"></app-toggle>
            <div class="whitespace-nowrap">การใช้งาน</div>
        </div>
    </div>
    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]"
                (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button mat-button class="btn-primary min-w-[8rem]" (click)="confirm()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>