<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">สต๊อกสินค้า</div>
    </div>
    <div class="card">
        @if (loading) {
        <div class="flex justify-center py-20">
            <app-loader></app-loader>
        </div>
        } @else {
        <form class="divide-y" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">จำนวนสินค้าขั้นต่ำ (ชิ้น)</div>
                    <div class="text-gray-500 text-sm">เตือนสินค้าใกล้หมดเมื่อถึงจำนวนขั้นต่ำที่กำหนด</div>
                </div>
                <input type="number" id="minStock" class="input" placeholder="จำนวนวัน" autocomplete="off"
                    formControlName="minStock">
            </div>
            <div *ngIf="formChanged" class="flex justify-end">
                <div class="grid grid-cols-2 gap-2 pt-4 w-full lg:w-1/2">
                    <button type="button" class="btn-secondary" (click)="onCancel()">ยกเลิก</button>
                    <button type="submit" class="btn-primary">บันทึก</button>
                </div>
            </div>
        </form>
        }
    </div>
</div>