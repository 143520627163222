<div class="p-4 space-y-2 w-[80vw] max-w-[300px]">
    <div class="flex justify-center py-2">
        <!-- Modal Icon -->
        <app-loader></app-loader>
    </div>
    <div class="flex justify-center">
        <!-- Title -->
        <div class="font-bold text-center">
            {{ data.title }}
        </div>
    </div>
    <div class="flex justify-center">
        <!-- Content -->
        <div class="text-center">
            {{ data.content }}
        </div>
    </div>
</div>