<div class="flex h-content justify-center items-center">
    <div class="text-center space-y-2 -mt-14">
        <div class="font-[1000] text-[150px] md:text-[250px] text-gray-800 relative flex justify-center">
            4<span class="text-gray-100">0</span>3
            <div class="flex flex-col justify-center items-center z-10 absolute bottom-16 md:bottom-28">
                <div>
                    <img src="assets/images/lock.png" class="w-28 md:w-48">
                </div>
            </div>
        </div>
        <div>
            <h2 class="font-bold text-2xl">
                Forbidden
            </h2>
            <div class="text-gray-600">
                You don't have permission to access this page.
            </div>
        </div>
    </div>
</div>