import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-provider-detail-dialog',
  templateUrl: './provider-detail-dialog.component.html',
  styleUrl: './provider-detail-dialog.component.css'
})
export class ProviderDetailDialogComponent {

  @Input('open')
  open: boolean = false;
  @Input('provider')
  provider: any;
  @Output('callBackProviderDetail')
  callBackProviderDetail: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;

  constructor() { }

  close() {
    this.callBackProviderDetail.emit({ confirm: false })
  }

}
