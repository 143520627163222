<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronDown" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                {{state === 'select-type' ? 'วิธีการรับคืน' : 'ข้อมูลเพื่อการรับเงินคืน'}}
            </div>
        </div>

        @if(state === 'select-type') {
        <div class="flex flex-col justify-between p-4 pt-16 h-full">
            <div class="space-y-4">
                <div class="flex flex-col gap-2 items-center justify-center w-full">
                    <div>
                        ยอดคืนสินค้าเดิม
                    </div>
                    <div class="text-3xl">
                        ฿{{total | number}}
                    </div>
                </div>
                <div class="flex justify-center w-full">
                    <div class="grid grid-cols-2 gap-6 w-4/5">
                        <button
                            class="flex flex-col justify-center items-center gap-4 bg-gray-200 rounded-lg w-full aspect-square active:bg-gray-300 transform transition-all duration-100"
                            [ngClass]="{'bg-yellow-500': selected && selected === 'return'}"
                            (click)="selectedType('return')">
                            <div>
                                <img [src]="iconReturn" class="w-[50px] h-[50px]" />
                            </div>
                            <div class="">คืนสินค้า</div>
                        </button>
                        <button
                            class="flex flex-col justify-center items-center gap-4 bg-gray-200 rounded-lg w-full aspect-square active:bg-gray-300 transform transition-all duration-100"
                            [ngClass]="{'bg-yellow-500': selected && selected === 'exchange'}"
                            (click)="selectedType('exchange')">
                            <div>
                                <img [src]="iconExchange" class="w-[50px] h-[50px]" />
                            </div>
                            <div class="">เปลี่ยนสินค้า</div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="w-full grid grid-cols-2 gap-2 pt-2">
                <button class="btn-secondary" (click)="close()">
                    ยกเลิก
                </button>
                <button class="btn-primary" (click)="confirmType()">
                    ยืนยัน
                </button>
            </div>
        </div>
        }
        @else {
        <form [formGroup]="refundForm" (ngSubmit)="onSubmit()" class="flex flex-col justify-between p-4 pt-16 h-full">
            <div class="space-y-4">
                <div class="pb-2">
                    <div class="grid grid-cols-{{paymentMethods.length}} border border-gray-200 rounded-lg divide-x">
                        @for(item of paymentMethods; track $index){
                        <button type="button" class="text-center py-1.5 text-gray-600"
                            [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : type === item.value, 'rounded-l-lg' : $index==0, 'rounded-r-lg' : $index==paymentMethods.length-1}"
                            (click)="changeType(item.value)">
                            {{item.name}}
                        </button>
                        }
                    </div>
                </div>
                @if(type==='bank_transfer'){
                <div class="gap-2">
                    <label class="text-sm">ธนาคาร</label>
                    <mat-form-field class="w-full">
                        <mat-select class="" placeholder="เลือกธนาคาร" formControlName="bank">
                            @for (bank of banks; track $index) {
                            <mat-option [value]="bank.value">
                                <div class="flex flex-row flex-nowrap items-center space-x-2">
                                    <img [src]="bank.icon" class="w-6 h-6 rounded-full object-cover">
                                    <div>{{bank.name}}</div>
                                </div>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submitted && f['bank']?.errors?.['required']" class="text-xs text-red-500">
                        กรุณาเลือก ธนาคาร</div>
                </div>
                <div class="gap-2">
                    <label class="text-sm">ชื่อบัญชี</label>
                    <input type="text" class="input" placeholder="ชื่อบัญชี" formControlName="acc_name"
                        autocomplete="off">
                    <div *ngIf="submitted &&  f['acc_name']?.errors?.['required']" class="text-xs text-red-500">
                        กรุณากรอก ชื่อบัญชี</div>
                </div>
                <div class="gap-2">
                    <label class="text-sm">เลขที่บัญชี</label>
                    <input type="tel" class="input" placeholder="เลขบัญชี" formControlName="acc_number"
                        autocomplete="off">
                    <div *ngIf="submitted && f['acc_number']?.errors?.['required']" class="text-xs text-red-500">
                        กรุณากรอก เลขที่บัญชี</div>
                    <div *ngIf="submitted && f['acc_number']?.errors?.['pattern']" class="text-xs text-red-500">
                        เลขที่บัญชีต้องเป็นตัวเลขและมีความยาว 10-15 ตัวอักษรเท่านั้น</div>
                </div>
                }
                <div class="gap-2">
                    <label class="text-sm">โทรศัพท์</label>
                    <input type="tel" class="input" placeholder="โทรศัพท์" formControlName="phone_number"
                        autocomplete="off" [minLength]="10" [maxLength]="10">
                    <div *ngIf="submitted && f['phone_number']?.errors?.['required']" class="text-xs text-red-500">
                        กรุณากรอก โทรศัพท์</div>
                    <div *ngIf="submitted && f['phone_number']?.errors?.['pattern']" class="text-xs text-red-500">
                        หมายเลขโทรศัพท์ต้องเป็นตัวเลขและมีความยาว 10 ตัวอักษรเท่านั้น</div>
                </div>
                <div class="gap-2">
                    <label class="text-sm">หมายเหตุ <span class="text-xs font-normal text-gray-500">(ถ้าไม่มีให้ใส่
                            -)</span></label>
                    <input type="text" class="input" placeholder="หมายเหตุ" formControlName="note" autocomplete="off">
                    <div *ngIf="submitted && f['note']?.errors?.['required']" class="text-xs text-red-500">
                        กรุณากรอก หมายเหตุ</div>
                </div>
            </div>
            <div class="w-full grid grid-cols-2 gap-2 pt-2">
                <button class="btn-secondary" (click)="back()">
                    ย้อนกลับ
                </button>
                <button class="btn-primary" type="submit">
                    ยืนยัน
                </button>
            </div>
        </form>
        }

    </div>
</div>