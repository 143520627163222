import { Injectable } from '@angular/core';
import { ProductApiService } from '../api/product-api.service';
import FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';

interface Price {
  name: string;
  price: number;
}
interface ProductExport {
  sku: string;
  name: string;
  category: string;
  qty: number;
  cost: number;
  prices: Price[];
}
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private productApiService: ProductApiService
  ) { }

  updatePriceProductShop(shopId: string, sku: string, body: any) {
    return this.productApiService.updatePriceProductShop(shopId, sku, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  createPriceConfig(body: any) {
    return this.productApiService.createPriceConfig(body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getAllPriceConfigByShop(shopId: string, filters: any) {
    return this.productApiService.getAllPriceConfigByShop(shopId, filters).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  updatePriceConfigByShop(shopId: string, body: any) {
    return this.productApiService.updatePriceConfigByShop(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  async generateProductExcel(data: ProductExport[], fileName: string) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('product', { properties: { tabColor: { argb: 'FF00FF00' } } });

    const columnPrice = data[0].prices.map((e, index) => ({ header: e.name, key: `price_${index}`, width: 10 }))
    worksheet.columns = [
      { header: 'SKU', key: 'sku', width: 15 },
      { header: 'name', key: 'name', width: 50 },
      { header: 'หมวดหมู่', key: 'category', width: 20 },
      { header: 'จำนวน', key: 'qty', width: 10 },
      { header: 'ต้นทุน', key: 'cost', width: 10 },
      ...columnPrice
    ];

    data.forEach((row) => {
      const rowData: any = {
        sku: row.sku,
        name: row.name,
        category: row.category,
        qty: row.qty,
        cost: row.cost
      };

      row.prices.forEach((price, index) => {
        rowData[`price_${index}`] = price.price;
      });

      worksheet.addRow(rowData);
    });

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.font = {
          name: 'Angsana New',  // ใช้ฟอนต์ Angsana New
          size: 14              // ขนาดฟอนต์ 14
        };
      });
    });

    const pastelColors = ['FFB3E5FC', 'FFFFF9C4', 'FFC8E6C9', 'FFFFCCBC', 'FFB2DFDB', 'FFD7CCC8'];
    worksheet.getRow(1).eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: pastelColors[index % pastelColors.length] }  // ใช้สีพาสเทลหมุนเวียน
      };
      cell.font = {
        name: 'Angsana New',  // ฟอนต์ Angsana New
        size: 14,             // ขนาดฟอนต์ 14
        bold: true            // ทำตัวหนา
      };
    });

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, `${fileName}.xlsx`);
  }

  private saveExcelFile(buffer: ArrayBuffer, filename: string) {
    const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, filename);
  }


}
