import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SaleListComponent } from './features/sales/pages/sale-list/sale-list.component';
import { SaleDetailComponent } from './features/sales/pages/sale-detail/sale-detail.component';
import { ReceiptListComponent } from './features/receipts/pages/receipt-list/receipt-list.component';
import { ReceiptDetailComponent } from './features/receipts/pages/receipt-detail/receipt-detail.component';
import { ProductListComponent } from './features/products/pages/product-list/product-list.component';
import { ProductDetailComponent } from './features/products/pages/product-detail/product-detail.component';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { SalesComponent } from './features/sales/pages/sales/sales.component';
import { ReportsComponent } from './features/reports/pages/reports/reports.component';
import { MerchantComponent } from './features/merchant/pages/merchant/merchant.component';
import { ForbiddenComponent } from './core/pages/forbidden/forbidden.component';
import { ExampleComponent } from './core/pages/example/example.component';
import { SaleCreateComponent } from './features/sales/pages/sale-create/sale-create.component';
import { ReceiptScanComponent } from './features/receipts/pages/receipt-scan/receipt-scan.component';
import { ReceiptHistoryListComponent } from './features/receipts/pages/receipt-history-list/receipt-history-list.component';
import { ReceiptHistoryDetailComponent } from './features/receipts/pages/receipt-history-detail/receipt-history-detail.component';
import { ClaimComponent } from './features/claim/pages/claim/claim.component';
import { ClaimCreateComponent } from './features/claim/pages/claim-create/claim-create.component';
import { ProductSettingComponent } from './features/products/pages/product-setting/product-setting.component';
import { ExchangeComponent } from './features/exchange/pages/exchange/exchange.component';
import { ExchangeCreateComponent } from './features/exchange/pages/exchange-create/exchange-create.component';
import { MerchantSettingGeneralComponent } from './features/merchant/pages/merchant-setting-general/merchant-setting-general.component';
import { MerchantSettingBoardcastComponent } from './features/merchant/pages/merchant-setting-boardcast/merchant-setting-boardcast.component';
import { MerchantSettingPaymentComponent } from './features/merchant/pages/merchant-setting-payment/merchant-setting-payment.component';
import { PromotionListComponent } from './features/promotion/pages/promotion-list/promotion-list.component';
import { ExchangeDetailComponent } from './features/exchange/pages/exchange-detail/exchange-detail.component';
import { MerchantSettingReturnComponent } from './features/merchant/pages/merchant-setting-return/merchant-setting-return.component';
import { ClaimFormComponent } from './features/claim/pages/claim-form/claim-form.component';
import { LoginComponent } from './core/pages/login/login.component';
import { ProfileComponent } from './core/pages/profile/profile.component';
import { StaffsComponent } from './features/staffs/pages/staffs/staffs.component';
import { AuthGuardService } from './core/services/auth-guard/auth-guard.service';
import { StockOutListComponent } from './features/stock-out/pages/stock-out/stock-out-list.component';
import { StockOutDetailComponent } from './features/stock-out/pages/stock-out-detail/stock-out-detail.component';
import { StockOutCreateComponent } from './features/stock-out/pages/stock-out-create/stock-out-create.component';
import { InventoryComponent } from './features/inventory/pages/inventory/inventory.component';
import { InventoryCountingComponent } from './features/inventory/pages/inventory-counting/inventory-counting.component';
import { InventoryDetailComponent } from './features/inventory/pages/inventory-detail/inventory-detail.component';
import { MerchantSettingStockComponent } from './features/merchant/pages/merchant-setting-stock/merchant-setting-stock.component';
import { CheckSerialComponent } from './features/serials/pages/check-serial/check-serial.component';
import { ProvidersComponent } from './features/providers/pages/providers/providers.component';
import { MerchantManagementComponent } from './features/merchant-management/pages/merchant-management/merchant-management.component';
import { MerchantSettingPrinterComponent } from './features/merchant/pages/merchant-setting-printer/merchant-setting-printer.component';
import { MerchantSettingMemberComponent } from './features/merchant/pages/merchant-setting-member/merchant-setting-member.component';


const routes: Routes = [
  { path: '', redirectTo: 'sales', pathMatch: 'full' },
  { path: 'claim', component: ClaimComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'claim/claim-create', component: ClaimCreateComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'claim/claim-form', component: ClaimFormComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'sales', component: SalesComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'sales/sale-list', component: SaleListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'sales/sale-detail', component: SaleDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'sales/sale-create', component: SaleCreateComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'exchange', component: ExchangeComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'exchange/exchange-detail', component: ExchangeDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'exchange/exchange-create', component: ExchangeCreateComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'receipts/receipt-list', component: ReceiptListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'receipts/receipt-detail', component: ReceiptDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'receipts/receipt-scan', component: ReceiptScanComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'receipts/receipt-history-list', component: ReceiptHistoryListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'receipts/receipt-history-detail', component: ReceiptHistoryDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'products/product-list', component: ProductListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor'] }, canActivate: [AuthGuardService] },
  { path: 'products/product-detail', component: ProductDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor'] }, canActivate: [AuthGuardService] },
  { path: 'products/product-setting', component: ProductSettingComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'reports', component: ReportsComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'merchant', component: MerchantComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/general', component: MerchantSettingGeneralComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/boardcast', component: MerchantSettingBoardcastComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/printer', component: MerchantSettingPrinterComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/payment', component: MerchantSettingPaymentComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/return', component: MerchantSettingReturnComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/stock', component: MerchantSettingStockComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'merchant/setting/member', component: MerchantSettingMemberComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'promotion', component: PromotionListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor'] }, canActivate: [AuthGuardService] },
  { path: 'example', component: ExampleComponent, data: { expectedRole: ['admin'] }, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, data: {}, canActivate: [AuthGuardService] },
  { path: 'staffs', component: StaffsComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'stock-out', component: StockOutListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'], expectedType: ['INHOUSE'] }, canActivate: [AuthGuardService] },
  { path: 'stock-out/stock-out-detail', component: StockOutDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'], expectedType: ['INHOUSE'] }, canActivate: [AuthGuardService] },
  { path: 'stock-out/stock-out-create', component: StockOutCreateComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'], expectedType: ['INHOUSE'] }, canActivate: [AuthGuardService] },
  { path: 'inventory', component: InventoryComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'inventory/counting', component: InventoryCountingComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'inventory/detail', component: InventoryDetailComponent, data: { expectedRole: ['admin', 'manager'] }, canActivate: [AuthGuardService] },
  { path: 'serial/check-serial', component: CheckSerialComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  { path: 'providers', component: ProvidersComponent, data: { expectedRole: ['admin'] }, canActivate: [AuthGuardService] },
  { path: 'merchant-management', component: MerchantManagementComponent, data: { expectedRole: ['admin'] }, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', component: PageNotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
