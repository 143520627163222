import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faExchange, faFileImage, faFolder, faPaperPlane, faPencil, faPrint, faUser } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { SaleApiService } from '../../services/api/sale-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { SalesService } from '../../services/sales/sales.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ShopService } from '../../../../core/services/shop/shop.service';
import { MatDialog } from '@angular/material/dialog';
import { LoaderDialogComponent } from '../../../../core/components/loader-dialog/loader-dialog.component';

@Component({
  selector: 'app-sale-detail',
  templateUrl: './sale-detail.component.html',
  styleUrl: './sale-detail.component.css'
})
export class SaleDetailComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faUser = faUser;
  faPencil = faPencil;
  faFolder = faFolder;
  faFileImage = faFileImage
  faPrint = faPrint;
  faPaperPlane = faPaperPlane;
  faExchange = faExchange;

  orderNo: string = '';

  orderDetail: any = {}
  shopDetail: any = {}

  items: any[] = []

  displayedDiscountNote = ['promotion', 'point']

  orderStatus: any = {
    waiting_for_payment: 'รอชำระเงิน',
    ongoing: 'กำลังดำเนินการ',
    completed: 'สำเร็จ',
    canceled: 'ยกเลิก',
    invalid_amount: 'ยอดชำระเงินไม่ตรง'
  }

  editCouponNote = false;
  discountNote = {
    type: '',
    note: ''
  }

  isLoading = false
  imageDailog = false

  users = this.shopService.shopUsers;
  shopId: string = this.authService.selectedShop?.shop_id || '';


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private saleApiService: SaleApiService,
    private modalService: ModalService,
    private router: Router,
    private salesService: SalesService,
    private shopService: ShopService,
    private dialog: MatDialog,
  ) {
    this.route.queryParams.subscribe((p: any) => {
      this.orderNo = p['no']
      // this.orderNo = '2404254610A3A'
    })
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true
    await this.getOrder()
    await this.getShop()
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  }

  async getOrder() {
    const order: any = await this.saleApiService.getOneOrder(this.orderNo)
    if (order.data) {
      this.orderDetail = order.data
      this.items = order?.data?.items || []
    } else {
      this.router.navigate(['/sales'])
    }
  }

  async getShop() {
    const shop: any = await this.shopService.getShopByShopId(this.shopId)
    if (shop.data) {
      this.shopDetail = shop.data
    }
  }

  displayDate(date: string) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY')
  }

  displayTime(date: string) {
    return this.utilService.dayjs(date).format('HH:mm')
  }


  formatProductDisplay(): any[] {
    let result: any[] = [];
    let result2: any[] = [];
    for (const item of this.items.filter(e => e.is_return)) {
      let index = result.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result.push({
          ...item,
          qty: 1,
          is_return: true
        });
      } else {
        result[index].qty += 1;
      }
    }
    for (const item of this.items.filter(e => !e.is_return)) {
      let index = result2.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result2.push({
          ...item,
          qty: 1
        });
      } else {
        result2[index].qty += 1;
      }
    }
    return [...result, ...result2];
  }

  addNote(discountType: string, defaultNote: string) {
    this.editCouponNote = !this.editCouponNote;
    this.discountNote.type = discountType;
    this.discountNote.note = defaultNote;
  }

  saveNote() {
    this.saleApiService.updateOrderDiscountNote(this.orderNo, this.discountNote.type, { note: this.discountNote.note }).then((response: any) => {
      this.resetFormDiscountNote()
      if (response.success) {
        this.modalService.openModal('success', 'อัพเดตข้อมูลสำเร็จ', '').then(() => { })
        this.getOrder()
      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถอัพเดตข้อมูลได้ขณะนี้ ลองใหม่อีกครั้ง').then(() => { })
      }
    })
  }

  resetFormDiscountNote() {
    this.editCouponNote = false
    this.discountNote = {
      note: '',
      type: ''
    }
  }

  getOrderStatus(status: string) {
    return this.orderStatus[status] || ''
  }

  cancelConfirmation() {
    this.modalService.openModal('confirm-warning', 'ยกเลิกคำสั่งซื้อ', 'ต้องการยกเลิกคำสั่งซื้อ?').then((confirm) => {
      if (confirm) {
        //call api cancel order
        this.saleApiService.cancelOrder(this.orderNo).then((result: any) => {
          if (result.success) {
            this.modalService.openModal('success', 'สำเร็จ', 'ยกเลิกคำสั่งซื้อสำเร็จ').then(() => {
              this.router.navigate(['/sales'])
            })
          } else {
            this.modalService.openModal('fail', 'ไม่สำเร็จ', 'ไม่สามารถยกเลิกคำสั่งซื้อได้');
          }
        })
      }
    })
  }

  confirmPaymentInvalidAmount() {
    this.modalService.openModal('confirm-warning', 'ต้องการยืนยันการชำระ?', 'โปรดตรวจสอบ ลูกค้าต้องจ่ายเงินส่วนต่างแล้วเท่านั้น!').then((result) => {
      console.log(result)
      if (result) {
        this.salesService.updateOrderStatus(this.orderNo).then((result: any) => {
          if (result.success) {
            this.modalService.openModal('success', 'สำเร็จ', 'ยืนยันการชำระเงินสำเร็จ').then(() => {
              this.router.navigate(['/sales'])
            })
          } else {
            this.modalService.openModal('fail', 'ไม่สำเร็จ', 'ไม่สามารถยืนยันการชำระเงินได้');
          }
        })
      }
    })
  }

  resendSlip() {
    if (this.orderDetail?.customer?.user_id) {
      this.modalService.openModal('confirm', 'ส่งใบเสร็จ', 'ต้องการส่งใบเสร็จคำสั่งซื้อใหม่อีกครั้ง?').then((confirm) => {
        if (confirm) {
          this.saleApiService.sentSlipMessage(this.orderNo).then((result: any) => {
            if (result.success) {
              this.modalService.openModal('success', 'ส่งใบเสร็จสำเร็จ', '')
            } else {
              this.modalService.openModal('fail', 'ไม่สำเร็จ', 'ไม่สามารถส่งใบเสร็จได้');
            }
          })
        }
      })
    } else {
      this.modalService.openModal('info', 'ขออภัย', 'ไม่สามารถส่งใบเสร็จได้ เนื่องจากคำสั่งซื้อนี้ไม่ได้ลงทะเบียนสมาชิก');
    }
  }

  getUserDetail(user: string) {
    return this.users.find(item => item.user_id === user)
  }

  reprintSlip() {
    this.modalService.openModal('confirm', 'พิมพ์ใบเสร็จ', 'ต้องการพิมพ์ใบเสร็จคำสั่งซื้อใหม่อีกครั้ง?').then((confirm) => {
      if (confirm) {
        const address = this.shopDetail?.address;
        const printer = this.shopDetail?.printer;
        const addressFormat = (address && address.address) ? (address?.address + ' ' + address?.district + ' ' + address?.subdistrict + ' ' + address?.province + ' ' + address?.postal_code) : '';
        const dataPrint = {
          "title": printer?.title || "บิลเงินสด",
          "shopName": printer?.shop_name || "Remax Thailand",
          "branchName": this.shopDetail.name,
          "address": addressFormat,
          "mobile": this.shopDetail.phone_number[0] ? this.shopDetail.phone_number.join(',') : '',
          "orderDate": this.utilService.dayjs(this.orderDetail.order_date).format('DD/MM/YYYY HH:mm'),
          "paymentMethod": this.orderDetail.payment_method.name,
          "seller": this.getUserDetail(this.orderDetail.created.by)?.name || '-',
          "discount": this.orderDetail.total_discount,
          "message": printer?.message || "ขอบคุณที่มาอุดหนุนค่ะ",
          "product": this.formatProductDisplay().map(e => ({ name: e.name, qty: e.qty, price: e.unit_price }))
        }

        const refDialog = this.dialog.open(LoaderDialogComponent, {
          data: {
            title: 'กำลังสั่งพิมพ์ใบเสร็จ',
            content: 'กำลังดำเนินการ กรุณารอสักครู่'
          },
          disableClose: true
        })
        this.saleApiService.printReceipt(this.shopId, this.orderNo, dataPrint).then((result: any) => {
          refDialog.close()
          if (result.success) {
            this.modalService.openModal('success', 'สั่งพิมพ์ใบเสร็จแล้ว', '')
          } else {
            this.modalService.openModal('fail', 'สั่งพิมพ์ไม่สำเร็จ', 'ไม่สามารถพิมพ์ใบเสร็จได้');
          }
        })
      }
    })
  }

  openImageDialog() {
    this.imageDailog = true;
  }

  callBackShowImage = (event: any) => {
    this.imageDailog = false;
  }

}
