import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faArrowDown, faCheck, faClockRotateLeft, faCodeMerge, faEllipsisVertical, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { StaffsService } from '../../services/staffs/staffs.service';
import { StaffApiService } from '../../services/api/staff-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { AddUserDialogComponent } from '../../components/add-user-dialog/add-user-dialog.component';
import { AuthService } from '../../../../core/services/auth/auth.service';

interface CredentialInfo {
  name: string;
  nickname: string;
  image?: string; // optional property
}

interface Permission {
  active: boolean;
  role: string;
  selected: boolean;
  system_id: string;
}

interface Shop {
  shop_id: string;
  selected: boolean;
}

interface Created {
  by: string;
  date: any;
}

interface DataUser {
  credential_info: CredentialInfo;
  username: string;
  user_id: string;
  permissions: Permission[];
  shops: Shop[];
  role: string;
  created: Created;
}

interface ShopList {
  shop_id: string;
  name: string;
  active: boolean;
  provider_id: string;
}

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrl: './staffs.component.css'
})
export class StaffsComponent implements OnInit {

  faArrowDown = faArrowDown;
  faCheck = faCheck;
  faXmark = faXmark;
  faCodeMerge = faCodeMerge;
  faEllipsisVertical = faEllipsisVertical;
  faPlus = faPlus;

  countDataSource = 0;
  dataSource: DataUser[] = [];
  isLoaded = false;

  shops: ShopList[] = [];

  showDetail = false;
  staffDetail: any;

  userCredential: any[] = [];
  shopUsers: any[] = [];
  userInfo: any[] = [];

  providerId = this.authService.selectedShop?.provider_id

  constructor(
    private utilService: UtilService,
    private staffApiService: StaffApiService,
    private dialog: MatDialog,
    public staffsService: StaffsService,
    public modalService: ModalService,
    private authService: AuthService
  ) {

  }

  async ngOnInit(): Promise<void> {
    const [shops, credential, shopUsers, userInfo]: any = await Promise.all([
      this.getShop(),
      this.getAllCredential(),
      this.getAllShopUser(),
      this.getAllUserInfo()
    ])

    this.shops = shops;
    this.userCredential = credential;
    this.shopUsers = shopUsers;
    this.userInfo = userInfo;
    this.setData();
    this.isLoaded = true;
  }

  getShop() {
    let filter: any = { active: true, fields: 'shop_id,name,active,provider_id' }
    if (this.authService.user.role === 'manager') {
      filter.providers = this.providerId
    }
    return new Promise((resolve, reject) => {
      this.staffApiService.getAllShops(filter).then((result: any) => {
        if (result.success) {
          resolve(result.data.sort((a: any, b: any) => a.shop_id - b.shop_id))
        } else {
          resolve([])
        }
      })
    });
  }

  getAllCredential() {
    return new Promise((resolve, reject) => {
      this.staffApiService.getAllCredential().then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve([])
        }
      })
    });
  }

  getAllShopUser() {
    return new Promise((resolve, reject) => {
      this.staffApiService.getAllShopUser().then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve([])
        }
      })
    })
  }

  getAllUserInfo() {
    return new Promise((resolve, reject) => {
      this.staffApiService.getAllUserInfo().then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve([])
        }
      })
    })
  }

  setData() {
    const data = []
    for (const item of this.userCredential) {
      for (const user of this.userInfo) {
        const indexChanel = user.channels.findIndex((e: any) => e.type === 'credential' && e.uid === item.username)
        if (indexChanel > -1) {
          const shops = this.shopUsers.find((e: any) => e.user_id === user.user_id)?.shops || [];
          const permissions = user.permissions.filter((e: any) => e.active && e.system_id === this.staffsService.system_id)
          const roleSelected = permissions.find((e: any) => e.selected)?.role || '';
          // filter user ที่อยู่ใน Provider
          if (this.authService.user.role === 'admin' || (this.authService.user.role === 'manager' && this.shops.find(e => shops.find((x: any) => x.shop_id === e.shop_id)))) {
            data.push({
              credential_info: item.info,
              username: item.username,
              user_id: user.user_id,
              permissions: permissions,
              shops: shops,
              role: roleSelected,
              created: item.created
            })
          }
        }
      }
    }
    console.log(data);
    this.dataSource = data.sort((a: any, b: any) => this.utilService.dayjs(b.created.date).valueOf() - this.utilService.dayjs(a.created.date).valueOf());
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY')
  }

  checkRoleActive(role: string, permission: Permission[]) {
    if (permission) {
      const index = permission.findIndex(item => item.role === role && item.active && item.system_id === this.staffsService.system_id)
      return index > -1 ? true : false
    } else {
      return false
    }
  }

  checkRoleAccess(role: string) {
    if (role === 'admin') {
      return this.authService.user.role === role;
    }
    if (role === 'manager') {
      return ['admin', 'manager'].includes(this.authService.user.role)
    }
    return true
  }

  checkPermissions(permissions: Permission[]) {
    const uniqueId = permissions.filter(e => e.active).map(e => e.role)
    const result = this.staffsService.roleOptions.filter(e => uniqueId.includes(e.id))
    return result;
  }

  countShopSelect(shops: { shop_id: string, selected: boolean }[]) {
    let count = 0;
    this.shops.forEach(element => {
      if (shops.find(shop => shop.shop_id === element.shop_id)) {
        count += 1;
      }
    });
    return count;
  }

  removeUserRole(role: string, data: DataUser) {
    this.modalService.openModal('confirm', 'ต้องการลบสิทธิ์?', `คุณต้องการลบสิทธิ์ ${role} ของผู้ใช้ ${data.credential_info.name}`).then((result) => {
      if (result) {
        const dataUpdatePermission = {
          "user_id": data.user_id,
          "system_id": this.staffApiService.system_id,
          "role": role,
          "active": false
        }
        this.staffApiService.updatePermission(dataUpdatePermission).then((result: any) => {
          if (result.success) {
            const index = this.userInfo.findIndex((item: any) => item.user_id === data.user_id);
            if (index > -1) {
              this.userInfo[index].permissions = result.data.permissions.filter((e: any) => e.system_id === this.staffsService.system_id && e.active);
              this.setData();
            }
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถลบสิทธิ์ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    })
  }

  addUserRole(role: string, data: DataUser) {
    const dataUpdatePermission = {
      "user_id": data.user_id,
      "system_id": this.staffApiService.system_id,
      "role": role,
      "active": true
    }
    this.staffApiService.updatePermission(dataUpdatePermission).then((result: any) => {
      if (result.success) {
        const index = this.userInfo.findIndex((item: any) => item.user_id === data.user_id);
        if (index > -1) {
          this.userInfo[index].permissions = result.data.permissions.filter((e: any) => e.system_id === this.staffsService.system_id && e.active);
          this.setData();
        }
      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มสิทธิ์ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  selectRoleChange(role: string, data: DataUser) {
    const body = {
      user_id: data.user_id,
      role: role,
      system_id: this.staffsService.system_id
    }
    this.staffApiService.updateRoleMemberSelected(body).then((result: any) => {
      if (result.success) {
        const index = this.userInfo.findIndex((item: any) => item.user_id === data.user_id);
        if (index > -1) {
          this.userInfo[index].permissions = result.data.permissions.filter((e: any) => e.system_id === this.staffsService.system_id && e.active);
          this.setData();
        }
      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มสิทธิ์ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  timeFormat(date: number) {
    return this.utilService.dayjs(date).format('HH:mm:ss')
  }

  openDialogSetting(user: any) {
    this.showDetail = true;
    this.staffDetail = user
  }

  callBackStaffUpdate = (data: any) => {
    if (data.confirm) {
      this.showDetail = false;
      this.updateShopUser(data.shops);
    } else {
      this.showDetail = false;
    }
  }

  updateShopUser(shops: string[]) {
    const dataUpdate = {
      "user_id": this.staffDetail.user_id,
      "system_id": this.staffsService.system_id,
      "shops": shops.map(e => ({ shop_id: e }))
    }

    this.staffApiService.updateShopUser(dataUpdate).then((res: any) => {
      if (res.success) {
        const index = this.shopUsers.findIndex((item: any) => item.user_id === this.staffDetail.user_id);
        if (index > -1) {
          this.shopUsers[index] = res.data;
          this.setData()
        }
      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มสิทธิ์ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  resetPassword(username: string, info: CredentialInfo) {
    this.modalService.openModal('confirm', `ต้องการ reset รหัสผ่าน ของ ${info.name} ?`, 'รหัสผ่านจะถูก reset ให้เป็นค่าเดียวกันกับ username').then((result: any) => {
      if (result) {
        this.staffApiService.resetPassword(username).then((response: any) => {
          if (response.success) {
            this.modalService.openModal('success', 'reset รหัสผ่านสำเร็จ', `รหัสผ่าน ของ ${info.name} ถูก reset แล้ว`)
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถ reset รหัสผ่านได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    });
  }

  addUser() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '80%',
      maxWidth: '480px',
      data: { shopList: this.shops }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log(result);
      if (result?.confirm) {
        // create credential
        const info = {
          "name": result.data.name,
          "nickname": result.data.nickname
        }
        const dataCreateCredential = {
          "username": result.data.username,
          "password": result.data.password,
          "role": result.data.role,
          "info": info,
          "system_id": this.staffsService.system_id
        }

        await this.staffApiService.createCredential(dataCreateCredential).then(async (res: any) => {
          console.log(res);
          if (res.success) {

            /**
             * 1. create user
             * 2. get access token from username credential
             * 3. get user id from token
             * 4. update user permissions
             * 5. create shop user
             */

            // create user
            const dataGetAccessToken = {
              "user_id": result.data.username,
              "channel": "credential",
              "info": info
            }
            const responseAuth: any = await this.staffApiService.getAccessToken(dataGetAccessToken)
            if (responseAuth.access_token) {

              // get user id
              const acessToken = responseAuth.access_token;
              const responseInfo: any = await this.staffApiService.getOneUserInfo(acessToken);
              if (responseInfo.success && responseInfo.data) {
                const userId = responseInfo.data.user_id;

                const dataUpdatePermission = {
                  "user_id": userId,
                  "system_id": this.staffsService.system_id,
                  "role": result.data.role,
                  "active": true
                }
                const dataCreateShopUser = {
                  "user_id": userId,
                  "system_id": this.staffsService.system_id,
                  "shops": result?.data?.shops?.length > 0 ? result.data.shops.map((e: string) => ({ shop_id: e })) : []
                }

                // update role user + create shop user
                const [responseUser, responseShopUser]: any = await Promise.all([
                  this.staffApiService.updatePermissions(dataUpdatePermission),
                  this.staffApiService.createShopUser(dataCreateShopUser)
                ])

                if (responseUser.success && responseShopUser.success) {
                  this.userCredential.push(res.data)
                  this.shopUsers.push(responseShopUser.data)
                  this.userInfo.push(responseUser.data)
                  this.setData()
                  this.modalService.openModal('success', 'เพิ่มผู้ใช้สำเร็จ', '')

                } else {
                  this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มผู้ใช้ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
                }

              } else {
                this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', responseInfo.message || 'ไม่สามารถเพิ่มผู้ใช้ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
              }

            } else {
              this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', responseAuth.message || 'ไม่สามารถเพิ่มผู้ใช้ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
            }

          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', res.message || 'ไม่สามารถเพิ่มผู้ใช้ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
          }
        })

      }

    })
  }


}
