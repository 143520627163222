@if(!isLoading) {
<div class="p-4 pb-32">
    <div class="divide-y bg-white rounded-lg border px-4">
        <div class="flex items-center w-full space-x-4 px-2">
            <img class="w-14" src="assets/images/shopping-cart.png">
            <div class="py-4">
                <div class="font-bold text-lg">
                    #{{orderNo}}
                </div>
                <div class="text-sm text-gray-600">
                    หมายเลขคำสั่งซื้อ
                </div>
            </div>
        </div>
        <div class="grid grid-cols-3 w-full divide-x py-4">
            <div class="text-center w-full pr-2">
                <div class="font-bold">
                    {{displayDate(orderDetail.order_date)}}
                </div>
                <div class="text-sm text-gray-600">
                    วันที่สั่งซื้อ
                </div>
            </div>
            <div class="text-center w-full px-2">
                <div class="font-bold">
                    {{displayTime(orderDetail.order_date)}}
                </div>
                <div class="text-sm text-gray-600">
                    เวลาสั่งซื้อ
                </div>
            </div>
            <div class="flex flex-col justify-center items-center w-full pl-2 text-center">
                <div class="font-bold text-sm min-h-5 bg-gray-300 bg-opacity-70 rounded-md w-fit px-1 content-center">
                    {{orderDetail?.payment_method?.name || 'ยังไม่ระบุ'}}
                </div>
                <div class="flex gap-x-2 flex-wrap items-center justify-center">
                    <div class="text-sm" [ngClass]="{
                        ' text-yellow-500':(orderDetail.status === 'ongoing' || orderDetail.status === 'waiting_for_payment'),
                        ' text-red-500':orderDetail.status=== 'canceled' || orderDetail.status === 'invalid_amount',  
                        'text-lime-500':orderDetail.status === 'completed'
                        }">
                        {{getOrderStatus(orderDetail.status)}}
                    </div>
                    @if (orderDetail.payment) {
                    <div class="text-xs md:text-sm text-gray-600">
                        (ยอดชำระ ฿{{orderDetail.payment.amount}})
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    @if(orderDetail?.payment?.image || orderDetail?.payment?.note){
    <div class="py-4 border-b ">
        <div class="flex gap-4">
            <fa-icon [icon]="faFolder"></fa-icon>
            <div class="font-bold">เอกสารการชำระ</div>
        </div>
        <div class="ml-[30px] flex justify-between items-center gap-4">
            <div class="text-sm text-red-500">หมายเหตุ {{orderDetail?.payment?.note || '-'}}</div>
            @if(orderDetail.payment.image){
            <button class="text-sm rounded-lg px-2 py-1 bg-white border flex" (click)="openImageDialog()">
                <!-- <fa-icon [icon]="faFileImage" class="mr-2"></fa-icon> -->
                <div class="">ดูเอกสารที่แนบ</div>
            </button>
            }
        </div>
    </div>
    }
    @if(orderDetail?.ref){
    <div class="py-4 border-b ">
        <div class="flex gap-4">
            <fa-icon [icon]="faExchange"></fa-icon>
            <div class="font-bold">หมายเลขรับคืน</div>
        </div>
        <div class="ml-[30px]">
            <a routerLink="/exchange/exchange-detail" [queryParams]="{return_no: orderDetail.ref}" class="text-blue-500">#{{orderDetail.ref}}</a>
        </div>
    </div>
    }
    <div class="py-4 border-b ">
        <div class="flex gap-4">
            <fa-icon [icon]="faUser"></fa-icon>
            <div class="font-bold">ลูกค้า</div>
        </div>
        <div class="ml-[30px]">
            @if(orderDetail?.customer) {
            คุณ {{orderDetail?.customer?.name || '-'}}
            }@else {
            -
            }
        </div>
    </div>
    <div class="py-4 space-y-3">
        <div class="font-bold">
            สินค้า
        </div>

        <div class="space-y-2">
            @for (item of formatProductDisplay(); track $index) {
            <div class="grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img/product/' + item.sku + '/1.jpg'"
                            [alt]="item.sku" class="rounded-sm">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-6 xl:col-span-10 text-sm md:text-base line-clamp-3 h-[56px]">
                    {{ item.name }}
                    @if(item.is_return){
                    <div class="w-fit px-1 rounded text-xs bg-yellow-500">เปลี่ยนสินค้า</div>
                    }
                </div>
                <div class="col-span-1 text-right pr-1">
                    <div class="text-sm md:text-base">
                        x {{ item.qty| number }}
                    </div>
                    <div class="font-bold text-sm md:text-base" [ngClass]="{'text-red-500': item.unit_price < 0}">
                        {{ item.unit_price | number }}฿
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    <div class="pb-4 flex justify-end">
        <div class="w-full md:w-2/4 xl:w-1/3">
            <div class="flex justify-between">
                <div>รวม</div>
                <div>{{orderDetail?.price | number}}</div>
            </div>

            @if (orderDetail.discounts) {
            @for (discount of orderDetail.discounts; track $index) {
            <div>
                <div class="flex justify-between">
                    <div>ส่วนลด {{discount.name}}</div>
                    <div class="text-red-500">-{{discount.discount | number}}</div>
                </div>
                @if (displayedDiscountNote.includes(discount.type)) {
                <div class="flex gap-2 text-gray-500 text-sm w-full bg-gray-200 bg-opacity-50 rounded px-2 py-1">
                    @if (editCouponNote && discountNote.type === discount.type) {
                    <div class="w-full pb-1 space-y-2">
                        <div class="relative w-full">
                            <!-- <label class="text-gray-500 text-sm font-normal">หมายเหตุ</label> -->
                            <textarea autofocus class="text-area" id="note" autocomplete="off" placeholder="หมายเหตุ"
                                [(ngModel)]="discountNote.note"></textarea>
                        </div>
                        <div class="flex justify-end gap-4">
                            <button class="btn-secondary !h-fit" (click)="resetFormDiscountNote()">ยกเลิก</button>
                            <button class="btn-primary text-black !h-fit" (click)="saveNote()">บันทึก</button>
                        </div>
                    </div>
                    }@else {
                    @if (discount?.note?.length > 0) {
                    <div class="flex gap-2 text-gray-500 text-sm w-full bg-gray-200 bg-opacity-50 rounded py-1">
                        <div>{{discount.note}}</div>
                        <button class="underline" (click)="addNote(discount.type, discount?.note ||'')"><fa-icon
                                [icon]="faPencil" class="text-sm"></fa-icon></button>
                    </div>
                    } @else {
                    <button (click)="addNote(discount.type,discount?.note ||'')">+ เพิ่มหมายเหตุส่วนลด</button>
                    }
                    }
                </div>
                }
            </div>
            }
            }
        </div>
    </div>
    <div class="flex justify-end">
        <div class="w-full md:w-2/4 xl:w-1/3 py-4 border-dashed border-t border-gray-300">
            <div class="flex justify-between">
                <div class="text-lg font-bold">ยอดรวมทั้งหมด</div>
                <div class="text-2xl font-bold text-blue-600">{{orderDetail.total_price | number}}</div>
            </div>
            @if (orderDetail.status === 'ongoing' || orderDetail.status === 'waiting_for_payment') {
            <div class="mt-8">
                <button class="btn-light text-red-500 w-full" (click)="cancelConfirmation()">ยกเลิกคำสั่งซื้อ</button>
            </div>
            }

            @if(orderDetail?.point) {
            <div class="flex justify-between">
                <div>คะแนนที่ได้รับ</div>
                <div class="text-yellow-500 font-bold">{{orderDetail?.point | number}}</div>
            </div>
            }

            @if (orderDetail.status === 'invalid_amount') {
            <div class="w-full bg-red-100 rounded-md mt-5 p-4">
                <div class="flex justify-between">
                    <div>
                        ยอดที่ต้องจ่าย:
                    </div>
                    <div>
                        {{orderDetail.total_price | number}}
                    </div>
                </div>
                <div class="flex justify-between pb-3">
                    <div>
                        จ่ายจริง:
                    </div>
                    <div>
                        {{orderDetail.payment.amount | number}}
                    </div>
                </div>
                <div class="flex justify-between text-red-600 border-t border-red-600 pt-3">
                    <div>ส่วนต่าง:</div>
                    <div>
                        <span class="text-lg">{{orderDetail.total_price - orderDetail.payment.amount |
                            number}}</span>
                    </div>
                </div>
                <button class="btn-danger w-full mt-4" (click)="confirmPaymentInvalidAmount()">ยืนยันการชำระ</button>
            </div>
            }

            @if (orderDetail.status === 'completed') {
            <div class="mt-8 space-y-2">
                <button class="btn-secondary w-full flex gap-4 justify-center" (click)="resendSlip()">
                    <fa-icon [icon]="faPaperPlane"></fa-icon>
                    <div class="">ส่งใบเสร็จอีกครั้ง</div>
                </button>
                @if(shopDetail.is_printer){
                <button class="btn-light w-full flex gap-4 justify-center" (click)="reprintSlip()">
                    <fa-icon [icon]="faPrint"></fa-icon>
                    <div class="">พิมพ์ใบเสร็จอีกครั้ง</div>
                </button>
                }
            </div>
            }
        </div>
    </div>
</div>
@if (orderDetail?.status !== 'completed' && orderDetail?.status !== 'canceled' && orderDetail?.status !==
'invalid_amount') {
<div
    class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-fit p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">
    <!-- If the status is 'success', do not display. -->
    <button class="btn-primary w-full" routerLink="/sales/sale-create" [queryParams]="{order_no: orderNo}">
        ดำเนินการต่อ
    </button>
</div>
}
}@else {
<div class="flex justify-center pt-40">
    <app-loader></app-loader>
</div>
}

<div class="">
    <app-show-image-dialog [open]="imageDailog" [imageUrl]="orderDetail?.payment?.image"
        (callBackShowImage)="callBackShowImage($event)"></app-show-image-dialog>
</div>