import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-inventory-product-transaction',
  templateUrl: './inventory-product-transaction.component.html',
  styleUrl: './inventory-product-transaction.component.css'
})
export class InventoryProductTransactionComponent {
  @Input('open')
  open: boolean = false;
  @Input('detail')
  detail: any = {};
  @Output('callBackProductTransaction')
  callBackPromotionDetail: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;

  constructor(
  ) { }

  close() {
    this.callBackPromotionDetail.emit({ confirm: false })
  }

  confirmReceipt() {
    this.callBackPromotionDetail.emit({ confirm: true, detail: this.detail })
  }
}
