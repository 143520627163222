import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { SaleApiService } from '../../services/api/sale-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { UtilService } from '../../../../core/services/util/util.service';

@Component({
  selector: 'app-sale-add-redeem-dialog',
  templateUrl: './sale-add-redeem-dialog.component.html',
  styleUrl: './sale-add-redeem-dialog.component.css'
})
export class SaleAddRedeemDialogComponent implements OnDestroy, OnChanges {

  @Input('open') open: boolean = false;
  @Input('defaultUserId') defaultUserId: string = '';
  @Input('defaultPointsBalance') defaultPointsBalance: number = 0;
  @Input('defaultRedeemDiscountRef') defaultRedeemDiscountRef: string = '';
  @Input('defaultTotalPrice') defaultTotalPrice: number = 0;

  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;

  @Output('callBackAddRedeemDiscount') callBackAddRedeemDiscount: EventEmitter<object> = new EventEmitter<object>();
  @Output('callBackRemoveRedeemDiscount') callBackRemoveRedeemDiscount: EventEmitter<object> = new EventEmitter<object>();

  faChevronDown = faChevronDown;
  faChevronLeft = faChevronLeft;

  userId: string = '';
  phoneNumber: string = '';
  pointsBalance: number = 0;
  minPointsBalance: number = 0;
  pointValue: number = 0;
  maxDiscount: number = 0;
  divisor: number = 10;
  usedPoint: number = 0;
  redeemDiscountRef: string = '';
  totalPrice: number = 0;
  canUseDiscount: number = 0;

  redemptionId: string = '';

  state: string = '' // 'no-member', 'send-otp', 'verify-otp', 'select-discount'

  errorMessage: string = '';
  verificationId: string = ''
  otp: string[] = ['', '', '', '', '', '', ''];
  form: FormGroup;
  isOtpSent: boolean = false;
  canResend: boolean = false;
  countdown: number = 30; // Countdown timer in seconds
  countdownInterval?: number;

  selectedRedemption: any = null
  allRedemptiontion: any[] = []
  approvedRedemtions: any[] = []
  selectedRedemptionId = ''

  private recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
  private authApp: firebase.app.App;
  private auth: firebase.auth.Auth;

  isLoading = false

  constructor(
    private fb: FormBuilder,
    private saleApiService: SaleApiService,
    private authService: AuthService,
    private modalService: ModalService,
    private utilService: UtilService
  ) {
    this.authApp = firebase.app('CRM_AUTH');
    this.auth = this.authApp.auth();

    this.form = this.fb.group({
      discount: ['', [Validators.required]],
      points: [{ value: '', disabled: true }, [Validators.required]],
    });

    this.redemptionId = ''

  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['open']) {
      if (!this.open) return

      this.isLoading = true

      this.userId = this.defaultUserId;
      if (this.userId === '') {
        this.isLoading = false
        return
      }

      if (this.userId !== '') {
        const member: any = await this.saleApiService.getCustomerByUserId(this.userId);
        if (!member.success) {
          this.isLoading = false
          return
        }
        if (member?.data?.phone?.length === 0) {
          this.state = 'no-member'
          console.log(this.state)
          this.isLoading = false
          return
        } else {
          this.phoneNumber = member.data.phone
        }
        if (!member?.data?.verify) {
          this.state = 'no-member'
          console.log(this.state)
          this.isLoading = false
          return
        }
      }

      this.totalPrice = this.defaultTotalPrice
      this.pointsBalance = this.defaultPointsBalance;
      this.redeemDiscountRef = this.defaultRedeemDiscountRef;
      this.selectedRedemptionId = this.redeemDiscountRef.length > 0 ? this.redeemDiscountRef : ''
      this.selectedRedemption = null

      if (!this.userId) {
        this.isLoading = false
        return
      }


      if (this.redeemDiscountRef.length > 0) {
        const usedRedemption: any = await this.saleApiService.getRedemptionByRedemptionId(this.redeemDiscountRef)
        this.selectedRedemption = usedRedemption.data
      }

      const approvedRedemptions: any = await this.saleApiService.getAllApprovedRedemptionByUser(this.userId, this.authService.selectedShop?.shop_id || '')
      this.approvedRedemtions = approvedRedemptions?.data || []

      this.allRedemptiontion = this.selectedRedemption ? [this.selectedRedemption, ...this.approvedRedemtions] : this.approvedRedemtions
      this.allRedemptiontion = this.allRedemptiontion.sort((a, b) => a.created.date > b.created.date ? 1 : -1)

      if (this.allRedemptiontion.length > 0) {
        this.state = 'select-discount'
      } else {
        this.state = 'send-otp'
        this.saleApiService.getCalculatePointRedemtion(this.pointsBalance).then(((response: any) => {
          console.log(response)
          if (response.success) {
            this.maxDiscount = response.data.max_discount
            this.minPointsBalance = response.data.min_points
            this.pointValue = response.data.point_value
            this.divisor = response.data.divisor

            this.canUseDiscount = this.maxDiscount < this.totalPrice ? this.maxDiscount : this.totalPrice;
            // Update form validation for discount
            this.form.get('discount')?.setValidators([
              Validators.required,
              Validators.min(1),
              Validators.max(this.canUseDiscount)  // Add max discount validator
            ]);
            this.form.get('discount')?.updateValueAndValidity();
          }
        }))
      }
      this.isLoading = false
    }
  }

  ngOnDestroy() {
    this.clearCountdownInterval();
  }

  clearRecaptcha() {
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear()
      this.recaptchaVerifier = undefined; // Set to undefined to avoid re-use
    }
  }

  changeState(value: string) {
    this.state = value;
  }

  close() {
    this.callBackAddRedeemDiscount.emit({ confirm: false, data: null })
  }

  initializeRecaptchaVerifier() {

    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',  // 'normal' for checkbox
        'callback': (response: any) => {
          // This function is called when the reCAPTCHA is successfully solved
          console.log(response)
          console.log('reCAPTCHA solved');
        },
        'expired-callback': (err: any) => {
          // This function is called when the reCAPTCHA expires
          console.log(err)
          console.log('reCAPTCHA expired');
          this.clearRecaptcha() // Clear the existing reCAPTCHA
          this.initializeRecaptchaVerifier() // Reinitialize the reCAPTCHA
        }
      }, this.authApp); // The Firebase app to associate with this reCAPTCHA verifier
    }

    // Render the reCAPTCHA widget and log the widget ID
    this.recaptchaVerifier.render().then(widgetId => {
      console.log('reCAPTCHA Widget ID:', widgetId);
    }).catch((error) => {
      console.error('Error rendering reCAPTCHA:', error);
    });
  }

  async createRedemption() {

    if (!this.form.valid) return

    const sentOtp = await this.sendOtp(this.phoneNumber)
    if (!sentOtp.success) {
      this.modalService.openModal('fail', 'ขออภัย', sentOtp?.message || '')
      return
    }
    if (!sentOtp.data?.verificationId) return

    const values = this.form.getRawValue()
    this.verificationId = sentOtp.data?.verificationId

    const body = {
      "user_id": this.userId,
      "shop_id": this.authService.selectedShop?.shop_id,
      "verification_id": this.verificationId,
      "points_redeemed": values.points,
    }

    const createRedemption: any = await this.saleApiService.createRedemption(body)
    if (createRedemption.success) {
      this.redemptionId = createRedemption.data.redemption_id
      this.state = 'verify-otp'; // update state
      this.isOtpSent = true; // Mark that OTP has been sent
      this.startResendOtpTimer(); // Start timer for resend OTP button
      this.resetInputOtp()

    } else {
      let message = createRedemption.message
      if (createRedemption.message === 'Insufficient points') message = 'แต้มไม่เพียงพอ'
      this.modalService.openModal('fail', 'ขออภัย', message)
      // alert failed
    }
  }

  async confirmOtp() {
    console.log('Verify OTP')

    const otp = this.otp.join('');
    const verifyResult = await this.verifyOtp(this.verificationId, otp)
    if (!verifyResult.success) {
      if (verifyResult.message === 'The verification code entered is invalid. Please try again') {
        this.resetInputOtp()
      }
      this.errorMessage = verifyResult.message || ''
      // alert failed
      return
    }

    if (this.redemptionId.length > 0) {
      const body = {
        redemption_id: this.redemptionId,
      }
      const verifyResult: any = await this.saleApiService.confirmRedemption(body)
      console.log(verifyResult)

      if (verifyResult.success) {
        const redemption: any = await this.saleApiService.getRedemptionByRedemptionId(this.redemptionId)
        if (redemption.success) {
          this.callBackAddRedeemDiscount.emit({ confirm: true, data: redemption.data })
        } else {
          this.modalService.openModal('fail', 'ขออภัย', redemption.message).then(() => {
            this.callBackAddRedeemDiscount.emit({ confirm: false, data: null })
          })
        }
      } else {
        this.modalService.openModal('fail', 'ขออภัย', verifyResult.message).then(() => {
          this.callBackAddRedeemDiscount.emit({ confirm: false, data: null })
        })
      }
    }
  }

  async sendOtp(phoneNumber: string): Promise<{
    success: boolean,
    data?: { verificationId: string },
    message?: string
  }> {

    const phoneNumberFormated = `+66${phoneNumber.slice(1)}`

    this.initializeRecaptchaVerifier()
    
    try {
      const confirmationResult = await this.auth.signInWithPhoneNumber(phoneNumberFormated, this.recaptchaVerifier!);
      console.log('Verification ID:', confirmationResult.verificationId);
      return { success: true, data: confirmationResult }
    } catch (error: any) {
      // Handle different error cases
      this.clearRecaptcha()
      let errorMessage = ''
      switch (error.code) {
        case 'auth/invalid-phone-number':
          errorMessage = 'The phone number provided is not valid. Please check and try again';
          break;
        case 'auth/missing-phone-number':
          errorMessage = 'Phone number is required. Please provide a valid phone number';
          break;
        case 'auth/quota-exceeded':
          errorMessage = 'Quota exceeded for sending OTP. Please try again later';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many requests. Please wait a moment and try again';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your internet connection and try again';
          break;
        default:
          errorMessage = 'An unexpected error occurred. Please try again later';
      }
      console.error('Error sending OTP:', error);
      return { success: false, message: errorMessage }
    }
  }


  async verifyOtp(verificationId: string, otp: string): Promise<{
    success: boolean,
    message?: string,
    data?: any
  }> {

    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
      const userCredential = await this.auth.signInWithCredential(credential);

      console.log('Logged in:', userCredential.user?.uid);
      return { success: true, data: userCredential.user }

    } catch (error: any) {
      this.recaptchaVerifier?.clear();
      this.initializeRecaptchaVerifier();
      // Handle different error cases
      let errorMessage = ''
      switch (error.code) {
        case 'auth/invalid-verification-code':
          errorMessage = 'The verification code entered is invalid. Please try again';
          break;
        case 'auth/expired-action-code':
          errorMessage = 'The verification code has expired. Please request a new one';
          break;
        case 'auth/invalid-verification-id':
          errorMessage = 'The verification ID is invalid or has expired. Please request a new one';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your connection and try again';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many requests. Please try again later';
          break;
        default:
          errorMessage = 'An unexpected error occurred. Please try again';
      }
      console.error('Error verify OTP:', error);
      return { success: false, message: errorMessage }
    }
  }

  async resendOtp() {
    if (this.canResend) {
      const sentOtpResult = await this.sendOtp(this.phoneNumber);
      if (sentOtpResult.success) {
        // update verifucationId
      } else {
        // err
        this.modalService.openModal('fail', 'ขออภัย', sentOtpResult?.message || '')
      }
    } else {
      this.errorMessage = 'Please wait before requesting a new OTP';
    }
  }

  onKeyUpDiscount(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    console.log(value)
    this.form.get('points')?.setValue((this.form.get('discount')?.value / this.pointValue))
  }

  onOtpInput(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Only allow numeric input
    if (/^\d$/.test(value)) {
      this.otp[index] = value;
      // Focus next input
      if (index < this.otpInputs.length - 1) {
        this.otpInputs.toArray()[index + 1].nativeElement.focus();
      } else {
        input.blur(); // If it's the last input, remove focus
      }
    } else {
      // Clear invalid input
      this.otp[index] = '';
      input.value = '';
    }

    // Combine all otp fields into one string
    const otpCode = this.otp.join('');
    console.log('OTP:', otpCode);
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace' && !this.otp[index] && index > 0) {
      // If backspace is pressed and current input is empty, focus previous input
      this.otpInputs.toArray()[index - 1].nativeElement.focus();
    }
  }

  startResendOtpTimer() {
    this.canResend = false;
    this.countdown = 30; // Reset countdown timer
    this.clearCountdownInterval();
    this.countdownInterval = window.setInterval(() => {
      this.countdown -= 1;
      if (this.countdown === 0) {
        this.canResend = true;
        this.clearCountdownInterval();
      }
    }, 1000); // Decrease countdown every second
  }

  clearCountdownInterval() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = undefined;
    }
  }

  resetInputOtp() {
    this.otp = ['', '', '', '', '', ''];
    this.otpInputs.forEach((input) => {
      input.nativeElement.value = '';
    });
    setTimeout(() => {
      this.otpInputs.toArray()[0].nativeElement.focus();
    }, 100);
  }

  blackToSendOtpState() {
    // this.state = 'send-otp';
    // this.redemptionId = '';
    // this.resetInputOtp()
    // this.isOtpSent = false;
    // this.clearRecaptcha()
  }

  cancel() {
    this.state = 'send-otp';
    this.redemptionId = '';
    this.resetInputOtp()
    this.isOtpSent = false;
    this.clearRecaptcha()
    this.close()
  }

  removeDiscount() {
    console.log('remove discount')
    this.callBackRemoveRedeemDiscount.emit({ confirm: true, type: 'point' })
  }

  applyDiscount() {
    console.log('applyDiscount')
    const selectedRedemption = this.allRedemptiontion.find((item: any) => item.redemption_id === this.selectedRedemptionId)
    console.log(selectedRedemption)
    if (selectedRedemption) {
      this.callBackAddRedeemDiscount.emit({ confirm: true, data: selectedRedemption })
    }
  }

  selecteDiscount(redemption: any) {
    console.log(redemption)
    this.selectedRedemptionId = redemption.redemption_id
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  securePhoneData(phoneNumber: string, visibleDigits = 4) {
    const maskedLength = phoneNumber.length - visibleDigits;
    const maskedPart = '*'.repeat(maskedLength);
    const visiblePart = phoneNumber.slice(-visibleDigits);
    return maskedPart + visiblePart;
  }

  useMaxDiscount(discount: number) {
    this.form.get('discount')?.setValue(discount)
  }


}
