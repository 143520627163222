import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaffsService } from '../../services/staffs/staffs.service';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrl: './add-user-dialog.component.css'
})
export class AddUserDialogComponent {

  faCheck = faCheck;
  faXmark = faXmark;

  submitted = false;
  addUserForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9@\-_.]{1,18}[a-zA-Z0-9]$/)]),
    password: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    nickname: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl('', Validators.required)
  });

  shopSelected: any;
  toggleAll: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<AddUserDialogComponent>,
    public staffsService: StaffsService,
    private authService: AuthService
  ) {

  }

  get f(): { [key: string]: AbstractControl } {
    return this.addUserForm.controls;
  }

  checkRoleActive(role: string) {
    return role === this.addUserForm.value.role;
  }

  removeUserRole(role: string) {
    this.addUserForm.patchValue({ role: '' })
  }

  addUserRole(role: string) {
    this.addUserForm.patchValue({ role })
  }

  formCreateString(value: any) {
    this.shopSelected = value;
  }

  toggleAllSelection() {
    this.toggleAll = !this.toggleAll
    if (this.toggleAll) {
      this.shopSelected = []
    } else {
      delete this.shopSelected
    }
  }

  close() {
    this.ref.close(false);
  }

  onSubmit() {
    this.submitted = true;
    if (this.addUserForm.invalid) {
      return;
    }

    const data = {
      ...this.addUserForm.getRawValue(),
      shops: this.toggleAll ? this.data.shopList.map((e: any) => e.shop_id) : this.shopSelected
    }
    this.ref.close({ confirm: true, data: data });
  }

  checkRoleAccess(role: string) {
    if (role === 'admin') {
      return this.authService.user.role === role;
    }
    if (role === 'manager') {
      return ['admin', 'manager'].includes(this.authService.user.role)
    }
    return true
  }

}
