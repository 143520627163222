import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantApiService {

  endpoint = environment.endpoint;


  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getShopByShopId(shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateShop(shopId: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/shops/${shopId}`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  getShopPaymentConfig() {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/payment-config`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProvider(providerId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/provider/${providerId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
