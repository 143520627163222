import { Component, OnInit } from '@angular/core';
import { ProvinceService } from '../../../../core/services/province/province.service.js';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faScrewdriverWrench, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MerchantService } from '../../services/merchant/merchant.service.js';
import { ModalService } from '../../../../core/services/modal/modal.service.js';
import { AuthService } from '../../../../core/services/auth/auth.service.js';
@Component({
  selector: 'app-merchant-setting-general',
  templateUrl: './merchant-setting-general.component.html',
  styleUrl: './merchant-setting-general.component.css'
})
export class MerchantSettingGeneralComponent implements OnInit {

  faPlus = faPlus
  faTrash = faTrash;
  faScrewdriverWrench = faScrewdriverWrench;

  provinces: any = {}
  provinceSelections: string[] = []
  districtSelections: string[] = []
  subdistrictSelections: string[] = []

  form: FormGroup;
  formChanged = false
  shopDetials: any = {}
  shopId: string = this.authService.selectedShop?.shop_id || '';
  providerId: string = this.authService.selectedShop?.provider_id || '';

  loading = false


  constructor(
    private authService: AuthService,
    private provinceService: ProvinceService,
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private modalService: ModalService
  ) {
    this.form = this.fb.group({
      shopId: [{ value: '', disabled: true }],
      shopName: [{ value: '', disabled: true }],
      providerId: [{ value: '', disabled: true }],
      providerName: [{ value: '', disabled: true }],
      phoneNumbers: this.fb.array([]),
      address: this.fb.group({
        address: [''],
        province: [''],
        district: [''],
        subdistrict: [''],
        postalCode: [''],
      }),
      location: this.fb.group({
        lat: [''],
        long: ['']
      }),
    });
  }

  async ngOnInit(): Promise<void> {
    await this.loadProvinces();
    this.loadShopDetails();
    this.loadProvider()
    this.form.valueChanges.subscribe(() => {
      this.formChanged = true;
    });

  }

  async loadProvinces(): Promise<void> {
    this.provinceService.getProvincesData().subscribe(response => {
      this.provinces = response
    })
  }

  loadProvider() {
    this.merchantService.getOneProvider(this.providerId).then(response => {
      console.log(response);
      if (response.success) {
        this.form.patchValue({
          providerName: response.data.name,
        })
      }
    })
  }

  loadShopDetails() {
    this.loading = true;
    this.merchantService.getOneShop(this.shopId).then(response => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      if (response.success) {
        this.shopDetials = response.data
        const shopDetials = this.shopDetials
        this.form.patchValue({
          shopId: shopDetials.shop_id,
          shopName: shopDetials.name,
          providerId: shopDetials.provider_id,
          address: {
            address: shopDetials?.address?.address || '',
            province: shopDetials?.address?.province || '',
            district: shopDetials?.address?.district || '',
            subdistrict: shopDetials?.address?.subdistrict || '',
            postalCode: shopDetials?.address?.postal_code || '',
          },
          location: {
            lat: shopDetials?.location?.lat || '',
            long: shopDetials?.location?.long || ''
          }
        });
        this.setPhoneNumbers(shopDetials?.phone_number || []);
        this.setProvinceSelection()
        this.setDistrictSelection(shopDetials?.address?.province)
        this.setSubdistrictSelection(shopDetials?.address?.province, shopDetials?.address?.district)
        this.formChanged = false
      } else {
        console.log('load shop failed')
      }
    })
  }

  setPhoneNumbers(phoneNumbers: string[]): void {
    const phoneNumbersFormArray = this.form.get('phoneNumbers') as FormArray;
    phoneNumbersFormArray.clear();
    phoneNumbers.forEach(phoneNumber => {
      phoneNumbersFormArray.push(this.fb.control(phoneNumber));
    });
  }

  setProvinceSelection(): void {
    this.provinceSelections = Object.keys(this.provinces);
  }

  setDistrictSelection(province: string): void {
    this.districtSelections = province ? Object.keys(this.provinces[province]) : [];
  }

  setSubdistrictSelection(province: string, district: string): void {
    this.subdistrictSelections = (province && district) ? Object.keys(this.provinces[province][district]) : [];
  }

  setPastalCode(province: string, district: string, subdistrict: string) {
    const postalCode = province && district && subdistrict ? this.provinces[province][district][subdistrict] : ''
    this.form.get('address.postalCode')?.setValue(postalCode)
  }


  onProvinceChange() {
    this.districtSelections = []
    this.subdistrictSelections = []
    this.form.get('address.district')?.reset()
    this.form.get('address.subdistrict')?.reset()
    this.form.get('address.postalCode')?.reset()
    this.setDistrictSelection(this.form.get('address.province')?.value)
  }

  onDistrictChange() {
    this.subdistrictSelections = []
    this.form.get('address.subdistrict')?.reset()
    this.form.get('address.postalCode')?.reset()
    this.setSubdistrictSelection(this.form.get('address.province')?.value, this.form.get('address.district')?.value)
  }

  onSubdistrictChange() {
    this.setPastalCode(this.form.get('address.province')?.value, this.form.get('address.district')?.value, this.form.get('address.subdistrict')?.value,)
  }

  get phoneNumbers(): FormArray {
    return this.form.get('phoneNumbers') as FormArray;
  }

  addPhoneNumber() {
    this.phoneNumbers.push(this.fb.control('', Validators.required));
  }

  removePhoneNumber(index: number) {
    this.phoneNumbers.removeAt(index);
  }


  onSubmit() {
    if (!this.form.valid) return
    const data = this.form.getRawValue()
    console.log(data);
    let updateData: any = {
      phone_number: data.phoneNumbers,
      address: {
        address: `${data.address.address}`,
        province: data.address.province,
        district: data.address.district,
        subdistrict: data.address.subdistrict,
        postal_code: `${data.address.postalCode}`
      },
    }
    if (data.location.lat && data.location.long) {
      updateData['location'] = {
        lat: data.location.lat,
        long: data.location.long
      }
    }
    console.log(updateData)
    this.merchantService.updateshop(this.shopId, updateData).then(response => {
      this.formChanged = false;
      if (response.success) {
        this.loadShopDetails()
        this.modalService.openModal('success', 'อัพเดตข้อมูลสำเร็จ', '').then(ok => {
          console.log('ok')
        })
      } else {
        console.log('failed')
        this.modalService.openModal('success', 'เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  onCancel() {
    this.loadShopDetails()
  }

}
