import { Component, Inject } from '@angular/core';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-credit-payment',
  templateUrl: './credit-payment.component.html',
  styleUrl: './credit-payment.component.css'
})
export class CreditPaymentComponent {

  orderStatus: any;
  note: string = '';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CreditPaymentComponent>,
    private modalService: ModalService,
  ) {
    console.log(data)
  }

  close() {
    this.ref.close(false);
  }

  confirm() {
    if (this.note) {
      this.ref.close({ note: this.note });
    } else {
      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกการชำระเงินได้ กรุณาแนบหลักฐาน')
    }
  }

}
