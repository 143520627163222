import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input()
  pageSize: number = 10;
  @Input()
  pageIndex: number = 0;
  @Input()
  length: number = 0;
  @Input()
  callBackPageChange!: ((data: any) => void);

  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;


  pageSizeOptions = [10, 20, 50, 100];
  pageList = [0];

  lastPage = 0;
  firstPage = 0;

  constructor() {

  }

  ngOnInit(): void {
    const amountPage = Math.ceil(this.length / this.pageSize);
    this.lastPage = amountPage
    const allPage = Array(amountPage).fill(null).map((e: any, idx: number) => idx);
    allPage.splice(amountPage - 1, 1)
    allPage.splice(0, 1)

    let start = 0;
    let end = allPage.length;

    if (allPage.length > 3) {
      if (this.pageIndex - 2 <= 0) {
        start = 0;
      } else {
        start = this.pageIndex - 2;
      }

      if (this.pageIndex + 1 >= allPage.length - 1) {
        end = allPage.length - 1;
      } else {
        end = this.pageIndex + 1;
      }

      if (end - start < 3) {
        end = start + 3 > allPage.length ? allPage.length : start + 3;
      }

      if (end - start < 3) {
        start = end - 3
      }

    }

    this.pageList = allPage.slice(start, end);

    // this.pageList = allPage;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['length']) {
      // this.pageIndex = 0;
      this.ngOnInit()
    }
  }

  goToFirstPage() {
    if (this.pageIndex !== 0) {
      this.pageIndex = 0;
      this.ngOnInit()
      this.pageIndexChange();
    }
  }

  goToPreviousPage() {
    if (this.pageIndex - 1 >= this.firstPage) {
      this.pageIndex = this.pageIndex - 1;
      this.ngOnInit()
      this.pageIndexChange();
    }
  }

  goToNextPage() {
    const lastPage = Math.ceil(this.length / this.pageSize) - 1;
    if (this.pageIndex + 1 <= lastPage) {
      this.pageIndex = this.pageIndex + 1;
      this.ngOnInit()
      this.pageIndexChange();
    }
  }

  goToLastPage() {
    const lastPage = Math.ceil(this.length / this.pageSize) - 1;
    if (this.pageIndex !== lastPage) {
      this.pageIndex = lastPage;
      this.ngOnInit()
      this.pageIndexChange();
    }
  }

  goToPageIndex(index: number) {
    if (this.pageIndex !== index) {
      this.pageIndex = index;
      this.ngOnInit()
      this.pageIndexChange();
    }
  }

  pageIndexChange(): void {
    const data = {
      offset: this.pageIndex * this.pageSize,
      limit: this.pageSize,
      pageIndex: this.pageIndex
    }
    this.callBackPageChange(data);
  }

  pageSizeChange(event: any): void {
    this.pageIndex = 0;
    const data = {
      offset: 0,
      limit: event,
      pageIndex: this.pageIndex
    }
    this.ngOnInit();
    this.callBackPageChange(data);
  }

}
