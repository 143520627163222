import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faCopy, faDownload, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { MerchantService } from '../../services/merchant/merchant.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-merchant-setting-member',
  templateUrl: './merchant-setting-member.component.html',
  styleUrl: './merchant-setting-member.component.css'
})
export class MerchantSettingMemberComponent implements OnInit, AfterViewInit {


  faScrewdriverWrench = faScrewdriverWrench;
  faDownload = faDownload;
  faCopy = faCopy;

  liffId: string = '';
  lineId: string = '';
  urlMember: string = '';
  loading: boolean = false

  copied: boolean = false;

  shopId: string = this.authService.selectedShop?.shop_id || '';


  constructor(
    private authService: AuthService,
    private merchantService: MerchantService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.loadShopDetails();
  }

  ngAfterViewInit() {
    // Ensures the view, including the QR code canvas, has fully rendered.
  }

  loadShopDetails() {
    this.loading = true;
    this.merchantService.getOneShop(this.shopId).then(response => {
      if (response.success) {
        console.log(response);

        this.liffId = response?.data?.line?.member_liff_id || ''
        this.lineId = response?.data?.line?.line_id || '';
        this.urlMember = `https://line.me/R/ti/p/${this.lineId}`
        // this.urlMember = `https://liff.line.me/${this.liffId}?liffId=${this.liffId}&shopId=${this.shopId}`

      }
      this.loading = false;

    })
  }

  copyText() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  @ViewChild('qrCodeCanvas', { static: false }) qrCodeCanvas!: ElementRef;

  downloadQRCode() {
    const qrCode: any = this.qrCodeCanvas
    const canvas = qrCode.qrcElement.nativeElement.querySelector('canvas');

    if (canvas) {
      // Convert the canvas to a PNG image
      const image = canvas.toDataURL('image/png');

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = image;
      link.download = 'qr-code.png'; // File name
      link.click(); // Trigger download
    } else {
      console.error('QR code canvas not found.');
    }
  }

}
