import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stock-out-footer',
  templateUrl: './stock-out-footer.component.html',
  styleUrl: './stock-out-footer.component.css'
})
export class StockOutFooterComponent {

  @Output('callBackConfirm')
  callBackConfirm: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private router: Router
  ) { }


  routeToStockOut() {
    this.router.navigate(['/stock-out']);
  }

  onConfirmOrder() {
    this.callBackConfirm.emit({ confirm: true })

  }

}
