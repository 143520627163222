import { AfterViewInit, Component, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrl: './circle-progress.component.css'
})
export class CircleProgressComponent implements AfterViewInit, OnChanges {

  @Input()
  color: string = '';
  @Input()
  value!: number;


  bgColor = '#f3f4f6';
  actualValue = 0;

  constructor(
    private elem: ElementRef
  ) { }

  ngAfterViewInit(): void {
    if (!!this.color) {
      const element = this.elem.nativeElement;
      const mainCircle = element.querySelector("#main-circle").querySelector("circle");
      mainCircle.style.stroke = this.color;
      const bgCircle = element.querySelector("#bg-circle").querySelector("circle");
      bgCircle.style.stroke = this.bgColor;
      this.actualValue = this.value;
    }
  }

  ngOnChanges() {
    this.actualValue = this.value;
  }

}
