import { Component, OnInit } from '@angular/core';
import { faBarcode, faShield, faTriangleExclamation, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { ClaimService } from '../../services/claim/claim.service';
import { Router } from '@angular/router';
import { faCircleCheck, faClock } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-claim-create',
  templateUrl: './claim-create.component.html',
  styleUrl: './claim-create.component.css'
})
export class ClaimCreateComponent implements OnInit {
  faBarcode = faBarcode;
  faShield = faShield;
  faTriangleExclamation = faTriangleExclamation;
  faCircleCheck = faCircleCheck;
  faClock = faClock;
  faSquareArrowUpRight = faSquareArrowUpRight;

  barcode: string = '';

  warranty: any = {}
  isCheckWarranty = false;

  isLoading = false;

  isClaimed = false;

  constructor(
    private claimService: ClaimService,
    private utilService: UtilService,
    private router: Router
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 50);
  }

  barcodeInput(event: any) {
    console.log(event)
    console.log(this.barcode)
  }

  checkProductWarraty() {
    if (this.barcode) {
      this.isCheckWarranty = true;
      this.isLoading = true;
      this.claimService.checkProductWarraty(this.barcode).then((response: any) => {
        console.log(response)
        this.barcode = ''
        if (response.success && response.data.productName) {
          this.warranty = {
            covered: true,
            ...response.data
          }
        } else {
          this.warranty = {
            covered: false,
            ...response.data
          }
        }

        this.isLoading = false;
        this.toggleSidebar()

        setTimeout(() => {
          this.toggleSidebar()
        }, 10)
      })
    }
  }

  toggleSidebar() {
    console.log(document.getElementById('warranty'))
    document.getElementById('warranty')?.classList.toggle('warranty-side');
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD MMM YYYY')
  }

  checkExpire(expireDate: any) {
    const currantDate = this.utilService.dayjs().valueOf()
    return currantDate > this.utilService.dayjs(expireDate).valueOf() ? true : false
  }

  claim() {
    // this.isClaimed = true;
    this.router.navigate(['/claim/claim-form'], { queryParams: { sn: this.warranty.barcode } });
  }
}
