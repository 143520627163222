import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrl: './keypad.component.css'
})
export class KeypadComponent {
  @Output('selectedNumber')
  selectedNumber: EventEmitter<string> = new EventEmitter<string>();
  
  keypad = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'delete']

  selected(item: string) {
    this.selectedNumber.emit(item)
  }
}
