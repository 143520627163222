<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer z-10"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronDown" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                ส่วนลด
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            <div class="space-y-4">
                <div class="pb-2">
                    <div class="grid grid-cols-2">
                        <button class="text-center py-1.5 rounded-l-lg text-gray-600 border border-gray-200"
                            [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : type === 'promotion'}"
                            (click)="changeState('promotion')">
                            Promotion
                        </button>
                        <button class="text-center py-1.5 rounded-r-lg text-gray-600 border border-gray-200"
                            [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : type === 'discount'}"
                            (click)="changeState('discount')">
                            กรอกส่วนลด
                        </button>
                    </div>
                </div>
                @if (type === 'promotion') {
                <div class="grid grid-cols-2 gap-4">
                    @for (discount of discountList; track $index) {
                    <button [disabled]="total >= discount.minimum? false: true"
                        class="flex flex-col justify-center items-center px-4 pt-4 pb-2 w-full min-h-20 bg-gray-200 active:bg-gray-300 rounded-lg transform transition-all duration-100"
                        [ngClass]="{'bg-yellow-500': selected === discount.promotion_id, 'bg-gray-50 text-gray-300 cursor-not-allowed': total < discount.minimum}"
                        (click)="selectedDiscount(discount)">
                        <div class="flex justify-center items-center gap-2">
                            @if(discount.unit === 'amount'){
                            <fa-icon [icon]="faTicket" class="text-lg flex"></fa-icon>
                            }@else{
                            <fa-icon [icon]="faPercent" class="text-lg flex"></fa-icon>
                            }
                            {{discount.name}}
                        </div>
                        <div class="text-xs text-gray-500">
                            ลด <span>{{discount.unit === 'amount'? '฿' :'%'}}</span>{{discount.value}}
                            @if (discount.minimum > 0) {
                            ขั้นต่ำ ฿{{discount.minimum | number}}
                            @if (total < discount.minimum) { <div class="text-red-500">
                                ซื้อเพิ่ม ฿{{discount.minimum - total | number}} เพื่อรับส่วนลด
                                </div>
                                } }
                                @else { ไม่มีขั้นต่ำ } </div>
                    </button>
                    }
                </div>
                }@else {
                <div class="relative pb-2">
                    <div class="flex justify-between items-center">
                        <label>กรอกส่วนลด</label>
                        <div class="">
                            <div class="grid grid-cols-2 z-0">
                                <button class="text-center py-1 px-2 rounded-l-lg text-gray-600 border border-gray-200"
                                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : unit === 'percent'}"
                                    (click)="changeUnit('percent')">
                                    เปอร์เซ็นต์
                                </button>
                                <button class="text-center py-1 px-2 rounded-r-lg text-gray-600 border border-gray-200"
                                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : unit === 'amount'}"
                                    (click)="changeUnit('amount')">
                                    บาท
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="relative mt-3">
                        <!-- <input type="text" id="barcode-add" hidden maxlength="10" [(ngModel)]="value"> -->
                        <div
                            class="text-center border-b-2 border-yellow-400 w-full h-10 text-3xl font-bold focus:ring-0 ">
                            {{value}}</div>
                        <div class="absolute bottom-1 right-0 text-2xl font-bold opacity-50">
                            {{unit==='percent'?'%':'฿'}}</div>
                    </div>
                </div>
                <div>
                    <app-keypad (selectedNumber)="selectedNumber($event)"></app-keypad>
                </div>
                }
                <div>
                    <div class="relative pb-2">
                        <label>หมายเหตุ</label>
                        <textarea type="text" class="text-area" autocomplete="off" [(ngModel)]="note"></textarea>
                    </div>
                </div>
                <div class="h-32"></div>
            </div>

        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="flex flex-col gap-4 w-full">
                <div class="self-center">
                    <button class="text-gray-500 underline" (click)="removeDiscount()">ไม่ต้องการใช้ส่วนลด</button>
                </div>
                <div class="grid grid-cols-2 gap-2 pt-2">
                    <button class="btn-secondary" (click)="close()">
                        ยกเลิก
                    </button>
                    <button class="btn-primary" (click)="apply()">
                        ยืนยัน
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>