import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ExchangeApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getOneSerial(serialNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/serial/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOneOrder(orderNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/orders/${orderNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllReturn(shop_id: string, offset: number, limit: number, status: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/return`, {
        headers,
        params: { shop_id, offset, limit, status }
      }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOneReturn(returnNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/return/${returnNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createOrderReturn(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/return/new`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  addReturnItem(return_no: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/return/${return_no}/products`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  removeReturnItem(return_no: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/return/${return_no}/products/remove`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateRefundDetail(return_no: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/return/${return_no}/refund`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createOrderExchange(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/orders/exchange`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  cancelReturnOrder(returnNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/return/${returnNo}/cancel`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  getShopByShopId(shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerByPhoneNumber(phoneNumber: string, shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/phone/${phoneNumber}/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerPointBalace(userId: string, shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/points/balance/${userId}/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerTotalBill(userId: string, shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/bills/total/${userId}/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCustomerByUserId(userId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/customers/users/${userId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  addCustomerToReturn(return_no: string, user_id: string, name: string) {
    const headers = this.apiService.getAPIHeader();
    const body = {
      user_id,
      name
    }
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/return/${return_no}/customer`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  removeCustomerFromReturn(return_no: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/return/${return_no}/customer/remove`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

}
