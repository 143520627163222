import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, Routes } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPermission(route) && this.checkType(route);
  }

  checkPermission(route: any): any {
    return new Promise((resolve, reject) => {
      if (route.data.expectedRole === undefined) {
        resolve(true);
      } else {
        const login = setInterval(async () => {
          if (this.authService.isLoggedIn) {
            clearInterval(login);
            if (!this.authService.user.role) {
              this.authService.user.role = await this.authService.getRole();
            }
            if (route.data.expectedRole.indexOf(this.authService.user.role) > -1) {
              resolve(true);
            } else {
              this.router.navigate(['/forbidden'])
              resolve(false);
            }
          }
        }, 100);
      }
    });
  }

  checkType(route: any): any {
    return new Promise((resolve, reject) => {
      if (route.data.expectedType === undefined) {
        resolve(true);
      } else {
        const login = setInterval(async () => {
          if (this.authService.isLoggedIn) {
            clearInterval(login);
            if (!this.authService.selectedShop?.type) {
              resolve(false);
            }
            if (route.data.expectedType.indexOf(this.authService.selectedShop?.type) > -1) {
              resolve(true);
            } else {
              this.router.navigate(['/forbidden'])
              resolve(false);
            }
          }
        }, 100);
      }
    });
  }

  canAction(expectedRole: string[]): any {
    return expectedRole.includes(this.authService.user.role)
  }

  canAccessRoute(path: string) {
    const matchedRoute: Route = this.findRouteByPath(path.substring(1));
    let canAccess = false
    if (matchedRoute) {
      if (matchedRoute.data) {
        if (matchedRoute?.data['expectedRole']) {
          const expectedRole = matchedRoute?.data['expectedRole'] || []
          canAccess = expectedRole.includes(this.authService.user.role)
        }
        if (matchedRoute?.data['expectedType']) {
          const expectedType = matchedRoute?.data['expectedType'] || []
          canAccess = expectedType.includes(this.authService.selectedShop?.type)
        }
      }
    }
    return canAccess
  }

  private findRouteByPath(path: string): any {
    const routes: Routes = this.router.config;
    return this.findRoute(routes, path);
  }

  private findRoute(routes: Routes, path: string): Route | null {
    for (const route of routes) {
      if (route.path === path) {
        return route;
      }
    }
    return null;
  }

}
