<div class="p-4">
    <div class="pb-4 flex space-x-4 items-center">
        <button class="items-center flex" (click)="backToReceiptLits()">
            <fa-icon [icon]="faChevronLeft" class="text-md"></fa-icon>
        </button>
        <div class="font-bold text-base lg:text-lg">
            ประวัติรับเข้า
        </div>
    </div>
    <div class="pt-4 border-t space-y-1">
        @if (isLoaded) {
        @for (receipt of dataSource; track $index) {
        <div class="border bg-white rounded-lg px-4 py-3 transition-opacity delay-300">
            <div class="h-full flex justify-between">
                <div>
                    <div>
                        <span class="font-bold text-sm">#{{receipt.id}}</span>
                    </div>
                    <div class="flex space-x-2 pt-1">
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faClock"></fa-icon>
                            {{ dateFormat(receipt.created.date) }}
                        </div>
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faUser"></fa-icon>
                            {{ getUserDetail(receipt.created.by)?.name }}
                        </div>
                    </div>
                    <div class="text-sm space-x-2 pt-4">
                        <span class="text-gray-600">รวมทั้งสิ้น (3 ชิ้น)</span>
                    </div>
                    <!-- <div class="text-sm text-gray-600">
                        โดย: {{ getUserDetail(receipt.created.by)?.name }}
                    </div> -->
                </div>
                <div class="content-center">
                    <button class="flex items-center" (click)="routeToReceiptHistoryDetial(receipt.id)">
                        <span class="hidden md:block pr-2 text-sm">ดูรายละเอียด</span>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        }
        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }
    </div>
</div>