<div class=" bg-white w-96 max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="flex justify-between w-full pt-4 font-bold text-lg border-b-2 border-gray-400 pb-2">
        <div>วิธีการชำระ</div>
        <div>{{data.paymentMethod.name}}</div>
    </div>
    <div class="flex flex-col justify-center items-center w-full">
        <div class="grid grid-cols-1 w-full">
            <div class="">
                @if(data?.payment?.image){<img src="{{data.payment.image}}" alt="{{data.payment.image}}"
                    class="w-full rounded-lg mb-2">}
                <div class="text-sm text-gray-500">note: {{data.payment.note || '-'}}</div>
            </div>
        </div>
    </div>
    <div class="h-2"></div>
</div>