import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faScrewdriverWrench, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MerchantService } from '../../services/merchant/merchant.service.js';
import { ModalService } from '../../../../core/services/modal/modal.service.js';
import { AuthService } from '../../../../core/services/auth/auth.service.js';

@Component({
  selector: 'app-merchant-setting-payment',
  templateUrl: './merchant-setting-payment.component.html',
  styleUrl: './merchant-setting-payment.component.css'
})
export class MerchantSettingPaymentComponent implements OnInit {
  faPlus = faPlus
  faTrash = faTrash;
  faScrewdriverWrench = faScrewdriverWrench;

  form: FormGroup;
  formChanged = false
  shopDetials: any = {}
  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopType: string = this.authService.selectedShop?.type || '';

  loading = false

  paymentConfigs: any[] = []
  salePaymentList: { id: string, img: string, name: string, description: string }[] = []
  refundPaymentList: { id: string, img: string, name: string, description: string }[] = []

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private modalService: ModalService,
  ) {
    this.form = this.fb.group({
      paymentMethods: this.fb.array([]),
      refundPaymentMethods: this.fb.array([]),
      promptpay: this.fb.group({
        name: '',
        number: ''
      })
    });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const paymentConfigsTmp = await this.loadShopPaymentConfig()
    if (this.shopType === 'OTHER') {
      this.paymentConfigs = paymentConfigsTmp.filter((item: { id: string; }) => !['counter_service', 'qr', 'bank_transfer'].includes(item.id))
    } else {
      this.paymentConfigs = paymentConfigsTmp;
    }
    
    this.salePaymentList = this.setPaymentConfigList(this.paymentConfigs.filter(item => item.type === 'sale'))
    this.refundPaymentList = this.setPaymentConfigList(this.paymentConfigs.filter(item => item.type === 'refund'))

    this.loadShopDetails();
    this.form.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
    this.changePromtpay();
  }

  async loadShopPaymentConfig() {
    const response: any = await this.merchantService.getShopPaymentConfig()
    console.log(response)
    if (response.success) {
      return response.data
    } else {
      console.log('load shop payment config failed')
      return []
    }
  }

  setPaymentConfigList(paymentConfigs: any[]) {
    const result = []
    for (const config of paymentConfigs) {
      result.push({
        id: config.id,
        img: config.img,
        name: config.name,
        description: config?.description || ''
      })
    }
    return result
  }

  loadShopDetails() {
    this.merchantService.getOneShop(this.shopId).then(response => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      if (response.success) {
        this.shopDetials = response.data
        const shopDetials = this.shopDetials
        this.setPaymentMethod(shopDetials?.payment_method || []);
        this.setRefundPaymentMethod(shopDetials?.refund_payment_method || []);
        if (response?.data?.promptpay) {
          this.form.patchValue({ promptpay: response.data.promptpay })
        }
        this.formChanged = false
      } else {
        console.log('load shop failed')
      }
    })
  }

  setPaymentMethod(paymentMethods: { id: string, name: string }[]): void {
    const paymentMethodsFormArray = this.form.get('paymentMethods') as FormArray;
    paymentMethodsFormArray.clear();
    paymentMethods.forEach(paymentMethod => {
      paymentMethodsFormArray.push(this.fb.control(paymentMethod.id));
    });
  }

  setRefundPaymentMethod(paymentMethods: { id: string, name: string }[]): void {
    const paymentMethodsFormArray = this.form.get('refundPaymentMethods') as FormArray;
    paymentMethodsFormArray.clear();
    paymentMethods.forEach(paymentMethod => {
      paymentMethodsFormArray.push(this.fb.control(paymentMethod.id));
    });
  }

  isPaymentMethodActive(paymentMethodId: string): boolean {
    const paymentMethods = this.form.get('paymentMethods') as FormArray;
    return paymentMethods.value.includes(paymentMethodId);
  }

  isRefundPaymentMethodActive(paymentMethodId: string): boolean {
    const paymentMethods = this.form.get('refundPaymentMethods') as FormArray;
    return paymentMethods.value.includes(paymentMethodId);
  }

  togglePaymentMethod(event: any, paymentMethodId: string): void {
    const paymentMethods = this.form.get('paymentMethods') as FormArray;
    const index = paymentMethods.value.indexOf(paymentMethodId);
    if (event.checked) {
      if (index === -1) {
        paymentMethods.push(this.fb.control(paymentMethodId));
      }
    } else {
      if (index > -1) {
        paymentMethods.removeAt(index);
      }
    }
    this.changePromtpay();
  }

  toggleRefundPaymentMethod(event: any, paymentMethodId: string): void {
    const paymentMethods = this.form.get('refundPaymentMethods') as FormArray;
    const index = paymentMethods.value.indexOf(paymentMethodId);
    if (event.checked) {
      if (index === -1) {
        paymentMethods.push(this.fb.control(paymentMethodId));
      }
    } else {
      if (index > -1) {
        paymentMethods.removeAt(index);
      }
    }
  }

  onSubmit() {
    if (!this.form.valid) return
    const data = this.form.getRawValue()
    let updateData: any = {
      payment_method: [],
      refund_payment_method: []
    }
    for (const payment of this.salePaymentList) {
      if (data.paymentMethods.includes(payment.id)) {
        updateData.payment_method.push({
          id: payment.id,
          name: payment.name
        })
      }
    }
    for (const payment of this.refundPaymentList) {
      if (data.refundPaymentMethods.includes(payment.id)) {
        updateData.refund_payment_method.push({
          id: payment.id,
          name: payment.name
        })
      }
    }
    if (data.paymentMethods.includes('promptpay_qr')) {
      updateData.promptpay = data.promptpay
    }
    this.merchantService.updateshop(this.shopId, updateData).then(response => {
      this.formChanged = false;
      if (response.success) {
        this.loadShopDetails()
        this.modalService.openModal('success', 'อัพเดตข้อมูลสำเร็จ', '').then(ok => {
          console.log('ok')
        })
      } else {
        console.log('failed')
        this.modalService.openModal('success', 'เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  onCancel() {
    this.loadShopDetails()
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  changePromtpay() {
    const promptpayForm: any = this.form.controls['promptpay'];

    if (this.isPaymentMethodActive('promptpay_qr')) {
      promptpayForm.controls['name']?.setValidators(Validators.required);
      promptpayForm.controls['number']?.setValidators([Validators.required, Validators.pattern(/^\d{10,13}$/)]);

    } else {
      promptpayForm.controls['name']?.clearValidators();
      promptpayForm.controls['number']?.clearValidators();
    }

    this.form.controls['promptpay'].updateValueAndValidity();

  }

}
