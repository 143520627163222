import { Component, OnInit } from '@angular/core';
import { faChevronRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { Router } from '@angular/router';
import { SaleApiService } from '../../services/api/sale-api.service';
import { SalesService } from '../../services/sales/sales.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ShopService } from '../../../../core/services/shop/shop.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.css'
})
export class SalesComponent implements OnInit {

  faChevronRight = faChevronRight;
  faClock = faClock;
  faUser = faUser;
  faArrowDown = faArrowDown;

  // state = 'ongoing'; // ongoing, success
  isLoaded = false;
  loadingMore = false

  orders: any[] = []
  offset: number = 0;
  limit: number = 10;
  countOrder: any = {
    'ongoing': 0,
    'success': 0
  }

  // all user from current shop
  users = this.shopService.shopUsers;

  shopId: string = this.authService.selectedShop?.shop_id || '';

  statusGetOrder: any = {
    ongoing: 'ongoing,waiting_for_payment,invalid_amount',
    success: 'completed'
  }

  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private saleApiService: SaleApiService,
    private router: Router,
    public salesService: SalesService,
    private shopService: ShopService
  ) { }

  async ngOnInit(): Promise<void> {
    this.reloadData();
  }

  async getOrderList(status: string): Promise<any[]> {
    return this.saleApiService.getOrderList(this.shopId, this.offset, this.limit, status).then((response: any) => {
      if (!response.success) {
        this.countOrder[this.salesService.state] = 0
        return [];
      }
      this.countOrder[this.salesService.state] = response.count
      return response.data;
    })
  }

  changeState(state: string) {
    if (this.salesService.state !== state) {
      this.salesService.state = state;
      this.offset = 0;
      this.reloadData();
    }
  }

  async reloadData() {
    this.isLoaded = false;
    if (this.salesService.state === 'ongoing') {
      this.orders = await this.getOrderList(this.statusGetOrder.ongoing);
      this.countOrder['success'] = await this.getCountOrder(this.statusGetOrder.success)
    } else {
      this.orders = await this.getOrderList(this.statusGetOrder.success);
      this.countOrder['ongoing'] = await this.getCountOrder(this.statusGetOrder.ongoing)
    }
    this.isLoaded = true;
  }

  getCountOrder(status: string) {
    return this.saleApiService.getOrderList(this.shopId, 0, 1, status).then((response: any) => {
      if (!response.success) return 0;
      return response.count
    })
  }

  routeToSaleCreate() {
    this.router.navigate(['/sales/sale-create']);
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  getUserDetail(user: string) {
    return this.users.find(item => item.user_id === user)
  }

  async loadMore() {
    this.loadingMore = true
    this.offset = this.offset + this.limit;
    let orderResponse = [];
    if (this.salesService.state === 'ongoing') {
      orderResponse = await this.getOrderList(this.statusGetOrder.ongoing);
    } else {
      orderResponse = await this.getOrderList(this.statusGetOrder.success);
    }
    this.orders.push(...orderResponse);
    this.loadingMore = false
  }

  displayStatusOrder(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_for_payment') {
      return 'รอชำระเงิน'
    } else if (status === 'completed') {
      return 'สำเร็จ'
    } else if (status === 'invalid_amount') {
      return 'ยอดชำระเงินไม่ตรง'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayStatusColor(status: string) {
    if (status === 'ongoing') {
      return 'text-yellow-500'
    } else if (status === 'waiting_for_payment') {
      return 'text-yellow-500'
    } else if (status === 'completed') {
      return 'text-lime-600'
    } else if (status === 'invalid_amount') {
      return 'text-red-500'
    } else if (status === 'canceled') {
      return 'text-red-500'
    } else {
      return 'text-yellow-500'
    }
  }

}
