import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExchangeApiService } from '../../services/api/exchange-api.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { faBasketShopping, faHandHoldingDollar, faUser } from '@fortawesome/free-solid-svg-icons';
import { ExchangeService } from '../../services/exchange/exchange.service';
import { ModalService } from '../../../../core/services/modal/modal.service';

@Component({
  selector: 'app-exchange-detail',
  templateUrl: './exchange-detail.component.html',
  styleUrl: './exchange-detail.component.css'
})
export class ExchangeDetailComponent implements OnInit {

  faBasketShopping = faBasketShopping;
  faHandHoldingDollar = faHandHoldingDollar;
  faUser = faUser;

  isLoading: boolean = true;

  returnNo: string = '';
  items: any[] = []
  orderDetail: any = {}

  constructor(
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private exchangeApiService: ExchangeApiService,
    public exchangeService: ExchangeService,
    private modalService: ModalService,

  ) {

  }

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async params => {
      this.returnNo = params.get('return_no') || '';
      if (this.returnNo) {
        this.exchangeApiService.getOneReturn(this.returnNo).then((res: any) => {
          console.log(res)
          if (res.success && res.data) {
            this.orderDetail = res.data;
            this.items = res.data?.items || [];
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 100);
        })
      } else {
        this.router.navigate(['/exchange'])
      }
    });
  }

  displayDate(date: string) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY')
  }

  displayTime(date: string) {
    return this.utilService.dayjs(date).format('HH:mm')
  }

  displayStatusOrder(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_refund') {
      return 'รอคืนเงิน'
    } else if (status === 'waiting_exchange') {
      return 'รอเปลี่ยนสินค้า'
    } else if (status === 'refunded') {
      return 'คืนเงินแล้ว'
    } else if (status === 'completed') {
      return 'เปลี่ยนสินค้าแล้ว'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayType(type: string) {
    if (type === 'bank_transfer') {
      return 'โอนผ่านบัญชี'
    } else if (type === 'cash') {
      return 'เงินสด'
    } else {
      return type
    }
  }

  formatProductDisplay(): any[] {
    let result: any[] = [];
    for (const item of this.items) {
      let index = result.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result.push({
          ...item,
          qty: 1
        });
      } else {
        result[index].qty += 1;
      }
    }
    return result;
  }

  cancelReturnOrder() {
    this.modalService.openModal('confirm-warning', 'ยกเลิกรับคืน', 'ต้องการยกเลิกรับคืน?').then((confirm) => {
      if (confirm) {
        this.exchangeApiService.cancelReturnOrder(this.returnNo).then((result: any) => {
          if (result.success) {
            this.modalService.openModal('success', 'สำเร็จ', 'ยกเลิกรับคืนสำเร็จ').then(() => {
              this.router.navigate(['/exchange'])
            })
          } else {
            let message = 'ไม่สามารถยกเลิกรับคืนได้';

            if (result.message === 'order was waiting_exchange') {
              message = 'รายการนี้กำลังเปลี่ยนสินค้า ไม่สามารถยกเลิกได้'
            } else if (result.message === 'order was refunded') {
              message = 'รายการนี้คืนเงินแล้ว ไม่สามารถยกเลิกได้'
            } else if (result.message === 'order was completed') {
              message = 'รายการนี้รับคืนสำเร็จแล้ว ไม่สามารถยกเลิกได้'
            } else if (result.message === 'order was canceled') {
              message = 'รายการนี้ถูกยกเลิกแล้ว'
            }

            this.modalService.openModal('fail', 'ไม่สำเร็จ', message)
          }
        })
      }
    })
  }

}
