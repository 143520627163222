<div class="p-4">
    <div class="pb-4">
        <div class="relative">
            <input #barcodeInput [(ngModel)]="barcodeValue" type="text" id="barcode" class="input !pr-12" placeholder="บาร์โค้ดสินค้า" autocomplete="off" (keyup.enter)="scanBarcode()">
            <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
        </div>
    </div>
    <div class="space-y-2">
        @for (product of products; track $index) {
        <div class="grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
            <div class="col-span-1">
                <div class="w-full lg:w-16 aspect-square">
                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                        [alt]="product.sku" class="rounded-sm">
                </div>
            </div>
            <div class="col-span-3 md:col-span-6 xl:col-span-10 h-[56px]">
                <div class="line-clamp-2 text-sm">{{ product.name }}</div>
            </div>
            <div class="col-span-1 text-right pr-1 flex justify-end h-fit">
                <div class="text-sm md:text-base text-secondary-700 font-medium">x{{ product.serials.length }}</div>
            </div>
        </div>
        }
        @if(products.length === 0) {
        <div class="pt-20 text-center">
            <div class="flex flex-col justify-center items-center w-full">
                <img src="../../../assets/images/scan-barcode.svg" class="h-20 aspect-auto">
            </div>
            <div class="text-gray-500">สแกนสินค้ารับเข้า</div>
        </div>
        }
    </div>
</div>