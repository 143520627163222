import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faArrowRight, faArrowsRotate, faBahtSign, faChevronLeft, faExternalLink, faEye, faPencil, faShield } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ProductApiService } from '../../services/api/product-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { AuthGuardService } from '../../../../core/services/auth-guard/auth-guard.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.css'
})
export class ProductDetailComponent implements OnInit {

  faPencil = faPencil;
  faChevronLeft = faChevronLeft;
  faBahtSign = faBahtSign;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faEye = faEye;
  faArrowsRotate = faArrowsRotate;
  faShield = faShield;
  faExternalLink = faExternalLink

  shopId: string = this.authService.selectedShop?.shop_id || '';
  sku: string = '';
  cost: number = 0;

  productDetail: { sku: string, product_id: string, name: string, detail: string, images: { name: string }[], warranty: number, category: { id: string, name: string }, brand: { id: string, name: string } } | any = {}
  productShopDetail: { sku: string, shop_id: string, prices: { id: string, price: number }[], summary: number, min_stock: number } | any = {}
  priceConfigs: { price_config_id: string, name: string, active: boolean }[] = []

  isEditingPrice: boolean = false;
  priceForm: FormGroup;

  isEditingStock: boolean = false;
  stockForm: FormGroup;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private modalService: ModalService,
    private productApi: ProductApiService,
    private router: Router,
    private fb: FormBuilder,
    private authGaurdService: AuthGuardService,

  ) {
    this.priceForm = this.fb.group({});
    this.stockForm = this.fb.group({});
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async params => {
      this.sku = params.get('sku') || '';
      const productShop = await this.getProductShopBySku(this.shopId, this.sku);
      this.priceConfigs = await this.getPriceConfig(this.shopId)
      if (productShop) {
        this.productShopDetail = productShop;
        const product = await this.getProductBySku(this.sku);
        if (product) {
          this.productDetail = product
        } else {
          this.router.navigate(['/products/product-list'])
        }
      } else {
        this.router.navigate(['/products/product-list'])
      }
    });
  }

  async getPriceConfig(shopId: string) {
    return await this.productApi.getAllPriceConfigByShop(shopId, { active: true }).then((response: any) => {
      return response.data || []
    })
  }

  async getProductBySku(sku: string) {
    return await this.productApi.getProductBySKu(sku, {}).then((response: any) => {
      return response.data || null
    })
  }

  async getProductShopBySku(shopId: string, sku: string) {
    return await this.productApi.getProductShopBySku(shopId, sku).then((response: any) => {
      return response.data || null
    })
  }

  formatWarranty(warranty: number) {
    const year = Math.floor(warranty / 365);
    const month = ((warranty % 365) / 30);
    let value = ''
    if (year > 0) {
      value += `${year} ปี `;
    }
    if (month > 0) {
      value += `${month} เดือน`;
    }
    return value;
  }

  getProductPrice(prices: { id: string, price: number }[], priceConfigId: string) {
    return prices?.find(el => el.id === priceConfigId)?.price || 0
  }

  submitProductChange() {
    this.modalService.openModal('confirm', 'ยืนยันการปรับปรุงข้อมูลสินค้า', 'เมื่อคุณยืนยัน ข้อมูลสินค้าจะถูกนำไปใช้ทันที่ ต้องการยืนยันหรือไม่').then((result) => {
      if (result) {
        this.modalService.openModal('success', 'ปรับปรุงข้อมูลสินค้าสำเร็จ', '')
      }
    })
  }

  editPrice() {
    this.resetEditPrice();
    this.isEditingPrice = true;
    this.priceConfigs.forEach(config => {
      const newControlName = config.price_config_id;
      const productShopDetail = JSON.parse(JSON.stringify(this.productShopDetail))
      const value = this.getProductPrice(productShopDetail.prices, config.price_config_id)
      this.priceForm.addControl(newControlName, this.fb.control(value, [Validators.required, Validators.min(0)]));
    })
  }

  resetEditPrice() {
    this.isEditingPrice = false;
    this.priceForm = this.fb.group({});
  }

  updatePrice() {
    if (this.priceForm.valid) {
      this.modalService.openModal('confirm', 'ยืนยันการปรับปรุงข้อมูลสินค้า', 'ข้อมูลสินค้าจะถูกนำไปใช้ทันที่ ต้องการยืนยันหรือไม่').then(async (result) => {
        if (result) {
          const productDetial = await this.getProductShopBySku(this.shopId, this.sku);
          if (productDetial) {
            const body: { prices: { id: string, price: number }[] } = { prices: [] }
            console.log(this.priceForm.controls)
            productDetial.prices.forEach((price: any) => {
              if (this.priceForm.controls[price.id]) {
                body.prices.push({
                  id: price.id,
                  price: this.priceForm.controls[price.id].value
                })
              } else {
                body.prices.push({
                  id: price.id,
                  price: price.price
                })
              }
            })
            console.log(body)
            this.productApi.updateAllPriceProductShop(this.shopId, this.sku, body).then((response: any) => {
              this.resetEditPrice()
              if (response.success) {
                this.modalService.openModal('success', 'ปรับปรุงข้อมูลสินค้าสำเร็จ', '')
                this.productShopDetail = response.data;
              } else {
                this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ปรับปรุงข้อมูลสินค้าไม่สำเร็จ')
              }
            })
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ปรับปรุงข้อมูลสินค้าไม่สำเร็จ')
          }
        }
      })
    } else {
      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'กรุณากรอกราคาให้ถูกต้อง')
    }
  }

  editStock() {
    this.isEditingStock = true;
    this.stockForm.addControl('min_stock', this.fb.control(this.productShopDetail?.min_stock || 0, [Validators.required, Validators.min(0)]));
  }

  resetEditStock() {
    this.isEditingStock = false;
    this.stockForm = this.fb.group({});
  }

  updateStock() {
    if (this.stockForm.valid) {
      this.productApi.updateMinStockProductShop(this.shopId, this.sku, { min_stock: this.stockForm.value.min_stock }).then((response: any) => {
        if (response.success) {
          this.modalService.openModal('success', 'บันทึกสำเร็จ', '')
          this.productShopDetail = response.data;
        } else {
          this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'บันทึกไม่สำเร็จ')
        }
        this.isEditingStock = false;
      })
    }
  }

  canEditPrice() {
    return this.authGaurdService.canAction(['manager', 'admin'])
  }


}
