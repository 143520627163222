import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faChevronRight, faCrown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sale-footer',
  templateUrl: './sale-footer.component.html',
  styleUrl: './sale-footer.component.css'
})
export class SaleFooterComponent {

  @Input('summary') summary!: number;
  @Input('discount') discount!: number;
  @Input('total') total!: number;
  @Input('points') points!: number;
  @Input('member') member: any;
  @Input('redeemDiscount') redeemDiscount!: number;

  @Input('confirmOrder') confirmOrder!: Function;
  @Input('addFriend') addFriend!: Function
  @Input('addDiscount') addDiscount!: Function
  @Input('addRedeemDiscount') addRedeemDiscount!: Function

  faPlus = faPlus;
  faChevronRight = faChevronRight;
  faCrown = faCrown;

  constructor(
    private router: Router
  ) { }

  routeToSales() {
    this.router.navigate(['/sales']);
  }

  onConfirmOrder() {
    this.confirmOrder();
  }

  onAddFriend() {
    this.addFriend()
  }

  onAddDiscount() {
    this.addDiscount()
  }

  onAddRedeemDiscount() {
    this.addRedeemDiscount()
  }

}
