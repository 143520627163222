<div class="p-4 w-full space-y-2 text-sm lg:text-base relative">
    <div class="font-bold text-base lg:text-lg"> รายการร้านค้า </div>
    <div class="grid grid-cols-1 gap-2 lg:flex lg:justify-end">
        <div class="w-full lg:w-fit">
            <div class="flex flex-row justify-between items-center border bg-white rounded-lg h-[46px]">
                <input type="text" id="keyword" class="py-2 px-4 !pr-8 w-full outline-none rounded-l-lg"
                    placeholder="ค้นหาชื่อร้านค้า, รหัสร้านค้า" [(ngModel)]="searchText" (keydown.enter)="search(searchText)"
                    autocomplete="off">
                <button
                    class="flex-none h-full aspect-square bg-yellow-500 rounded-r-lg flex items-center justify-center"
                    (click)="search(searchText)">
                    <fa-icon [icon]="faMagnifyingGlass" class="text-xl"></fa-icon></button>
            </div>
        </div>
        <div class="w-full lg:w-fit flex justify-end">
            <button class="btn-light !border flex space-x-2 items-center" (click)="clickCreateMerchant()">
                <fa-icon [icon]="faPlus" class=""></fa-icon>
                <div class="">เพิ่มร้านค้า</div>
            </button>
        </div>
    </div>
    @if (isLoading) {
    <div class="flex justify-center pt-40">
        <app-loader></app-loader>
    </div>
    }@else {
    <!-- For Mobile -->
    <div class="lg:hidden grid gap-1">
        @if(!filteredData || filteredData.length === 0){
        <div>
            <div class="flex flex-col justify-center items-center w-full pt-10">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
            </div>
            <div class="text-center pt-4">ไม่พบรายการร้านค้าในขณะนี้</div>
        </div>
        }
        @for (shop of filteredData; track $index) {
        <button class="border bg-white rounded-lg p-3 text-left space-y-2" (click)="showMerchantDetial(shop)">
            <div class="flex justify-between">
                <div class="font-bold ">
                    {{shop.name}}
                </div>
                <div class="font-bold ">
                    {{shop.shop_id}}
                </div>
            </div>
            <div class="flex justify-start space-x-2 items-center">
                <div class="">
                    @if(getShopType(shop.provider_id) === 'INHOUSE'){
                    <div class="px-2 py-0.5 rounded bg-yellow-500 text-sm md:text-base">
                        #{{getShopType(shop.provider_id)}}</div>
                    }
                    @if(getShopType(shop.provider_id) === 'OTHER'){
                    <div class="px-2 py-0.5 rounded bg-gray-200 text-sm md:text-base">
                        #{{getShopType(shop.provider_id)}}</div>
                    }
                </div>
                <div class="px-2 py-0.5 flex space-x-1 text-sm md:text-base">
                    <fa-icon [icon]="faHandshake" class="text-gray-400"></fa-icon>
                    <div class="text-blue-500">{{getProviderName(shop.provider_id)}}</div>
                </div>
            </div>
        </button>
        }
    </div>

    <!-- For Desktop -->
    <div class="hidden lg:grid grid-cols-1 gap-2">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                รหัสร้านค้า
                            </th>
                            <th class="text-left">
                                ชื่อ
                            </th>
                            <th class="text-left">
                                ประเภท
                            </th>
                            <th class="text-left">
                                Provider
                            </th>
                        </tr>
                    </thead>
                    @if(!filteredData || filteredData.length === 0){
                    <tbody>
                        <tr class="tbody-tr">
                            <td colspan="100" class="text-center py-10">
                                <div class="flex flex-col justify-center items-center w-full">
                                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                    <div class="text-center pt-4">ไม่พบรายการร้านค้าในขณะนี้</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }
                    @else {
                    <tbody>
                        @for (shop of filteredData; track $index) {
                        <tr class="tbody-tr cursor-pointer" (click)="showMerchantDetial(shop)">
                            <td>
                                <div class="h-12 content-center">
                                    {{shop.shop_id}}
                                </div>
                            </td>
                            <td>
                                <div class="h-12 content-center">
                                    {{shop.name}}
                                </div>
                            </td>
                            <td>
                                @if(getShopType(shop.provider_id) === 'INHOUSE'){
                                <div class="w-fit px-2 py-0.5 rounded bg-yellow-500">
                                    #{{getShopType(shop.provider_id)}}
                                </div>
                                }@else {
                                <div class="w-fit px-2 py-0.5 rounded bg-gray-200">
                                    #{{getShopType(shop.provider_id)}}
                                </div>
                                }
                            </td>
                            <td>
                                <div class="px-2 py-0.5 flex space-x-2">
                                    <fa-icon [icon]="faHandshake" class="text-gray-500"></fa-icon>
                                    <div class="text-blue-500">{{getProviderName(shop.provider_id)}}</div>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                    }
                </table>
            </div>
        </div>
    </div>
    }
</div>

<div>
    <app-merchant-detail-dialog [open]="showDetail" [shop]="shopDetial"
        (callBackMerchantDetail)="callBackMerchantDetail($event)"></app-merchant-detail-dialog>
</div>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}