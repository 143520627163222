import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private authService: AuthService
  ) { }


  getAPIHeader(): any {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.token}`
    }
    return headers
  }

  parseFilters(filters: any) {
    if (filters && Object.keys(filters).length > 0) {
      const filtersItem = []
      for (const key of Object.keys(filters)) {
        filtersItem.push(`${key}=${filters[key]}`)
      }
      return filtersItem.join("&");
    } else {
      return ''
    }
  }
}
