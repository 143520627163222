<div class="p-4 space-y-2">
    <button class="absolute right-3 top-2" (click)="close()">
        <fa-icon [icon]="faXmark"></fa-icon>
    </button>
    <div class="flex justify-center py-2">

        <!-- Modal Icon -->
        <span [class]="data.iconClass" class="relative flex rounded-full h-16 w-16 justify-center items-center">
            <fa-icon [icon]="data.icon" class="text-white text-4xl mt-1"></fa-icon>
        </span>
    </div>
    <div class="flex justify-center">
        <!-- Title -->
        <div class="font-bold text-center">
            {{ data.title }}
        </div>
    </div>
    <div class="flex justify-center">
        <!-- Content -->
        <div class="text-center">
            {{ data.content }}
        </div>
    </div>
    <div class="flex justify-center space-x-2 py-4">
        <div>
            <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]" (click)="close()">
                <div class="w-full text-center">
                    {{ data.closeBtnLabel }}
                </div>
            </button>
        </div>
        <div *ngIf="data.showConfirmBtn">
            <button mat-button class="{{data.btnConfirmClass}} min-w-[8rem]" (click)="confirm()">
                <div class="w-full text-center">
                    {{ data.confirmBtnLabel }}
                </div>
            </button>
        </div>
    </div>
</div>