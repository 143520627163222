import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { StockOutService } from '../../services/stock-out/stock-out.service';
import { faArrowDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { StockOutApiService } from '../../services/api/stock-out-api.service';
import { ShopService } from '../../../../core/services/shop/shop.service';
import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-stock-out-list',
  templateUrl: './stock-out-list.component.html',
  styleUrl: './stock-out-list.component.css'
})
export class StockOutListComponent implements OnInit {

  faArrowDown = faArrowDown;
  faChevronRight = faChevronRight;
  faClock = faClock;
  faUser = faUser;

  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopName: string = this.authService.selectedShop?.name || '';

  statusGetOrder: any = {
    ongoing: 'ongoing,waiting_confirm',
    success: 'completed'
  }

  isLoaded = false;

  stockOuts: any[] = []
  offset: number = 0;
  limit: number = 10;
  countStockOut: any = {
    'ongoing': 0,
    'success': 0
  }

  users = this.shopService.shopUsers;


  constructor(
    private authService: AuthService,
    private stockOutApiService: StockOutApiService,
    public stockOutService: StockOutService,
    public utilService: UtilService,
    private router: Router,
    private shopService: ShopService
  ) { }

  async ngOnInit(): Promise<void> {
    this.reloadData();
  }

  routeToStockOutCreate() {
    this.router.navigate(['/stock-out/stock-out-create']);
  }

  async reloadData() {
    this.isLoaded = false;
    if (this.stockOutService.state === 'ongoing') {
      this.stockOuts = await this.getStockOutList(this.statusGetOrder.ongoing);
      this.countStockOut['success'] = await this.getCountOrder(this.statusGetOrder.success)
    } else {
      this.stockOuts = await this.getStockOutList(this.statusGetOrder.success);
      this.countStockOut['ongoing'] = await this.getCountOrder(this.statusGetOrder.ongoing)
    }
    this.isLoaded = true;
  }

  async getCountOrder(status: string) {
    const filter = {
      shop_id: this.shopId,
      offset: 0,
      limit: 1,
      status: status
    }
    return this.stockOutApiService.getAllStockOut(filter).then((response: any) => {
      if (!response.success) return 0;
      return response.count
    })
  }

  async getStockOutList(status: string) {
    const filter = {
      shop_id: this.shopId,
      offset: this.offset,
      limit: this.limit,
      status: status
    }
    return this.stockOutApiService.getAllStockOut(filter).then((response: any) => {
      if (!response.success) {
        this.countStockOut[this.stockOutService.state] = 0
        return [];
      }
      this.countStockOut[this.stockOutService.state] = response.count
      return response.data;
    })
  }

  changeState(state: string) {
    if (this.stockOutService.state !== state) {
      this.stockOutService.state = state;
      this.offset = 0;
      this.reloadData();
    }
  }

  displayStatusOrder(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_confirm') {
      return 'รอยืนยัน'
    } else if (status === 'completed') {
      return 'สำเร็จ'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayStatusColor(status: string) {
    if (status === 'ongoing') {
      return 'text-yellow-500'
    } else if (status === 'waiting_confirm') {
      return 'text-yellow-500'
    } else if (status === 'completed') {
      return 'text-lime-600'
    } else if (status === 'canceled') {
      return 'text-red-500'
    } else {
      return 'text-yellow-500'
    }
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  getUserDetail(user: string) {
    return this.users.find(item => item.user_id === user)
  }

  async loadMore() {
    this.isLoaded = false;
    this.offset = this.offset + this.limit;
    let orderResponse = [];
    if (this.stockOutService.state === 'ongoing') {
      orderResponse = await this.getStockOutList(this.statusGetOrder.ongoing);
    } else {
      orderResponse = await this.getStockOutList(this.statusGetOrder.success);
    }
    this.stockOuts.push(...orderResponse);
    this.isLoaded = true;
  }

}
