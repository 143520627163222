import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-receipt-dialog',
  templateUrl: './add-receipt-dialog.component.html',
  styleUrl: './add-receipt-dialog.component.css'
})
export class AddReceiptDialogComponent {

  errorAlert: any = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<AddReceiptDialogComponent>
  ) {
    this.setDefaultData()

    console.log(this.data)
  }

  setDefaultData() {

    console.log(this.data)
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    console.log(this.validateInput())
    if (this.validateInput()) {
      this.ref.close({ confirm: true, data: this.data });
    }
  }

  validateInput() {
    this.errorAlert = {};
    if (this.data.form.purchase_order_no) {
      return true
    } else {
      if (!this.data.form.purchase_order_no) {
        this.errorAlert['purchase_order_no'] = 'กรอกหมายเลขคำสั่งซื้อ'
      }
      return false
    }
  }
}
