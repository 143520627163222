<div class="p-4 w-full space-y-4" id="example-page">

    <app-scroll-button id="example-page"></app-scroll-button>

    <!-- Input -->
    <div class="relative">
        <label for="example-input">กล่องข้อความ</label>
        <input type="text" id="example-input" class="input" placeholder="ตัวอย่างอักษร" autocomplete="off">
    </div>
    <div class="relative">
        <label for="example-input">กล่องข้อความสัญลักษณ์</label>
        <input type="text" id="example-input" class="input !pr-12" placeholder="ตัวอย่างอักษร" autocomplete="off">
        <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
    </div>
    <!-- Button -->
    <div class="flex flex-wrap gap-2">
        <button class="btn-primary">
            ปุ่มกดหลัก
        </button>
        <button class="btn-secondary">
            ปุ่มกดรอง
        </button>
        <button class="btn-danger">
            ปุ่มกดอันตราย
        </button>
        <button class="btn-disable">
            ปุ่มห้ามกด
        </button>
    </div>

    <div class="w-full">
        <label>Select</label>
        <mat-form-field class="w-full">
            <mat-select class="">
                <mat-option value="option1">option 1</mat-option>
                <mat-option value="option2">option 2</mat-option>
                <mat-option value="option3">option 3</mat-option>
                <mat-option value="option4">option 4</mat-option>
                <mat-option value="option5">option 5</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="">
        <div class="flex gap-2">
            <input type="radio" name="hs-default-radio" id="hs-default-radio">
            <label for="hs-default-radio" class="">Default radio</label>
        </div>
        <div class="flex gap-2">
            <input type="radio" name="hs-default-radio" id="hs-checked-radio" checked="">
            <label for="hs-checked-radio" class="">Checked radio</label>
        </div>
        <div class="flex gap-2">
            <input type="checkbox" name="hs-default-checkbox" id="hs-default-checkbox" [checked]="" class="" />
            <label for="hs-default-checkbox" class="">Default checkbox</label>
        </div>
        <div class="flex gap-2">
            <input type="checkbox" name="hs-checked-checkbox" id="hs-checked-checkbox" [checked]="true" class="" />
            <label for="hs-checked-checkbox" class="">Checked checkbox</label>
        </div>
    </div>

    <div class="space-y-2">
        <h1>
            Table
        </h1>
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                table
                            </th>
                            <th class="text-left">
                                table
                            </th>
                            <th class="text-left">
                                table
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tbody-tr">
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                        </tr>
                        <tr class="tbody-tr">
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="space-y-2">
            <h1>
                Table
            </h1>
            <div>
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    table
                                </th>
                                <th class="text-left">
                                    table
                                </th>
                                <th class="text-left">
                                    table
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tbody-tr">
                                <td colspan="100" class="text-center py-10">
                                    <div class="flex flex-col justify-center items-center w-full">
                                        <app-loader></app-loader>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="space-y-2">
            <h1>
                Table
            </h1>
            <div>
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    table
                                </th>
                                <th class="text-left">
                                    table
                                </th>
                                <th class="text-left">
                                    table
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tbody-tr">
                                <td colspan="100" class="text-center py-10">
                                    <div class="flex flex-col justify-center items-center w-full">
                                        <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="space-y-2">
        <div>
            Modal
        </div>
        <div class="flex flex-wrap gap-2">
            <button class="btn-light" (click)="exampleModal('info')">info</button>
            <button class="btn-light" (click)="exampleModal('success')">success</button>
            <button class="btn-light" (click)="exampleModal('confirm')">Confirm</button>
            <button class="btn-light" (click)="exampleModal('confirm-warning')">Confirm Waning</button>
            <button class="btn-light" (click)="exampleModal('fail')">fail</button>
        </div>
    </div>
</div>