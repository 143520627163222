<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        เพิ่มช่องราคา
    </div>
    <div class="flex flex-col gap-4 w-full md:w-80 text-sm md:text-base">
        <div>
            <input type="text" class="input" placeholder="ระบุชื่อ" autocomplete="off" #nameInput
                (keyup)="formCreate('name', nameInput.value)">
        </div>
        <div class="w-full">
            <mat-form-field class="w-full">
                <mat-select class="" (selectionChange)="formCreate('price_config_ref', $event.value)">
                    @for (price of data.priceConfig; track $index) {
                    <mat-option [value]="price.price_config_id">
                        {{price.name}}
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex items-center space-x-2">
            <app-toggle [id]="'toggle-create-active'" [callbackToggle]="callbackActiveCreate"
                [defaultValue]="true"></app-toggle>
            <div class="whitespace-nowrap">การใช้งาน</div>
        </div>
        <div class="flex items-center space-x-2">
            <app-toggle [id]="'toggle-create-default'" [callbackToggle]="callbackDefaultCreate"
                [defaultValue]="false"></app-toggle>
            <div class="whitespace-nowrap">ตั้งเป็นช่องราคาหลัก</div>
        </div>
    </div>
    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]"
                (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button mat-button class="btn-primary min-w-[8rem]" (click)="confirm()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>