import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CashPaymentComponent } from '../cash-payment/cash-payment.component';
import { ModalService } from '../../../../core/services/modal/modal.service';

@Component({
  selector: 'app-counter-payment',
  templateUrl: './counter-payment.component.html',
  styleUrl: './counter-payment.component.css'
})
export class CounterPaymentComponent {
  orderStatus: any;
  note: string = '';

  urlImage!: string;
  typeImage!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CashPaymentComponent>,
    private modalService: ModalService,
  ) {
    console.log(data)
  }

  close() {
    this.ref.close(false);
  }

  confirm() {
    if (this.urlImage) {
      this.ref.close({ urlImage: this.urlImage, typeImage: this.typeImage, note: this.note });
    } else {
      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกการชำระเงินได้ กรุณาแนบหลักฐาน')
    }
  }

  callbackUploadImage = (base64: any, type: any): void => {
    this.urlImage = base64;
    this.typeImage = type;
  }

}
