<div class="bg-white w-full min-h-0 max-h-[80vh] flex flex-col justify-center items-center rounded-xl relative">
    <div class="flex-none w-full text-center py-4 relative">
        เพิ่ม Provider
        <button type="button" class="absolute z-10 top-1 right-3" (click)="close()">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
    <form [formGroup]="addProviderForm" (ngSubmit)="onSubmit()" class="flex-1 w-full overflow-auto px-6 pb-6">
        <div class="flex flex-col space-y-4">
            <div class="gap-2">
                <label class="text-sm">ชื่อ Provider *</label>
                <input type="text" class="input" placeholder="ชื่อ Provider" formControlName="name" autocomplete="off">
                <div *ngIf="submitted && f['name'].errors?.['required']" class="text-xs text-red-500">กรุณากรอก
                    ชื่อ Provider</div>
            </div>
            <div class="gap-2">
                <label class="text-sm">ประเภท *</label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="type">
                        @for (item of providerType; track $index) {
                        <mat-option class="" [value]="item">
                            {{item}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <div *ngIf="submitted && f['type'].errors?.['required']" class="text-xs text-red-500">กรุณาเลือก
                    ประเภท Provider</div>
            </div>
        </div>
    </form>
    <div class="flex-none flex justify-center space-x-2 py-4">
        <div>
            <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]"
                (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button mat-button class="btn-primary min-w-[8rem]" (click)="onSubmit()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>