import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-claim-customer-dialog',
  templateUrl: './claim-customer-dialog.component.html',
  styleUrl: './claim-customer-dialog.component.css'
})
export class ClaimCustomerDialogComponent {

  state = 'follow';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<ClaimCustomerDialogComponent>,
  ) {
    console.log(data)
  }

  close() {
    this.ref.close(false);
  }
}
