<div class="bg-white w-fit max-w-96 min-h-fit p-6 rounded-lg relative max-h-[80vh] overflow-y-auto">
    <div class="flex flex-col justify-center items-center space-y-6">
        <div class="w-full text-center">
            ชำระเงินด้วยบัตรเครดิต/เดบิต
        </div>
        <div class="w-full">
            <div class="flex flex-col gap-2 items-center justify-center w-full">
                <div>
                    ยอดรวมสุทธิ
                </div>
                <div class="text-3xl font-bold text-red-500">
                    ฿{{data.total | number}}
                </div>
            </div>
        </div>
        <div class="w-full">
            <div class="relative pb-2">
                <label>หมายเหตุ <span class="text-xs">(ระบุเลขบัตร 4 ตัวท้าย)</span></label>
                <textarea type="text" class="text-area" autocomplete="off" [(ngModel)]="note"></textarea>
            </div>
        </div>
        <div class="flex justify-center space-x-2 pt-4">
            <div>
                <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]"
                    (click)="close()">
                    <div class="w-full text-center">
                        ยกเลิก
                    </div>
                </button>
            </div>
            <div>
                <button mat-button class="btn-primary min-w-[8rem]" (click)="confirm()">
                    <div class="w-full text-center">
                        ยืนยัน
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>