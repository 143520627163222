<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold whitespace-nowrap">
                รายละเอียดเคลม #{{detail.claim_ref}}
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            @if(detail){
            <div class="space-y-5">
                <div class="flex flex-col gap-4 my-4">
                    <div class="flex flex-col justify-center items-center w-full">
                        <div class="">
                            <img [src]="'https://storage.remaxthailand.co.th/img/product/'+ detail.product?.sku +'/1.jpg'"
                                class="w-48 h-48 rounded-lg border"
                                onerror="this.onerror=null; this.src='assets/images/warranty.png'" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        สินค้า
                    </div>
                    <div>
                        {{detail.product?.name}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        Serial Number
                    </div>
                    <div>
                        {{detail.product?.serial_no}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        วันที่ส่งเคลม
                    </div>
                    <div>
                        {{dateFormat(detail.created?.date)}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        อาการชำรุด
                    </div>
                    <div>
                        {{detail.request && detail.request.description? detail.request.description : '-'}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        ผู้เคลม ({{detail.order_no? 'ลูกค้า' : 'ร้านค้า'}})
                    </div>
                    <div>
                        @if(detail.request && (detail.request.firstname || detail.request.lastname ||
                        detail.request.nickname)) {
                        {{detail.request? detail.request.firstname : ''}}
                        {{detail.request? detail.request.lastname : ''}}
                        {{detail.request? '('+ detail.request.nickname +')' : ''}}
                        }@else {
                        -
                        }
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        เบอร์โทรศัพท์
                    </div>
                    <div>
                        @if (detail.request && detail.request.tel) {
                        <a [href]="'tel:'+detail.request.tel">{{detail.request.tel}}</a>
                        }@else {
                        -
                        }
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        สถานะการเคลม
                    </div>
                    <div>
                        {{detail.response && detail.response.claimStatus? detail.response.claimStatus : '-'}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        สถานะการดำเนินการ
                    </div>
                    <div>
                        {{detail.response && detail.response.claimAction? detail.response.claimAction : '-'}}
                    </div>
                </div>
                <div class="h-20"></div>
            </div>
            }
        </div>
        @if (detail.order_no) {
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <button class="btn-secondary w-full" (click)="followUp()">ติดตามสถานะเคลม</button>
        </div>
        }
    </div>
</div>