<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                รายละเอียดพนักงาน
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            @if(detail){
            <div class="space-y-5">
                <div>
                    <div class="text-gray-500">
                        ชื่อพนักงาน
                    </div>
                    <div>
                        {{detail.credential_info.name}}
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        Username
                    </div>
                    <div>
                        {{detail.username}}
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        สิทธิ์เข้าใช้งาน
                    </div>
                    <div class="flex gap-1 flex-wrap">
                        @for(role of checkPermissions(); track $index){
                        <div
                            class="flex items-center px-2 py-0.5 gap-2 bg-blue-100 rounded-md text-blue-500">
                            <div class="w-2 h-2 rounded-full bg-blue-500"></div>
                            <div class="">{{role.name}}</div>
                        </div>
                        }
                    </div>
                </div>

                <div>
                    <div class="text-gray-500">
                        ร้านค้า
                    </div>
                    <div class="mt-2 space-y-2">
                        <div class="flex gap-2">
                            <input type="checkbox" [name]="'select-shop-all'" [id]="'select-shop-all'"
                                [(ngModel)]="isSelectAll" class="cursor-pointer" (change)="selectAllChange($event)" />
                            <label [for]="'select-shop-all'" class="cursor-pointer">เลือกทั้งหมด</label>
                        </div>
                        @for(item of shops; track $index){
                        <div class="flex gap-2">
                            <input type="checkbox" [name]="'select-shop-'+item.shop_id"
                                [id]="'select-shop-'+item.shop_id" [checked]="checkSelected(item.shop_id)"
                                class="cursor-pointer" (change)="changeValue($event, item.shop_id)" />
                            <label [for]="'select-shop-'+item.shop_id" class="cursor-pointer">{{item.name}}</label>
                        </div>
                        }
                    </div>
                </div>

                <div class="h-32"></div>
            </div>
            }
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full grid grid-cols-2 gap-2 pt-2">
                <button class="btn-secondary w-full" (click)="close()">ยกเลิก</button>
                <button class="btn-primary w-full" (click)="submit()">บันทึก</button>
            </div>
        </div>
    </div>
</div>