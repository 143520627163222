import { Component, Input } from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-scroll-button',
  templateUrl: './scroll-button.component.html',
  styleUrl: './scroll-button.component.css'
})
export class ScrollButtonComponent {

  @Input()
  id: string = '';

  faArrowUp = faArrowUp;

  scrollToTop() {
    const element = document.querySelector(`#${this.id}`)
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

}
