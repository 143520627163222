import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { Router } from '@angular/router';
import { faChevronRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { ExchangeService } from '../../services/exchange/exchange.service';
import { ExchangeApiService } from '../../services/api/exchange-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ShopService } from '../../../../core/services/shop/shop.service';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrl: './exchange.component.css'
})
export class ExchangeComponent implements OnInit {

  faChevronRight = faChevronRight;
  faClock = faClock;
  faUser = faUser;
  faArrowDown = faArrowDown;

  shopId: string = this.authService.selectedShop?.shop_id || '';

  isLoaded = false;

  exchangeOrders: any[] = []
  offset: number = 0;
  limit: number = 10;
  countOrder: any = {
    'ongoing': 0,
    'success': 0
  }

  statusGetOrder: any = {
    ongoing: 'ongoing,waiting_refund,waiting_exchange',
    success: 'completed,refunded'
  }

  users = this.shopService.shopUsers;

  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private router: Router,
    public exchangeService: ExchangeService,
    private exchangeApiService: ExchangeApiService,
    private shopService: ShopService
  ) { }

  async ngOnInit(): Promise<void> {
    this.reloadData();
  }

  async getReturnList(status: string): Promise<any[]> {
    return this.exchangeApiService.getAllReturn(this.shopId, this.offset, this.limit, status).then((response: any) => {
      if (!response.success) {
        this.countOrder[this.exchangeService.state] = 0
        return [];
      }
      this.countOrder[this.exchangeService.state] = response.count
      return response.data;
    })
  }

  async getCountReturn(status: string) {
    return this.exchangeApiService.getAllReturn(this.shopId, 0, 1, status).then((response: any) => {
      if (!response.success) return 0;
      return response.count
    })
  }

  routeToExchangeCreate() {
    this.router.navigate(['/exchange/exchange-create']);
  }

  changeState(state: string) {
    if (this.exchangeService.state !== state) {
      this.exchangeService.state = state;
      this.offset = 0;
      this.reloadData();
    }
  }

  async reloadData() {
    this.isLoaded = false;
    if (this.exchangeService.state === 'ongoing') {
      this.exchangeOrders = await this.getReturnList(this.statusGetOrder.ongoing);
      this.countOrder['success'] = await this.getCountReturn(this.statusGetOrder.success)
    } else {
      this.exchangeOrders = await this.getReturnList(this.statusGetOrder.success);
      this.countOrder['ongoing'] = await this.getCountReturn(this.statusGetOrder.ongoing)
    }
    this.isLoaded = true;
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  getUserDetail(user: string) {
    return this.users.find(item => item.user_id === user)
  }

  displayStatusOrder(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_refund') {
      return 'รอคืนเงิน'
    } else if (status === 'waiting_exchange') {
      return 'รอเปลี่ยนสินค้า'
    } else if (status === 'refunded') {
      return 'คืนเงินแล้ว'
    } else if (status === 'completed') {
      return 'เปลี่ยนสินค้าแล้ว'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayStatusColor(status: string) {
    if (status === 'ongoing') {
      return 'text-yellow-500'
    } else if (status === 'waiting_refund' || status === 'waiting_exchange') {
      return 'text-yellow-500'
    } else if (status === 'completed' || status === 'refunded') {
      return 'text-lime-600'
    } else if (status === 'canceled') {
      return 'text-red-500'
    } else {
      return 'text-yellow-500'
    }
  }

  async loadMore() {
    this.offset = this.offset + this.limit;
    let orderResponse: any = [];
    if (this.exchangeService.state === 'ongoing') {
      orderResponse = await this.getReturnList(this.statusGetOrder.ongoing);
    } else {
      orderResponse = await this.getReturnList(this.statusGetOrder.success);
    }
    this.exchangeOrders.push(...orderResponse);
  }

}
