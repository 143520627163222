import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sale-payment-dialog',
  templateUrl: './sale-payment-dialog.component.html',
  styleUrl: './sale-payment-dialog.component.css'
})
export class SalePaymentDialogComponent {
  @Input()
  open: boolean = false;
  @Input()
  isPrinter: boolean = false;
  @Input()
  paymentList: { id: string, img: string, name: string }[] = [];
  @Input()
  total!: number;
  @Output('callBackPayment')
  callBackPayment: EventEmitter<object> = new EventEmitter<object>();

  faChevronDown = faChevronDown;

  selected: any
  defaultPrint: boolean = true;

  close() {
    this.callBackPayment.emit({ confirm: false })
  }

  confirmPayment() {
    this.callBackPayment.emit({ confirm: true, paymentMethod: this.selected, print: this.defaultPrint })
  }

  selectedPayment(value: any) {
    this.selected = value
  }
}
