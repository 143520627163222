import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faFilter, faUndo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-filter-dialog',
  templateUrl: './product-filter-dialog.component.html',
  styleUrl: './product-filter-dialog.component.css'
})
export class ProductFilterDialogComponent implements OnInit {

  @Input('open') open: boolean = false;

  @Input('brands') brands: Array<{ id: string, name: string }> = [];
  @Input('categories') categories: Array<{ id: string, name: string }> = [];
  @Input('stock_status_options') stock_status_options: Array<{ id: string, name: string }> = [];

  @Input('category_id') category_id: string = '';
  @Input('brand_id') brand_id: string = '';
  @Input('stock_status') stock_status: string = '';

  @Output('callBackSubmit')
  callBackSubmit: EventEmitter<object> = new EventEmitter<object>();

  faFilter = faFilter;
  faUndo = faUndo

  constructor(

  ) { }

  ngOnInit(): void {
    this.resetFilter()
  }

  resetFilter() {
    this.category_id = ""
    this.brand_id = ""
    this.stock_status = ""
  }

  submit() {
    this.callBackSubmit.emit({
      confirm: true,
      data: {
        category_id: this.category_id,
        brand_id: this.brand_id,
        stock_status: this.stock_status
      }
    })
  }

  close() {
    this.callBackSubmit.emit({
      confirm: false,
      data: null
    })
  }

}
