import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faScrewdriverWrench, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MerchantService } from '../../services/merchant/merchant.service.js';
import { ModalService } from '../../../../core/services/modal/modal.service.js';
import { AuthService } from '../../../../core/services/auth/auth.service.js';

@Component({
  selector: 'app-merchant-setting-boardcast',
  templateUrl: './merchant-setting-boardcast.component.html',
  styleUrl: './merchant-setting-boardcast.component.css'
})
export class MerchantSettingBoardcastComponent implements OnInit {
  faPlus = faPlus
  faTrash = faTrash;
  faScrewdriverWrench = faScrewdriverWrench;

  form: FormGroup;
  formChanged = false
  shopDetials: any = {}
  shopId: string = this.authService.selectedShop?.shop_id || '';

  loading = false


  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private modalService: ModalService
  ) {
    this.form = this.fb.group({
      line: this.fb.group({
        accessToken: [''],
        channelSecret: [''],
        channelId: [''],
        liffId: [''],
        lineId: ['']
      }),
      // claim_line: this.fb.group({
      //   accessToken: [''],
      //   channelSecret: [''],
      //   channelId: [''],
      //   liffId: ['']
      // }),
    });


  }

  async ngOnInit(): Promise<void> {
    this.loadShopDetails();
    this.form.valueChanges.subscribe(() => {
      this.formChanged = true;
    });

  }

  loadShopDetails() {
    this.loading = true;
    this.merchantService.getOneShop(this.shopId).then(response => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      if (response.success) {
        this.shopDetials = response.data
        const shopDetials = this.shopDetials
        this.form.patchValue({
          line: {
            accessToken: shopDetials?.line?.access_token || '',
            channelSecret: shopDetials?.line?.channel_secret || '',
            channelId: shopDetials?.line?.channel_id || '',
            liffId: shopDetials?.line?.liff_id || '',
            lineId: shopDetials?.line?.line_id || ''
          },
          // claim_line: {
          //   accessToken: shopDetials?.claim_line?.access_token || '',
          //   channelSecret: shopDetials?.claim_line?.channel_secret || '',
          //   channelId: shopDetials?.claim_line?.channel_id || '',
          //   liffId: shopDetials?.claim_line?.liff_id || ''
          // }
        });
        this.formChanged = false
      } else {
        console.log('load shop failed')
      }
    })
  }

  onSubmit() {
    if (!this.form.valid) return
    const data = this.form.getRawValue()
    console.log(data);
    let updateData: any = {
      line: {
        access_token: `${data.line.accessToken}`,
        channel_secret: `${data.line.channelSecret}`,
        channel_id: `${data.line.channelId}`,
        liff_id: `${data.line.liffId}`,
        line_id: `${data.line.lineId}`,
      },
      // claim_line: {
      //   access_token: `${data.claim_line.accessToken}`,
      //   channel_secret: `${data.claim_line.channelSecret}`,
      //   channel_id: `${data.claim_line.channelId}`,
      //   liff_id: `${data.claim_line.liffId}`,
      // },
    }
    console.log(updateData)
    this.merchantService.updateshop(this.shopId, updateData).then(response => {
      this.formChanged = false;
      if (response.success) {
        this.loadShopDetails()
        this.modalService.openModal('success', 'อัพเดตข้อมูลสำเร็จ', '').then(ok => {
          console.log('ok')
        })
      } else {
        console.log('failed')
        this.modalService.openModal('success', 'เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  onCancel() {
    this.loadShopDetails()
  }
}
