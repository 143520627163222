import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-promotion-create-dialog',
  templateUrl: './promotion-create-dialog.component.html',
  styleUrl: './promotion-create-dialog.component.css'
})
export class PromotionCreateDialogComponent {
  toggleAll: any = true;

  errorAlert: any = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<PromotionCreateDialogComponent>
  ) {
    this.setDefaultData()

    console.log(this.data)
  }

  setDefaultData() {
    if (!this.data.formCreatePromotion.unit) {
      this.data.formCreatePromotion.unit = 'amount'
    }
    if (this.data.formCreatePromotion.active === undefined || this.data.formCreatePromotion.active == null) {
      this.data.formCreatePromotion.active = true;
    }
    if (this.data.formCreatePromotion.shops && this.data.formCreatePromotion.shops.length > 0) {
      this.toggleAll = false
    }else {
      this.toggleAll = false
      this.toggleAllSelection()
    }

    console.log( this.data)
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    console.log(this.validateInput())
    if (this.validateInput()) {
      this.ref.close({ confirm: true, data: this.data });
    }
  }

  validateInput() {
    this.errorAlert = {};
    if (this.data.formCreatePromotion.name && this.data.formCreatePromotion.value && this.data.formCreatePromotion.minimum >= 0) {
      return true
    } else {
      console.log(this.data.formCreatePromotion.name)
      if (!this.data.formCreatePromotion.name) {
        this.errorAlert['name'] = 'กรุณากรอกชื่อ'
      } else if (!this.data.formCreatePromotion.value) {
        this.errorAlert['value'] = 'กรุณากรอกมูลค่า'
      } else if (this.data.formCreatePromotion.minimum === undefined || this.data.formCreatePromotion.minimum == null) {
        this.errorAlert['minimum'] = 'กรุณากรอกยอดสั่งซื้อขั้นต่ำ'
      }
      return false
    }
  }

  formCreateString(name: string, value: any) {
    this.data.formCreatePromotion[`${name}`] = value;
  }

  formCreateNumber(name: string, value: any) {
    this.data.formCreatePromotion[`${name}`] = parseFloat(value);
  }

  toggleAllSelection() {
    this.toggleAll = !this.toggleAll

    console.log(this.toggleAll)
    if (this.toggleAll) {
      this.data.formCreatePromotion.shops = []
    } else {
      delete this.data.formCreatePromotion.shops
    }

    console.log(this.data.formCreatePromotion)
  }

  changeUnit(value: string) {
    this.data.formCreatePromotion.unit = value
  }

  callbackActiveCreate = (value: any): void => {
    this.data.formCreatePromotion.active = value;
  }
}
