<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh" [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div
            class="absolute z-50 top-0 right-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center justify-between cursor-pointer">
            <div class="font-semibold flex space-x-1 items-center">
                <fa-icon [icon]="faFilter" class=""></fa-icon>
                <div class="">ตัวกรอง</div>
            </div>
            <button class="text-red-500 flex space-x-1 items-center" (click)="resetFilter()">
                <fa-icon [icon]="faUndo" class=""></fa-icon>
                <div class="">ล้างตัวกรอง</div>
            </button>
        </div>
        <div class="w-full h-full flex flex-col justify-between pt-16 pb-10 px-4 overflow-auto">
            <div class="grid grid-cols-1 gap-3">
                <div class="w-full">
                    <label for="selected-category">หมวดหมู่</label>
                    <mat-form-field class="w-full">
                        <mat-select id="selected-category" class="" [(ngModel)]="category_id">
                            @for (category of categories; track $index){
                            <mat-option [value]="category.id"> {{category.name}} </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-full">
                    <label for="selected-brand">แบรนด์</label>
                    <mat-form-field class="w-full">
                        <mat-select id="selected-brand" class="" [(ngModel)]="brand_id">
                            @for (brand of brands; track $index){
                            <mat-option [value]="brand.id"> {{brand.name}} </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-full">
                    <label for="selected-brand">สถานะ</label>
                    <mat-form-field class="w-full">
                        <mat-select id="selected-brand" class="" [(ngModel)]="stock_status">
                            @for (option of stock_status_options; track $index){
                            <mat-option [value]="option.id"> {{option.name}} </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div
            class="absolute bottom-0 right-0 w-full flex flex-row-reverse md:flex-col gap-4 px-4 py-4 bg-white shadow-lg">
            <button class="w-full btn-primary" (click)="submit()">
                กรองข้อมูล
            </button>
            <button class="w-full btn-secondary" (click)="close()">
                ยกเลิก
            </button>
        </div>
    </div>
</div>