<div class="m-2 flex flex-col lg:flex-row items-center space-x-1 text-gray-500 text-base justify-between">

    <div class="flex lg:hidden text-sm py-2">
        แสดง {{((pageIndex)*pageSize)+1}} ถึง {{(((pageIndex+1)*pageSize) > length) ? length :
        ((pageIndex+1)*pageSize)}}
    </div>
    <div class="hidden lg:flex text-sm">แสดง {{((pageIndex)*pageSize)+1}} ถึง {{(((pageIndex+1)*pageSize) > length) ?
        length: ((pageIndex+1)*pageSize)}} จาก {{length |
        number}} รายการ</div>

    <div class="flex space-x-2">

        <div class="flex flex-row items-center space-x-1">
            <div class="cursor-pointer hover:text-yellow-500 h-8 rounded-full p-1 px-2 flex items-center justify-center gap-2 text-sm"
                (click)="goToPreviousPage()">
                <fa-icon [icon]="faAngleLeft" class=""></fa-icon>
                <span class="hidden lg:block">ก่อนหน้า</span>
            </div>
            <div class="cursor-pointer w-8 h-8 rounded-md p-1 flex items-center justify-center"
                (click)="goToFirstPage()"
                [ngClass]="{'bg-yellow-400 aspect-square !p-1 !text-white': pageIndex === 0, 'hover:text-yellow-500': pageIndex !== 0}">
                1
            </div>
            <div *ngIf="lastPage > 5 && pageIndex > 2">
                ...
            </div>
            <ng-container *ngFor="let item of pageList">
                <div class="cursor-pointer w-8 h-8 rounded-md p-1 flex items-center justify-center"
                    [ngClass]="{'bg-yellow-400 aspect-square !p-1 !text-white': item === pageIndex, 'hover:text-yellow-500 ': item !== pageIndex}"
                    (click)="goToPageIndex(item)">
                    {{item+1}}
                </div>
            </ng-container>
            <div *ngIf="lastPage > 5 && pageIndex < (lastPage-3)">
                ...
            </div>
            <div class="cursor-pointer w-8 h-8 rounded-md p-1 flex items-center justify-center" *ngIf="lastPage > 1"
                (click)="goToLastPage()"
                [ngClass]="{'bg-yellow-400 aspect-square !p-1 !text-white': pageIndex === lastPage-1, 'hover:text-yellow-500': pageIndex !== lastPage-1}">
                {{lastPage}}
            </div>
            <div class="cursor-pointer hover:text-yellow-500 h-8 rounded-md p-1 px-2 flex items-center justify-center gap-2 text-sm"
                (click)="goToNextPage()">
                <span class="hidden lg:block">ถัดไป</span>
                <fa-icon [icon]="faAngleRight" class=""></fa-icon>
            </div>
        </div>


        <div class="hidden lg:flex items-center space-x-2">
            <div class="w-28 flex items-center">
                <mat-form-field class="">
                    <mat-select class="!h-8 !text-xs" [(value)]="pageSize" (valueChange)="pageSizeChange($event)">
                        <mat-option *ngFor="let size of pageSizeOptions" [value]="size" class="!text-xs">
                            {{size}} / หน้า
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </div>

</div>