import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../services/util/util.service';
import { faCalendarMinus } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrl: './notify.component.css'
})
export class NotifyComponent {

  @Input('open')
  open: boolean = false;
  @Input('notice')
  notice: any;

  @Input('title')
  title: string = '';
  @Input('detail')
  detail: string = '';
  @Input('date')
  date: number = 0;

  @Output('callBackNotifyUpdate')
  callBackNotifyUpdate: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;
  faCheck = faCheck;
  faCalendarMinus = faCalendarMinus;

  constructor(
    private utilService: UtilService,
  ) { }

  displayDatetime(date: number) {
    if (+this.utilService.dayjs() - date > (86400000 * 3)) {
      return this.utilService.dayjs(date).format('DD/MM/YYYY HH:mm')
    }
    return this.utilService.dayjs(date).fromNow()
  }

  markAsRead() {
    this.callBackNotifyUpdate.emit({ confirm: true, read: true })
  }

  close() {
    this.callBackNotifyUpdate.emit({ confirm: false })
  }

}
