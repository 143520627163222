import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StockOutApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getOneStockOut(stockOutNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/stock-out/${stockOutNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllStockOut(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/stock-out?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createOrderStockOut(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/products/stock-out/new`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  addStockOutItem(stockOutNo: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/products/stock-out/${stockOutNo}/products`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  removeStockOutItem(stockOutNo: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/products/stock-out/${stockOutNo}/products/remove`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateStockOutWaiting(stockOutNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/products/stock-out/${stockOutNo}/status`, { status: 'waiting_confirm' }, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  cancelStockOut(stockOutNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/products/stock-out/${stockOutNo}/cancel`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  confirmStockOut(stockOutNo: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/products/stock-out/${stockOutNo}/confirm`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }


}
