<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">ระบบสมาชิก</div>
    </div>
    <div class="card">
        @if (loading) {
        <div class="flex justify-center py-20">
            <app-loader></app-loader>
        </div>
        } @else {
        <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div class="">
                <div class="font-semibold">ลิงค์สำหรับระบบสมาชิก</div>
            </div>
            <div class="">
                @if(lineId){
                <div class="flex w-full justify-center">
                    <div class="flex justify-center items-center">
                        <qrcode #qrCodeCanvas [qrdata]="urlMember" [allowEmptyString]="true" [cssClass]="'center'"
                            [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
                            [errorCorrectionLevel]="'M'" [imageHeight]="60" [imageWidth]="60" [margin]="4" [scale]="1"
                            [width]="288"></qrcode>
                    </div>
                </div>
                <div class="w-full flex justify-center">
                    <button (click)="downloadQRCode()"
                        class="flex space-x-2 w-fit rounded-md bg-gray-200 shadow transition duration-300 hover:bg-yellow-500 py-2 px-4">
                        <fa-icon [icon]="faDownload" class=""></fa-icon>
                        <div class="">บันทึก QR Code</div>
                    </button>
                </div>
                <div class="mt-6">
                    <div class="flex rounded-lg border">
                        <input type="text" class="flex-1 py-3 px-4 cursor-text rounded-lg bg-white" [value]="urlMember"
                            disabled>
                        <div class="flex-none p-1">
                            <button
                                class="bg-slate-200 rounded-md p-3 text-sm transition duration-300 hover:bg-yellow-500"
                                [cdkCopyToClipboard]="urlMember" (click)="copyText()">
                                {{copied ? 'คัดลอกแล้ว!':'คัดลอก'}}
                            </button>
                        </div>
                    </div>
                </div>
                }@else {
                <div class="flex w-full justify-center">
                    <div class="relative flex justify-center items-center mb-4">
                        <qrcode [qrdata]="urlMember" [allowEmptyString]="true" [cssClass]="'center'"
                            [colorDark]="'#00000052'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
                            [errorCorrectionLevel]="'M'" [imageHeight]="60" [imageWidth]="60" [margin]="4" [scale]="1"
                            [width]="288"></qrcode>
                        <div class="absolute bg-white p-2 border text-center">
                            <div class="font-medium text-red-500">ร้านค้าของคุณยังไม่มี Liff ID สำหรับระบบสมาชิก!
                            </div>
                            <div class="text-sm">กรุณาเพิ่ม Liff ID
                                ที่เมนูตั้งค่าร้านค้าเพื่อให้สามารถใช้งานฟังก์ชันนี้ได้</div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
        }
    </div>
</div>