<div
    class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-fit p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">

    <div class="flex gap-2 w-full border-b border-dashed pb-3">
        <div class="flex gap-1">
            <fa-icon [icon]="faCrown" class="text-yellow-500"></fa-icon>
            <div>สมาชิก</div>
        </div>
        @if (member?.member?.userId) {
        <div class="flex w-full gap-2 justify-between">
            <div>คุณ{{member?.member?.name}}</div>
            <button class="text-sm text-gray-600" (click)="onAddFriend()">
                เพิ่มเติม
                <fa-icon [icon]="faChevronRight" class="text-xs"></fa-icon>
            </button>
        </div>
        }@else {
        <div class="flex justify-start w-full">
            <button (click)="onAddFriend()">
                <div class="flex gap-2 items-center bg-yellow-500 rounded-md px-2 text-sm">
                    <fa-icon [icon]="faPlus" class="flex text-xs"></fa-icon>
                    ข้อมูลสมาชิก
                </div>
            </button>
        </div>
        }
    </div>

    <div class="py-2 border-b">
        <div class="flex justify-between">
            <div class="text-sm md:text-base">
                รวม
            </div>
            <div class="">
                @if (price) {
                {{ price | number: '1.2-2' }}฿
                } @else {
                {{ 0 }}฿
                }
            </div>
        </div>

        <div class="flex justify-between">
            <div class="text-sm md:text-base">
                ส่วนลด
            </div>
            <div class="text-red-500">
                @if (discount) {
                <button class="font-medium">
                    <span class="">-</span>{{ discount | number: '1.2-2' }}฿
                </button>
                }
            </div>
        </div>

    </div>

    <div class="flex justify-between items-end pt-2">
        <div class="font-bold">
            ยอดคืนสินค้าเดิม
        </div>
        <div class="font-bold">
            @if (total) {
            {{ total | number: '1.2-2' }}฿
            } @else {
            <span class="text-xl">{{ 0 }}</span>฿
            }
        </div>
    </div>

    <div class="grid grid-cols-2 gap-2 pt-2">
        <button class="btn-secondary" (click)="routeToExchange()">
            ย้อนกลับ
        </button>
        <button class="btn-primary" (click)="onConfirmCreateReturn()">
            ดำเนินการต่อ
        </button>
    </div>
</div>