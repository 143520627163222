<div class="p-4">
    <div class="pb-4">
        <button class="btn-secondary w-full" (click)="routeToStockOutCreate()">
            สร้างรายการคืนสำนักงาน
        </button>
    </div>
    <div class="pt-4 border-t space-y-1">

        <div class="font-bold">
            รายการคืนสำนักงาน
        </div>

        <div class="pb-2">
            <div class="rounded-lg bg-white grid grid-cols-2 divide-x">
                <button class="text-center py-1.5 rounded-l-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : stockOutService.state === 'ongoing'}"
                    (click)="changeState('ongoing')">
                    รอดำเนินการ ({{countStockOut['ongoing']}})
                </button>
                <button class="text-center py-1.5 rounded-r-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : stockOutService.state !== 'ongoing'}"
                    (click)="changeState('success')">
                    สำเร็จ ({{countStockOut['success']}})
                </button>
            </div>
        </div>


        @if (isLoaded) {
        @if(stockOuts.length === 0) {
        <div>
            <div class="flex flex-col justify-center items-center w-full pt-10">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
            </div>
            <div class="text-center pt-4">ไม่พบรายการส่งคืนในขณะนี้</div>
        </div>
        }
        @for (item of stockOuts; track $index) {
        <div class="border bg-white rounded-lg px-4 py-3 cursor-pointer" routerLink="stock-out-detail"
            [queryParams]="{stock_out_no: item.stock_out_no}">
            <div class="h-full flex justify-between">
                <div class="space-y-1">
                    <div>
                        <span class="font-bold text-sm">{{item.stock_out_no}}</span>
                        <span class="text-sm pl-1" [ngClass]="displayStatusColor(item.status)">
                            ({{ displayStatusOrder(item.status) }})
                        </span>
                    </div>
                    <div class="flex flex-wrap gap-x-2 gap-y-1">
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faClock"></fa-icon>
                            {{ dateFormat(item.stock_out_date) }}
                        </div>
                        <div class="text-sm text-gray-600 bg-gray-50 w-fit pl-2 pr-3 py-0.5 rounded">
                            <fa-icon [icon]="faUser"></fa-icon>
                            {{ getUserDetail(item.created.by)?.name }}
                        </div>
                    </div>
                    <div class="">
                        <span class="text-gray-600 text-sm">รวมสุทธิ ({{item.items.length | number}} ชิ้น)</span>
                    </div>
                </div>

                <div class="content-center">
                    <button>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </button>
                </div>


            </div>

        </div>
        }

        <div class="flex items-center justify-center w-full pt-4 space-x-4"
            *ngIf="countStockOut[stockOutService.state] > 10 && countStockOut[stockOutService.state] > stockOuts.length">
            <button (click)="loadMore()"
                class="btn-light !py-1 w-full lg:w-fit flex justify-between md:justify-center md:space-x-6 xl:space-x-8 items-center">
                <div class="flex space-x-1 justify-start items-center">
                    <div>แสดง</div>
                    <div class="text-primary-500 font-medium">{{stockOuts.length}}</div>
                    <div>/</div>
                    <div>{{countStockOut[stockOutService.state]}}</div>
                </div>
                <div class="flex space-x-2 justify-end items-center font-medium">
                    <div>แสดงเพิ่มเติม</div>
                    <div><fa-icon [icon]="faArrowDown"></fa-icon></div>
                </div>
            </button>
        </div>

        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }

    </div>

</div>