<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="font-bold">
        นำเข้าคำสั่งซื้อ
    </div>
    <div class="flex flex-col gap-4 w-full md:w-80 text-sm md:text-base">
        <div>
            <input type="text" class="input" placeholder="หมายเลขคำสั่งซื้อ" autocomplete="off"
                [(ngModel)]="data.form.purchase_order_no" required>
            @if (errorAlert['purchase_order_no']) {
            <span class="text-xs md:text-sm text-red-500">*{{errorAlert['purchase_order_no']}}</span>
            }
            <p class="pt-2  text-gray-500 text-sm">ดูจากใบส่งสินค้า เช่น 2402Bxxxxx</p>
            <p class="pt-2  text-gray-500 text-sm">รายการสินค้าจะยึดตามรายการที่แพ็คได้</p>
        </div>
        <div class="flex justify-center space-x-2 pt-4">
            <div>
                <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]"
                    (click)="close()">
                    <div class="w-full text-center">
                        ยกเลิก
                    </div>
                </button>
            </div>
            <div>
                <button mat-button class="btn-primary min-w-[8rem]" (click)="confirm()">
                    <div class="w-full text-center">
                        ยืนยันนำเข้า
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>