<div class="p-4 w-full space-y-2 text-sm lg:text-base relative">
    <div class="font-bold text-base lg:text-lg"> รายการโปรโมชั่น </div>
    <div class="flex justify-end">
        <button class="btn-light !border flex space-x-2 items-center" (click)="clickCreatePromotion()">
            <fa-icon [icon]="faPlus" class=""></fa-icon>
            <div class="">เพิ่มโปรโมชั่น</div>
        </button>
    </div>
    @if (isLoading) {
    <div class="flex justify-center pt-40">
        <app-loader></app-loader>
    </div>
    }@else {
    <!-- For Mobile -->
    <div class="lg:hidden grid gap-1">
        @if(!promotionList || promotionList.length === 0){
        <div class="text-center text-base py-10">
            <div class="flex flex-col justify-center items-center w-full">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                <div class="text-center pt-4">ไม่พบรายการโปรโมชั่น</div>
            </div>
        </div>
        }
        @for (promotion of promotionList; track $index) {
        <button class="border bg-white rounded-lg p-3 text-left" (click)="clickDetailPromotion(promotion)">
            <div class="font-bold ">
                @if (promotion.unit === 'amount') {
                <fa-icon [icon]="faTags"></fa-icon>
                }@else if (promotion.unit === 'percent') {
                <fa-icon [icon]="faPercent"></fa-icon>
                }
                {{promotion.name}}
            </div>

            <div class=" flex gap-2">
                <div class="space-x-1">
                    <span>รับส่วนลด</span>
                    <span class=" font-bold">{{promotion.value}}</span>
                    <span>{{promotion.unit === 'amount'? 'บาท' :'%'}}</span>
                </div>
                <div>
                    @if (promotion.minimum > 0) {
                    เมื่อซื้อสินค้าครบ <span class=" font-bold">{{promotion.minimum}}</span> บาท
                    }@else {
                    ไม่มีขั้นต่ำ
                    }
                </div>
            </div>

            <div class=" text-gray-500">
                @if (promotion.maximun) {
                ลดได้สูงสุด <span class=" font-bold">{{promotion.maximun}}</span>
                }
            </div>

            <div class="flex justify-between">
                <div class=" text-blue-500">
                    @if (promotion.shops.length > 0) {
                    ร้านค้าที่เข้าร่วม {{promotion.shops.length}} ร้านค้า
                    }@else {
                    ทุกร้านค้าเข้าร่วม
                    }
                </div>
                <div class="flex items-center gap-1"
                    [ngClass]="{'text-lime-500': promotion.active, 'text-red-500': !promotion.active}">
                    <div class="rounded-full h-2 w-2"
                        [ngClass]="{'bg-lime-500': promotion.active, 'bg-red-500': !promotion.active}"></div>
                    {{promotion.active? 'เปิดใช้งาน': 'ปิดใช้งาน'}}
                </div>
            </div>

        </button>
        }
    </div>

    <!-- For Desktop -->
    <div class="hidden lg:grid grid-cols-1 gap-2">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                ชื่อ
                            </th>
                            <th class="text-left">
                                เงื่อนไข
                            </th>
                            <th class="text-center">
                                ร้านค้าที่เข้าร่วม
                            </th>
                            <th class="text-center">
                                เปิด/ปิดใช้งาน
                            </th>
                        </tr>
                    </thead>
                    @if(!promotionList || promotionList.length === 0){
                    <tbody>
                        <tr class="tbody-tr">
                            <td colspan="100" class="text-center py-10">
                                <div class="flex flex-col justify-center items-center w-full">
                                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                    <div class="text-center pt-4">ไม่พบรายการโปรโมชั่น</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }
                    @else {
                    <tbody>
                        @for (promotion of promotionList; track $index) {
                        <tr class="tbody-tr cursor-pointer" (click)="clickDetailPromotion(promotion)">
                            <td>
                                <div class="h-12 content-center">
                                    {{promotion.name}}
                                </div>
                            </td>
                            <td>
                                <div class=" flex gap-2">
                                    <div class="space-x-1">
                                        <span>รับส่วนลด</span>
                                        <span class=" font-bold">{{promotion.value}}</span>
                                        <span>{{promotion.unit === 'amount'? 'บาท' :'%'}}</span>
                                    </div>
                                    <div>
                                        @if (promotion.minimum > 0) {
                                        เมื่อซื้อสินค้าครบ <span class=" font-bold">{{promotion.minimum}}</span> บาท
                                        }@else {
                                        ไม่มีขั้นต่ำ
                                        }
                                    </div>
                                </div>

                                <div class=" text-gray-500">
                                    @if (promotion.maximun) {
                                    ลดได้สูงสุด <span class=" font-bold">{{promotion.maximun}}</span>
                                    }
                                </div>
                            </td>
                            <td class="text-center">
                                @if (promotion.shops.length > 0) {
                                {{promotion.shops.length}} ร้านค้า
                                }@else {
                                ทุกร้านค้า
                                }
                            </td>
                            <td>
                                <div class="flex items-center justify-center gap-1"
                                    [ngClass]="{'text-lime-500': promotion.active, 'text-red-500': !promotion.active}">
                                    <div class="rounded-full h-2 w-2"
                                        [ngClass]="{'bg-lime-500': promotion.active, 'bg-red-500': !promotion.active}">
                                    </div>
                                    {{promotion.active? 'เปิดใช้งาน': 'ปิดใช้งาน'}}
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                    }
                </table>
            </div>
        </div>
    </div>
    }
</div>

<div>
    <!-- <app-sale-add-discount-dialog [open]="addDiscount" [defaultValue]="selectDiscount.value" [defaultNote]="selectDiscount.note"
        [defaultUnit]="selectDiscount.unit" [defaultRef]="selectDiscount.ref" [defaultType]="selectDiscount.type"
        (callBackAddDiscount)="callBackAddDiscount($event)" (callBackRemoveDiscount)="callBackRemoveDiscount($event)"></app-sale-add-discount-dialog> -->

    <app-promotion-detail-dialog [open]="showDetail" [promotion]="promotionDetail" [shops]="shopAll"
        (callBackPromotionDetail)="callBackPromotionDetail($event)"></app-promotion-detail-dialog>
</div>