import { Component } from '@angular/core';
import { UtilService } from '../../services/util/util.service';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard/auth-guard.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {

  faXmark = faXmark;
  currentPath = '';
  currentFullPath = '';

  menuList: any[] = []

  constructor(
    protected utilService: UtilService,
    private router: Router,
    public authGuardService: AuthGuardService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentFullPath = this.router.url;
        this.currentPath = this.router.url.split('?')[0].split('/')[1];
      }
    });

    this.menuList = this.utilService.menuList;
    this.recheck()
  }

  recheck() {
    this.currentPath = this.router.url.split('?')[0].split('/')[1];

  }

  toggleMenu() {
    this.utilService.toggleNavbar()
  }

  gotoPath(path: string) {
    this.router.navigate([path]).then(() => {
      this.toggleMenu();
    })
  }
}
