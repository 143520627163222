<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronDown" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                Check out
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto pb-36">
            <div class="space-y-4">
                <div class="flex flex-col gap-2 items-center justify-center w-full">
                    <div>
                        ยอดรวมสุทธิ
                    </div>
                    <div class="text-3xl">
                        ฿{{total | number}}
                    </div>
                </div>
                <div class="flex justify-center w-full">
                    <div class="grid grid-cols-2 gap-6 w-4/5">
                        @for (payment of paymentList; track $index) {
                        <div class="flex flex-col justify-center items-center gap-4 bg-gray-200 rounded-lg w-full md:w-32 aspect-square active:bg-gray-300 transform transition-all duration-100"
                            [ngClass]="{'bg-yellow-500': selected && selected.id === payment.id}"
                            (click)="selectedPayment(payment)">
                            <div>
                                <img [src]="payment.img" class="w-[50px] h-[50px]" />
                            </div>
                            <div class="text-center">
                                {{payment.name}}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div class="h-36"></div>
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full space-y-4">
                @if(isPrinter){
                <div class="flex justify-center gap-2">
                    <input type="checkbox" name="print-receipt" id="print-receipt" [(ngModel)]="defaultPrint" class="" />
                    <label for="print-receipt" class="">พิมพ์ใบเสร็จอัตโนมัติ</label>
                </div>
                }
                <button class="w-full btn-primary" (click)="confirmPayment()">
                    ยืนยัน
                </button>
            </div>
        </div>
    </div>
</div>