import { Component, ElementRef, ViewChild } from '@angular/core';
import { faArrowRight, faBarcode, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { SerialApiService } from '../../services/api/serial-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthGuardService } from '../../../../core/services/auth-guard/auth-guard.service';

interface ProductPrice {
  id: string;
  name: string;
  price: number;
}

@Component({
  selector: 'app-check-serial',
  templateUrl: './check-serial.component.html',
  styleUrl: './check-serial.component.css'
})
export class CheckSerialComponent {

  faBarcode = faBarcode
  faArrowRight = faArrowRight
  faShieldAlt = faShieldAlt

  @ViewChild('barcodeInput') barcodeInputElement: ElementRef | undefined;

  barcodeValue: string = '';
  productDetail: any;
  transactions: Array<any> = [];
  productPrice: Array<ProductPrice> = [];
  productPoint: number = 0;
  productBarcode: string = '';
  shopSerial: string = '';

  existedSerial: any;

  shopId: string = this.authService.selectedShop?.shop_id || '';

  isLoaded: boolean = true;
  processing: boolean = false


  permissionsPrice = ['admin', 'manager']

  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private serialApiService: SerialApiService,
    private modalService: ModalService,
    private authGaurdService: AuthGuardService
  ) { }

  async ngOnInit(): Promise<any> {
    setTimeout(() => {
      this.barcodeInputElement?.nativeElement.focus();
    }, 10);
  }

  formatWarranty(warranty: number) {
    const year = Math.floor(warranty / 365);
    const month = ((warranty % 365) / 30);
    let value = ''
    if (year > 0) {
      value += `${year} ปี `;
    }
    if (month > 0) {
      value += `${month} เดือน`;
    }
    return value;
  }

  async scanBarcode() {
    this.resetData();
    if (this.barcodeValue) {
      this.barcodeValue = this.barcodeValue?.trim() || ''
      this.isLoaded = false;
      this.productBarcode = this.barcodeValue

      const resultTransactionPromise = this.getProductTransaction(this.barcodeValue)
      const resultSerial = await this.getProductBySerialNo(this.barcodeValue)
      console.log(resultSerial)
      if (!resultSerial[this.barcodeValue] || !resultSerial[this.barcodeValue]?.sku) {
        this.modalService.openModal('fail', 'ไม่พบ Barcode ในระบบ', 'กรุณาตรวจสอบ barcode ใหม่อีกครั้ง')
        this.resetBacodeValue();
        return;
      }

      const sku = resultSerial[this.barcodeValue].sku
      const [resultPriceConfig, resultProductShop, resultProduct, resultPoint, resultExistedSerial]: any = await Promise.all([
        this.getPriceConfig(this.shopId),
        this.getProductShopBySku(this.shopId, sku),
        this.getProductBySku(sku),
        this.getPointBySku(sku),
        this.getSerialDetail(this.barcodeValue)
      ])

      const resultTransaction = await resultTransactionPromise;
      this.transactions = resultTransaction?.transaction?.sort((a: any, b: any) => new Date(b.date || (+new Date())).getTime() - new Date(a.date || (+new Date())).getTime()) || [];
      this.productDetail = resultProduct;
      this.productPoint = resultPoint?.point || 0
      this.shopSerial = this.transactions[0]?.shopId || ''
      this.existedSerial = resultExistedSerial

      const priceConfigfiltered = this.canAccessPrice() ? resultPriceConfig : resultPriceConfig.filter((e: any) => e.active);
      this.productPrice = priceConfigfiltered.map((e: any) => {
        return {
          id: e.price_config_id,
          name: e.name,
          price: resultProductShop?.prices?.find((price: { id: string; price: number }) => price.id === e.price_config_id)?.price || 0
        }
      })

      if (!resultProduct) {
        this.modalService.openModal('info', 'ไม่พบข้อมูลสินค้า', 'โปรดติดต่อเจ้าที่เพื่ออัพเดตข้อมูลสินค้าก่อน')
      }

      this.resetBacodeValue();

    } else {
      this.modalService.openModal('fail', 'กรุณากรอก Barcode', '')
    }
  }

  canAccessPrice() {
    return this.permissionsPrice.includes(this.authService.user.role)
  }

  isShopSerial() {
    if (this.shopId.length > 0) {
      return this.shopId === this.shopSerial ? true : false
    } else {
      return false
    }
  }

  canReceipt() {
    return this.authGaurdService.canAction(['manager', 'admin'])
  }

  resetData() {
    this.transactions = [];
    this.productDetail = undefined;
    this.productPoint = 0;
    this.productPrice = [];
    this.shopSerial = ''
    this.existedSerial = undefined
  }

  resetBacodeValue() {
    this.isLoaded = true;
    this.barcodeValue = ''
  }

  async getProductBySerialNo(serialNo: string) {
    const body = { serial: [serialNo] }
    return await this.serialApiService.getProductBySerialNo(body).then((response: any) => {
      return response.data || null
    })
  }

  async getProductTransaction(serialNo: string) {
    return await this.serialApiService.getProductTransaction(serialNo).then((response: any) => {
      return response.data || null
    })
  }

  async getPriceConfig(shopId: string) {
    return await this.serialApiService.getAllPriceConfigByShop(shopId, {}).then((response: any) => {
      return response.data || []
    })
  }

  async getProductShopBySku(shopId: string, sku: string) {
    return await this.serialApiService.getProductShopBySku(shopId, sku).then((response: any) => {
      return response.data || null
    })
  }

  async getProductBySku(sku: string) {
    return await this.serialApiService.getProductBySku(sku, {}).then((response: any) => {
      return response.data || null
    })
  }

  async getPointBySku(sku: string) {
    return await this.serialApiService.getPointBySku(sku).then((response: any) => {
      return response.data || null
    })
  }

  async getSerialDetail(serial_no: string) {
    return await this.serialApiService.getOneSerial(serial_no).then((response: any) => {
      return response.data || null
    })
  }

  confirmReceipt() {

    if (this.shopId.length === 0) {
      this.modalService.openModal('info', 'ร้านค้าไม่ถูกต้อง', 'ไม่สามารถสร้างใบรับเข้าได้ เนื่องจากไม่ใช่จากร้านค้าล่าสุดที่ทำรายการ')
      return;
    }

    if (this.shopSerial !== this.shopId) {
      this.modalService.openModal('info', 'ร้านค้าไม่ถูกต้อง', 'ไม่สามารถสร้างใบรับเข้าได้ เนื่องจากไม่ใช่จากร้านค้าล่าสุดที่ทำรายการ')
      return;
    }

    this.modalService.openModal('confirm', 'ยืนยันการรับเข้าสินค้า', 'ระบบจะทำการสร้างใบรับเข้า').then(async (result: any) => {
      if (result) {
        this.processing = true
        const body = {
          serial_no: this.productBarcode,
          shop_id: this.shopId
        }
        this.serialApiService.createSerialByLostSerial(body).then((response: any) => {
          console.log(response)
          this.processing = false;

          if (response.success) {
            this.modalService.openModal('success', 'สร้างใบรับเข้าสำเร็จ', '')
          } else {
            let message = 'ไม่สามารถสร้างใบรับเข้าได้';
            if (response.message === 'can not get transaction') {
              message = 'ไม่สามารถโหลดข้อมูลโหลดประวัติสินค้าได้'
            } else if (response.message === 'can not get cost') {
              message = 'ไม่สามารถโหลดต้นทุนสินค้าได้'
            } else if (response.message === 'not found cost') {
              message = 'ไม่พบต้นทุนของสินค้านี้ กรุณาตรวจสอบบาร์โค้ดลองและใหม่อีกครั้ง'
            } else if (response.message === 'shop id not match') {
              message = 'ไม่สามารถสร้างใบรับเข้าได้ เนื่องจากพบบาร์โค้ดนี้พร้อมขายในร้านอื่น กรุณาตรวจสอบสินค้าที่ร้านค้าต้นทางหรือติดต่อเจ้าหน้าที่'
            } else if (response.message === 'can not get shop') {
              message = 'ไม่สามารถโหลดข้อมูลร้านค้า กรุณาตรวจสอบบาร์โค้ดและลองใหม่อีกครั้ง'
            } else if (response.message === 'get receipt failed') {
              message = 'ไม่สามารถโหลดใบรับเข้า กรุณาลองใหม่อีกครั้ง'
            } else if (response.message === 'receipt already existed') {
              message = 'หมายเลขใบรับเข้าซ้ำ กรุณาลองใหม่อีกครั้ง'
            } else if (response.message === 'can not get serial') {
              message = 'ไม่สามารถโหลดข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง'
            } else if (response.message === 'serial already existed') {
              message = 'สินค้านี้อยู่ในระบบแล้ว'
            } else if (response.message === 'can not get receipt') {
              message = 'ไม่สามารถโหลดข้อมูลใบรับเข้าได้ กรุณาลองใหม่อีกครั้ง'
            } else if (response.message === 'serial already existed in receipt') {
              message = 'สินค้านี้ถูกเพิ่มใบรับเข้าแล้ว ตรวจสอบที่หน้ารายการรับเข้า'
            }
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
          }
        })
      }
    });
  }

}
