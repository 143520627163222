<div class="p-4 pb-64 min-h-full">

    <div class="relative flex w-full mb-2 shadow-sm rounded">
        <button class="flex justify-center items-center gap-2 transition-all transform duration-300 ease-out"
            [ngClass]="{'w-[15%] text-blue-700 bg-white font-bold rounded-l': mode === 'delete', 'w-[85%] btn-secondary !bg-blue-700 !rounded-r-none font-bold': mode === 'add'}"
            (click)="modeToggle('add')">
            <fa-icon [icon]="faCartPlus"></fa-icon>
            <div class="flex flex-nowrap"
                [ngClass]="{'duration-100 opacity-100 delay-75 ease-out': mode === 'add', 'hidden': mode === 'delete'}">
                กำลังเพิ่มสินค้า</div>
        </button>
        <button class="flex justify-center items-center gap-2 transition-all transform duration-300 ease-out"
            [ngClass]="{'w-[15%] px-4 text-red-500 bg-white font-bold rounded-r': mode === 'add', 'w-[85%] btn-danger !rounded-l-none font-bold': mode === 'delete'}"
            (click)="modeToggle('delete')">
            <div class="flex flex-nowrap"
                [ngClass]="{'duration-100 opacity-100 delay-75 ease-out': mode === 'delete', 'hidden': mode === 'add'}">
                กำลังลบสินค้า</div>
            <fa-icon [icon]="faTrash"></fa-icon>
        </button>
    </div>

    @if (mode === 'add') {
    <div class="relative pb-2">
        <input type="text" id="barcode-add" class="input !pr-12" placeholder="บาร์โค้ดสินค้า" autocomplete="off"
            [(ngModel)]="serialNoAdd" (keyup.enter)="onAdd($event)">
        <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
    </div>
    }
    @if (mode === 'delete') {
    <div class="relative pb-2">
        <input type="text" id="barcode-delete" class="input-invalid placeholder:text-red-500 !pr-12 !h-[46px]"
            placeholder="ลบสินค้า" autocomplete="off" [(ngModel)]="serialNoDelete" (keyup.enter)="onDelete($event)">
        <fa-icon [icon]="faTrash" class="absolute right-4 -mt-9 text-2xl text-red-500"></fa-icon>
    </div>
    }

    <div class="pb-2 font-bold">
        <mat-form-field class="w-full">
            <mat-select [(ngModel)]="priceSelect" class="text-center" (ngModelChange)="orderPriceConfigChange()">
                @for (price of priceSlots; track $index) {
                <mat-option [value]="price.key">{{price.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

    </div>

    <div class="border-t pt-4 space-y-1">

        @for (item of formatProductDisplay(); track $index) {

        <div class="flex flex-row gap-2 border p-2 bg-white rounded-lg">
            <div class="flex-none">
                <div class="w-16 md:w-20 aspect-square">
                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + item.sku + '/1.jpg'"
                        [alt]="item.sku" class="rounded-md">
                </div>
            </div>

            <div class="flex-1 flex flex-col justify-between">
                <div class="w-full grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12">
                    <div class="col-span-4 md:col-span-7 xl:col-span-11 text-sm md:text-base line-clamp-3 h-[56px]">
                        {{ item.name }}
                        @if(item.is_return){
                        <div class="w-fit px-1 rounded text-xs bg-yellow-500">เปลี่ยนสินค้า</div>
                        }
                    </div>
                    <div class="col-span-1 text-right pr-1">
                        <div class="text-sm md:text-base">
                            x {{ item.qty }}
                        </div>
                        <button class="font-bold text-sm md:text-base"
                            [ngClass]="{'text-red-500': item.unit_price < 0}">
                            {{ item.unit_price | number }}฿
                        </button>
                    </div>
                </div>
                @if(!item.is_return){
                <div class="col-span-full flex justify-end">
                    <mat-form-field class="w-40 pt-2">
                        <mat-select [(ngModel)]="item.price_config_id"
                            class="text-center !h-8 !text-xs md:!h-11 md:!text-sm"
                            (selectionChange)="itemPriceConfigChange(item.sku, $event.value)">
                            @for (price of priceSlots; track $index) {
                            <mat-option [value]="price.key" class="!text-xs md:!text-sm">{{ price.name }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                }
            </div>

        </div>
        }

    </div>
</div>
<div class="md:relative">
    <app-sale-footer [confirmOrder]="confirmOrder" [discount]="order.total_discount" [summary]="order.price"
        [total]="order.total_price" [member]="member" [addFriend]="openAddfriendDialog" [points]="order?.points || 0"
        [redeemDiscount]="getRedeemDiscount(order)" [addDiscount]="openAddDiscountDialog"
        [addRedeemDiscount]="openAddRedeemDiscountDialog"></app-sale-footer>
</div>
<div>
    <app-sale-add-friend-dialog [open]="addFriend" [orderNo]="orderNo" [isMember]="isMember" [memberData]="memberData"
        [phoneNumber]="this.member?.phone_number || ''" (callBackAddFriend)="callBackAddFriend($event)"
        (callbackAddFriendClose)="callbackAddFriendClose()"></app-sale-add-friend-dialog>
</div>
<div>
    <app-sale-add-discount-dialog [open]="addDiscount" [total]="order.price" [defaultValue]="selectDiscount.value"
        [defaultNote]="selectDiscount.note" [defaultUnit]="selectDiscount.unit" [defaultRef]="selectDiscount.ref"
        [defaultType]="selectDiscount.type" (callBackAddDiscount)="callBackAddDiscount($event)"
        (callBackRemoveDiscount)="callBackRemoveDiscount($event)"></app-sale-add-discount-dialog>
</div>

<div>
    <app-sale-add-redeem-dialog [open]="addRedeemDiscount"
        (callBackAddRedeemDiscount)="callBackAddRedeemDiscount($event)"
        (callBackRemoveRedeemDiscount)="callBackRemoveRedeemDiscount($event)" [defaultTotalPrice]="order.total_price"
        [defaultPointsBalance]="member?.member?.point" [defaultUserId]="member?.member?.userId"
        [defaultRedeemDiscountRef]="getRedeemDiscountRef(order)"></app-sale-add-redeem-dialog>
</div>

<div>
    <app-sale-payment-dialog [open]="paymentOption" [total]="order.total_price" [paymentList]="paymentList"
        [isPrinter]="shopDetail.is_printer" (callBackPayment)="callBackPayment($event)"></app-sale-payment-dialog>
</div>