<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">ข้อมูลทั่วไป </div>
    </div>
    <div class="card">
        @if (loading) {
        <div class="flex justify-center py-20">
            <app-loader></app-loader>
        </div>
        } @else {
        <form class="divide-y" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">ชื่อร้านค้า</div>
                    <div class="text-gray-500 text-sm">แสดงในใบเสร็จของลูกค้า</div>
                </div>
                <input type="text" id="shopName" class="input" placeholder="ชื่อร้านค้า" autocomplete="off"
                    formControlName="shopName">
            </div>
            <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">รหัสร้านค้า</div>
                    <div class="text-gray-500 text-sm"></div>
                </div>
                <div>
                    <input type="text" id="shopId" class="input" placeholder="รหัสร้านค้า" autocomplete="off"
                        formControlName="shopId">
                </div>
            </div>
            <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">Provider</div>
                    <div class="text-gray-500 text-sm"></div>
                </div>
                <div class="space-y-2 md:space-y-4">
                    <div>
                        <label for="locationLat">ID</label>
                        <input type="text" id="providerId" class="input" placeholder="Provider" autocomplete="off"
                            formControlName="providerId">
                    </div>
                    <div>
                        <label for="locationLong">ชื่อ</label>
                        <input type="text" id="providerName" class="input" placeholder="Provider" autocomplete="off"
                            formControlName="providerName">
                    </div>
                </div>
            </div>
            <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">เบอร์ติดต่อ</div>
                    <div class="text-gray-500 text-sm"></div>
                </div>
                <div formArrayName="phoneNumbers">
                    <div *ngFor="let phone of phoneNumbers.controls; let i=index"
                        class="flex flex-col items-end justify-end pb-4">
                        <div class="w-full">
                            <div class="w-full flex justify-between">
                                <label for="phone_{{i}}"> เบอร์ {{i + 1}}: </label>
                                <button type="button" class="flex gap-2 items-center text-red-500 rounded-md px-2"
                                    (click)="removePhoneNumber(i)">
                                    <fa-icon [icon]="faTrash" class="text-xs"></fa-icon>
                                    <div>ลบ</div>
                                </button>
                            </div>
                            <input [formControlName]="i" class="input" id="phone_{{i}}" autocomplete="off">
                        </div>
                    </div>
                    <button type="button" class="flex gap-2 items-center bg-yellow-500 rounded-md px-2 text-sm"
                        (click)="addPhoneNumber()">
                        <fa-icon [icon]="faPlus"></fa-icon>
                        <div>เพิ่มเบอร์</div>
                    </button>
                </div>
            </div>
            <div formGroupName="address" class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">ที่ตั้งร้าน</div>
                    <div class="text-gray-500 text-sm"></div>
                </div>
                <div class="space-y-2 md:space-y-4">
                    <div>
                        <label for="addressAddress">ถนน อาคาร บ้านเลขที่</label>
                        <textarea type="text" class="text-area" placeholder="" autocomplete="off" id="addressAddress"
                            formControlName="address"></textarea>
                    </div>
                    <div>
                        <label for="provinceSelection">จังหวัด</label>
                        <mat-form-field class="w-full">
                            <mat-select id="provinceSelection" formControlName="province"
                                (selectionChange)="onProvinceChange()">
                                @for (option of provinceSelections; track $index){
                                <mat-option [value]="option"> {{option}} </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <label for="districtSelection">อำเภอ</label>
                        <mat-form-field class="w-full">
                            <mat-select id="districtSelection" class="" formControlName="district"
                                (selectionChange)="onDistrictChange()">
                                @for (option of districtSelections; track $index){
                                <mat-option [value]="option"> {{option}} </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <label for="subdistrictSelection">ตำบล</label>
                        <mat-form-field class="w-full">
                            <mat-select id="subdistrictSelection" formControlName="subdistrict"
                                (selectionChange)="onSubdistrictChange()">
                                @for (option of subdistrictSelections; track $index){
                                <mat-option [value]="option"> {{option}} </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <label for="addressPostalCode">รหัสไปรษณีย์</label>
                        <input type="text" id="addressPostalCode" formControlName="postalCode" class="input"
                            placeholder="" autocomplete="off">
                    </div>
                </div>
            </div>
            <div formGroupName="location" class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">พิกัดร้าน</div>
                    <div class="text-gray-500 text-sm"></div>
                </div>
                <div class="space-y-2 md:space-y-4">
                    <div>
                        <label for="locationLat">ละติจูด</label>
                        <input type="number" id="locationLat" class="input" formControlName="lat" placeholder=""
                            autocomplete="off">
                    </div>
                    <div>
                        <label for="locationLong">ลองติจูด</label>
                        <input type="number" id="locationLong" class="input" formControlName="long" placeholder=""
                            autocomplete="off">
                    </div>
                </div>
            </div>
            <div *ngIf="formChanged" class="flex justify-end">
                <div class="grid grid-cols-2 gap-2 pt-4 w-full lg:w-1/2">
                    <button type="button" class="btn-secondary" (click)="onCancel()">ยกเลิก</button>
                    <button type="submit" class="btn-primary">บันทึก</button>
                </div>
            </div>
        </form>
        }
    </div>
</div>