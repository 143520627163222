<div class="w-full flex flex-col items-center justify-center h-full relative">
    <ng-container *ngIf="!imagePreview">
        <button (click)="fileUpload.click()" class="pb-2">
            <div class="hover:text-primary-400 duration-150 transition">
                <div>
                    <fa-icon [icon]="faCloudArrowUp" class="text-4xl"></fa-icon>
                </div>
                <div>
                    อัปโหลดไฟล์
                </div>
            </div>
        </button>
        <div class="text-secondary-600 text-xs">
            PNG, JPG, JPEG ไม่เกิน 10MB
        </div>
        <div class="text-secondary-600 text-xs">
            ขนาดภาพ: ไม่จำกัด ({{imageSize}} แนะนำ)
        </div>
    </ng-container>
    <input type="file" (change)="getFile($event)" #fileUpload accept="image/png, image/jpg, image/jpeg, image/svg+xml"
        hidden>
    <ng-container *ngIf="imagePreview">
        <div
            class="absolute z-20 w-full h-full flex justify-end p-3 opacity-20 hover:opacity-100 transition-opacity duration-150">
            <div class="flex flex-col space-y-3">
                <button class="flex justify-center items-center w-9 h-9 bg-secondary-900 rounded-full hover:scale-105"
                    (click)="removeImage()">
                    <fa-icon [icon]="faTrashCan" class="text-white"></fa-icon>
                </button>
                <button class="flex justify-center items-center w-9 h-9 bg-secondary-900 rounded-full hover:scale-105"
                    (click)="fileUpload.click()">
                    <fa-icon [icon]="faRepeat" class="text-white"></fa-icon>
                </button>
            </div>
        </div>
        <img [id]="id" [src]="sanitizer.bypassSecurityTrustResourceUrl(imagePreview)" alt="your image"
            class="rounded-xl w-full" [ngClass]="class_image? class_image : ''" />
    </ng-container>
</div>

<!-- Dialog -->
<ng-template #alertFileSizeOverLimit>
    <div class="w-80 min-h-[150px] flex flex-col justify-between p-4">
        <div>
            <div class="flex items-end space-x-2 text-xl font-bold">
                <img src="../../../assets/images/info.png" class="w-8" />
                <div>ขนาดไฟล์ใหญ่เกิน</div>
            </div>
            <div class="py-4">
                กรุณาอัพโหลดไฟล์ไม่เกิน {{limitFileSizeInMB}} MB
            </div>
        </div>
        <div class="flex w-full justify-end space-x-3">
            <button mat-button mat-dialog-close class="btn-secondary-sm">รับทราบ</button>
        </div>
    </div>
</ng-template>