<div
    class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-fit p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">

    <div class="grid grid-cols-2 gap-2 pt-2">
        <button class="btn-secondary" (click)="routeToStockOut()">
            ยกเลิก
        </button>
        <button class="btn-primary" (click)="onConfirmOrder()">
            ดำเนินการต่อ
        </button>
    </div>
</div>