import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionApiService {
  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllPromotion(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/promotion/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllShops(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createPromotion(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/promotion`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePromotion(promotionId: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/promotion/${promotionId}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }
}
