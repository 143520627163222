<div class="p-4 w-full">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div class="bg-white border border-basic-100 rounded-2xl p-6 md:col-span-2">
            <div class="flex flex-col md:flex-row items-center justify-center gap-6 border-b w-full pb-6">
                <div class="w-28 aspect-square bg-gray-100 rounded-full relative cursor-pointer"
                    (click)="changeImage()">
                    <fa-icon [icon]="faEdit" class="absolute top-1 right-1 text-gray-500"></fa-icon>
                    <img [src]="authService.user.image" class="w-full h-full rounded-full" loading="lazy"
                        onerror="this.onerror=null; this.src='assets/images/user.png'" />
                </div>
                <div class="w-full">
                    <div class="text-xl font-bold mb-4 text-center md:text-left w-full">
                        {{authService.user.name}}
                    </div>
                    <div class="text-primary-500 text-center md:text-left w-full">
                        {{getNameRole(authService.user.role)}}
                    </div>
                    <div class="text-center md:text-left w-full">
                        รหัสพนักงาน: {{authService.user.uid}}
                    </div>
                    <button class="text-gray-400 text-sm text-center md:text-left w-full md:w-fit"
                        (click)="changePassword()">
                        เปลี่ยนรหัสผ่าน
                    </button>
                </div>
            </div>
            <div class="pt-6 space-y-6">
                <div class="flex justify-center lg:justify-start text-gray-500 text-sm">
                    เริ่มใช้ระบบ : {{userInfo?.created ? util.dayjs(userInfo.created.date).format('DD/MM/YYYY HH:mm:ss')
                    :
                    ''}}
                </div>
                <div class="flex justify-center lg:justify-end">
                    <button class="btn-light border w-full lg:w-fit" (click)="logout()">
                        <fa-icon [icon]="faSignOut" class="pr-2"></fa-icon>
                        ออกจากระบบ
                    </button>
                </div>
            </div>
        </div>
        <div class="bg-white border border-basic-100 rounded-2xl p-6 col-span-1">
            <div class="mb-4 font-bold">
                สิทธิ์การใช้งาน
            </div>
            <div *ngIf="userInfo?.permissions" class="flex flex-col gap-3">
                @for (role of authService.roleOptions; track $index) {
                <ng-container *ngIf="checkRoleActive(role.id, userInfo.permissions)">
                    <button class="!w-full h-10 !text-base flex items-center justify-center"
                        [ngClass]="{'label-primary' : checkSelectedRole(role.id, userInfo.permissions), 'label-light opacity-60' : !checkSelectedRole(role.id, userInfo.permissions)}"
                        (click)="selectedPermission(role.id)">
                        {{role.name}}
                    </button>
                </ng-container>
                }
            </div>
        </div>
        <div class="bg-white border border-basic-100 rounded-2xl p-6 col-start-1 md:col-span-2">
            <div class="mb-4 font-bold">
                ช็อปที่รับผิดชอบ ({{authService.shops.length | number}})
            </div>
            <div *ngIf="authService?.shops" class="flex flex-col gap-3">
                <div *ngFor="let shop of authService?.shops" class="flex justify-between">
                    <div class="text-sm pr-4"
                        [ngClass]="{ 'text-lime-500': authService.selectedShop?.shop_id === shop.shop_id}">
                        {{shop.name}}
                    </div>
                    @if (authService.selectedShop?.shop_id === shop.shop_id) {
                    <button class="text-sm text-lime-500">
                        กำลังใช้งาน
                    </button>
                    }@else {
                    <button (click)="selectShop(shop.shop_id)"
                        class="text-sm px-3 bg-gray-50 py-1 border rounded-full w-fit h-fit whitespace-nowrap">
                        ใช้งาน
                    </button>
                    }
                </div>
            </div>
        </div>
    </div>
</div>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}


<ng-template #callAPIDialogChangeImage>
    <div class="dialog-md space-y-2">
        <button mat-button mat-dialog-close class="absolute right-3 top-2">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
        <div class="flex justify-center">
            <!-- Title -->
            <div class="font-bold text-center">
                เปลี่ยนรูปโปรไฟล์
            </div>
        </div>
        <div class="flex justify-center gap-4 flex-wrap">
            <div *ngFor="let image of profileImage">
                <div class="w-16 h-16 rounded-full relative cursor-pointer bg-gray-100"
                    (click)="profileSelected = image">
                    <div class="absolute top-0 right-0" *ngIf="profileSelected === image">
                        <fa-icon [icon]="faCheckCircle" class="text-green-500"></fa-icon>
                    </div>
                    <img [src]="image" class="w-16 h-16 rounded-full">
                </div>
            </div>
        </div>
        <div class="flex justify-center space-x-2 pt-4 pb-2">
            <button mat-button mat-dialog-close class="btn-light  min-w-[8rem]">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
            <button [mat-dialog-close]="true" mat-button class="btn-danger min-w-[8rem]">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #callAPIDialogChangePassword>
    <div class="dialog-xs space-y-2">
        <button mat-button mat-dialog-close class="absolute right-3 top-2">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
        <div class="flex justify-center">
            <!-- Title -->
            <div class="font-bold text-center">
                เปลี่ยนรหัสผ่าน
            </div>
        </div>
        <div class="py-2">
            <form [formGroup]="resetPasswordForm">
                <div class="space-y-2">
                    <div class="relative">
                        <label for="oldPassword">รหัสผ่านปัจจุบัน</label>
                        <input type="password" formControlName="oldPassword" id="oldPassword" class="input"
                            autocomplete="current-password">
                        <div class="absolute right-2 top-10 cursor-pointer" (click)="showPassword('oldPassword')">
                            <fa-icon [icon]="faEye" class="text-sm text-gray-500"
                                [ngClass]="{'flex' : eyeActive['oldPassword'], 'hidden' : !eyeActive['oldPassword']}"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" class="text-sm text-gray-500"
                                [ngClass]="{'hidden' : eyeActive['oldPassword'], 'flex' : !eyeActive['oldPassword']}"></fa-icon>
                        </div>
                    </div>
                    <div class="relative">
                        <label for="newPassword">รหัสผ่านใหม่</label>
                        <input type="password" formControlName="newPassword" id="newPassword" class="input"
                            autocomplete="new-password">
                        <div class="absolute right-2 top-10 cursor-pointer" (click)="showPassword('newPassword')">
                            <fa-icon [icon]="faEye" class="text-sm text-gray-500"
                                [ngClass]="{'flex' : eyeActive['newPassword'], 'hidden' : !eyeActive['newPassword']}"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" class="text-sm text-gray-500"
                                [ngClass]="{'hidden' : eyeActive['newPassword'], 'flex' : !eyeActive['newPassword']}"></fa-icon>
                        </div>
                    </div>
                    <div class="relative">
                        <label for="confirmPassword">กรอกรหัสผ่านอีกครั้ง</label>
                        <input type="password" formControlName="confirmPassword" id="confirmPassword" class="input"
                            autocomplete="new-password">
                        <div class="absolute right-2 top-10 cursor-pointer" (click)="showPassword('confirmPassword')">
                            <fa-icon [icon]="faEye" class="text-sm text-gray-500"
                                [ngClass]="{'flex' : eyeActive['confirmPassword'], 'hidden' : !eyeActive['confirmPassword']}"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" class="text-sm text-gray-500"
                                [ngClass]="{'hidden' : eyeActive['confirmPassword'], 'flex' : !eyeActive['confirmPassword']}"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="h-6 text-red-500 text-sm pt-1">
                    {{textAlert? '*' + textAlert : ''}}
                </div>
            </form>
        </div>
        <div class="flex justify-center space-x-2 py-2">
            <button mat-button mat-dialog-close class="btn-light  min-w-[8rem]">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
            <button (click)="confirmChangePassword()" mat-button class="btn-danger min-w-[8rem]">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</ng-template>