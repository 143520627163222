import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronRight, faCrown, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-exchange-footer',
  templateUrl: './exchange-footer.component.html',
  styleUrl: './exchange-footer.component.css'
})
export class ExchangeFooterComponent {

  @Input('member') member: any;
  @Input('price') price: number = 0;
  @Input('discount') discount: number = 0;
  @Input('total') total: number = 0;

  @Input('confirmCreateReturn') confirmCreateReturn!: Function;
  @Input('addFriend') addFriend!: Function

  faCrown = faCrown;
  faChevronRight = faChevronRight;
  faPlus = faPlus;

  constructor(
    private router: Router,
  ) { }

  routeToExchange() {
    this.router.navigate(['/exchange']);
  }

  onAddFriend() {
    this.addFriend()
  }

  onConfirmCreateReturn() {
    this.confirmCreateReturn();
  }

}
