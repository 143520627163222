import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WebSocketService } from '../../services/web-socket/web-socket.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { SalesService } from '../../services/sales/sales.service';

@Component({
  selector: 'app-qr-payment',
  templateUrl: './qr-payment.component.html',
  styleUrl: './qr-payment.component.css'
})
export class QrPaymentComponent implements OnInit, OnDestroy {
  thaiQRLogo = '../../../../../assets/images/logo/thaiQR.png';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<QrPaymentComponent>,
    private webSocketService: WebSocketService,
    private modalService: ModalService,
    private salesService: SalesService
  ) {
    console.log(data)
  }

  ngOnInit(): void {
    console.log('--ngOnInit--')
    console.log(this.data)
    if (this.data.status === 'paid') {
      this.modalService.openModal('success', 'ชำระเงินสำเร็จ', '').then((result: any) => {
        this.confirm();
      })
    } else {
      this.subscribeOrderPaymentStatus(this.data.orderNo)
    }
  }

  ngOnDestroy(): void {
    this.webSocketService.disconnect();
  }

  close() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }

  subscribeOrderPaymentStatus(order_no: string) {

    this.webSocketService.sendOrderStatusRequest(order_no);

    this.webSocketService.onOrderStatusUpdate(order_no).subscribe((response) => {
      if (response.success) {
        if (response.data.is_paid) {
          this.modalService.openModal('success', 'ชำระเงินสำเร็จ', '').then((result: any) => {
            this.confirm();
          })
        } else {
          this.modalService.openModal('fail', 'ทำรายการชำระเงินไม่เสร็จ', 'กรุณาลองใหม่อีกครั้ง')
        }
      }
    });
  }

  checkStatusQrPayment() {
    this.salesService.checkSatusQrPayment(this.data.orderNo).then((response: any) => {
      if (response.success) {
        this.modalService.openModal('success', 'ชำระเงินสำเร็จ', '').then((result: any) => {
          this.confirm();
        })
      } else {
        this.modalService.openModal('fail', 'ไม่พบรายการการชำระเงิน', 'กรุณาชำระเงินก่อนกดปุ่ม "ตรวจสอบการชำระเงิน"')
      }
    })
  }
}
