import { Component } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { ShopService } from './core/services/shop/shop.service';
import { NotificationService } from './core/services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'maxx-pos';

  openNotify: boolean = false;
  notice: any;

  count: number = 10;

  constructor(
    public authService: AuthService,
    private shopService: ShopService,
    public notificationService: NotificationService
  ) {
    this.initialApp()
    this.loopGetNotification();
  }

  async initialApp() {
    console.log('loadcredential')
    this.authService.isLoading = true;
    await this.loadCredentials();
    this.notificationService.loadNotification();
    this.authService.isLoading = false;
  }

  loopGetNotification() {
    console.log('loopGetNotification');
    let timerId = setInterval(() => {
      this.count--;
      this.notificationService.loadNotification();

      if (this.count === 0) {
        clearInterval(timerId);
      }
    }, 3600000);

    // setInterval(() => {
    //   this.notificationService.loadNotification();
    // }, 3600000);
  }

  async loadCredentials() {
    const expired = localStorage.getItem('expired')
    const user: any = localStorage.getItem('user')
    const token = localStorage.getItem('token')

    if (expired && user && token) {

      if (this.authService.isExpired(expired)) {

        this.authService.token = token;
        this.authService.user = JSON.parse(user);
        this.authService.isLoggedIn = true
        this.authService.user.role = await this.authService.getRole();
        await this.checkAccessShop()
        await this.loadUserShop();

      } else {
        //time out
        this.authService.logout();
      }
    } else {
      //not found active
      this.authService.logout();
    }
  }

  async checkAccessShop() {
    const shops: any = await this.authService.getAccessShopUser()
    this.authService.shops = shops?.data?.sort((a: any, b: any) => a.shop_id - b.shop_id) || []

    if (this.authService.shops.length > 0) {
      const shopIds = this.authService.shops.map(item => item.shop_id);

      const [shopDetails, providerDetails]: any = await Promise.all([
        this.shopService.getAllShop({ shop_id: shopIds.toString() }),
        this.shopService.getAllProvider({})
      ])

      this.authService.shops.forEach(shop => {
        const index = shopDetails.data.findIndex((item: any) => item.shop_id === shop.shop_id)
        shop.name = shopDetails.data[index]?.name || '';
        shop.line_id = shopDetails.data[index]?.line?.line_id || '';
        shop.liff_id = shopDetails.data[index]?.line?.liff_id || '';
        shop.provider_id = shopDetails.data[index]?.provider_id || '';
      })
      const selectedShop = this.authService.shops.find(item => item.selected === true)
      if (selectedShop) {
        const selectedProvider = providerDetails.data.find((e: any) => e.provider_id === selectedShop.provider_id)
        this.authService.selectedShop = {
          shop_id: selectedShop.shop_id,
          name: selectedShop.name,
          line_id: selectedShop.line_id,
          liff_id: selectedShop.liff_id,
          provider_id: selectedShop.provider_id,
          type: selectedProvider.type
        }
      } else {
        const shop = this.authService.shops[0]
        const result: any = await this.authService.selectShop(shop.shop_id)
        const selectedProvider = providerDetails.data.find((e: any) => e.provider_id === shop.provider_id)
        if (result.success) {
          this.authService.selectedShop = {
            shop_id: shop.shop_id,
            name: shop.name,
            line_id: shop.line_id,
            liff_id: shop.liff_id,
            provider_id: shop.provider_id,
            type: selectedProvider.type
          }
        } else {
          this.authService.selectedShop = undefined
        }
      }
    }
  }

  async loadUserShop() {
    if (this.authService.token) {
      await this.shopService.getActiveUserInfo().then((res: any) => {
        if (res.success) {
          this.shopService.shopUsers = res.data;
        }
      })
    }
  }


  displayLoadMoreNotice() {
    return this.notificationService.notifications.filter(e => e.read)
  }

  callBackOpenNotify = (event: any) => {
    if (event.confirm) {
      this.notice = event.data;
      this.openNotify = true;
    } else {
      this.openNotify = false;
    }
  }

  callBackLoadNotify = async (event: any) => {
    if (event.confirm) {
      this.notificationService.offset = this.notificationService.offset + this.notificationService.limit;
      const filterList = {
        shop_id: this.authService.selectedShop?.shop_id,
        offset: this.notificationService.offset,
        limit: this.notificationService.limit
      }
      const result = await this.notificationService.getListNotification(filterList)
      this.notificationService.notifications.push(...result);
    }
  }

  callBackNotifyUpdate = (event: any) => {
    if (event.confirm) {
      if (event.read) {
        this.notificationService.updateNotificationRead(this.notice.notification_id).then((res: any) => {
          if (res.success) {
            this.notice = res.data;
            const index = this.notificationService.notifications.findIndex((e: any) => e.notification_id === this.notice.notification_id)
            if (index !== -1) {
              this.notificationService.count -= 1;
              this.notificationService.notifications[index] = res.data;
            }
          }
        })
      }
    } else {
      this.openNotify = false;
    }
  }

}
