import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../../core/services/util/util.service';
import { StockOutApiService } from '../../services/api/stock-out-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { faBarcode, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SerialOutDailogComponent } from '../../components/serial-out-dailog/serial-out-dailog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-stock-out-detail',
  templateUrl: './stock-out-detail.component.html',
  styleUrl: './stock-out-detail.component.css'
})
export class StockOutDetailComponent implements OnInit {

  faBarcode = faBarcode;
  faChevronRight = faChevronRight;

  isLoading: boolean = true;

  stockOutNo: string = '';
  stockOutDetail: any = {};
  items: any[] = []

  permissionsOngoing = ['admin', 'manager', 'supervisor', 'sale'];
  permissionsConfirm = ['admin', 'manager', 'supervisor'];

  showSerial: boolean = false;
  serials: any[] = [];

  constructor(
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private stockOutApiService: StockOutApiService,
    private modalService: ModalService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async params => {
      this.stockOutNo = params.get('stock_out_no') || '';
      if (this.stockOutNo) {
        this.stockOutApiService.getOneStockOut(this.stockOutNo).then((res: any) => {
          console.log(res)
          if (res.success && res.data) {
            this.stockOutDetail = res.data;
            this.items = res.data?.items || [];
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 100);
        })
      } else {
        this.router.navigate(['/stock-out'])
      }
    });
  }

  displayDate(date: string) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY')
  }

  displayTime(date: string) {
    return this.utilService.dayjs(date).format('HH:mm')
  }


  displayStatusOrder(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_confirm') {
      return 'รอยืนยัน'
    } else if (status === 'completed') {
      return 'สำเร็จ'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayStatusColor(status: string) {
    if (status === 'ongoing') {
      return 'text-yellow-500'
    } else if (status === 'waiting_confirm') {
      return 'text-yellow-500'
    } else if (status === 'completed') {
      return 'text-lime-600'
    } else if (status === 'canceled') {
      return 'text-red-500'
    } else {
      return 'text-yellow-500'
    }
  }

  formatProductDisplay(): any[] {
    let result: any[] = [];
    for (const item of this.items) {
      let index = result.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result.push({
          ...item,
          qty: 1
        });
      } else {
        result[index].qty += 1;
      }
    }
    return result;
  }

  canAccess() {
    if (this.stockOutDetail.status === 'ongoing') {
      return this.permissionsOngoing.includes(this.authService.user.role)
    }
    return this.permissionsConfirm.includes(this.authService.user.role)
  }

  canAccessSerial() {
    return this.permissionsConfirm.includes(this.authService.user.role)
  }

  cancelStockOut() {
    if (this.canAccess()) {
      this.modalService.openModal('confirm-warning', 'ยกเลิกคืนสำนักงาน', 'สินค้าทั้งหมดจะกลับคืนสต๊อก ต้องการยกเลิกคืนสำนักงาน?').then((confirm) => {
        if (confirm) {
          this.stockOutApiService.cancelStockOut(this.stockOutNo).then((result: any) => {
            if (result.success) {
              this.modalService.openModal('success', 'สำเร็จ', 'ยกเลิกคืนสำนักงานสำเร็จ').then(() => {
                this.router.navigate(['/stock-out'])
              })
            } else {
              let message = 'ไม่สามารถยกเลิกคืนสำนักงานได้';

              if (result.message === 'not found stock out') {
                message = 'ไม่พบรายการคืนสำนักงาน กรุณาตรวจสอบใหม่อีกครั้ง'
              } else if (result.message === 'stock out status was completed') {
                message = 'รายการคืนสำนักงานนี้สำเร็จแล้ว ไม่สามารถยกเลิกได้'
              } else if (result.message === 'stock out status was canceled') {
                message = 'รายการนี้ถูกยกเลิกแล้ว'
              }

              this.modalService.openModal('fail', 'ไม่สำเร็จ', message)
            }
          })
        }
      })
    } else {
      this.modalService.openModal('info', 'ขออภัย', 'คุณไม่มีสิทธิ์ยืนยันคืนสำนักงาน กรุณาใช้สิทธิ์ที่สูงกว่า')
    }
  }

  async confirmStockOut() {
    if (this.canAccess()) {
      this.modalService.openModal('confirm', 'ยืนยันการคืนสำนักงาน', 'ระบบจะนำ barcode ออกจากร้านค้า ยืนยันการคืนสำนักงานหรือไม่?').then(result => {
        if (result) {
          this.stockOutApiService.confirmStockOut(this.stockOutNo).then((res: any) => {
            if (res.success) {
              this.modalService.openModal('success', 'สำเร็จ', 'ยกเลิกคืนสำนักงานสำเร็จ').then(() => {
                this.router.navigate(['/stock-out'])
              })
            } else {
              let message = 'ไม่สามารถยืนยันคืนสำนักงานได้';

              if (res.message === 'not found stock out') {
                message = 'ไม่พบรายการคืนสำนักงาน กรุณาตรวจสอบใหม่อีกครั้ง'
              } else if (res.message === 'stock out status was ongoing') {
                message = 'รายการนี้กำลังดำเนินการ ไม่สามารถยืนยันได้'
              } else if (res.message === 'stock out status was completed') {
                message = 'รายการนี้สำเร็จแล้ว ไม่สามารถทำรายการซ้ำได้'
              } else if (res.message === 'stock out status was canceled') {
                message = 'รายการนี้ถูกยกเลิกแล้ว ไม่สามารถยืนยันได้'
              }

              this.modalService.openModal('fail', 'ไม่สำเร็จ', message)
            }
          })
        }
      })
    } else {
      this.modalService.openModal('info', 'ขออภัย', 'คุณไม่มีสิทธิ์ยืนยันคืนสำนักงาน กรุณาใช้สิทธิ์ที่สูงกว่า')
    }
  }

  callBackClose = (event: any) => {
    this.showSerial = false;
    this.serials = []
  }

  openDialogShowBarcode(sku: string) {
    if (this.canAccessSerial()) {
      this.showSerial = true;
      this.serials = this.items.filter(e => e.sku === sku)
    }
  }

}
