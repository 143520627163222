<div class="bg-white w-full min-h-0 max-h-[80vh] flex flex-col justify-center items-center rounded-xl relative">
    <div class="flex-none w-full text-center py-4">
        เพิ่มผู้ใช้งาน
    </div>
    <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()" class="flex-1 w-full overflow-auto px-6 pb-6">
        <div class="flex flex-col space-y-4">
            <div class="gap-2">
                <label class="text-sm">ชื่อผู้ใช้ *</label>
                <input type="text" class="input" placeholder="ชื่อผู้ใช้" formControlName="username" autocomplete="off">
                <div *ngIf="submitted && f['username'].errors?.['required']" class="text-xs text-red-500">กรุณากรอก
                    ชื่อผู้ใช้</div>
                <div *ngIf="submitted && f['username'].errors?.['pattern']" class="text-xs text-red-500">
                    ชื่อผู้ใช้ต้องมีความยาว 3-20 ตัวอักษร และประกอบด้วยตัวอักษร ตัวเลข {{'@'}}, -, _ หรือ .
                </div>
            </div>
            <div class="gap-2">
                <label class="text-sm">รหัสผ่าน *</label>
                <input type="password" class="input" placeholder="รหัสผ่าน" formControlName="password"
                    autocomplete="off">
                <div *ngIf="submitted && f['password'].errors?.['required']" class="text-xs text-red-500">
                    กรุณากรอก รหัสผ่าน</div>
            </div>
            <div class="gap-2">
                <label class="text-sm">ชื่อ-สกุล *</label>
                <input type="text" class="input" placeholder="ชื่อ-สกุล" formControlName="name" autocomplete="off">
                <div *ngIf="submitted && f['name'].errors?.['required']" class="text-xs text-red-500">
                    กรุณากรอก ชื่อ-สกุล</div>
            </div>
            <div class="gap-2">
                <label class="text-sm">ชื่อเล่น *</label>
                <input type="text" class="input" placeholder="ชื่อเล่น" formControlName="nickname" autocomplete="off">
                <div *ngIf="submitted && f['nickname'].errors?.['required']" class="text-xs text-red-500">
                    กรุณากรอก ชื่อเล่น</div>
            </div>
            <div class="gap-2">
                <label class="text-sm">สิทธิ์เข้าใช้งาน *</label>
                <div class="grid grid-cols-2 gap-2">
                    @for(role of staffsService.roleOptions; track $index){
                    <ng-container *ngIf="checkRoleActive(role.id) && checkRoleAccess(role.id)">
                        <button class="!w-full !h-8 flex items-center label-primary gap-2 whitespace-nowrap"
                            (click)="removeUserRole(role.id)">
                            <fa-icon [icon]="faCheck" class="w-4 h-4 flex items-center"></fa-icon>{{role.name}}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!checkRoleActive(role.id) && checkRoleAccess(role.id)">
                        <button class="!w-full !h-8 flex items-center label-light gap-2 whitespace-nowrap opacity-70"
                            (click)="addUserRole(role.id)">
                            <fa-icon [icon]="faXmark" class="w-4 h-4 flex items-center"></fa-icon>{{role.name}}
                        </button>
                    </ng-container>
                    }
                </div>
                <div *ngIf="submitted && f['role'].errors?.['required']" class="text-xs text-red-500">
                    กรุณาเลือก สิทธิ์เข้าใช้งาน</div>
            </div>
            <div class="gap-2">
                <label class="text-sm">ร้านค้า</label>
                <mat-form-field class="w-full">
                    <mat-select (selectionChange)="formCreateString($event.value)" [value]='toggleAll? 0: shopSelected'
                        multiple>
                        <mat-option class="" #allSelected (click)="toggleAllSelection()"
                            [value]="0">ทั้งหมด</mat-option>
                        @for (shop of data.shopList; track $index) {
                        <mat-option class="" [disabled]="allSelected.selected" [value]="shop.shop_id">
                            {{shop.name}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="flex-none flex justify-center space-x-2 py-4">
        <div>
            <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]"
                (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button mat-button class="btn-primary min-w-[8rem]" (click)="onSubmit()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>