<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">บรอดคาสต์</div>
    </div>
    <div class="card">
        @if (loading) {
        <div class="flex justify-center py-20">
            <app-loader></app-loader>
        </div>
        } @else {
        <form class="divide-y" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div formGroupName="line" class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">Messaging API สำหรับการขาย</div>
                    <div class="text-gray-500 text-sm">ใช้สำหรับส่งใบเสร็จ</div>
                </div>
                <div class="space-y-2 md:space-y-4">
                    <div>
                        <label for="lineAccessToken">Channel access token</label>
                        <textarea type="text" id="lineAccessToken" formControlName="accessToken" class="text-area"
                            placeholder="" autocomplete="off"></textarea>
                    </div>
                    <div>
                        <label for="lineChannelSecret">Channel secret</label>
                        <input type="text" id="lineChannelSecret" formControlName="channelSecret" class="input"
                            placeholder="" autocomplete="off">
                    </div>
                    <div>
                        <label for="lineChannelId">Channel ID</label>
                        <input type="text" id="lineChannelId" formControlName="channelId" class="input" placeholder=""
                            autocomplete="off">
                    </div>
                    <div>
                        <label for="lineLiffId">LIFF ID</label>
                        <input type="text" id="lineLiffId" formControlName="liffId" class="input" placeholder=""
                            autocomplete="off">
                        <div *ngIf="!form.getRawValue()['line']['liffId']" class="text-sm text-gray-500 mt-2">* ติดต่อเจ้าหน้าที่เพื่อสร้าง LIFF ID สำหรับสแกนรับใบเสร็จ</div>
                    </div>
                    <div>
                        <label for="lineLineId">Line ID</label>
                        <input type="text" id="lineLineId" formControlName="lineId" class="input" placeholder=""
                            autocomplete="off">
                    </div>
                </div>
            </div>
            <!-- <div formGroupName="claim_line" class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">Messaging API สำหรับการเคลม</div>
                    <div class="text-gray-500 text-sm">ใช้สำหรับส่งใบเคลมและแจ้งเตือนสถานะการเคลม</div>
                </div>
                <div class="space-y-2 md:space-y-4">
                    <div>
                        <label for="lineAccessToken">Channel access token</label>
                        <textarea type="text" id="lineAccessToken" formControlName="accessToken"
                            class="text-area" placeholder="" autocomplete="off"></textarea>
                    </div>
                    <div>
                        <label for="lineChannelSecret">Channel secret</label>
                        <input type="text" id="lineChannelSecret" formControlName="channelSecret" class="input"
                            placeholder="" autocomplete="off">
                    </div>
                    <div>
                        <label for="cliamLineChannelId">Channel ID</label>
                        <input type="text" id="cliamLineChannelId" formControlName="channelId" class="input"
                            placeholder="" autocomplete="off">
                    </div>
                    <div>
                        <label for="lineLiffId">LIFF ID</label>
                        <input type="text" id="lineLiffId" formControlName="liffId" class="input" placeholder=""
                            autocomplete="off">
                    </div>
                </div>
            </div> -->
            <div *ngIf="formChanged" class="flex justify-end">
                <div class="grid grid-cols-2 gap-2 pt-4 w-full lg:w-1/2">
                    <button type="button" class="btn-secondary" (click)="onCancel()">ยกเลิก</button>
                    <button type="submit" class="btn-primary">บันทึก</button>
                </div>
            </div>
        </form>
        }
    </div>
</div>