import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-merchant-detail-dialog',
  templateUrl: './merchant-detail-dialog.component.html',
  styleUrl: './merchant-detail-dialog.component.css'
})
export class MerchantDetailDialogComponent {
  @Input('open')
  open: boolean = false;
  @Input('shop')
  shop: any;
  @Output('callBackMerchantDetail')
  callBackMerchantDetail: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;

  constructor() { }

  close() {
    this.callBackMerchantDetail.emit({ confirm: false })
  }

}
