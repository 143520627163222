<div class="p-4 pb-32 space-y-6" id="inventory-detail">
    <div class="divide-y bg-white rounded-lg border px-4">
        <div class="flex justify-center items-center w-full space-x-4 px-2">
            <div class="text-center w-full py-4">
                <div class="font-bold text-lg md:text-xl text-blue-500">
                    {{ calculateProductAll() | number: '1.0-2' }}
                </div>
                <div class="text-sm text-gray-600">
                    สินค้าก่อนนับ
                </div>
            </div>
        </div>
        <div class="grid grid-cols-3 w-full divide-x py-4">
            <div class="text-center w-full pr-2">
                <div class="font-bold text-lg md:text-xl text-lime-500">
                    {{ inventory?.counted?.length | number: '1.0-2' }}
                </div>
                <div class="text-sm text-gray-600">
                    นับได้
                </div>
            </div>
            <div class="text-center w-full px-2">
                <div class="font-bold text-lg md:text-xl text-yellow-500">
                    {{ inventory?.new?.length | number: '1.0-2' }}
                </div>
                <div class="text-sm text-gray-600">
                    สินค้าใหม่
                </div>
            </div>
            <div class="text-center w-full px-2">
                <div class="font-bold text-lg md:text-xl text-red-500">
                    {{ inventory?.loss?.length | number: '1.0-2' }}
                </div>
                <div class="text-sm text-gray-600">
                    สินค้าหาย
                </div>
            </div>
        </div>
    </div>



    <div class="w-full text-base font-medium text-center text-gray-500 border-b border-gray-200">
        <div class="w-full md:w-fit flex flex-wrap -mb-px">
            <button class="flex-1 me-2" (click)="state='loss-product'">
                <p [ngClass]="{'text-black font-semibold border-yellow-500':state==='loss-product' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='loss-product'}"
                    class="inline-block whitespace-nowrap p-4 border-b-2 rounded-t-lg">
                    รายการสินค้าที่หาย</p>
            </button>
            <button class="flex-1 me-2" (click)="state='new-product'">
                <p [ngClass]="{'text-black font-semibold border-yellow-500':state==='new-product' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='new-product'}"
                    class="inline-block whitespace-nowrap p-4 border-b-2 rounded-t-lg">
                    รายการสินค้าใหม่</p>
            </button>
        </div>
    </div>



    <div class="grid grid-cols-1 lg:grid-cols-3 lg:grid-flow-dense gap-4">
        <div class="col-span-1 grid h-fit gap-4 order-first lg:order-last">
            <div class="card space-y-4">
                <div class="border-b pb-4">
                    <span class="text-lg font-bold">สรุปรายการสินค้าหาย</span>
                </div>
                <div class="space-y-2">
                    <div class="flex justify-between">
                        <div>จำนวนทั้งหมด</div>
                        <div>{{ inventory?.loss?.length | number: '1.0-2' }}</div>
                    </div>
                    <div class="flex items-end justify-between">
                        <div>คิดเป็นมูลค่า</div>
                        <div class="text-2xl text-red-500 font-bold">฿{{ inventory?.total_price || 0 | number:
                            '1.0-2' }}
                        </div>
                    </div>
                </div>
                <button class="btn-secondary w-full" [ngClass]="{'!bg-lime-600': inventory?.status === 'complete'}"
                    [disabled]="inventory?.status === 'complete'? true: false" (click)="confirmInventoryComplete()">
                    {{inventory?.status === 'complete'? 'ชำระเงินแล้ว': 'ยืนยันการชำระเงิน'}}
                </button>
            </div>
        </div>
        @if (state==='loss-product') {
        <!-- Mobile View -->
        <div class="space-y-2 pb-4 mb-4 border-b block lg:hidden">
            @for (product of loss; track $index) {
            <div class="grid grid-cols-6 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full lg:w-16 aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                            [alt]="product.sku" class="rounded-sm" loading="lazy"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-5 xl:col-span-9 h-[56px]">
                    <div class="line-clamp-3 text-sm text-gray-600">{{ product.name }}</div>
                </div>
                <div class="col-span-2 text-right pr-1 flex flex-col justify-between">
                    <div class="font-medium text-sm md:text-base">
                        x {{ product.qty | number}}
                    </div>
                    <div class="space-x-1">
                        ฿{{product.cost | number: '1.0-2'}}
                    </div>
                </div>
            </div>
            }
        </div>
        <!-- Desktop View -->
        <div class="col-span-2 hidden lg:grid gap-4">
            <div class="card divide-y">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-right">
                                จำนวนทั้งหมด
                            </th>
                            <th class="text-right">
                                ราคาเฉลี่ย/ชิ้น
                            </th>
                        </tr>
                    </thead>
                    <tbody class="">
                        @for (product of loss; track $index) {
                        <tr>
                            <td>
                                <div class="flex w-full space-x-2">
                                    <div class="">
                                        <div class="w-full lg:w-16 aspect-square">
                                            <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                                                [alt]="product.sku" class="rounded-sm" loading="lazy"
                                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="line-clamp-2 text-sm text-gray-600">{{ product.name }}</div>
                                        <div class="text-primary-500">{{ product.sku }}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-sm font-medium">
                                    x{{ product.qty}}
                                </div>
                            </td>
                            <td class="relative">
                                <div class="text-right text-sm font-medium">
                                    {{product.cost | number: '1.0-2'}}
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        }
        @if(state==='new-product'){
        <!-- Mobile View -->
        <div class="space-y-2 pb-4 mb-4 border-b block lg:hidden">
            @for (product of new; track $index) {
            <div class="grid grid-cols-6 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full lg:w-16 aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product?.sku + '/1.jpg'"
                            [alt]="product?.sku" class="rounded-sm" loading="lazy"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-5 xl:col-span-9">
                    <div class="line-clamp-3 text-sm text-gray-600">{{ product.name }}</div>
                    <div class="font-medium text-sm md:text-base text-blue-500">
                        {{ product.serial_no}}
                    </div>
                </div>
                <div class="col-span-2 text-right pr-1 flex flex-col justify-between">
                    <div class="font-medium text-sm md:text-base">

                    </div>
                    <!-- <div class="space-x-1">
                        <span class="text-gray-500 text-sm">ทุน</span> {{!product?.cost ? '-' : product.cost |
                        number: '1.0-2'}}
                    </div> -->
                    <div>
                        <button class="underline underline-offset-2 text-sm"
                        (click)="clickProductTraction(product.serial_no)">ดูประวัติ</button>
                    </div>

                </div>
            </div>
            }
        </div>
        <!-- Desktop View -->
        <div class="col-span-2 hidden lg:grid gap-4">
            <div class="card divide-y">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-right">
                                Serial No
                            </th>
                            <!-- <th class="text-right">
                                ราคาทุน
                            </th> -->
                            <th class="text-right">
                                ประวัติการขาย
                            </th>
                        </tr>
                    </thead>
                    <tbody class="">
                        @for (product of new; track $index) {
                        <tr>
                            <td>
                                <div class="flex w-full space-x-2">
                                    <div class="">
                                        <div class="w-full lg:w-16 aspect-square">
                                            <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                                                [alt]="product.sku" class="rounded-sm" loading="lazy"
                                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="line-clamp-2 text-sm text-gray-600">{{ product.name ?
                                            product.name : 'ไม่พบข้อมูลสินค้า' }}</div>
                                        <div class="text-primary-500">{{ product.sku ? product.sku : '-' }}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-sm font-medium">
                                    {{ product.serial_no}}
                                </div>
                            </td>
                            <!-- <td class="relative">
                                <div class="text-right text-sm font-medium">
                                    {{!product.cost? '-' : product.cost | number: '1.0-2'}}
                                </div>
                            </td> -->
                            <td class="relative">
                                <div class="text-right text-sm font-medium">
                                    <button class="underline underline-offset-2"
                                        (click)="clickProductTraction(product.serial_no)">ดูประวัติ</button>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        }
    </div>


</div>

<app-scroll-button id="inventory-detail"></app-scroll-button>

<app-inventory-product-transaction [open]="showProductTransaction" [detail]="productTransaction"
    (callBackProductTransaction)="callBackProductTransaction($event)"></app-inventory-product-transaction>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}