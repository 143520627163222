import { Component } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-receipt-history-detail',
  templateUrl: './receipt-history-detail.component.html',
  styleUrl: './receipt-history-detail.component.css'
})
export class ReceiptHistoryDetailComponent {

  faChevronLeft = faChevronLeft

  dataSource: any[] = [
    {
      "id": "13318",
      "sku": "D2400001",
      "name": "Phone and Powerbank strap (Gray,LY01) - สายคล้อง",
      "serials": [
        "180200000277135",
        "180200000277136"
      ]
    },
    {
      "id": "13319",
      "sku": "D2400002",
      "name": "สายคล้องโทรศัพท์แบบแหวน (Black,008) - Maxx",
      "serials": [
        "180200000277135",
        "180200000277136"
      ]
    },
    {
      "id": "13320",
      "sku": "D2400003",
      "name": "Cable Type-C to Type-C 2M 30W (RC-C021,White) - สายชาร์จ REMAX",
      "serials": [
        "180200000277135",
        "180200000277136"
      ]
    },
    {
      "id": "13321",
      "sku": "D2400004",
      "name": "Cable Type-C 100W (RC-C128,Blue) - สายชาร์จ REMAX",
      "serials": [
        "180200000277135",
        "180200000277136"
      ]
    },
  ]

  constructor(
    public utilService: UtilService,
    private router: Router
  ) { }

  backToReceiptHistoryLits() {
    this.router.navigate(['/receipts/receipt-history-list']);
  }
}
