import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';

const END_POINT = environment.endpoint

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  count: number = 0;
  countAll: number = 0;
  notifications: any[] = [];

  openNotify: boolean = false;
  notice: any;
  offset: number = 0;
  limit: number = 10;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthService
  ) { }

  getNotifications(filters: any) {
    const headers = this.apiService.getAPIHeader()
    return new Promise((resolve, reject) => {
      return this.http.get(`${END_POINT}/shops/notification?${this.apiService.parseFilters(filters)}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateNotificationRead(notification_id: string) {
    const headers = this.apiService.getAPIHeader()
    return new Promise((resolve, reject) => {
      return this.http.patch(`${END_POINT}/shops/notification/${notification_id}/read`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  async loadNotification() {
    if (this.authService.token) {
      this.offset = 0;
      this.limit = 10;
      const filterCount = { shop_id: this.authService.selectedShop?.shop_id, read: false, offset: 0, limit: 1 }
      const filterCountAll = { shop_id: this.authService.selectedShop?.shop_id, offset: 0, limit: 1 }
      const filterList = { shop_id: this.authService.selectedShop?.shop_id, offset: this.offset, limit: this.limit }
      this.count = await this.getCountNotification(filterCount);
      this.countAll = await this.getCountNotification(filterCountAll);
      this.notifications = await this.getListNotification(filterList)
    }
  }

  async getCountNotification(filter: any) {
    return this.getNotifications(filter).then((response: any) => {
      if (!response.success) return 0;
      return response.count
    })
  }

  async getListNotification(filter: any) {
    return this.getNotifications(filter).then((response: any) => {
      if (!response.success) return 0;
      return response.data
    })
  }

}
