<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer z-10"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronDown" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                ระบบสมาชิก
            </div>
        </div>

        @if(isLoadingCustomer) {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }@else {
        @if (!isMember) {
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            <div class="space-y-4">
                <div class="pb-2">
                    <div class="grid grid-cols-{{states.length}}">
                        @for (item of states; track $index) {
                        <button class="text-center py-1.5 rounded-l-lg text-gray-600 border border-gray-200"
                            [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state === item.value, 'rounded-l-lg' : $index==0, 'rounded-r-lg' : $index==states.length-1}"
                            (click)="changeState(item.value)">
                            {{item.name}}
                        </button>
                        }
                    </div>
                </div>
                @if (state === 'phone-number') {
                <div class="space-y-4">
                    <div class="relative pb-2">
                        <label>เบอร์มือถือ</label>
                        <input type="text" id="barcode-add"
                            class="text-center border-b-2 border-yellow-400 w-full h-10 text-3xl font-bold focus:ring-0"
                            autocomplete="off" maxlength="10" [(ngModel)]="phoneNumber">
                    </div>
                    <div>
                        <app-keypad (selectedNumber)="selectedNumber($event)"></app-keypad>
                    </div>
                </div>
                }@else {
                @if(liffId){
                <div>
                    <div class="flex w-full justify-center">
                        <div class="flex justify-center items-center mb-10">
                            <qrcode
                                qrdata="https://liff.line.me/{{liffId}}/return?returnNo={{returnNo}}&shopId={{shopId}}"
                                [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#000000ff'"
                                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'"
                                [imageHeight]="60" [imageWidth]="60" [margin]="4" [scale]="1" [width]="288"></qrcode>
                        </div>
                    </div>
                    <div class="text-center pt-4">
                        <p class="text-pretty">
                            สแกน QR Code นี้บนแอปพลิเคชั่น Line <br>เพื่อลงทะเบียนรับคืน
                        </p>
                    </div>
                </div>
                }@else {
                <div>
                    <div class="flex w-full justify-center">
                        <div class="relative flex justify-center items-center mb-4">
                            <qrcode qrdata="https://liff.line.me/{{liffId}}/return?returnNo={{returnNo}}&shopId={{shopId}}"
                                [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#00000052'"
                                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'"
                                [imageHeight]="60" [imageWidth]="60" [margin]="4" [scale]="1" [width]="288"></qrcode>
                            <div class="absolute bg-white p-2 border text-center">
                                <div class="font-medium text-red-500">ร้านค้าของคุณยังไม่มี Liff ID
                                    สำหรับสแกนรับคำสั่งซื้อ!</div>
                                <div class="text-sm">กรุณาเพิ่ม Liff ID
                                    ที่เมนูตั้งค่าร้านค้าเพื่อให้สามารถใช้งานฟังก์ชันนี้ได้</div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                }
                <div class="h-36"></div>
            </div>
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full space-y-4">
                <button class="w-full btn-primary" [disabled]="phoneNumber.length !== 10 && state === 'phone-number'"
                    (click)="memberDetail()">
                    ยืนยัน
                </button>
                <button class="w-full btn-secondary" (click)="noMember()">
                    ไม่เป็นสมาชิก
                </button>
            </div>
        </div>
        }@else {
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            <div class="space-y-4">
                <button class="flex gap-2" (click)="backToKeypad()">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                    ย้อนกลับ
                </button>

                @if(memberData) {
                <div class="px-4 space-y-3">
                    <div class="relative">
                        <img [src]="cardImage" class="rounded-md w-full" />
                        <div class="absolute bottom-4 right-4">
                            <div class="bg-black bg-opacity-50 text-white rounded-full px-4">
                                คะแนนสะสม <span class="font-bold text-2xl">{{memberData.point | number}}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex gap-2 text-gray-500">
                            <fa-icon [icon]="faUser"></fa-icon>
                            ชื่อ
                        </div>
                        <div class="ml-6">
                            {{memberData.name || '-'}}
                        </div>
                    </div>
                    <div>
                        <div class="flex gap-2 text-gray-500">
                            <fa-icon [icon]="faBagShopping"></fa-icon>
                            จำนวนครั้งที่ซื้อ
                        </div>
                        <div class="ml-6">
                            {{memberData.orders || '-'}}
                        </div>
                    </div>
                </div>
                }@else {
                <div class="flex flex-col items-center gap-6 pt-6">
                    <div class="text-red-500 font-bold text-xl">
                        ไม่พบข้อมูลสมาชิก
                    </div>
                    <div class="flex flex-col items-center gap-4">
                        <div class="flex justify-center items-center mb-10">
                            <qrcode
                                qrdata="https://liff.line.me/{{liffId}}/return?returnNo={{returnNo}}&shopId={{shopId}}"
                                [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#000000ff'"
                                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'"
                                [imageHeight]="60" [imageWidth]="60" [margin]="4" [scale]="1" [width]="288"></qrcode>
                        </div>
                        <p class="text-pretty">
                            สแกนเพื่อติดตามสถานะการคืนเงิน
                        </p>
                    </div>
                </div>
                }
                <div class="h-20"></div>
            </div>

        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full space-y-4">
                <button class="w-full btn-primary" (click)="apply()">
                    ยืนยัน
                </button>
            </div>
        </div>
        }
        }
    </div>
</div>