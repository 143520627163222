import { Injectable } from '@angular/core';
import { default as dayjs } from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { faArrowDown, faBox, faCashRegister, faChartLine, faFileShield, faScrewdriverWrench, faStore, faUsers, faExchange, faTags, faFileCircleCheck, faTruckArrowRight, faBarcode, faHandshake, faGear } from '@fortawesome/free-solid-svg-icons';

dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);
dayjs.locale("th");

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  dayjs = dayjs;
  navbarOpen = false;
  loading = false;

  menuList: any[] = [
    {
      path: '/sales',
      name: 'การขาย',
      icon: faCashRegister,
      visible: true
    },
    {
      path: '/sales/sale-detail',
      name: 'รายละเอียดคำสั่งซื้อ',
      icon: faCashRegister,
      visible: false,
      back: true
    },
    {
      path: '/sales/sale-create',
      name: 'เพิ่มคำสั่งซื้อ',
      icon: faCashRegister,
      visible: false,
      back: false
    },
    {
      path: '/serial/check-serial',
      name: 'เช็คสินค้า',
      icon: faBarcode,
      visible: true
    },
    {
      path: '/exchange',
      name: 'เปลี่ยน/คืน',
      icon: faExchange,
      visible: true
    },
    {
      path: '/exchange/exchange-detail',
      name: 'รายละเอียดการคืนสินค้า',
      icon: faExchange,
      visible: false,
      back: true
    },
    {
      path: '/products/product-list',
      name: 'สินค้า',
      icon: faBox,
      visible: true
    },
    {
      path: '/products/product-detail',
      name: 'รายละเอียดสินค้า',
      icon: faBox,
      visible: false,
      back: true
    },
    {
      path: '/products/product-setting',
      name: 'ตั้งค่าช่องราคา',
      icon: faBox,
      visible: false,
      back: true
    },
    {
      path: '/receipts/receipt-list',
      name: 'รับเข้า',
      icon: faArrowDown,
      visible: true
    },
    {
      path: '/receipts/receipt-detail',
      name: 'รายละเอียดการสั่งซื้อ',
      icon: faArrowDown,
      visible: false,
      back: true
    },
    {
      path: '/receipts/receipt-scan',
      name: 'รับเข้าสินค้า',
      icon: faArrowDown,
      visible: false,
      back: true
    },
    {
      path: '/stock-out',
      name: 'คืนสำนักงาน',
      icon: faTruckArrowRight,
      visible: true
    },
    {
      path: '/stock-out/stock-out-detail',
      name: 'รายละเอียดการคืนสำนักงาน',
      icon: faTruckArrowRight,
      visible: false,
      back: true
    },
    {
      path: '/stock-out/stock-out-create',
      name: 'สร้างรายการคืนสำนักงาน',
      icon: faTruckArrowRight,
      visible: false
    },
    {
      path: '/reports',
      name: 'รายงาน',
      icon: faChartLine,
      visible: true
    },
    {
      path: '/merchant',
      name: 'ตั้งค่า',
      icon: faGear,
      visible: true
    },
    {
      path: '/merchant/setting/general',
      name: 'ตั้งค่าร้านค้า ข้อมูลทั่วไป',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/merchant/setting/boardcast',
      name: 'ตั้งค่าร้านค้า ข้อมูลบรอดคาสต์',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/merchant/setting/printer',
      name: 'ตั้งค่าการพิมพ์ใบเสร็จ',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/merchant/setting/payment',
      name: 'ตั้งค่าร้านค้า ข้อมูลการชำระเงิน',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/merchant/setting/return',
      name: 'ตั้งค่าร้านค้า เงื่อนไขการคืนสินค้า',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/merchant/setting/stock',
      name: 'ตั้งค่าสต๊อก ข้อมูลคลังสินค้า',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/merchant/setting/member',
      name: 'ตั้งค่าระบบสมาชิก',
      icon: faGear,
      visible: false,
      back: true
    },
    {
      path: '/staffs',
      name: 'พนักงาน',
      icon: faUsers,
      visible: true
    },
    {
      path: '/claim',
      name: 'ส่งเคลม',
      icon: faFileShield,
      visible: true
    },
    {
      path: '/claim/claim-create',
      name: 'ส่งเคลม',
      icon: faCashRegister,
      visible: false,
      back: true
    },
    {
      path: '/claim/claim-form',
      name: 'ส่งเคลม',
      icon: faCashRegister,
      visible: false,
      back: false
    },
    {
      path: '/promotion',
      name: 'โปรโมชั่น',
      icon: faTags,
      visible: true,
      back: false
    },
    {
      path: '/inventory',
      name: 'นับสต๊อก',
      icon: faFileCircleCheck,
      visible: true,
      back: false
    },
    {
      path: '/inventory/counting',
      name: 'นับสต๊อก',
      icon: faFileCircleCheck,
      visible: false,
      back: true
    },
    {
      path: '/inventory/detail',
      name: 'นับสต๊อก',
      icon: faFileCircleCheck,
      visible: false,
      back: true
    },
    {
      path: '/providers',
      name: 'Provider',
      icon: faHandshake,
      visible: true
    },
    {
      path: '/merchant-management',
      name: 'เพิ่มร้านค้า',
      icon: faStore,
      visible: true
    },
    {
      path: '/example',
      name: 'ตัวอย่าง',
      icon: faScrewdriverWrench,
      visible: false,
      back: true
    },
  ]

  constructor() { }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  closeToggleNevbar() {
    this.navbarOpen = false
  }

  screenLoader(value: boolean) {
    this.loading = value;
  }
}
