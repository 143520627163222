import { Injectable } from '@angular/core';
import { ReceiptApiService } from '../api/receipt-api.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiptsService {

  state = 'ongoing';

  constructor(
    private receiptApiService: ReceiptApiService
  ) {
    this.setState('ongoing')
  }

  getState() {
    return this.state;
  }

  setState(state: string) {
    this.state = state;
  }


  async syncReceipt(shopId: string, purchaseOrderNo: string) {
    return this.receiptApiService.syncReceipt(shopId, purchaseOrderNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }


}
