<!-- laoding -->
@if(authService.isLoading) {
<div
  class="fixed top-0 h-full w-full flex flex-col justify-center items-center gap-4 transform transition-all bg-white z-[150]">
  <span class="loader-page"></span>
  <div class="text-lg font-bold">
    Maxx POS
  </div>
</div>
}
@else {

<!-- loaded -->
@if(!authService.isLoading) {

<!-- login suucess -->
@if(authService.isLoggedIn){

<!-- shop existed -->
@if(authService.selectedShop) {
<main>
  <app-header [count]="notificationService.count" [countAll]="notificationService.countAll" [notifications]="notificationService.notifications" (callBackOpenNotify)="callBackOpenNotify($event)"
    (callBackLoadNotify)="callBackLoadNotify($event)"></app-header>
  <div class="relative h-content">
    <div class="w-full md:flex h-full overflow-y-auto">
      <app-navbar></app-navbar>
      <div class="w-full overflow-y-auto">
        <app-location-back></app-location-back>
        <router-outlet />
      </div>
    </div>
  </div>
</main>
<app-screen-loader></app-screen-loader>
<app-notify [open]="openNotify" [notice]="notice" (callBackNotifyUpdate)="callBackNotifyUpdate($event)"></app-notify>
}
@else {

<!-- shop does not existed -->
<div class="flex h-content justify-center items-center">
  <div class="text-center space-y-2 -mt-14">
    <h1 class="text-8xl font-bold">
      403
    </h1>
    <div>
      <h2 class="font-bold text-2xl">
        Forbidden
      </h2>
      <div class="text-gray-600">
        กรุณาขอสิทธิ์เข้าใช้กับเจ้าหน้าที่ช็อป
      </div>
    </div>
    <button class="btn-primary" (click)="authService.logout()">เข้าสู่ระบบด้วยบัญชีอื่น</button>
  </div>
</div>
}
} @else {
<!-- login falid -->
<div class="flex h-[100vh]">
  <router-outlet></router-outlet>
</div>
}

}

}