import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MerchantManagementApiService {

  endpoint = environment.endpoint;
  auth_endpoint = environment.auth_endpoint;
  system_id = environment.system_id

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }


  createSmallShop(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.post(`${this.endpoint}/shops/small-shop`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  createProvider(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/shops/provider`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateShop(shopId: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.patch(`${this.endpoint}/shops/${shopId}`, body, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  getDealerByUsername(username: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve) => {
      return this.http.get(`${this.endpoint}/shops/dealer/username/${username}`, { headers }).subscribe((data) => {
        resolve(data);
      })
    });
  }

  addShopUser(userId: string, shops: { shop_id: string }[]) {
    return new Promise((resolve, reject) => {
      const body = {
        "user_id": userId,
        "system_id": this.system_id,
        "shops": shops
      }
      const headers = this.apiService.getAPIHeader();
      return this.http.patch(`${this.auth_endpoint}/shop-user/add`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
