<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="flex w-full">
        <button class="flex-1 text-center p-2 border-b" [ngClass]="{'border-b-2 border-yellow-500':state==='follow'}"
            (click)="state='follow'">ติดตามสถานะ</button>
        <button class="flex-1 text-center p-2 border-b"
            [ngClass]="{'border-b-2 border-yellow-500':state==='add-friend'}"
            (click)="state='add-friend'">เพิ่มเพื่อน</button>
    </div>
    @if(state==='follow'){
    <div class="pt-4 font-bold text-lg">
        แสกนเพื่อติดตามสถานะการเคลม
    </div>
    <div class="flex flex-col justify-center items-center w-full">
        <div class="">
            <qrcode [qrdata]="data.qrcode" [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'" [imageHeight]="60"
                [imageWidth]="60" [margin]="4" [scale]="1" [width]="288"></qrcode>
        </div>
    </div>
    }
    @if(state==='add-friend'){
    <div class="pt-4 font-bold text-lg">
        แสกนเพื่อเพิ่มเพื่อน
    </div>
    <div class="flex flex-col justify-center items-center w-full">
        <div class="w-[288px]">
            <img src="https://storage.maxxgadget.com/pos/config/qr/claim.png"
                alt="https://storage.maxxgadget.com/pos/config/qr/claim.png" class="w-full">
        </div>
    </div>
    }
    <div class="h-2"></div>
</div>