import { Component, OnInit } from '@angular/core';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrl: './merchant.component.css'
})
export class MerchantComponent implements OnInit {

  faScrewdriverWrench = faScrewdriverWrench

  constructor() { }

  async ngOnInit(): Promise<void> { }


}
