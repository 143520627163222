<div class="block md:hidden absolute bg-white h-full overflow-y-auto transition-all z-50"
    [ngClass]="{'w-full': utilService.navbarOpen, 'w-0' : !utilService.navbarOpen}">
    <div class="h-full p-4" [ngClass]="{'opacity-100 transition-opacity delay-300 duration-300': utilService.navbarOpen, 
        'opacity-0' : !utilService.navbarOpen}">

        <div class="w-full flex justify-end">
            <button (click)="toggleMenu()">
                <fa-icon [icon]="faXmark" class="text-lg text-gray-600"></fa-icon>
            </button>
        </div>
        <div
            class="grid grid-cols-1 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 gap-4 mt-4 overflow-y-auto pb-8">
            @for (menu of menuList; track $index) {
            @if (menu.visible && authGuardService.canAccessRoute(menu.path)) {
            <button class="bg-gray-100 text-gray-400 rounded-lg aspect-square flex justify-center items-center"
                [ngClass]="{'bg-yellow-300 !text-black' : menu.path.split('/')[1] === currentPath}"
                (click)="gotoPath(menu.path)">
                <div class="text-center">
                    <div>
                        <fa-icon [icon]="menu.icon" class="text-3xl"></fa-icon>
                    </div>
                    <div class="pt-2">
                        {{ menu.name }}
                    </div>
                </div>

            </button>
            }
            }
        </div>
    </div>
</div>

<div class="hidden md:block w-32 bg-white h-content overflow-y-auto pb-4">
    @for (menu of menuList; track $index) {
    @if (menu.visible && authGuardService.canAccessRoute(menu.path)) {
    <button class="w-full aspect-[4/3] text-gray-400 transition-colors" (click)="gotoPath(menu.path)"
        [ngClass]="{'bg-yellow-500 !text-black' : menu.path.split('/')[1] === currentPath}">
        <div class="text-center">
            <div>
                <fa-icon [icon]="menu.icon" class="text-3xl"></fa-icon>
            </div>
            <div>
                {{ menu.name }}
            </div>
        </div>
    </button>
    }
    }
</div>