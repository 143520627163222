import { Component } from '@angular/core';
import { faBarcode, faCartPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ExchangeService } from '../../services/exchange/exchange.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ExchangeApiService } from '../../services/api/exchange-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

/**
 * This component handles the creation of exchange transactions.
 * The process includes:
 * 1. Adding serials for return.
 * 2. Confirming the serials.
 * 3. Adding exchange items if there are any.
 * 4. Confirming the new order with the exchange items, ensuring the summary
 *    reflects the exchange item value minus the return item value.
 * 
 * Note: Exchange transactions do not grant points to the customer.
 */

@Component({
  selector: 'app-exchange-create',
  templateUrl: './exchange-create.component.html',
  styleUrl: './exchange-create.component.css'
})
export class ExchangeCreateComponent {

  faTrash = faTrash;
  faBarcode = faBarcode;
  faCartPlus = faCartPlus;

  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopName: string = this.authService.selectedShop?.name || '';
  orderNo: string = ''
  returnNo: string = ''

  banks: [] = [];
  mode: string = 'add'; // add, delete
  type: string = 'return';
  items: any[] = []
  orderReturn: any = {
    price: 0,
    total_price: 0,
    total_discount: 0
  }

  serialNoDelete: any;
  serialNoAdd: any;

  typeOption: boolean = false;
  paymentMethods: { value: string; name: string }[] = [];

  addFriend = false;
  confirmMember = false;
  isMember: boolean = false;
  memberData: any = null;
  member: any = {
    member: {
      userId: '',
      name: '',
      point: 0,
      orders: 0
    },
    phone_number: ''
  };

  isCreating: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private exchangeService: ExchangeService,
    private exchangeApiService: ExchangeApiService
  ) { }

  ngOnInit(): void {
    this.getBank();
    this.getShop()
    this.route.queryParamMap.subscribe(async params => {
      this.returnNo = params.get('return_no') || '';
      if (this.returnNo) {
        this.exchangeApiService.getOneReturn(this.returnNo).then((res: any) => {
          console.log(res)
          if (res.success && res.data) {
            this.orderReturn = res.data;
            this.items = res.data.items
            this.orderNo = res.data.order_no;
            this.getMemberData()
          }
        })
      }
    });
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 10)
  }

  getShop() {
    this.exchangeApiService.getShopByShopId(this.shopId).then((res: any) => {
      if (res.success) {
        this.paymentMethods = res.data.refund_payment_method.map((e: any) => ({ ...e, value: e.id }));
      }
    })
  }

  getBank() {
    this.exchangeService.loadBankData().then((res: any) => {
      this.banks = res || [];
    })
  }

  async onAdd(event: any) {

    const serialNo = event.target.value;

    if (this.isCreating) {
      console.log('Sorry, Creating exchange order');
      return;
    }

    if (serialNo) {
      this.isCreating = true;

      if (this.items.find(e => e.serial_no === serialNo)) {
        this.modalService.openModal('info', 'Ooop!', 'มี barcode นี้ในรายการรับคืนแล้ว').then(() => { })
        this.clearInputSerialNoAdd();
        return;
      }

      if (this.returnNo) {
        console.log('update')
        await this.addReturnItem(serialNo);
      } else {
        console.log('create')
        await this.createReturn(serialNo);
      }

      this.clearInputSerialNoAdd();

    }

  }

  /**
    * Adds a serial to the return order.
    * 
    * @param serial The serial number of the item to be added to the return order.
    * 
    */
  async createReturn(serialNo: string) {
    const dataCreate = {
      shop_id: this.shopId,
      shop_name: this.shopName,
      serial_no: serialNo
    }
    const resultReturn: any = await this.exchangeApiService.createOrderReturn(dataCreate);
    if (resultReturn.success) {
      this.orderReturn = resultReturn.data;
      this.items = resultReturn.data.items;
      this.returnNo = resultReturn.data.return_no;
      this.orderNo = resultReturn.data.order_no;
      this.getMemberData()
    } else {
      let message = '';

      if (resultReturn.message === 'can not get serial') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลบาร์โค้ด กรุณาลองใหม่อีกครั้ง'
      } else if (resultReturn.message === 'not found serial') {
        message = 'ไม่พบบาร์โค้ดสินค้านี้ในระบบ กรุณาตรวจสอบบาร์โค้ดใหม่อีกครั้ง'
      } else if (resultReturn.message === 'serial no is availble') {
        message = 'สินค้านี้ยังไม่ถูกขาย ไม่สามารถรับคืนได้'
      } else if (resultReturn.message === 'shop id not match') {
        message = 'สินค้านี้ไม่ได้ขายที่ร้านค้านี้ ไม่สามารถรับคืนได้'
      } else if (resultReturn.message === 'can not get order') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลข้อมูลคำสั่งซื้อ กรุณาลองใหม่อีกครั้ง'
      } else if (resultReturn.message === 'not found order') {
        message = 'ไม่พบข้อมูลคำสั่งซื้อของสินค้านี้'
      } else if (resultReturn.message === 'order not completed') {
        message = 'คำสั่งซื้อไม่เสร็จสมบูรณ์ ไม่สามารถรับคืนได้'
      } else if (resultReturn.message === 'can not get shop') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลร้านค้า กรุณาลองใหม่อีกครั้ง'
      } else if (resultReturn.message === 'not found shop') {
        message = 'ไม่ร้านค้านี้ในระบบ กรุณาลองใหม่อีกครั้ง'
      } else if (resultReturn.message === 'order is overdue') {
        message = 'คำสั่งซื้อนี้เกินระยะเวลาในการคืนสินค้า'
      }

      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
    }
  }

  /**
  * Adds a serial to the return order.
  * 
  * @param serial The serial number of the item to be added to the return order.
  * 
  */
  async addReturnItem(serialNo: string) {
    const data = {
      serial_no: serialNo, shop_id: this.shopId
    }
    const result: any = await this.exchangeApiService.addReturnItem(this.returnNo, data)
    if (result.success) {
      this.orderReturn = result.data;
      this.items = result.data.items;
      this.returnNo = result.data.return_no;
      this.orderNo = result.data.order_no;
    } else {
      let message = '';

      if (result.message === 'can not get serial') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลบาร์โค้ด กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'not found serial') {
        message = 'ไม่พบบาร์โค้ดสินค้านี้ในระบบ กรุณาตรวจสอบบาร์โค้ดใหม่อีกครั้ง'
      } else if (result.message === 'serial no is availble') {
        message = 'สินค้านี้ยังไม่ถูกขาย ไม่สามารถรับคืนได้'
      } else if (result.message === 'shop id not match') {
        message = 'สินค้านี้ไม่ได้ขายที่ร้านค้านี้ ไม่สามารถรับคืนได้'
      } else if (result.message === 'can not get return') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลข้อมูลคำสั่งซื้อ กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'not found return') {
        message = 'ไม่พบข้อมูลใบรับคืนนี้'
      } else if (result.message === 'order number not match') {
        message = 'สินค้านี้ไม่ได้อยู่ในคำสั่งซื้อเดียวกัน กรุณาทำรายการทีละคำสั่งซื้อ'
      } else if (result.message === 'order not completed') {
        message = 'คำสั่งซื้อไม่เสร็จสมบูรณ์ ไม่สามารถรับคืนได้'
      } else if (result.message === 'can not get shop') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลร้านค้า กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'not found shop') {
        message = 'ไม่ร้านค้านี้ในระบบ กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'order is overdue') {
        message = 'คำสั่งซื้อนี้เกินระยะเวลาในการคืนสินค้า'
      }

      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
    }
  }

  async onDelete(event: any) {
    const serialNo = event.target.value;
    if (serialNo) {
      if (!this.items.find(e => e.serial_no === serialNo)) {
        this.modalService.openModal('info', 'Ooop!', 'ไม่พบ barcode นี้ในรายการรับคืน').then(() => { })
        this.clearInputSerialNoDelete();
        return;
      }

      const body = { serial_no: serialNo, order_no: this.orderNo }
      const resultReturn: any = await this.exchangeApiService.removeReturnItem(this.returnNo, body);
      if (resultReturn.success) {
        this.orderReturn = resultReturn.data;
        this.items = resultReturn.data.items;
      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถลบสินค้าจากการรับคืนได้')

      }
      this.clearInputSerialNoDelete();

    }
  }

  clearInputSerialNoAdd() {
    this.serialNoAdd = null;
    this.isCreating = false;
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 10)
  }

  clearInputSerialNoDelete() {
    this.serialNoDelete = null;
    setTimeout(() => {
      document.getElementById('barcode-delete')?.focus()
    }, 10)
  }

  formatProductDisplay(): any[] {
    let result: any[] = [];
    for (const item of this.items) {
      let index = result.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result.push({
          ...item,
          qty: 1
        });
      } else {
        result[index].qty += 1;
      }
    }
    return result;
  }

  modeToggle(value: string) {
    this.mode = value;
    if (this.mode === 'add') {
      setTimeout(() => {
        document.getElementById('barcode-add')?.focus()
      }, 10)
    } else {
      setTimeout(() => {
        document.getElementById('barcode-delete')?.focus()
      }, 10)
    }
  }

  openAddfriendDialog = () => {
    this.addFriend = true;
  }

  /**
   * After click next step , show dialog select type return
   */
  confirmCreateReturn = () => {
    this.typeOption = true;
  }

  /**
   * if return product
   * 1. update payment customer
   * 
   * if exchange product
   * 1. create order by return_no
   * 2. redirect sale
   */
  callBackType = (event: any) => {
    this.typeOption = false;
    if (event.confirm) {
      if (event.type === 'return') {
        console.log('รับคืน')
      } else {
        console.log('เปลี่ยนสินค้า')
        const dataCreate = {
          order_date: +new Date(),
          shop_id: this.shopId,
          shop_name: this.shopName,
          ref: this.returnNo
        }
        this.exchangeApiService.createOrderExchange(dataCreate).then((res: any) => {
          if (res.success) {
            this.router.navigate(['/sales/sale-create'], { queryParams: { order_no: res.data.order_no } });
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    }
  }

  callBackRefund = (event: any) => {
    if (event.confirm) {
      console.log(event.data)
      let dataUpdate = {}
      if (event.data.payment_method === 'bank_transfer') {
        dataUpdate = {
          payment_method: event.data.payment_method,
          bank: event.data.bank,
          acc_name: event.data.acc_name,
          acc_number: event.data.acc_number,
          phone_number: event?.data?.phone_number || '',
          note: event.data.note
        }
      } else if (event.data.payment_method === 'cash') {
        dataUpdate = {
          payment_method: event.data.payment_method,
          phone_number: event?.data?.phone_number || '',
          note: event.data.note
        }
      }
      this.exchangeApiService.updateRefundDetail(this.returnNo, dataUpdate).then((res: any) => {
        if (res.success) {
          this.modalService.openModal('success', 'บันทึกข้อมูลสำเร็จ', 'บริษัทจะดำเนินการคืนเงินภายใน 7 วันทำการ').then((result) => {
            this.router.navigate(['/exchange']);
          })
        } else {
          this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
        }
      })
    }
  }

  async getMemberData() {
    if (!this.orderReturn.customer) return;
    console.log(this.orderReturn);
    let memberData = {
      member: {
        userId: '',
        name: '',
        point: 0,
        orders: 0
      },
      phone_number: ''
    }
    // 1. get user info that include phone number
    // 2. get point balance
    // 3. get customer bill
    const member: any = await this.exchangeApiService.getCustomerByUserId(this.orderReturn.customer.user_id);
    if (!member.success) {
      this.isMember = false;
      this.memberData = null;
      this.member = null;
      return;
    };
    console.log(member)
    memberData.member.userId = this.orderReturn.customer.user_id;
    memberData.member.name = this.orderReturn.customer.name;
    memberData.phone_number = member?.data?.phone || ''

    const pointBalance: any = await this.exchangeApiService.getCustomerPointBalace(memberData.member.userId, this.authService.selectedShop?.shop_id || '')
    const totalBill: any = await this.exchangeApiService.getCustomerTotalBill(memberData.member.userId, this.authService.selectedShop?.shop_id || '')
    memberData.member.point = pointBalance.data ?? 0;
    memberData.member.orders = totalBill.data ?? 0;

    // for sale-add-friend component
    this.isMember = true;
    this.memberData = {
      userId: memberData.member.userId,
      name: memberData.member.name,
      point: memberData.member.point,
      orders: memberData.member.orders
    }
    this.member = memberData;
    this.confirmMember = true;
  }

  callBackAddFriend = async (data: any) => {
    this.confirmMember = data.confirm;
    console.log(data)
    if (data.confirm && data.isScan) {
      this.addFriend = false
      this.exchangeApiService.getOneReturn(this.returnNo).then((res: any) => {
        console.log(res)
        if (res.success && res.data) {
          this.orderReturn = res.data;
          this.items = res.data.items
          this.getMemberData()
          this.member = {
            member: {
              userId: this.orderReturn?.customer?.user_id || '',
              name: this.orderReturn?.customer?.name || '',
              point: 0,
              orders: 0
            },
            phone_number: ''
          }
        }
      })

    } else if (data.confirm && data.data) {
      this.addFriend = false
      this.member = data.data;
      // call api update order customer
      this.exchangeApiService.addCustomerToReturn(this.returnNo, this.member.member.userId, this.member.member.name)

    } else {
      this.addFriend = false
      this.member = {
        member: {
          userId: '',
          name: '',
          point: 0,
          orders: 0
        },
        phone_number: ''
      };
      this.exchangeApiService.removeCustomerFromReturn(this.returnNo).then((res) => console.log(res));
    }
    console.log(this.member)
  }

}
