import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js/auto';
import { default as Annotation } from 'chartjs-plugin-annotation';
Chart.defaults.font.family = "'Noto Sans Thai', sans-serif"

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrl: './charts.component.css'
})
export class ChartsComponent implements AfterViewInit {

  @Input() chartId: string = '';
  @Input() chartType: ChartType = 'line'; // default line chart
  @Input() chartData: ChartConfiguration['data'] = {
    datasets: []
  };
  @Input() chartOptions: ChartConfiguration['options'] = {};

  chart!: Chart;

  constructor() {
    Chart.register(Annotation);
  }

  ngAfterViewInit(): void {
    this.chart = new Chart(this.chartId, {
      type: this.chartType,
      data: this.chartData,
      options: this.chartOptions
    })
  }


}
