import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrl: './coupon-detail.component.css'
})
export class CouponDetailComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CouponDetailComponent>,
  ) {
    console.log(data)
  }

  close() {
    this.ref.close(false);
  }
}
