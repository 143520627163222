<div class="p-4">
    <div class="relative pb-2">
        <input type="text" id="barcode-add" class="input !pr-12" placeholder="บาร์โค้ดสินค้า" autocomplete="off"
            [(ngModel)]="barcode" (keyup.enter)="checkProductWarraty()">
        <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
    </div>

    @if (isCheckWarranty) {
    @if (!isLoading) {
    <div>
        @if (warranty.covered) {
        <div id="warranty" class="warranty-hidden">
            <div class="mt-4 rounded-lg bg-white shadow-md">
                <div class="p-4 rounded-t-lg flex flex-col gap-2 justify-center items-center">
                    <div class="flex justify-center items-center h-10 w-10 rounded-full bg-lime-200">
                        <fa-icon [icon]="faCircleCheck" class="text-lime-500 text-2xl flex" />
                    </div>
                    <div class="flex flex-col md:flex-row justify-center items-center gap-0 md:gap-1">
                        <div class="text-lime-500 text-lg">สินค้ารายการนี้เป็น <span
                                class="underline underline-offset-2 decoration-double">ของแท้</span> จาก </div>
                        <div class="text-lime-500 text-xl font-bold">Remax
                            Thailand</div>
                    </div>
                </div>
                <div class="flex flex-col md:flex-row items-center p-4 rounded-b-lg gap-4 w-full">
                    <div class="flex justify-center w-fit">
                        <img [src]="'https://storage.remaxthailand.co.th/img/product/'+ warranty.sku +'/1.jpg'"
                            class="w-3/5 md:w-48 aspect-square rounded-lg border md:max-w-48"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'" />
                    </div>
                    <div class="rounded-lg p-4 h-full md:h-48 w-full"
                        [ngClass]="{'bg-yellow-200': checkExpire(warranty.expireDate), 'bg-lime-200': !checkExpire(warranty.expireDate)}">
                        <div class="font-bold pb-3">
                            {{warranty.productName}}
                        </div>
                        <div>
                            Serial Number: {{warranty.barcode}}
                        </div>
                        <div>
                            จำหน่ายเมื่อ: {{dateFormat(warranty.sellDate)}}
                        </div>
                        <div>
                            วันหมดอายุการรับประกัน: {{dateFormat(warranty.expireDate)}}
                        </div>
                        <div class="flex w-full justify-center md:justify-start mt-3">
                            @if(checkExpire(warranty.expireDate)) {
                            <div class="rounded-full px-4 py-1 bg-yellow-600 text-white">
                                <fa-icon [icon]="faClock" />
                                หมดอายุการรับประกันแล้ว
                            </div>
                            }@else {
                            <div class="rounded-full px-4 py-1 bg-lime-600 text-white">
                                <fa-icon [icon]="faClock" />
                                อยู่ในช่วงการรับประกัน
                            </div>
                            }
                        </div>
                    </div>
                </div>
                @if(!checkExpire(warranty.expireDate)) {
                <div class="p-4">
                    <button class="w-full" [ngClass]="{'btn-disable': isClaimed, 'btn-secondary': !isClaimed}"
                        (click)="claim()" [disabled]="isClaimed">
                        {{isClaimed? 'ส่งเคลมสินค้าแล้ว':'ส่งเคลมสินค้า'}}
                        <fa-icon [icon]="faSquareArrowUpRight"></fa-icon>
                    </button>
                </div>
                }
            </div>
        </div>
        }@else {
        <div id="warranty" class="warranty-hidden">
            <div class="mt-4 rounded-lg bg-white shadow-md">
                <div class="p-4 rounded-t-lg flex flex-col gap-2 justify-center items-center">
                    <div class="flex justify-center items-center h-10 w-10 rounded-full bg-red-200">
                        <fa-icon [icon]="faTriangleExclamation" class="text-red-500 text-2xl flex" />
                    </div>
                    <div class="flex flex-col md:flex-row justify-center items-center gap-0 md:gap-1">
                        <div class="text-red-500 text-lg">สินค้าที่ตรวจสอบ ไม่พบว่าเป็น <span
                                class="underline underline-offset-2 decoration-double">ของแท้</span> จาก </div>
                        <div class="text-red-500 text-xl font-bold">Remax
                            Thailand</div>
                    </div>
                </div>
                <div class="p-4">
                    <div class="p-4 bg-red-200 rounded-lg">
                        <div>
                            1. โปรดตรวจสอบว่าคุณกรอก Serial Number ถูกต้องหรือไม่?
                        </div>
                        <div>
                            2. แน่ใจว่าสินค้านี้เป็นของแท้จาก Remax Thailand หรือไม่?
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    }@else {
    <div class="flex justify-center pt-40">
        <app-loader></app-loader>
    </div>
    }
    }
</div>