<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                รายละเอียดการแจ้งเตือน
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            <div class="space-y-5">

                <div class="flex space-x-2 items-start">
                    <img src="assets/svg/loud-speaker.svg" alt="assets/svg/loud-speaker.svg" class="w-5">
                    <div class="font-semibold">{{notice?.title}}</div>
                </div>
                <div class="flex space-x-2 justify-end">
                    <fa-icon [icon]="faCalendarMinus" class="text-gray-600"></fa-icon>
                    <div class="text-gray-500">{{displayDatetime(notice?.created?.date)}} </div>
                </div>
                <div class="whitespace-pre-wrap text-sm">{{notice?.message}}</div>

                @if (notice?.read) {
                <div class="flex">
                    <div class="text-gray-500 text-sm">อ่านแล้วเมื่อ {{displayDatetime(notice?.read_date)}} </div>
                </div>
                }@else {
                <div class="flex">
                    <button class="text-sm border rounded-md px-2 py-1 flex gap-2 hover:bg-gray-50" (click)="markAsRead()">
                        <fa-icon [icon]="faCheck" class="text-gray-600"></fa-icon>
                        <div class="">ทำเครื่องหมายว่าอ่านแล้ว</div>
                    </button>
                </div>}

                <div class="h-32"></div>
            </div>
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full pt-2">
                <button class="btn-secondary w-full" (click)="close()">ปิด</button>
            </div>
        </div>
    </div>
</div>