import { Component, OnInit } from '@angular/core';
import { faArrowLeft, faArrowsRotate, faCheck, faChevronLeft, faFloppyDisk, faPencil, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ProductsService } from '../../services/products/products.service';
import { MatDialog } from '@angular/material/dialog';
import { CreatePriceConfigDialogComponent } from '../../components/create-price-config-dialog/create-price-config-dialog.component';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-product-setting',
  templateUrl: './product-setting.component.html',
  styleUrl: './product-setting.component.css'
})
export class ProductSettingComponent implements OnInit {

  faChevronLeft = faChevronLeft;
  faArrowLeft = faArrowLeft;
  faPlus = faPlus;
  faFloppyDisk = faFloppyDisk;
  faArrowsRotate = faArrowsRotate;
  faPencil = faPencil;
  faXmark = faXmark;
  faCheck = faCheck;

  priceConfig = []

  dataSource: any = [];

  idUpdate = '';
  formUpdatePriceConfig: any = {}

  isCreate = false;
  formCreatePriceConfig: any = {}

  shopId: string = this.authService.selectedShop?.shop_id || '';

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private productsService: ProductsService,
    public dialog: MatDialog,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.getPriceConfig()
  }

  getPriceConfig() {
    this.productsService.getAllPriceConfigByShop(this.shopId, {}).then((response: any) => {
      console.log(response)
      if (response.success) {
        this.dataSource = response.data;
        this.priceConfig = response.data.map((item: any) => {
          return { price_config_id: item.price_config_id, name: item.name }
        })
      } else {
        this.dataSource = []
      }
    })
  }

  clickButtonAddPriceConfig() {
    this.isCreate = true;
    this.formCreatePriceConfig.active = true;
    this.formCreatePriceConfig.is_default = false;

    const dialogRef = this.dialog.open(CreatePriceConfigDialogComponent, {
      data: { formCreatePriceConfig: this.formCreatePriceConfig, priceConfig: this.priceConfig },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Payment result: `, result);
      if (result.confirm) {
        this.formCreatePriceConfig = result.data
        this.createPriceConfig()
      } else {
        this.cancelCreate()
      }
    });
  }

  formCreate(name: string, value: any) {
    this.formCreatePriceConfig[`${name}`] = value;
  }

  callbackActiveCreate = (value: any): void => {
    this.formCreatePriceConfig.active = value;
  }

  callbackDefaultCreate = (value: any): void => {
    this.formCreatePriceConfig.is_default = value;
  }

  cancelCreate() {
    this.isCreate = false;
    this.formCreatePriceConfig = {};
  }

  createPriceConfig() {
    console.log(this.formCreatePriceConfig)
    if (!this.formCreatePriceConfig.name || !this.formCreatePriceConfig.price_config_ref) {
      this.modalService.openModal('fail', 'กรุณากรอกข้อมูล', 'คุณกรอกข้อมูลไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง')
      this.isCreate = false;
      return;
    }

    this.modalService.openModal('confirm', 'ยืนยันการเพิ่มช่องราคา', '').then((result) => {
      if (result) {
        const body = {
          price_config_ref: this.formCreatePriceConfig.price_config_ref,
          shop_id: this.shopId,
          name: this.formCreatePriceConfig.name,
          active: this.formCreatePriceConfig.active,
          is_default: this.formCreatePriceConfig.is_default,
          is_change: true
        }
        this.productsService.createPriceConfig(body).then((response: any) => {
          console.log(response)
          if (response.success) {
            if (this.formCreatePriceConfig.is_default) {
              this.dataSource = this.dataSource.map((e: any) => ({ ...e, is_default: false }))
            }
            this.dataSource.push(response.data)
            this.isCreate = false;
            this.formCreatePriceConfig = {};
            this.modalService.openModal('success', 'เพิ่มช่องราคาสำเร็จ', '')
          } else {
            this.modalService.openModal('fail', 'เพิ่มช่องราคาไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    })
  }




  clickButtonUpdatePriceConfig(price_config_id: string) {
    this.idUpdate = price_config_id;
    const data = this.dataSource.find((e: any) => e.price_config_id === price_config_id)
    this.formUpdatePriceConfig = {
      name: data.name,
      active: data.active,
      is_default: data.is_default,
      price_config_ref: data.price_config_ref
    }
  }

  formChange(name: string, value: any) {
    this.formUpdatePriceConfig[`${name}`] = value;
  }

  callbackActiveChange = (value: any): void => {
    this.formUpdatePriceConfig.active = value;
  }

  callbackDefaultChange = (value: any): void => {
    this.formUpdatePriceConfig.is_default = value;
  }

  cancelUpdate() {
    this.idUpdate = '';
    this.formUpdatePriceConfig = {};
  }

  updatePriceConfig() {
    console.log(this.formUpdatePriceConfig)
    if (!this.formUpdatePriceConfig.name) {
      this.modalService.openModal('fail', 'กรุณากรอกข้อมูล', 'คุณกรอกข้อมูลไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง')
      return;
    }

    this.modalService.openModal('confirm', 'ยืนยันการแก้ไขช่องราคา', '').then((result) => {
      if (result) {
        const body = {
          price_config_id: this.idUpdate,
          name: this.formUpdatePriceConfig.name,
          active: this.formUpdatePriceConfig.active,
          is_default: this.formUpdatePriceConfig.is_default,
        }
        this.productsService.updatePriceConfigByShop(this.shopId, body).then((response: any) => {
          console.log(response)
          if (response.success) {
            const index = this.dataSource.findIndex((e: any) => e.price_config_id === this.idUpdate)
            if (this.formUpdatePriceConfig.is_default) {
              this.dataSource = this.dataSource.map((e: any) => ({ ...e, is_default: false }))
            }
            this.dataSource[index] = response.data;

            this.idUpdate = '';
            this.formUpdatePriceConfig = {};
            this.modalService.openModal('success', 'แก้ไขช่องราคาสำเร็จ', '')
          } else {
            this.modalService.openModal('fail', 'แก้ไขช่องราคาไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
          }
        })

        // //  mock data
        // const index = this.dataSource.findIndex((e: any) => e.price_config_id === this.idUpdate)
        // const dataUpdate = {
        //   "price_config_id": this.idUpdate,
        //   "price_config_ref": this.formUpdatePriceConfig.price_config_ref,
        //   "name_ref": this.dataSource.find((e: any) => e.price_config_id === this.formUpdatePriceConfig.price_config_ref).name,
        //   "shop_id": this.dataSource[index].shop_id,
        //   "name": this.formUpdatePriceConfig.name,
        //   "active": this.formUpdatePriceConfig.active,
        //   "is_default": this.formUpdatePriceConfig.is_default,
        //   isChange: true
        // }
        // if (this.formUpdatePriceConfig.is_default) {
        //   this.dataSource = this.dataSource.map((e: any) => ({ ...e, is_default: false }))
        // }
        // this.dataSource[index] = dataUpdate;

        // this.idUpdate = '';
        // this.formUpdatePriceConfig = {};
        // this.modalService.openModal('success', 'แก้ไขช่องราคาสำเร็จ', '')
      }
    })
  }


}
