import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class StaffApiService {

  endpoint = environment.endpoint;
  auth_endpoint = environment.auth_endpoint;
  system_id = environment.system_id

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }


  getAllShops(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllCredential() {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.get(`${this.auth_endpoint}/credential?system_id=${this.system_id}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllShopUser() {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.get(`${this.auth_endpoint}/shop-user/all?system_id=${this.system_id}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllUserInfo() {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.get(`${this.auth_endpoint}/user/info/all?system_id=${this.system_id}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateShopUser(body: any) {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.patch(`${this.auth_endpoint}/shop-user/set`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePermission(body: any) {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.patch(`${this.auth_endpoint}/user/update-permissions`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateRoleMemberSelected(body: any) {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.patch(`${this.auth_endpoint}/user/update-role-member`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  resetPassword(username: string) {
    return new Promise((resolve, _reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.patch(`${this.auth_endpoint}/credential/reset-password/${username}`, {}, { headers }).subscribe((data) => {
        resolve(data);
      })
    })
  }

  createCredential(body: any) {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.post(`${this.auth_endpoint}/credential`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAccessToken(body: { user_id: string, channel: string, info: any }) {
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.auth_endpoint}/auth/token`, body, {}).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePermissions(body: { user_id: string, system_id: string, role: string, active: boolean }) {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.patch(`${this.auth_endpoint}/user/update-permissions`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createShopUser(body: { user_id: string, system_id: string, shops: { shop_id: string }[] }) {
    return new Promise((resolve, reject) => {
      const headers = this.apiService.getAPIHeader();
      return this.http.post(`${this.auth_endpoint}/shop-user/create`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOneUserInfo(token: string) {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
      return this.http.get(`${this.auth_endpoint}/user/info/one?system_id=${this.system_id}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
