<div class="p-4 pb-32 min-h-full">

    <div class="relative flex w-full mb-2 shadow-sm rounded">
        <button class="flex justify-center items-center gap-2 transition-all transform duration-300 ease-out"
            [ngClass]="{'w-[15%] text-blue-700 bg-white font-bold rounded-l': mode === 'delete', 'w-[85%] btn-secondary !bg-blue-700 !rounded-r-none font-bold': mode === 'add'}"
            (click)="modeToggle('add')">
            <fa-icon [icon]="faCartPlus"></fa-icon>
            <div class="flex flex-nowrap"
                [ngClass]="{'duration-100 opacity-100 delay-75 ease-out': mode === 'add', 'hidden': mode === 'delete'}">
                กำลังเพิ่มสินค้า</div>
        </button>
        <button class="flex justify-center items-center gap-2 transition-all transform duration-300 ease-out"
            [ngClass]="{'w-[15%] px-4 text-red-500 bg-white font-bold rounded-r': mode === 'add', 'w-[85%] btn-danger !rounded-l-none font-bold': mode === 'delete'}"
            (click)="modeToggle('delete')">
            <div class="flex flex-nowrap"
                [ngClass]="{'duration-100 opacity-100 delay-75 ease-out': mode === 'delete', 'hidden': mode === 'add'}">
                กำลังลบสินค้า</div>
            <fa-icon [icon]="faTrash"></fa-icon>
        </button>
    </div>

    @if (mode === 'add') {
    <div class="relative pb-2">
        <input type="text" id="barcode-add" class="input !pr-12" placeholder="บาร์โค้ดสินค้า" autocomplete="off"
            [(ngModel)]="serialNoAdd" (keyup.enter)="onAdd($event)">
        <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
    </div>
    }
    @if (mode === 'delete') {
    <div class="relative pb-2">
        <input type="text" id="barcode-delete" class="input-invalid placeholder:text-red-500 !pr-12 !h-[46px]"
            placeholder="ลบสินค้า" autocomplete="off" [(ngModel)]="serialNoDelete" (keyup.enter)="onDelete($event)">
        <fa-icon [icon]="faTrash" class="absolute right-4 -mt-9 text-2xl text-red-500"></fa-icon>
    </div>
    }

    <div class="border-t pt-4 space-y-1">

        @for (item of formatProductDisplay(); track $index) {

        <div class="grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
            <div class="col-span-1">
                <div class="w-full aspect-square">
                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + item.sku + '/1.jpg'"
                        [alt]="item.sku" class="rounded-sm">
                </div>
            </div>
            <div class="col-span-3 md:col-span-6 xl:col-span-10 text-sm md:text-base line-clamp-3 h-[56px]">
                {{ item.name }}
            </div>
            <div class="col-span-1 text-right pr-1">
                <div class="text-sm md:text-base">
                    x {{ item.qty }}
                </div>
            </div>

        </div>
        }

    </div>
</div>
<div class="md:relative">
    <app-stock-out-footer (callBackConfirm)="callBackConfirm($event)"></app-stock-out-footer>
</div>