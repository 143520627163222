import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import generatePayload from 'promptpay-qr';

@Component({
  selector: 'app-qr-promtpay-payment',
  templateUrl: './qr-promtpay-payment.component.html',
  styleUrl: './qr-promtpay-payment.component.css'
})
export class QrPromtpayPaymentComponent implements OnInit {

  thaiQRLogo = '../../../../../assets/images/logo/thaiQR.png';
  qrPromptpayNo = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<QrPromtpayPaymentComponent>
  ) {
    console.log(data)
  }

  ngOnInit(): void {
    console.log('--ngOnInit--')
    console.log(this.data);
    this.qrPromptpayNo = generatePayload(this.data.promptpayNumber, { amount: this.data.amount }) //First parameter : mobileNumber || IDCardNumber
    console.log(this.qrPromptpayNo)
  }

  close() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }

}
