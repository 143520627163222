import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const END_POINT = environment.endpoint
const SYSTEM_ID = environment.system_id
const AUTH_END_POINT = environment.auth_endpoint
@Injectable({
  providedIn: 'root'
})
export class ShopService {

  shopUsers: { user_id: string; name: string, uid: string }[] = [];

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllShop(filters: any) {
    const headers = this.apiService.getAPIHeader()
    return new Promise((resolve, reject) => {
      return this.http.get(`${END_POINT}/shops?${this.apiService.parseFilters(filters)}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getActiveUserInfo() {
    const headers = this.apiService.getAPIHeader()
    return new Promise((resolve, reject) => {
      return this.http.get(`${AUTH_END_POINT}/user/info/active`, { headers, params: { system_id: SYSTEM_ID } }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProvider(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${END_POINT}/shops/provider?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getShopByShopId(shopId: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${END_POINT}/shops/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
