import { Component } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

@Component({
  selector: 'app-screen-loader',
  templateUrl: './screen-loader.component.html',
  styleUrl: './screen-loader.component.css'
})
export class ScreenLoaderComponent {
  constructor(
    public utilService: UtilService
  ) {}
}
