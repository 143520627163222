<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        ชำระเงินด้วย QR Code
    </div>
    @if (data.status === 'waiting') {
    <div class="border rounded-lg flex flex-col justify-center items-center w-full">
        <img src="https://storage.maxxgadget.com/remaxthailand/payment-method/qr-header.jpg" class="rounded-t-lg" />
        <!-- <qrcode [qrdata]="data.qrcode" [errorCorrectionLevel]="'M'" class="w-full"></qrcode> -->
        <div class="qrcodeImage">
            <qrcode [qrdata]="data.qrcode" [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'"
                [imageSrc]="thaiQRLogo" [imageHeight]="40"
                [imageWidth]="40" [margin]="4" [scale]="1" [width]="256"></qrcode>
        </div>
        <div class="flex w-full justify-between px-4 pb-4">
            <div class="text-sm">
                Remax Thailand
            </div>
            <div class="text-right">
                <div class="text-xl">
                    {{data.amount | number: '.2'}}
                </div>
                <div class="text-xs">
                    บาท (BAHT)
                </div>
            </div>
        </div>
    </div>
    <div class="text-red-500 text-center">
        อย่าปิดหน้าต่างนี้ จนกว่าขั้นตอนการชำระเงินจะเสร็จสมบูรณ์
    </div>
    <button class="btn-secondary" (click)="checkStatusQrPayment()">
        ตรวจสอบการชำระเงิน
    </button>
    <button (click)="close()" class="text-gray-700 underline underline-offset-2">
        เปลี่ยนวิธีการชำระเงิน
    </button>
    }@else if (data.status === 'failed') {
    <div class="font-bold text-lg text-red-500 text-center">
        รายการชำระเงินมีปัญหา กรุณาแจ้งเจ้าหน้าที่ที่เกี่ยวข้อง
    </div>
    <div>
        <div class="space-x-3">
            <span class="text-sm text-gray-500">Order No:</span> <span>{{data.orderNo}}</span>
        </div>
        <div class="space-x-3">
            <span class="text-sm text-gray-500">Ref No:</span> <span>{{data.gbpReferenceNo}}</span>
        </div>
    </div>
    <button (click)="close()" class="text-gray-700 underline underline-offset-2">
        ปิด
    </button>
    }
</div>