import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';

@Component({
  selector: 'app-claim-detail-dialog',
  templateUrl: './claim-detail-dialog.component.html',
  styleUrl: './claim-detail-dialog.component.css'
})
export class ClaimDetailDialogComponent {
  @Input('open')
  open: boolean = false;
  @Input('detail')
  detail: any = {};
  @Output('callBackClaimDetail')
  callBackPromotionDetail: EventEmitter<object> = new EventEmitter<object>();

  faChevronLeft = faChevronLeft;

  constructor(
    private utilService: UtilService
  ){}

  close() {
    this.callBackPromotionDetail.emit({ followUp: false })
  }

  followUp() {
    this.callBackPromotionDetail.emit({ followUp: true, serial_no: this.detail.product? this.detail.product.serial_no : null})
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }
}
