import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimApiService {
  private endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getClaim(shopId: string, filters: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/claim/${shopId}?${this.apiService.parseFilters(filters)}`, { headers }).subscribe((data) => {
        console.log(data)
        resolve(data);
      });
    })
  }

  checkProductWarraty(barcode: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/warranty/${barcode}`, { headers }).subscribe((data) => {
        console.log(data)
        resolve(data);
      });
    })
  }

  createClaimProduct(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/products/claim`, body, { headers }).subscribe((data) => {
        console.log(data)
        resolve(data);
      });
    })
  }

  getShopByShopId(shopId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/shops/${shopId}`, { headers }).subscribe((data) => {
        console.log(data)
        resolve(data);
      });
    })
  }

  validateClaimSerial(serialNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/claim/validate/${serialNo}`, { headers }).subscribe((data) => {
        console.log(data)
        resolve(data);
      });
    })
  }
}
