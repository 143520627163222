import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllProvider(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops/provider?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllShop(filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/shops?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createProvider(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/shops/provider`,body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }


}
