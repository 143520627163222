<div class="p-4 w-full space-y-4 text-sm lg:text-base relative">

    <div class="">
        <div class="font-bold text-base lg:text-lg"> ตั้งค่าช่องราคา </div>
        <div class="text-sm lg:text-base text-gray-500">
            สินค้าที่สร้างใหม่หลังจากสร้างช่องราคาแล้ว จะเป็นราคาปลีกตั้งต้นสำหรับทุกช่องราคา
        </div>
        <div class="flex justify-end py-2">
            <button class="btn-light !border flex space-x-2 items-center" (click)="clickButtonAddPriceConfig()"
                [disabled]="idUpdate">
                <fa-icon [icon]="faPlus" class=""></fa-icon>
                <div class="">เพิ่มช่องราคา</div>
            </button>
        </div>
        <div>
            <!-- FOR MOBILE -->
            <div class="flex flex-col gap-1 lg:hidden">

                @for (item of dataSource; track $index) {
                <div class="border bg-white rounded-lg p-3 relative h-28 flex flex-col">
                    <div class="mb-3">
                        @if(idUpdate===item.price_config_id){
                        <div class="w-56">
                            <input type="text" class="input" placeholder="ระบุชื่อ" autocomplete="off" #nameInput
                                [value]="item.name" (keyup)="formChange('name', nameInput.value)">
                        </div>
                        }@else {
                        <div class="flex h-8 text-sm font-bold border-b">
                            {{item.name}}
                        </div>
                        }
                    </div>
                    <div class="flex gap-10 items-end">
                        <div>
                            @if(idUpdate===item.price_config_id){
                            <div class="flex items-center space-x-2">
                                <app-toggle [id]="'mobile-toggle-update-active'" [callbackToggle]="callbackActiveChange"
                                    [defaultValue]="item.active"></app-toggle>
                                <div class="whitespace-nowrap">การใช้งาน</div>
                            </div>
                            }@else{
                            <div *ngIf="!item.active" class="my-1 flex font-semibold items-center text-red-500">
                                <div class="bg-red-500 w-2 h-2 rounded-full mr-2"></div>
                                <div class="whitespace-nowrap">ปิดใช้งาน</div>
                            </div>
                            <div *ngIf="item.active" class="my-1 flex font-semibold items-center text-green-500">
                                <div class="bg-green-500 w-2 h-2 rounded-full mr-2"></div>
                                <div class="whitespace-nowrap">เปิดใช้งาน</div>
                            </div>
                            }
                        </div>
                        <div>
                            @if(idUpdate===item.price_config_id){
                            <div class="flex items-center space-x-2">
                                <app-toggle [id]="'mobile-toggle-update-default'"
                                    [callbackToggle]="callbackDefaultChange"
                                    [defaultValue]="item.is_default"></app-toggle>
                                <div class="whitespace-nowrap">ตั้งเป็นช่องราคาหลัก</div>
                            </div>
                            }@else{
                            <div *ngIf="item.is_default"
                                class="flex font-semibold items-center bg-sky-50 text-sky-500 w-fit py-1 px-2 rounded">
                                <fa-icon [icon]="faCheck" class="mr-2"></fa-icon>
                                <div class="whitespace-nowrap">ช่องราคาหลัก</div>
                            </div>
                            }
                        </div>
                    </div>
                    <div class="absolute top-2 right-2">
                        <div class="flex justify-center space-x-2">
                            @if(!isCreate && idUpdate === item.price_config_id){
                            <button (click)="cancelUpdate()"
                                class="w-7 aspect-square rounded text-black flex items-center justify-center transition hover:bg-gray-200">
                                <fa-icon [icon]="faXmark" class=""></fa-icon>
                            </button>
                            <button (click)="updatePriceConfig()"
                                class="w-7 aspect-square rounded text-black  flex items-center justify-center transition hover:bg-yellow-500 ">
                                <fa-icon [icon]="faFloppyDisk" class=""></fa-icon>
                            </button>
                            }
                            @if(!idUpdate){
                            <button (click)="clickButtonUpdatePriceConfig(item.price_config_id)"
                                class="w-7 aspect-square rounded text-black  flex items-center justify-center transition hover:bg-yellow-500 ">
                                <fa-icon [icon]="faPencil" class=""></fa-icon>
                            </button>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>

            <!-- FOR DESKTOP -->
            <div class="hidden lg:block">
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    ช่องราคา
                                </th>
                                <th class="text-left">
                                    สถานะ
                                </th>
                                <th class="text-left">
                                    ช่องราคาหลัก
                                </th>
                                <th class="text-center w-40">
                                    จัดการ
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of dataSource; track $index) {
                            <tr class="tbody-tr">
                                <td class="h-16 py-2 w-96">
                                    @if(idUpdate===item.price_config_id){
                                    <div class="w-56">
                                        <input type="text" class="input" placeholder="ระบุชื่อ" autocomplete="off"
                                            #nameInput [value]="item.name"
                                            (keyup)="formChange('name', nameInput.value)">
                                    </div>
                                    }@else {
                                    {{item.name}}
                                    }
                                </td>
                                <td class="text-left w-40">
                                    @if(idUpdate===item.price_config_id){
                                    <div class="flex items-center space-x-2">
                                        <app-toggle [id]="'toggle-update-active'"
                                            [callbackToggle]="callbackActiveChange"
                                            [defaultValue]="item.active"></app-toggle>
                                        <div class="whitespace-nowrap">การใช้งาน</div>
                                    </div>
                                    }@else{
                                    <div *ngIf="!item.active" class="my-1 flex font-semibold items-center text-red-500">
                                        <div class="bg-red-500 w-2 h-2 rounded-full mr-2"></div>
                                        <div class="whitespace-nowrap">ปิดใช้งาน</div>
                                    </div>
                                    <div *ngIf="item.active"
                                        class="my-1 flex font-semibold items-center text-green-500">
                                        <div class="bg-green-500 w-2 h-2 rounded-full mr-2"></div>
                                        <div class="whitespace-nowrap">เปิดใช้งาน</div>
                                    </div>
                                    }
                                </td>
                                <td class="text-left w-56">
                                    @if(idUpdate===item.price_config_id){
                                    <div class="flex items-center space-x-2">
                                        <app-toggle [id]="'toggle-update-default'"
                                            [callbackToggle]="callbackDefaultChange"
                                            [defaultValue]="item.is_default"></app-toggle>
                                        <div class="whitespace-nowrap">ตั้งเป็นช่องราคาหลัก</div>
                                    </div>
                                    }@else{
                                    <div *ngIf="item.is_default"
                                        class="flex font-semibold items-center bg-sky-50 text-sky-500 w-fit py-1 px-2 rounded">
                                        <fa-icon [icon]="faCheck" class="mr-2"></fa-icon>
                                        <div class="whitespace-nowrap">ช่องราคาหลัก</div>
                                    </div>
                                    }
                                </td>
                                <td class="text-center">
                                    <div class="flex justify-center space-x-2">
                                        @if(!isCreate && idUpdate === item.price_config_id){
                                        <button (click)="cancelUpdate()"
                                            class="w-7 aspect-square rounded text-black flex items-center justify-center transition hover:bg-gray-200">
                                            <fa-icon [icon]="faXmark" class=""></fa-icon>
                                        </button>
                                        <button (click)="updatePriceConfig()"
                                            class="w-7 aspect-square rounded text-black  flex items-center justify-center transition hover:bg-yellow-500 ">
                                            <fa-icon [icon]="faFloppyDisk" class=""></fa-icon>
                                        </button>
                                        }
                                        @if(!idUpdate){
                                        <button (click)="clickButtonUpdatePriceConfig(item.price_config_id)"
                                            class="w-7 aspect-square rounded text-black  flex items-center justify-center transition hover:bg-yellow-500 ">
                                            <fa-icon [icon]="faPencil" class=""></fa-icon>
                                        </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                            }

                            <!-- <tr>
                                <td colspan="100">
                                    <div class="">
                                        <button class="btn-light !border flex space-x-2 items-center"
                                            (click)="clickButtonAddPriceConfig()" [disabled]="idUpdate">
                                            <fa-icon [icon]="faPlus" class=""></fa-icon>
                                            <div class="">เพิ่มช่องราคา</div>
                                        </button>
                                    </div>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>