<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer z-10"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronDown" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                เอกสารที่แนบ
            </div>
        </div>

        @if(imageUrl) {
        <div class="pt-16 p-4">
            <img [src]="imageUrl" [alt]="imageUrl" class="max-w-full rounded-md">
        </div>
        }

        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full space-y-4">
                <button class="w-full btn-secondary" (click)="close()">
                    ปิด
                </button>
            </div>
        </div>
    </div>
</div>