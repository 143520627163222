import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCloudArrowUp, faRepeat } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrl: './upload-image.component.css'
})
export class UploadImageComponent {

  @ViewChild('fileUpload', { static: true, read: ElementRef }) fileUpload: any;

  @Input()
  callbackUploadImage!: ((base64: any, type: any, id: string) => void);
  @Input()
  imagePreview: any;
  @Input()
  class_image: any;
  @Input()
  imageSize: any;
  @Input()
  id: string;

  faCloudArrowUp = faCloudArrowUp;
  faTrashCan = faTrashCan;
  faRepeat = faRepeat;

  @ViewChild('alertFileSizeOverLimit')
  alertFileSizeOverLimit!: TemplateRef<any>;

  limitFileSizeInMB = 10

  constructor(
    public sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {
    this.id = 'blah'
  }

  ngOnInit(): void { }

  getFile(event: any) {
    const file = event.target.files[0];

    const maxSize = this.limitFileSizeInMB * 1024 * 1024;
    if (file.size > maxSize) {
      console.log('File size exceeds the allowed limit');
      this.dialog.open(this.alertFileSizeOverLimit)
      this.fileUpload.nativeElement.value = '';
      return;
    }
    // File Preview
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imagePreview = reader.result;
        const name = file.name.split('.');
        this.callbackUploadImage(this.imagePreview, name[name.length - 1], this.id)
      };
    }

  }

  removeImage() {
    this.imagePreview = '';
    this.callbackUploadImage('', '', this.id)
    this.fileUpload.nativeElement.value = '';
  }


}
