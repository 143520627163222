<div class="p-4 pb-32">
    <div class="flex space-x-2 items-center">
        <fa-icon [icon]="faScrewdriverWrench" class="text-xl"></fa-icon>
        <div class="py-6 font-bold text-base lg:text-lg">การพิมพ์ใบเสร็จ</div>
    </div>
    <div class="card">
        @if (loading) {
        <div class="flex justify-center py-20">
            <app-loader></app-loader>
        </div>
        } @else {
        <form class="divide-y" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                    <div class="font-semibold">ตั้งค่าการพิมพ์ใบเสร็จ</div>
                    <div class="text-gray-500 text-sm">ใช้สำหรับพิมพ์ใบเสร็จ</div>
                </div>
                <div class="space-y-2 md:space-y-4">
                    <div class="flex items-center space-x-2">
                        <app-toggle [id]="'toggle-printer-active'" [callbackToggle]="callbackActivePrinter"
                            [defaultValue]="isPrinter"></app-toggle>
                        <div class="whitespace-nowrap">การใช้งานเครื่องปริ้น</div>
                    </div>
                    <div class="text-gray-500 text-sm">* กรุณาติดต่อเจ้าหน้าที่เพื่อติดตั้งเครื่องปริ้นก่อนใช้งาน</div>

                    @if(isPrinter){
                    <div>
                        <label for="title">ชื่อบิล</label>
                        <input type="text" id="title" formControlName="title" class="input" placeholder="ex. บิลเงินสด"
                            autocomplete="off">
                        <div *ngIf="submitted && f['title']?.errors?.['required']" class="text-xs text-red-500">
                            กรุณากรอก ชื่อบิล</div>
                    </div>
                    <div>
                        <label for="shopName">ชื่อร้าน</label>
                        <input type="text" id="shopName" formControlName="shopName" class="input"
                            placeholder="ex. Remax Shop" autocomplete="off">
                        <div *ngIf="submitted && f['shopName']?.errors?.['required']" class="text-xs text-red-500">
                            กรุณากรอก ชื่อร้าน</div>
                    </div>
                    <div>
                        <label for="branchName">ชื่อหรือรหัสสาขา</label>
                        <input type="text" id="branchName" formControlName="branchName" class="input" placeholder=""
                            autocomplete="off">
                    </div>
                    <div>
                        <label for="message">ข้อความท้ายบิล</label>
                        <input type="text" id="message" formControlName="message" class="input"
                            placeholder="ex. ขอบคุณที่มาอุดหนุนค่ะ" autocomplete="off">
                        <div *ngIf="submitted && f['message']?.errors?.['required']" class="text-xs text-red-500">
                            กรุณากรอก ข้อความท้ายบิล</div>
                    </div>
                    }
                </div>
            </div>
            @if(formChanged){
            <div class="flex justify-end">
                <div class="grid grid-cols-2 gap-2 pt-4 w-full lg:w-1/2">
                    <button type="button" class="btn-secondary" (click)="onCancel()">ยกเลิก</button>
                    <button type="submit" class="btn-primary">บันทึก</button>
                </div>
            </div>
            }
        </form>
        }
    </div>
</div>