import { Component } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faFileText, faTruck } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ReceiptApiService } from '../../services/api/receipt-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-receipt-detail',
  templateUrl: './receipt-detail.component.html',
  styleUrl: './receipt-detail.component.css'
})
export class ReceiptDetailComponent {

  faChevronLeft = faChevronLeft
  faFileText = faFileText
  faTruck = faTruck

  receiptDetails: any = {
    orderNo: '',
    orderDate: '',
    products: [],
    shippedDate: '',
    summaryPrice: 0,
    shippingPrice: 0,
    discount: 0,
    vat: 0,
    percentVat: 7,
    total: 0
  }

  shopId: string = this.authService.selectedShop?.shop_id || '';
  orderNo = ''
  totalReceiptedItem = 0;
  totalOrderedItem = 0;


  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private receiptApiService: ReceiptApiService

  ) { }

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async params => {
      this.orderNo = params.get('order_no') || '';
      this.receiptApiService.getOneReceipt(this.orderNo).then((res: any) => {
        if (res.success && res.data) {
          if (res.data.shop_id !== this.shopId) {
            this.backToReceiptLits()
          } else {
            this.receiptDetails = {

              orderNo: this.orderNo,
              orderDate: this.utilService.dayjs(res.data.purchase_order_date).format('DD/MM/YYYY, HH:mm'),
              shippedDate: res.data?.shipped_date ? this.utilService.dayjs(res.data.shipped_date).format('DD/MM/YYYY, HH:mm') : '',
              products: res.data.products,
              summaryPrice: res?.data?.summary_price || 0,
              shippingPrice: res?.data?.shipping_price || 0,
              discount: res?.data?.discount || 0,
              percentVat: res?.data?.percent_vat || 0,
              vat: res?.data?.vat || 0,
              total: res?.data?.total || 0,
            }
            this.calculateProductItem(this.receiptDetails.products);
          }
        } else {
          this.backToReceiptLits()
        }
      })
    })
  }

  calculateProductItem(products: any[]) {
    this.totalReceiptedItem = 0;
    this.totalOrderedItem = 0;
    products.forEach(product => {
      this.totalReceiptedItem += product.receipted_serials.length
      this.totalOrderedItem += product.ordered_serials.length
    })
  }

  confirmReceiptAll() {
    this.modalService.openModal('confirm', 'รับเข้าสินค้าทั้งหมด', 'ระบบจะนำเข้าสินค้าทั้งหมดในออเดอร์นี้โดยไม่ต้องสแกนรับเข้าทีละชิ้น ยืนยันรับเข้าทั้งหมดหรือไม่').then((result) => {
      console.log(result);
      if (result) {
        this.receiptApiService.receiptAllSerial(this.orderNo, this.shopId).then(async (res: any) => {
          if (res.success) {
            await this.ngOnInit();
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถรับเข้าสินค้าทั้งหมดได้ กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    })
  }

  goToReceiptScan() {
    this.router.navigate(['/receipts/receipt-scan']);
  }

  backToReceiptLits() {
    this.router.navigate(['/receipts/receipt-list']);
  }

  calcuatePercent(count: number, total: number) {
    return Math.ceil((count / total) * 100)
  }

}
