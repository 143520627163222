import { Injectable } from '@angular/core';
import { InventoryApiService } from '../api/inventory-api.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private inventoryApiService: InventoryApiService
  ) { }

  getData(shopId: string) {
    return this.inventoryApiService.getData(shopId)
  }

  getProductBySerialNo(body: any) {
    return this.inventoryApiService.getProductBySerialNo(body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventory(shopId: string) {
    return this.inventoryApiService.getInventory(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  createInventory(shopId: string, body: any) {
    return this.inventoryApiService.createInventory(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  inventoryCounting(shopId: string, body: any) {
    return this.inventoryApiService.inventoryCounting(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getProductTransaction(serialNo: string) {
    return this.inventoryApiService.getProductTransaction(serialNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  deleteInventory(shopId: string) {
    return this.inventoryApiService.deleteInventory(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }


  getActiveSerialByShop(shopId: string) {
    return this.inventoryApiService.getActiveSerialByShop(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getProductShopByshop(shopId: string, filter: any) {
    return this.inventoryApiService.getProductShopByshop(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  confirmInventory(body: any) {
    return this.inventoryApiService.confirmInventory(body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventoryHistory(shopId: string, filter: any) {
    return this.inventoryApiService.getInventoryHistory(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventoryDetail(inventoryId: string) {
    return this.inventoryApiService.getInventoryDetail(inventoryId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  confirmInventoryComplete(inventoryId: string) {
    return this.inventoryApiService.confirmInventoryComplete(inventoryId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  updateInventoryCountSerial(inventoryId: string, body: any) {
    return this.inventoryApiService.updateInventoryCountSerial(inventoryId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

}
