<div class="h-14 overflow-clip justify-between bg-white grid grid-cols-5 shadow-sm">
    <div class="overflow-clip flex w-full col-span-2">
        <button class="flex justify-center items-center w-16 h-full md:hidden" (click)="toggleMenu()">
            <fa-icon [icon]="faBars" class="text-2xl text-gray-600"></fa-icon>
        </button>
        <div class="bg-header w-24 h-full -ml-4 sm:ml-2 md:ml-4 -mt-0.5"></div>
    </div>
    <div class="w-full col-span-3">
        <div class="flex h-full items-center mr-4 space-x-2 justify-end md:pr-2">
            <div class="inline w-4/6 sm:w-fit">
                <div class="text-xs md:text-sm font-bold whitespace-nowrap text-end truncate">
                    {{ authService.user.name }}
                </div>
                <div class="text-right">
                    <div class="text-xs md:text-sm text-gray-600 whitespace-nowrap w-full truncate">
                        {{ authService.selectedShop?.name }}
                    </div>
                </div>
            </div>
            <button mat-icon-button class="rounded-full bg-gray-100 h-9 w-9 flex-none" routerLink="/profile">
                <img class="rounded-full bg-gray-100 h-9 w-9" [src]="authService.user.image" alt="user" loading="lazy"
                    (click)="closeToggleMenu()" onerror="this.onerror=null; this.src='assets/images/user.png'">
            </button>
            <button mat-icon-button class="relative" [matMenuTriggerFor]="notifyContent">
                <fa-icon [icon]="faBell"
                    class="text-2xl md:text-3xl text-gray-400 hover:text-gray-500 transition-all duration-300"></fa-icon>
                <div *ngIf="count > 0"
                    class="absolute -top-0.5 -right-1 md:-right-1.5 rounded-full w-4 h-4 md:w-5 md:h-5 bg-red-500 text-white text-xs content-center">
                    {{count}}</div>
            </button>
        </div>
    </div>
</div>

<mat-menu #notifyContent="matMenu" class="!mt-4 w-[320px] sm:w-[360px] max-h-[80vh] text-sm">
    <div class="p-2 border-b flex items-center space-x-2">
        <div class="">การแจ้งเตือน</div>
        <div class="w-5 h-5 rounded-full bg-gray-200 text-xs content-center text-center">{{count}}</div>
    </div>
    <div class="divide-y">
        @if(notifications.length === 0){
        <div class="flex flex-col py-8 gap-4 items-center">
            <img src="assets/svg/empty-box.svg" alt="assets/svg/empty-box.svg" class="w-20">
            <div class="">ไม่มีการแจ้งเตือนในขณะนี้</div>
        </div>
        }
        @for (item of notifications; track $index) {
        <button class="w-full hover:bg-gray-50" (click)="openDetailDialog(item)">
            <div class="px-2 py-3 border-l-4 text-left w-full"
                [ngClass]="{'border-red-400' : !item.read, 'border-white' : item.read}">
                <div class="w-full flex gap-4">
                    <div class="">
                        <fa-icon [icon]="faBullhorn" class="text-yellow-500"></fa-icon>
                    </div>
                    <div class="flex-1 w-9/12">
                        <div class="truncate font-semibold">{{item.title}}</div>
                        <div class="text-gray-500 text-xs">{{displayDatetime(item.created.date)}} </div>
                        <div class="truncate">{{item.message}} </div>
                    </div>
                    <div class="mt-1 w-2.5 h-2.5 rounded-full" [ngClass]="{'bg-red-400' : !item.read}"></div>
                </div>
            </div>
        </button>
        }
        @if(countAll > notifications.length){ <button class="w-full text-center hover:underline h-9"
            (click)="loadMore($event)">
            แสดงเพิ่มเติม
        </button>
        }
    </div>
</mat-menu>