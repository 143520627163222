import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MerchantService } from '../../services/merchant/merchant.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-merchant-setting-return',
  templateUrl: './merchant-setting-return.component.html',
  styleUrl: './merchant-setting-return.component.css'
})
export class MerchantSettingReturnComponent implements OnInit {

  faScrewdriverWrench = faScrewdriverWrench

  form: FormGroup;
  formChanged = false
  shopDetials: any = {}
  shopId: string = this.authService.selectedShop?.shop_id || '';
  loading = false

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private modalService: ModalService
  ) {
    this.form = this.fb.group({
      returnDays: [{ value: 0, }],
    });
  }

  async ngOnInit(): Promise<void> {
    this.loadShopDetails();
    this.form.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
  }

  loadShopDetails() {
    this.loading = true;
    this.merchantService.getOneShop(this.shopId).then(response => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      if (response.success) {
        this.shopDetials = response.data
        const shopDetials = this.shopDetials
        this.form.patchValue({
          returnDays: shopDetials?.return_days || 0,
        });
        this.formChanged = false
      } else {
        console.log('load shop failed')
      }
    })
  }

  onSubmit() {
    if (!this.form.valid) return
    const data = this.form.getRawValue()
    console.log(data);
    let updateData: any = {
      return_days: data.returnDays,
    }
    console.log(updateData)
    this.merchantService.updateshop(this.shopId, updateData).then(response => {
      this.formChanged = false;
      if (response.success) {
        this.loadShopDetails()
        this.modalService.openModal('success', 'อัพเดตข้อมูลสำเร็จ', '').then(ok => {
          console.log('ok')
        })
      } else {
        console.log('failed')
        this.modalService.openModal('success', 'เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  onCancel() {
    this.loadShopDetails()
  }


}
