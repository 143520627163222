import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiptApiService {

  private API_END_POINT = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllReceipt(shop_id: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/receipt/${shop_id}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOneReceipt(purchase_order_no: string) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/receipt/purchase-order-no/${purchase_order_no}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOneReceiptBySerialNo(serialNo: string) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/receipt/serial-no/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  receiptOneSerial(serialNo: string, shopId: string) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/products/serial`, { serial_no: serialNo, shop_id: shopId }, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  receiptAllSerial(purchaseOrderNo: string, shopId: string) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/products/serials`, { purchase_order_no: purchaseOrderNo, shop_id: shopId }, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  syncReceipt(shopId: string, purchaseOrderNo: string) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/products/receipt/sync/${shopId}/${purchaseOrderNo}`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
