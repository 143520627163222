import { Component } from '@angular/core';
import { faArrowDown, faChevronRight, faHistory, faPlus, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { Router } from '@angular/router';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { ReceiptApiService } from '../../services/api/receipt-api.service';
import { ReceiptsService } from '../../services/receipts/receipts.service';
import { MatDialog } from '@angular/material/dialog';
import { AddReceiptDialogComponent } from '../../components/add-receipt-dialog/add-receipt-dialog.component';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrl: './receipt-list.component.css'
})
export class ReceiptListComponent {
  faChevronRight = faChevronRight
  faHistory = faHistory
  faClock = faClock
  faArrowDown = faArrowDown
  faPlus = faPlus
  faShieldHalved = faShieldHalved

  isLoaded = false
  loadingMore = false


  filteredOrders: { order_no: string, date: string, total: number, total_ordered: number, total_receipted: number, status: string, type: string }[] = []

  shopId: string = this.authService.selectedShop?.shop_id || '';
  countOrder: any = {
    'ongoing': 0,
    'success': 0
  }

  limit = 12;
  offset = 0;



  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private router: Router,
    private receiptApi: ReceiptApiService,
    public receiptsService: ReceiptsService,
    public dialog: MatDialog,
    private modalService: ModalService
  ) {

  }

  async ngOnInit(): Promise<any> {
    await this.reloadData();
  }

  async changeState(state: string) {
    if (this.receiptsService.state !== state) {
      this.receiptsService.state = state;
      this.offset = 0;
      this.filteredOrders = [];
      await this.reloadData();
    }
  }

  formatData(receipts: any[]): { order_no: string, date: string, total: number, total_ordered: number, total_receipted: number, status: string, type: string }[] {
    return receipts.map(receipt => ({
      order_no: receipt.purchase_order_no,
      date: this.utilService.dayjs(receipt.purchase_order_date).format('DD/MM/YYYY, HH:mm'),
      status: receipt.status,
      total: receipt.total,
      type: receipt.type,
      total_ordered: receipt.products.reduce((accumulator: any, currentValue: { ordered_serials: string[], receipted_serials: string[] }) => {
        return accumulator + currentValue.ordered_serials.length
      }, 0),
      total_receipted: receipt.products.reduce((accumulator: any, currentValue: { ordered_serials: string[], receipted_serials: string[] }) => {
        return accumulator + currentValue.receipted_serials.length
      }, 0)
    }))
  }

  async reloadData() {
    this.isLoaded = false;
    if (this.receiptsService.state === 'ongoing') {
      const receiptList = await this.getReceiptList('ongoing');
      this.filteredOrders = this.formatData(receiptList)
      this.countOrder['success'] = await this.getCountReceipt('complete');

    } else {
      const receiptList = await this.getReceiptList('complete');
      this.filteredOrders = this.formatData(receiptList)
      this.countOrder['ongoing'] = await this.getCountReceipt('ongoing');
    }
    this.isLoaded = true;
  }

  async loadMore() {
    this.loadingMore = true
    this.offset = this.offset + this.limit;
    let orderResponse = [];
    if (this.receiptsService.state === 'ongoing') {
      orderResponse = await this.getReceiptList('ongoing');
    } else {
      orderResponse = await this.getReceiptList('complete');
    }
    this.filteredOrders.push(...this.formatData(orderResponse));
    this.loadingMore = false
  }

  async getReceiptList(status: string): Promise<any[]> {
    const filter = { status, limit: this.limit, offset: this.offset }
    return this.receiptApi.getAllReceipt(this.shopId, filter).then((response: any) => {
      if (!response.success) {
        this.countOrder[this.receiptsService.state] = 0
        return [];
      }
      this.countOrder[this.receiptsService.state] = response.count
      return response.data;
    })
  }

  async getCountReceipt(status: string) {
    const filter = { status, limit: 1, offset: 0, fields: "purchase_order_no" }
    return this.receiptApi.getAllReceipt(this.shopId, filter).then((response: any) => {
      if (!response.success) return 0;
      return response.count
    })
  }

  routeToReceiptDetial(order_no: string) {
    this.router.navigate(['/receipts/receipt-detail'], { queryParams: { order_no: order_no } });
  }

  routeToReceiptScan() {
    this.router.navigate(['/receipts/receipt-scan']);
  }

  routeToReceiptHistoryList() {
    this.router.navigate(['/receipts/receipt-history-list']);
  }

  addReceipt() {
    const dialogRef = this.dialog.open(AddReceiptDialogComponent, {
      data: { form: { purchase_order_no: '' } },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`result: `, result);
      if (result.confirm) {
        console.log(result)
        this.receiptsService.syncReceipt(this.shopId, result.data.form.purchase_order_no).then((response: any) => {
          if (response.success) {
            this.ngOnInit()
            this.modalService.openModal('success', 'นำเข้าข้อมูลสำเร็จ', `หมายเลขคำสั่งซื้อ ${result.data.form.purchase_order_no}`)
          } else {
            if (response.message === 'The order not found') this.modalService.openModal('fail', 'ไม่พบข้อมูลคำสั่งซื้อนี้', 'โปรดตรวจสอบหมายเลขคำสั่งซื้ออีกครั้ง')
            else if (response.message === 'The order do not match your shop') this.modalService.openModal('fail', 'ไม่ใช่คำสั่งซื้อของร้านค้านี้', 'โปรดตรวจสอบหมายเลขคำสั่งซื้อ')
            else if (response.message === 'The order has not yet been packed') this.modalService.openModal('fail', 'คำสั่งซื้อนี้ยังแพ็คสินค้าไม่สำเร็จ', '')
            else if (response.message === 'The order has been cancelled') this.modalService.openModal('fail', 'คำสั่งซื้อนี้ถูกยกเลิกแล้ว', '')
            else if (response.message === 'update product first') this.modalService.openModal('fail', 'ไม่พบข้อมูลสินค้า', 'โปรดติดต่อเจ้าที่เพื่ออัพเดตข้อมูลสินค้าก่อน')
            else if (response.message === 'receipt already existed') this.modalService.openModal('fail', 'คำสั่งซื้อนี้มีอยู่แล้ว', '')
            else this.modalService.openModal('fail', 'โหลดข้อมูลไม่สำเร็จ', 'กรุณาติดต่อเจ้าหน้าที่')
          }
        })
      } else { }
    });
  }
}
