import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-price-config-dialog',
  templateUrl: './create-price-config-dialog.component.html',
  styleUrl: './create-price-config-dialog.component.css'
})
export class CreatePriceConfigDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CreatePriceConfigDialogComponent>
  ) {
    console.log(data)
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    this.ref.close({ confirm: true, data: this.data.formCreatePriceConfig });
  }

  formCreate(name: string, value: any) {
    this.data.formCreatePriceConfig[`${name}`] = value;
  }

  callbackActiveCreate = (value: any): void => {
    this.data.formCreatePriceConfig.active = value;
  }

  callbackDefaultCreate = (value: any): void => {
    this.data.formCreatePriceConfig.is_default = value;
  }
}
