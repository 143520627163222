import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
const iconReturn = '../../../../../assets/svg/return.svg'
const iconExchange = '../../../../../assets/svg/exchange.svg'

@Component({
  selector: 'app-exchange-type-dialog',
  templateUrl: './exchange-type-dialog.component.html',
  styleUrl: './exchange-type-dialog.component.css'
})
export class ExchangeTypeDialogComponent implements OnInit, OnChanges {
  @Input() open: boolean = false;
  @Input('total') total: number = 0;
  @Input('paymentMethods') paymentMethods: { value: string; name: string }[] = [];
  @Input('banks') banks: { value: string; name: string; icon: string; }[] = [];
  @Output('callBackType') callBackType: EventEmitter<object> = new EventEmitter<object>();
  @Output('callBackRefund') callBackRefund: EventEmitter<object> = new EventEmitter<object>();

  faChevronDown = faChevronDown;
  iconReturn = iconReturn;
  iconExchange = iconExchange;

  selected: any
  // paymentMethods: { value: string; name: string }[] = [];

  isLoaded = true;
  state: string = 'select-type'
  submitted = false;
  type: string = '';
  refundForm: UntypedFormGroup = new UntypedFormGroup({
    payment_method: new UntypedFormControl('', Validators.required),
    bank: new UntypedFormControl('', Validators.required),
    acc_name: new UntypedFormControl('', Validators.required),
    acc_number: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{10,15}$/)]),
    phone_number: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
    note: new UntypedFormControl('', Validators.required)
  });


  constructor(
  ) {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['paymentMethods'] && this.paymentMethods[0]) {
      this.type = this.paymentMethods[0].value;
      this.changeType(this.type);
    }
  }

  close() {
    this.callBackType.emit({ confirm: false })
  }

  confirmType() {
    if (this.selected === 'return') {
      this.state = 'refund-form'
    } else {
      this.callBackType.emit({ confirm: true, type: this.selected })
    }
  }

  selectedType(value: any) {
    this.selected = value
  }

  back() {
    this.state = 'select-type'
  }

  get f(): { [key: string]: AbstractControl } {
    return this.refundForm.controls;
  }

  changeType(type: string) {
    this.type = type;
    this.refundForm.patchValue({ payment_method: type })

    if (type === 'bank_transfer') {
      this.refundForm.controls['bank'].setValidators(Validators.required);
      this.refundForm.controls['acc_name'].setValidators(Validators.required);
      this.refundForm.controls['acc_number'].setValidators([Validators.required, Validators.pattern(/^\d{10,15}$/)]);

    } else if (type === 'cash') {
      this.refundForm.controls['bank'].clearValidators();
      this.refundForm.controls['acc_name'].clearValidators();
      this.refundForm.controls['acc_number'].clearValidators();
    }

    Object.keys(this.refundForm.controls).forEach(key => {
      this.refundForm.controls[key].updateValueAndValidity();
    });

  }

  onSubmit() {
    this.submitted = true;
    if (this.refundForm.invalid) {
      return;
    }
    const data = this.refundForm.getRawValue()
    this.callBackRefund.emit({ confirm: true, data })
  }


}
