import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';

import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReceiptListComponent } from './features/receipts/pages/receipt-list/receipt-list.component';
import { ReceiptDetailComponent } from './features/receipts/pages/receipt-detail/receipt-detail.component';
import { SaleListComponent } from './features/sales/pages/sale-list/sale-list.component';
import { SaleDetailComponent } from './features/sales/pages/sale-detail/sale-detail.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { HeaderComponent } from './core/components/header/header.component';
import { ProductListComponent } from './features/products/pages/product-list/product-list.component';
import { ProductDetailComponent } from './features/products/pages/product-detail/product-detail.component';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { ForbiddenComponent } from './core/pages/forbidden/forbidden.component';
import { SalesComponent } from './features/sales/pages/sales/sales.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReportsComponent } from './features/reports/pages/reports/reports.component';
import { MerchantComponent } from './features/merchant/pages/merchant/merchant.component';
import { ExampleComponent } from './core/pages/example/example.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { SaleCreateComponent } from './features/sales/pages/sale-create/sale-create.component';
import { SaleFooterComponent } from './features/sales/components/sale-footer/sale-footer.component';
import { ReceiptScanComponent } from './features/receipts/pages/receipt-scan/receipt-scan.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SaleAddFriendDialogComponent } from './features/sales/components/sale-add-friend-dialog/sale-add-friend-dialog.component';
import { PaginationComponent } from './core/components/pagination/pagination.component';
import { ReceiptHistoryListComponent } from './features/receipts/pages/receipt-history-list/receipt-history-list.component';
import { SalePaymentDialogComponent } from './features/sales/components/sale-payment-dialog/sale-payment-dialog.component';
import { ReceiptHistoryDetailComponent } from './features/receipts/pages/receipt-history-detail/receipt-history-detail.component';
import { ProductFilterDialogComponent } from './features/products/components/product-filter-dialog/product-filter-dialog.component';
import { ClaimComponent } from './features/claim/pages/claim/claim.component';
import { ClaimCreateComponent } from './features/claim/pages/claim-create/claim-create.component';
import { ModalComponent } from './core/components/modal/modal.component';
import { ScrollButtonComponent } from './core/components/scroll-button/scroll-button.component';
import { KeypadComponent } from './features/sales/components/keypad/keypad.component';
import { SaleAddDiscountDialogComponent } from './features/sales/components/sale-add-discount-dialog/sale-add-discount-dialog.component';
import { ChartsComponent } from './core/components/charts/charts.component';
import { CircleProgressComponent } from './core/components/circle-progress/circle-progress.component';
import { ProductSettingComponent } from './features/products/pages/product-setting/product-setting.component';
import { ToggleComponent } from './core/components/toggle/toggle.component';
import { LocationBackComponent } from './core/components/location-back/location-back.component';
import { ScreenLoaderComponent } from './core/components/screen-loader/screen-loader.component';
import { QrPaymentComponent } from './features/sales/components/qr-payment/qr-payment.component';
import { CashPaymentComponent } from './features/sales/components/cash-payment/cash-payment.component';
import { ExchangeComponent } from './features/exchange/pages/exchange/exchange.component';
import { ExchangeCreateComponent } from './features/exchange/pages/exchange-create/exchange-create.component';
import { ExchangeFooterComponent } from './features/exchange/components/exchange-footer/exchange-footer.component';
import { CreatePriceConfigDialogComponent } from './features/products/components/create-price-config-dialog/create-price-config-dialog.component';
import { ExchangeTypeDialogComponent } from './features/exchange/components/exchange-type-dialog/exchange-type-dialog.component';
import { PromotionListComponent } from './features/promotion/pages/promotion-list/promotion-list.component';
import { MerchantSettingGeneralComponent } from './features/merchant/pages/merchant-setting-general/merchant-setting-general.component';
import { MerchantSettingBoardcastComponent } from './features/merchant/pages/merchant-setting-boardcast/merchant-setting-boardcast.component';
import { MerchantSettingPaymentComponent } from './features/merchant/pages/merchant-setting-payment/merchant-setting-payment.component';
import { ExchangeDetailComponent } from './features/exchange/pages/exchange-detail/exchange-detail.component';
import { AddReceiptDialogComponent } from './features/receipts/components/add-receipt-dialog/add-receipt-dialog.component';
import { MerchantSettingReturnComponent } from './features/merchant/pages/merchant-setting-return/merchant-setting-return.component';
import { ClaimFormComponent } from './features/claim/pages/claim-form/claim-form.component';
import { ClaimDetailDialogComponent } from './features/claim/components/claim-detail-dialog/claim-detail-dialog.component';
import { PromotionDetailDialogComponent } from './features/promotion/components/promotion-detail-dialog/promotion-detail-dialog.component';
import { PromotionCreateDialogComponent } from './features/promotion/components/promotion-create-dialog/promotion-create-dialog.component';
import { ClaimCustomerDialogComponent } from './features/claim/components/claim-customer-dialog/claim-customer-dialog.component';
import { CounterPaymentComponent } from './features/sales/components/counter-payment/counter-payment.component';
import { UploadImageComponent } from './core/components/upload-image/upload-image.component';
import { ExchangeAddFriendDialogComponent } from './features/exchange/components/exchange-add-friend-dialog/exchange-add-friend-dialog.component';
import { LoginComponent } from './core/pages/login/login.component';
import { ProfileComponent } from './core/pages/profile/profile.component';
import { StaffsComponent } from './features/staffs/pages/staffs/staffs.component';
import { StaffUpdateDialogComponent } from './features/staffs/components/staff-update-dialog/staff-update-dialog.component';
import { AddUserDialogComponent } from './features/staffs/components/add-user-dialog/add-user-dialog.component';
import { CouponDetailComponent } from './features/reports/components/coupon-detail/coupon-detail.component';
import { StockOutListComponent } from './features/stock-out/pages/stock-out/stock-out-list.component';
import { StockOutCreateComponent } from './features/stock-out/pages/stock-out-create/stock-out-create.component';
import { StockOutDetailComponent } from './features/stock-out/pages/stock-out-detail/stock-out-detail.component';
import { InventoryComponent } from './features/inventory/pages/inventory/inventory.component';
import { InventoryCountingComponent } from './features/inventory/pages/inventory-counting/inventory-counting.component';
import { StockOutFooterComponent } from './features/stock-out/components/stock-out-footer/stock-out-footer.component';
import { SaleAddRedeemDialogComponent } from './features/sales/components/sale-add-redeem-dialog/sale-add-redeem-dialog.component';
import { InventoryDetailComponent } from './features/inventory/pages/inventory-detail/inventory-detail.component';
import { InventoryProductTransactionComponent } from './features/inventory/components/inventory-product-transaction/inventory-product-transaction.component';
import { MerchantSettingStockComponent } from './features/merchant/pages/merchant-setting-stock/merchant-setting-stock.component';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { SerialOutDailogComponent } from './features/stock-out/components/serial-out-dailog/serial-out-dailog.component';
import { CheckSerialComponent } from './features/serials/pages/check-serial/check-serial.component';
import { ShowImageDialogComponent } from './features/sales/components/show-image-dialog/show-image-dialog.component';
import { NotifyComponent } from './core/components/notify/notify.component';
import { CreditPaymentComponent } from './features/sales/components/credit-payment/credit-payment.component';
import { PaymentDetailComponent } from './features/reports/components/payment-detail/payment-detail.component';
import { QrPromtpayPaymentComponent } from './features/sales/components/qr-promtpay-payment/qr-promtpay-payment.component';
import { ProvidersComponent } from './features/providers/pages/providers/providers.component';
import { ProviderCreateDialogComponent } from './features/providers/components/provider-create-dialog/provider-create-dialog.component';
import { ProviderDetailDialogComponent } from './features/providers/components/provider-detail-dialog/provider-detail-dialog.component';
import { MerchantManagementComponent } from './features/merchant-management/pages/merchant-management/merchant-management.component';
import { MerchantCreateDialogComponent } from './features/merchant-management/components/merchant-create-dialog/merchant-create-dialog.component';
import { MerchantDetailDialogComponent } from './features/merchant-management/components/merchant-detail-dialog/merchant-detail-dialog.component';
import { LoaderDialogComponent } from './core/components/loader-dialog/loader-dialog.component';
import { MerchantSettingPrinterComponent } from './features/merchant/pages/merchant-setting-printer/merchant-setting-printer.component';
import { MerchantSettingMemberComponent } from './features/merchant/pages/merchant-setting-member/merchant-setting-member.component';

@NgModule({
  declarations: [
    AppComponent,
    ReceiptListComponent,
    ReceiptDetailComponent,
    SaleListComponent,
    SaleDetailComponent,
    NavbarComponent,
    HeaderComponent,
    ProductListComponent,
    ProductDetailComponent,
    PageNotFoundComponent,
    ForbiddenComponent,
    SalesComponent,
    ReportsComponent,
    MerchantComponent,
    ExampleComponent,
    LoaderComponent,
    SaleCreateComponent,
    SaleFooterComponent,
    SaleAddFriendDialogComponent,
    PaginationComponent,
    ReceiptScanComponent,
    ReceiptHistoryListComponent,
    ReceiptHistoryDetailComponent,
    ProductFilterDialogComponent,
    SalePaymentDialogComponent,
    ReceiptHistoryDetailComponent,
    ClaimComponent,
    ClaimCreateComponent,
    ModalComponent,
    ScrollButtonComponent,
    KeypadComponent,
    SaleAddDiscountDialogComponent,
    ChartsComponent,
    CircleProgressComponent,
    ProductSettingComponent,
    ToggleComponent,
    LocationBackComponent,
    ScreenLoaderComponent,
    QrPaymentComponent,
    CashPaymentComponent,
    ExchangeComponent,
    ExchangeCreateComponent,
    ExchangeFooterComponent,
    CreatePriceConfigDialogComponent,
    ExchangeTypeDialogComponent,
    PromotionListComponent,
    MerchantSettingGeneralComponent,
    MerchantSettingBoardcastComponent,
    MerchantSettingPaymentComponent,
    ExchangeDetailComponent,
    AddReceiptDialogComponent,
    MerchantSettingReturnComponent,
    ClaimFormComponent,
    ClaimDetailDialogComponent,
    PromotionDetailDialogComponent,
    PromotionCreateDialogComponent,
    ClaimCustomerDialogComponent,
    CounterPaymentComponent,
    UploadImageComponent,
    ExchangeAddFriendDialogComponent,
    LoginComponent,
    ProfileComponent,
    StaffsComponent,
    StaffUpdateDialogComponent,
    AddUserDialogComponent,
    CouponDetailComponent,
    StockOutListComponent,
    StockOutCreateComponent,
    StockOutDetailComponent,
    StockOutFooterComponent,
    InventoryComponent,
    InventoryCountingComponent,
    SaleAddRedeemDialogComponent,
    InventoryDetailComponent,
    InventoryProductTransactionComponent,
    MerchantSettingStockComponent,
    SerialOutDailogComponent,
    CheckSerialComponent,
    ShowImageDialogComponent,
    NotifyComponent,
    CreditPaymentComponent,
    PaymentDetailComponent,
    QrPromtpayPaymentComponent,
    ProvidersComponent,
    ProviderCreateDialogComponent,
    ProviderDetailDialogComponent,
    MerchantManagementComponent,
    MerchantCreateDialogComponent,
    MerchantDetailDialogComponent,
    LoaderDialogComponent,
    MerchantSettingPrinterComponent,
    MerchantSettingMemberComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatBottomSheetModule,
    HttpClientModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    QRCodeModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    AngularFireDatabaseModule,
    ClipboardModule
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Initialize the second Firebase app if not already initialized
    if (!firebase.apps.find(app => app.name === 'CLAIM_AUTH')) {
      firebase.initializeApp(environment.firebase, 'CLAIM_AUTH');
    }
    console.log(firebase.apps)
    if (!firebase.apps.find(app => app.name === 'CRM_AUTH')) {
      firebase.initializeApp(environment.firebaseAuthCRM, 'CRM_AUTH');
    }
  }
}
