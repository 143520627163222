import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket: Socket;
  websocket = environment.websocket;

  constructor() {
    this.socket = io(this.websocket, {
      path: '/socket.io/',
      transports: ['websocket'],
      secure: true
    });
  }

  connect() {
    this.socket = io(this.websocket, {
      path: '/socket.io/',
      transports: ['websocket'],
      secure: true
    });

    this.socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    this.socket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });

    this.socket.on('error', (err) => {
      console.error('Error:', err);
    });
  }

  sendOrderStatusRequest(order_no: string) {
    this.connect()
    this.socket.emit('orderStatus', { order_no });
  }

  onOrderStatusUpdate(order_no: string): Observable<any> {
    return new Observable(observer => {
      console.log(`orderStatusUpdate${order_no}`)
      this.socket.on(`orderStatusUpdate${order_no}`, (data) => {
        console.log(data)
        observer.next(data);
      });
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
