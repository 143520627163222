import { Component } from '@angular/core';
import { faFilter, faHandshake, faMagnifyingGlass, faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { ProviderApiService } from '../../../providers/services/api/provider-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { MerchantCreateDialogComponent } from '../../components/merchant-create-dialog/merchant-create-dialog.component';
import { MerchantManagementApiService } from '../../serives/api/merchant-management-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ShopService } from '../../../../core/services/shop/shop.service';

interface Provider {
  name: string;
  provider_id: string;
  type: string;
  created: any;
}
interface Shop {
  shop_id: string;
  name: string;
  provider_id: string;
  created: any;
}

@Component({
  selector: 'app-merchant-management',
  templateUrl: './merchant-management.component.html',
  styleUrl: './merchant-management.component.css'
})
export class MerchantManagementComponent {

  faPlus = faPlus;
  faTags = faTags;
  faHandshake = faHandshake;
  faFilter = faFilter
  faMagnifyingGlass = faMagnifyingGlass

  providerList: any[] = []
  shopList: any[] = []
  isLoading = false;

  showDetail = false;
  shopDetial: any;

  processing = false;

  filteredData: any[] = []
  searchText = ''
  // price = 4,5,7 (4.5)

  constructor(
    private providerApiService: ProviderApiService,
    private dialog: MatDialog,
    public modalService: ModalService,
    private merchantManagementApiService: MerchantManagementApiService,
    private authService: AuthService,
    private shopService: ShopService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.providerList = await this.getAllProvider()
    this.shopList = await this.getAllShop()
    this.filteredData = this.shopList
    this.isLoading = false;
  }

  async getAllProvider(): Promise<any[]> {
    return this.providerApiService.getAllProvider({}).then((response: any) => {
      if (response.success) {
        return response.data.sort((a: any, b: any) => b.created.date - a.created.date)
      } else {
        return []
      }
    })
  }

  async getAllShop(): Promise<any[]> {
    const filter = { fields: 'shop_id,name,provider_id,created,address' }
    return this.providerApiService.getAllShop(filter).then((response: any) => {
      if (response.success) {
        return response.data.sort((a: any, b: any) => b.created.date - a.created.date)
      } else {
        return []
      }
    })
  }

  countShopProvider(providerId: string) {
    return this.shopList.filter(e => e.provider_id === providerId).length;
  }

  clickCreateMerchant() {
    const dialogRef = this.dialog.open(MerchantCreateDialogComponent, {
      width: '90%',
      // height: '80%',
      maxWidth: '520px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log(result);
      if (result?.confirm && result.data) {
        await this.createSmallShop(result.data)
      }
    })
  }

  async createSmallShop(data: any) {
    console.log(data)
    this.processing = true;

    const providerId = data?.providerId || ''
    const newProvider = providerId.length === 0 ? true : false;
    const addUserToShop = data.addShopToUser

    const body = {
      "shop_name": data.shopName,
      "provider_id": providerId,
      "username": data.userAccount.username,
      "password": data.userAccount.password,
      "first_name": data.userAccount.firstName,
      "last_name": data.userAccount.lastName,
      "email": data.userAccount.email,
      "mobile": data.userAccount.mobileNumber,
      "price": data.price,
      "address": {
        "address": data.address.address,
        "subdistrict": data.address.subdistrict,
        "district": data.address.district,
        "province": data.address.province,
        "postal_code": `${data.address.postalCode}`
      },
    }
    console.log(body)

    const createSmallShopResponse: any = await this.merchantManagementApiService.createSmallShop(body)
    if (createSmallShopResponse.success) {
      this.processing = false;
      const shopDetials = createSmallShopResponse.data

      if (newProvider) {
        const createProviderResult: any = await this.createProviderAndUpdateToShop({ shopName: shopDetials.name, shopId: shopDetials.shop_id })
        if (!createProviderResult.success) {
          this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', createProviderResult.message || 'ไม่สามารถเพิ่ม provider ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
        }
      }

      if (addUserToShop) {
        // add permission
        await this.addShopUser(shopDetials.shop_id)
      }

      this.modalService.openModal('success', 'เพิ่มร้านค้าสำเร็จ', '')

    } else {
      // alert error
      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', createSmallShopResponse.message || 'ไม่สามารถเพิ่มร้านค้า ได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง')
    }

    this.ngOnInit()
  }


  async createProviderAndUpdateToShop(data: { shopName: string, shopId: string }) {
    const createProviderBody = {
      "name": data.shopName,
      "type": "OTHER"
    }
    const createProviderResult: any = await this.merchantManagementApiService.createProvider(createProviderBody)
    if (!createProviderResult.success) return createProviderResult
    const updateShop: any = await this.merchantManagementApiService.updateShop(data.shopId, { provider_id: createProviderResult.data.provider_id })
    if (!updateShop.success) return updateShop;

    return { success: true }
  }

  async addShopUser(shopId: string) {
    const userInfo = this.shopService.shopUsers.find(user => user.uid === this.authService.user.uid)
    if (userInfo) {
      const shops: any = await this.authService.getAccessShopUser()
      const canAccessShops = shops?.data || []
      const oldShop = canAccessShops.map((shop: any) => ({ shop_id: shop.shop_id }))
      const newShop = { shop_id: shopId }
      await this.merchantManagementApiService.addShopUser(userInfo.user_id, [...oldShop, newShop])
    }
  }

  showMerchantDetial(shop: Shop) {
    console.log(shop)
    this.shopDetial = {
      ...shop,
      type: this.providerList.find(item => item.provider_id === shop.provider_id)?.type || '',
      provider_name: this.getProviderName(shop.provider_id)
    }
    this.showDetail = true;
  }

  callBackMerchantDetail = (event: any) => {
    this.showDetail = false;
  }

  getShopType(providerId: string) {
    return this.providerList.find(item => item.provider_id === providerId)?.type || 'UNDEFINED'
  }

  getProviderName(providerId: string) {
    return this.providerList.find(item => item.provider_id === providerId)?.name || 'UNDEFINED'
  }

  search(text: string) {
    this.filteredData = this.shopList.filter(item =>
      item.shop_id === text || item.name.toLowerCase().includes(text.toLowerCase())
    );
  }
}
