import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-provider-create-dialog',
  templateUrl: './provider-create-dialog.component.html',
  styleUrl: './provider-create-dialog.component.css'
})
export class ProviderCreateDialogComponent {

  faXmark = faXmark

  providerType: string[] = ['INHOUSE', 'OTHER']

  submitted = false;
  addProviderForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('', Validators.required)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<ProviderCreateDialogComponent>
  ) {
    console.log(this.data)
  }

  get f(): { [key: string]: AbstractControl } {
    return this.addProviderForm.controls;
  }

  close() {
    this.ref.close(false);
  }

  onSubmit() {
    this.submitted = true;
    if (this.addProviderForm.invalid) {
      return;
    }
    this.ref.close({ confirm: true, data: this.addProviderForm.getRawValue() });
  }

}
