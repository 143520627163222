import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, faDeleteLeft, faChevronLeft, faUser, faCakeCandles, faBagShopping, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { ExchangeApiService } from '../../services/api/exchange-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-exchange-add-friend-dialog',
  templateUrl: './exchange-add-friend-dialog.component.html',
  styleUrl: './exchange-add-friend-dialog.component.css'
})
export class ExchangeAddFriendDialogComponent {
  @Input('open')
  open: boolean = false;
  @Input('memberData')
  memberData: any = null;
  @Input('returnNo')
  returnNo: string = '';
  @Input('isMember')
  isMember: any;
  @Input('phoneNumber')
  phoneNumber = ''
  @Output('callBackAddFriend')
  callBackAddFriend: EventEmitter<object> = new EventEmitter<object>();



  faChevronDown = faChevronDown;
  faDeleteLeft = faDeleteLeft;
  faChevronLeft = faChevronLeft;
  faUser = faUser;
  faCakeCandles = faCakeCandles;
  faBagShopping = faBagShopping;
  faLocationDot = faLocationDot;

  states: any[] = []
  state: string = ''

  userId: string = '';
  shopId: string = this.authService.selectedShop?.shop_id || '';
  lineId: string = this.authService.selectedShop?.line_id || '';
  liffId: string = this.authService.selectedShop?.liff_id || '';
  shopType: string = this.authService.selectedShop?.type || '';


  isLoadingCustomer = false

  cardImage = "https://storage.maxxgadget.com/crm/member/card.png";

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private exchangeApiService: ExchangeApiService
  ) {
    if (this.shopType === 'INHOUSE') {
      this.states = [
        { name: 'กรอกเบอร์มือถือ', value: 'phone-number' },
        { name: 'สแกน', value: 'qr-code' }
      ]
    } else {
      this.states = [
        { name: 'สแกน', value: 'qr-code' }
      ]
    }
    this.state = this.states[0].value
  }

  changeState(value: string) {
    this.state = value;
  }

  close() {
    this.phoneNumber = '';
    this.callBackAddFriend.emit({ confirm: false, data: null })
  }

  noMember() {
    this.backToKeypad()
    this.phoneNumber = '';
    this.callBackAddFriend.emit({ confirm: true, data: null })
  }

  apply() {

    let data: any = null;
    if (this.phoneNumber.length === 10 && this.memberData) {
      data = {
        phone_number: this.phoneNumber,
        member: this.memberData
      }
      this.callBackAddFriend.emit({ confirm: true, data })
    } else {
      this.backToKeypad()
      this.phoneNumber = '';
      this.callBackAddFriend.emit({ confirm: true, isScan: true })
    }

  }

  async memberDetail() {
    if (this.state === 'phone-number') {
      console.log(this.phoneNumber)
      this.memberData = {
        name: '',
        point: 0,
        orders: 0
      }
      this.isLoadingCustomer = true;
      const customer: any = await this.exchangeApiService.getCustomerByPhoneNumber(this.phoneNumber, this.shopId)
      if (customer.data) {
        const pointBalance: any = await this.exchangeApiService.getCustomerPointBalace(customer.data.user_id, this.authService.selectedShop?.shop_id || '')
        const totalBill: any = await this.exchangeApiService.getCustomerTotalBill(customer.data.user_id, this.authService.selectedShop?.shop_id || '')
        this.isMember = true;
        this.memberData = {
          userId: customer.data.user_id,
          name: customer?.data?.first_name || '-',
          point: pointBalance?.data || 0,
          orders: totalBill?.data || 0
        }
      } else {
        this.isMember = true;
        this.memberData = null
      }
      this.isLoadingCustomer = false;
    } else {
      this.callBackAddFriend.emit({ confirm: true, isScan: true })
    }
  }

  selectedNumber(number: string) {
    if (number !== '.' && number !== 'delete') {
      if (this.phoneNumber.length < 10) {
        this.phoneNumber = this.phoneNumber + number;
      }
    } else if (number === 'delete') {
      this.phoneNumber = this.phoneNumber.slice(0, this.phoneNumber.length - 1);
    }
  }

  formatPhoneNumber() {
    let format = '';
    let index = 1;
    for (const value of this.phoneNumber) {
      if (index === 4 || index === 7) {
        format += '-' + value
      } else {
        format += value
        index++
      }

    }
    return format
  }

  backToKeypad() {
    this.isMember = false;
    this.memberData = null
  }

  dateFormat(date: string) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY')
  }
}
