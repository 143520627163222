<div class="p-4 w-full space-y-2 text-sm lg:text-base relative" id="staff-list-content">
    <div class="font-bold text-base lg:text-lg"> รายชื่อพนักงาน </div>
    <div class="flex justify-end">
        <button class="btn-light !border flex space-x-2 items-center" (click)="addUser()">
            <fa-icon [icon]="faPlus" class=""></fa-icon>
            <div class="">เพิ่มผู้ใช้งาน</div>
        </button>
    </div>

    <div class="space-y-2">

        @if (isLoaded) {
        <div class="lg:hidden grid gap-2">
            @if(!dataSource || dataSource.length === 0){
            <div class="text-center py-10">
                <div class="flex flex-col justify-center items-center w-full">
                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                    <div class="text-center pt-4">ไม่พบพนักงาน</div>
                </div>
            </div>
            }
            @else {
            @for (staff of dataSource; track $index) {
            <button class="border bg-white rounded-lg divide-y">
                <div class="p-3 grid grid-cols-6 md:grid-cols-9 gap-2 text-left">
                    <div class="col-span-1">
                        <img [src]="staff.credential_info.image" class="w-full aspect-square object-cover rounded-full"
                            loading="lazy" onerror="this.onerror=null; this.src='../../assets/images/user.png'" />
                    </div>
                    <div class="col-span-4 md:col-span-7">
                        <div class="flex flex-col gap-1">
                            <div class="">{{staff.credential_info.name}}</div>
                            <div class="flex flex-wrap gap-1">
                                @for(role of staffsService.roleOptions; track $index){
                                <ng-container *ngIf="checkRoleActive(role.id, staff.permissions) && checkRoleAccess(role.id)">
                                    <button class="flex items-center label-primary gap-2 whitespace-nowrap"
                                        (click)="removeUserRole(role.id, staff)">
                                        <fa-icon [icon]="faCheck"
                                            class="w-4 h-4 flex items-center"></fa-icon>{{role.name}}
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="!checkRoleActive(role.id, staff.permissions) && checkRoleAccess(role.id)">
                                    <button class="flex items-center label-light gap-2 whitespace-nowrap opacity-70"
                                        (click)="addUserRole(role.id, staff)">
                                        <fa-icon [icon]="faXmark"
                                            class="w-4 h-4 flex items-center"></fa-icon>{{role.name}}
                                    </button>
                                </ng-container>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1 flex flex-col items-end">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <fa-icon [icon]="faEllipsisVertical" class=""></fa-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="resetPassword(staff.username, staff.credential_info)">
                                <span>Reset Password</span>
                            </button>
                            <button mat-menu-item (click)="openDialogSetting(staff)">
                                <span>ตั้งค่าสิทธิ์</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="px-3 py-2 flex justify-between items-center text-gray-500">
                    <div class="w-32 flex items-center">
                        <mat-form-field class="">
                            <mat-select class="!h-8 !text-xs" [value]="staff.role"
                                (selectionChange)="selectRoleChange($event.value, staff)">
                                @for(role of checkPermissions(staff.permissions); track $index){
                                <mat-option class="!text-xs" [value]="role.id">{{role.name}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="bg-gray-100 text-gray-500 rounded-md flex px-3 py-1 w-fit">
                        <fa-icon [icon]="faCodeMerge" class="mr-1"></fa-icon>
                        <span>{{countShopSelect(staff.shops)}}</span>
                    </div>
                </div>
            </button>
            }
            }
        </div>


        <div class="hidden lg:grid grid-cols-1 gap-2">
            <div>
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    วันที่เริ่มใช้ระบบ
                                </th>
                                <th class="text-left">
                                    ชื่อ
                                </th>
                                <th class="text-left">
                                    สิทธิ์เข้าใช้งาน
                                </th>
                                <th class="text-left">
                                    กำลังใช้งาน
                                </th>
                                <th class="text-left">

                                </th>
                                <th class="text-left">
                                    จัดการ
                                </th>
                            </tr>
                        </thead>
                        @if(!dataSource || dataSource.length === 0){
                        <tbody>
                            <tr class="tbody-tr">
                                <td colspan="100" class="text-center py-10">
                                    <div class="flex flex-col justify-center items-center w-full">
                                        <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                        <div class="text-center pt-4">ไม่พบพนักงาน</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        }
                        @else {
                        <tbody>
                            @for (staff of dataSource; track $index) {
                            <tr class="tbody-tr">
                                <td>
                                    <div class="">
                                        <div class="">{{dateFormat(staff.created.date)}}</div>
                                        <div class="text-blue-500 text-xs">{{timeFormat(staff.created.date)}}</div>
                                    </div>
                                </td>
                                <td>

                                    <div class="flex gap-2">
                                        <div class="bg-white w-10 h-10 rounded-full">
                                            <img [src]="staff.credential_info.image"
                                                class="w-full aspect-square object-cover rounded-full" loading="lazy"
                                                onerror="this.onerror=null; this.src='../../assets/images/user.png'" />
                                        </div>
                                        <div class="">
                                            <div class="">{{staff.credential_info.name}}</div>
                                            <div class="">
                                                <button class="text-gray-500 cursor-pointer"
                                                    (click)="resetPassword(staff.username, staff.credential_info)">
                                                    <img [src]="'../../assets/svg/reset.svg'" class="w-4 h-4" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="flex flex-wrap gap-3">
                                        @for(role of staffsService.roleOptions; track $index){
                                        <ng-container *ngIf="checkRoleActive(role.id, staff.permissions) && checkRoleAccess(role.id)">
                                            <button class="flex items-center label-primary gap-2 whitespace-nowrap"
                                                (click)="removeUserRole(role.id, staff)">
                                                <fa-icon [icon]="faCheck"
                                                    class="w-4 h-4 flex items-center"></fa-icon>{{role.name}}
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!checkRoleActive(role.id, staff.permissions) && checkRoleAccess(role.id)">
                                            <button
                                                class="flex items-center label-light gap-2 whitespace-nowrap opacity-70"
                                                (click)="addUserRole(role.id, staff)">
                                                <fa-icon [icon]="faXmark"
                                                    class="w-4 h-4 flex items-center"></fa-icon>{{role.name}}
                                            </button>
                                        </ng-container>
                                        }
                                    </div>
                                </td>
                                <td class="text-left">
                                    <mat-form-field class="">
                                        <mat-select class="" [value]="staff.role"
                                            (selectionChange)="selectRoleChange($event.value, staff)">
                                            @for(role of checkPermissions(staff.permissions); track $index){
                                            <mat-option [value]="role.id">{{role.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td class="text-left">
                                    <div class="bg-gray-100 text-gray-500 rounded-md flex px-3 py-1 w-fit">
                                        <fa-icon [icon]="faCodeMerge" class="mr-1"></fa-icon>
                                        <span>{{countShopSelect(staff.shops)}}</span>
                                    </div>
                                </td>
                                <td class="text-left">
                                    <button class="text-blue-500 font-bold"
                                        (click)="openDialogSetting(staff)">ตั้งค่าสิทธิ์</button>
                                </td>
                            </tr>
                            }
                        </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }
    </div>

</div>

<div>
    <app-staff-update-dialog [open]="showDetail" [detail]="staffDetail" [shops]="shops"
        (callBackStaffUpdate)="callBackStaffUpdate($event)"></app-staff-update-dialog>
</div>