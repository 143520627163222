<div class="fixed flex flex-col justify-center items-center w-full h-full top-0 left-0 bg-white/80  z-[100] overflow-y-auto">
    <div
        class="fixed flex flex-col justify-center items-center w-full h-full bg-cover top-0 left-0 bg-login blur-sm z-[100] overflow-y-auto">
    </div>
    <div
        class="slide-top w-4/5 md:w-96 h-fit rounded-lg xl:shadow-lg z-[101] xl:px-6 py-10 flex flex-col gap-6 xl:backdrop-blur-md xl:bg-white/80">
        <div class="flex flex-col justify-start items-center pb-4">
            <div class="-mb-8 -mt-6">
                <img src="..\..\assets\images\logo\remax-logo-transparent.png" class="w-28 rounded-lg">
            </div>
            <div class="flex items-end gap-2">
                <div class="text-lg font-bold">
                   เข้าสู่ระบบ <span class="text-primary-500">POS</span>
                </div>
            </div>
        </div>
        <form [formGroup]="form" (ngSubmit)="confirmLogin()">
            <div class="space-y-4">
                <div class="">
                    <input class="input" type="text" id="username" formControlName="username" autocomplete="username" 
                        placeholder="ชื่อผู้ใช้งาน">
                </div>
                <div class="">
                    <input class="input" type="password" id="password" autocomplete="current-password" placeholder="รหัสผ่าน"
                        formControlName="password" (keyup.enter)="confirmLogin()">
                </div>
            </div>
            <div class="h-10 text-red-500 text-sm py-2">
                {{textAlert}}
            </div>
            <button class="btn-primary w-full" type="submit">เข้าสู่ระบบ</button>
        </form>
    </div>
</div>