<div class=" bg-white w-96 max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="flex justify-between w-full pt-4 font-bold text-lg border-b-2 border-gray-400 pb-2">
        <div>ส่วนลด</div>
        <div>{{data.totalDiscount}}</div>
    </div>
    <div class="flex flex-col justify-center items-center w-full">
        @for (item of data.discounts; track $index) {
            <div class="grid grid-cols-5 w-full">
                <div class="col-span-3">
                    <div>{{item.name}}</div>
                    <div class="text-sm text-gray-500">note: {{item.note || '-'}}</div>
                </div>
                <div class="col-span-2 text-right">{{item.discount | number}}</div>
            </div>
        }
    </div>
    <div class="h-2"></div>
</div>