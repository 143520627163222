<div class="bg-white w-full flex flex-col justify-center items-center rounded-xl relative">
    <div class="flex-none w-full text-center py-6 font-bold relative">
        เพิ่มร้านค้า
        <button type="button" class="absolute z-10 top-1 right-3" (click)="close()">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
    <div class="px-6 pb-6 w-full max-h-[70vh] overflow-y-auto">
        <form [formGroup]="form" class="">
            <div class="flex flex-col space-y-4">
                <div class="space-y-4 pb-6">
                    <div class="gap-2">
                        <label class="text-sm">ชื่อร้านค้า *</label>
                        <input type="text" class="input" placeholder="เช่น SM001-กทม(เซ็นทรัลลาดพร้าว)"
                            formControlName="shopName" autocomplete="off">
                        <div *ngIf="form.get('shopName')?.invalid && form.get('shopName')?.touched">
                            <small class="text-red-500"
                                *ngIf="form.get('shopName')?.hasError('required')">กรุณากรอกชื่อร้านค้า</small>
                        </div>
                    </div>
                    <div class="flex gap-2">
                        <input type="checkbox" [name]="'have-provider'" [id]="'have-provider'"
                            formControlName="haveProvider" class="cursor-pointer" (change)="checkHaveProvider()" />
                        <label [for]="'have-provider'" class="cursor-pointer text-sm">เคยมีร้าน Small Shop แล้ว</label>
                    </div>
                    @if (form.get('haveProvider')?.value) {
                    <div class="gap-2">
                        <label class="text-sm">provider *</label>
                        <mat-form-field class="w-full">
                            <mat-select formControlName="providerId" placeholder="เลือก provider">
                                @for (option of prodiverOptions; track $index) {
                                <mat-option class="" [value]="option.id">
                                    {{option.name}}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="form.get('providerId')?.invalid && form.get('providerId')?.touched">
                            <small class="text-red-500" *ngIf="form.get('providerId')?.hasError('required')">กรุณาเลือก
                                Provider</small>
                        </div>
                    </div>
                    }
                </div>
                <div formGroupName="address" class="pb-6 border-t-4 border-primary-500 pt-4">
                    <div class="space-y-2 md:space-y-4">
                        <div>
                            <label for="addressAddress" class="text-sm">ถนน อาคาร บ้านเลขที่ *</label>
                            <textarea type="text" class="text-area" placeholder="" autocomplete="off"
                                id="addressAddress" formControlName="address"></textarea>
                        </div>
                        <div>
                            <label for="provinceSelection" class="text-sm">จังหวัด *</label>
                            <mat-form-field class="w-full">
                                <mat-select id="provinceSelection" formControlName="province"
                                    (selectionChange)="onProvinceChange()">
                                    @for (option of provinceSelections; track $index){
                                    <mat-option [value]="option"> {{option}} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label for="districtSelection" class="text-sm">อำเภอ *</label>
                            <mat-form-field class="w-full">
                                <mat-select id="districtSelection" class="" formControlName="district"
                                    (selectionChange)="onDistrictChange()">
                                    @for (option of districtSelections; track $index){
                                    <mat-option [value]="option"> {{option}} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label for="subdistrictSelection" class="text-sm">ตำบล *</label>
                            <mat-form-field class="w-full">
                                <mat-select id="subdistrictSelection" formControlName="subdistrict"
                                    (selectionChange)="onSubdistrictChange()">
                                    @for (option of subdistrictSelections; track $index){
                                    <mat-option [value]="option"> {{option}} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label for="addressPostalCode" class="text-sm">รหัสไปรษณีย์ *</label>
                            <input type="text" id="addressPostalCode" formControlName="postalCode" class="input"
                                placeholder="" autocomplete="off">
                        </div>
                    </div>
                </div>
                <div formGroupName="userAccount" class="pb-6 border-t-4 border-primary-500 pt-4">
                    <div class="space-y-2 md:space-y-4">
                        <div>
                            <label for="username" class="text-sm">ชื่อผู้ใช้งาน (Sync Dealer สั่งสินค้าหน้าเว็บ)
                                *</label>
                            <input type="text" class="input" placeholder="ชื่อเข้าระบบ" formControlName="username"
                                autocomplete="off">
                            <div
                                *ngIf="form.get('userAccount.username')?.invalid && form.get('userAccount.username')?.touched">
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.username')?.hasError('required')">กรุณากรอกชื่อผู้ใช้งาน</small>
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.username')?.hasError('duplicate')">ชื่อผู้ใช้นี้มีอยู่แล้ว
                                    ลองชื่อใหม่</small>
                            </div>
                        </div>
                        <div class="relative">
                            <label for="password" class="text-sm">รหัสผ่าน *</label>
                            <input type="password" class="input" placeholder="ประกอบด้วย a-z A-Z และ 0-9 เท่านั้น"
                                id="password" formControlName="password" autocomplete="off">
                            <div class="absolute right-2 top-10 cursor-pointer" (click)="showPassword('password')">
                                <fa-icon [icon]="faEye" class="text-sm text-gray-500"
                                    [ngClass]="{'flex' : eyeActive['password'], 'hidden' : !eyeActive['password']}"></fa-icon>
                                <fa-icon [icon]="faEyeSlash" class="text-sm text-gray-500"
                                    [ngClass]="{'hidden' : eyeActive['password'], 'flex' : !eyeActive['password']}"></fa-icon>
                            </div>
                            <div
                                *ngIf="form.get('userAccount.password')?.invalid && form.get('userAccount.password')?.touched">
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.password')?.hasError('required')">กรุณากรอกรหัสผ่าน</small>
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.password')?.hasError('pattern')">รหัสผ่านต้องมีเฉพาะ
                                    a-z A-Z และ 0-9 เท่านั้น</small>
                            </div>
                        </div>
                        <div class="relative">
                            <label for="confirmPassword" class="text-sm">ยืนยันรหัสผ่าน *</label>
                            <input type="password" class="input" placeholder="ยืนยันรหัสผ่าน" id="confirmPassword"
                                formControlName="confirmPassword" autocomplete="off" (paste)="preventPaste($event)">
                            <div class="absolute right-2 top-10 cursor-pointer"
                                (click)="showPassword('confirmPassword')">
                                <fa-icon [icon]="faEye" class="text-sm text-gray-500"
                                    [ngClass]="{'flex' : eyeActive['confirmPassword'], 'hidden' : !eyeActive['confirmPassword']}"></fa-icon>
                                <fa-icon [icon]="faEyeSlash" class="text-sm text-gray-500"
                                    [ngClass]="{'hidden' : eyeActive['confirmPassword'], 'flex' : !eyeActive['confirmPassword']}"></fa-icon>
                            </div>
                            <div
                                *ngIf="form.get('userAccount.confirmPassword')?.invalid && form.get('userAccount.confirmPassword')?.touched">
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.confirmPassword')?.hasError('required')">กรุณายืนยันรหัสผ่าน</small>
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.confirmPassword')?.hasError('mustMatch')">รหัสผ่านไม่ตรงกัน</small>
                            </div>
                        </div>
                        <div>
                            <label for="firstName" class="text-sm">ชื่อ *</label>
                            <input type="text" class="input" placeholder="ชื่อ" formControlName="firstName"
                                autocomplete="off">
                            <div
                                *ngIf="form.get('userAccount.firstName')?.invalid && form.get('userAccount.firstName')?.touched">
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.firstName')?.hasError('required')">กรุณากรอกชื่อ</small>
                            </div>
                        </div>
                        <div>
                            <label for="lastName" class="text-sm">นามสกุล *</label>
                            <input type="text" class="input" placeholder="นามสกุล" formControlName="lastName"
                                autocomplete="off">
                            <div
                                *ngIf="form.get('userAccount.lastName')?.invalid && form.get('userAccount.lastName')?.touched">
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.lastName')?.hasError('required')">กรุณากรอกนามสกุล</small>
                            </div>
                        </div>
                        <div>
                            <label for="mobileNumber" class="text-sm">เบอร์มือถือ *</label>
                            <input type="tel" class="input" placeholder="09XXXXXXXX" formControlName="mobileNumber"
                                autocomplete="off" maxLength="10" minlength="10">
                            <div
                                *ngIf="form.get('userAccount.mobileNumber')?.invalid && form.get('userAccount.mobileNumber')?.touched">
                                <small class="text-red-500">กรุณากรอกเบอร์ 10 หลัก ไม่มีขีด</small>
                            </div>
                        </div>
                        <div>
                            <label for="email" class="text-sm">อีเมล *</label>
                            <input type="email" class="input" placeholder="maxx@gmail.com" formControlName="email"
                                autocomplete="off">
                            <div
                                *ngIf="form.get('userAccount.email')?.invalid && form.get('userAccount.email')?.touched">
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.email')?.hasError('required')">กรุณากรอกอีเมลให้ถูกต้อง</small>
                                <small class="text-red-500"
                                    *ngIf="form.get('userAccount.email')?.hasError('email')">กรุณากรอกอีเมลให้ถูกต้อง</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pb-6 border-t-4 border-primary-500 pt-4 space-y-2 md:space-y-4">
                    <div class="gap-2 pb-4">
                        <label for="price" class="text-sm">ช่องราคา *</label>
                        <mat-form-field class="w-full">
                            <mat-select formControlName="price" placeholder="เลือกช่องราคา">
                                @for (option of pricesOption; track $index) {
                                <mat-option class="" [value]="option.id">
                                    {{option.name}}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="form.get('price')?.invalid && form.get('price')?.touched">
                            <small class="text-red-500" *ngIf="form.get('price')?.hasError('required')">กรุณาเลือก
                                ช่องราคา</small>
                        </div>
                    </div>
                    <div class="flex gap-2">
                        <input type="checkbox" [name]="'add-shop-user'" [id]="'add-shop-user'"
                            formControlName="addShopToUser" class="cursor-pointer" />
                        <label [for]="'add-shop-user'" class="cursor-pointer text-sm">เพิ่มสิทธิ์ตัวเองใน Shop
                            นี้ด้วย</label>
                    </div>
                </div>
            </div>
        </form>
        <div class="flex-none flex justify-center space-x-2 py-4">
            <div>
                <button mat-button mat-dialog-close class="btn-disable hover:!cursor-pointer min-w-[8rem]" type="button"
                    (click)="close()">
                    <div class="w-full text-center">
                        ยกเลิก
                    </div>
                </button>
            </div>
            <div>
                <button mat-button class="btn-primary min-w-[8rem]" (click)="onSubmit()" [disabled]="form.invalid"
                    type="button">
                    <div class="w-full text-center">
                        ยืนยัน
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>