import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SerialApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getProductBySerialNo(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/inventory/product/info`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }


  getOneSerial(serialNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/serial/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }


  getProductTransaction(serialNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/inventory/product/transaction/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllPriceConfigByShop(shopId: string, filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/price-config/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductShopBySku(shop_id: string, sku: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product-shop/shop-id/${shop_id}/sku/${sku}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductBySku(sku: string, filters: any) {
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/products/product/sku/${sku}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getPointBySku(sku: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/crm/product/${sku}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createSerialByLostSerial(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/products/receipt/lost`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
