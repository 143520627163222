import { Component } from '@angular/core';
import { faArrowDown, faArrowUp, faArrowUpRightDots, faCloudArrowDown, faHandHoldingDollar, faMedal, faMinus, faMoneyCheckDollar, faPlus, faSackXmark, faTrophy, faWallet, faUsers, faArrowDownLong, faCircleInfo, faCalendarDay, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { ReportsService } from '../../services/reports/reports.service';
import { CouponDetailComponent } from '../../components/coupon-detail/coupon-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ShopService } from '../../../../core/services/shop/shop.service';
import { PaymentDetailComponent } from '../../components/payment-detail/payment-detail.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css'
})
export class ReportsComponent {

  faPlus = faPlus;
  faArrowUp = faArrowUp;
  faMinus = faMinus;
  faArrowDown = faArrowDown;

  faCloudArrowDown = faCloudArrowDown;

  faTrophy = faTrophy;
  faMedal = faMedal;

  faArrowUpRightDots = faArrowUpRightDots;
  faWallet = faWallet;
  faMoneyCheckDollar = faMoneyCheckDollar;
  faHandHoldingDollar = faHandHoldingDollar;
  faSackXmark = faSackXmark;

  faUsers = faUsers;

  faArrowDownLong = faArrowDownLong;
  faCircleInfo = faCircleInfo;

  faCalendarDay = faCalendarDay;

  faCalendarDays = faCalendarDays;

  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopName: string = this.authService.selectedShop?.name || '';

  bgTemplate = [
    '#facc15',
    '#f59e0b',
    '#f97316',
    '#c04f00',
    '#5b2300',
  ];

  exampleProfit: number = -1220;

  exampleSaleChart: any = {
    id: 'sale-period',
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          label: 'มูลค่า',
          data: [],
          backgroundColor: '#fcd34d',
          borderRadius: 50,
          borderSkipped: false,
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false
          }
        },
        y: {
          border: {
            display: false,
            dash: [5, 5]
          },
          ticks: {
            display: true,
            padding: 20
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  }


  salesReport: any;
  paymentReport: any;
  bestSellerProductReport: any;
  orderReport: any;
  returnReport: any;
  discountReport: any;
  orderStatusReport: any;
  newCustomerReport: any;

  paymentMethodQtyChart: any;
  paymentMethodValueChart: any
  paymentMethodQtyChartShow = false;
  paymentMethodValueChartShow = false;

  // selectedDate: any;
  selectedStartDate: any;
  selectedEndDate: any;
  maxDate = this.utilService.dayjs().format();

  isLoading = true;

  users = this.shopService.shopUsers;
  permissionsExport = ['admin', 'manager', 'supervisor'];

  constructor(
    private authService: AuthService,
    public utilService: UtilService,
    private reportsService: ReportsService,
    private dialog: MatDialog,
    private shopService: ShopService
  ) {
    for (let index = 9; index < 22; index++) {
      const pad = '00';
      this.exampleSaleChart.data.labels.push(`${pad.substring(0, pad.length - (`${index}`).length) + `${index}`}:00`);
      this.exampleSaleChart.data.datasets[0].data.push(Math.floor(Math.random() * 1000) * 10);
    }

    this.selectedStartDate = this.utilService.dayjs().format()
    this.selectedEndDate = this.utilService.dayjs().format()
    this.getDataReport(this.selectedStartDate, this.selectedEndDate)
  }

  async getDataReport(startDate: any, endDate: any) {
    this.resetReport();
    this.isLoading = true;

    const salesReportPermise = this.getSalesReport(startDate, endDate);
    const paymentReportPromise = this.getPaymentReport(startDate, endDate)
    const bestSellerProductReportPromise = this.getBestSellerProductReport(startDate, endDate)
    const orderReportPromise = this.getOrderReport(startDate, endDate, 5)
    const returnReportPromise = this.getReturnReport(startDate, endDate, 5)
    const discountReportPromise = this.getDiscountReport(startDate, endDate)
    const orderStatusReportPromise = this.getOrderStatusReport(startDate, endDate)
    const newCustomerReportPromise = this.getNewCustomerReport(startDate, endDate)

    this.salesReport = await salesReportPermise
    this.paymentReport = await paymentReportPromise
    this.bestSellerProductReport = await bestSellerProductReportPromise
    this.orderReport = await orderReportPromise
    this.returnReport = await returnReportPromise
    this.discountReport = await discountReportPromise
    this.orderStatusReport = await orderStatusReportPromise
    this.newCustomerReport = await newCustomerReportPromise

    const paymentConfig = await this.getPaymentConfig()

    this.setPaymentChart(this.paymentReport, paymentConfig);

    console.log(this.salesReport)
    console.log(this.paymentReport)
    console.log(this.bestSellerProductReport)
    console.log(this.orderReport)
    console.log(this.returnReport)
    console.log(this.discountReport)
    console.log(this.orderStatusReport)
    console.log(this.newCustomerReport)

    this.isLoading = false;
  }

  resetReport() {
    this.paymentMethodQtyChartShow = false;
    this.paymentMethodValueChartShow = false;
    this.paymentMethodQtyChart = null;
    this.paymentMethodValueChart = null;
    this.salesReport = null;
    this.paymentReport = null;
    this.bestSellerProductReport = null;
    this.orderReport = null;
    this.returnReport = null;
    this.discountReport = null;
    this.orderStatusReport = null;
    this.newCustomerReport = null;
  }

  async getSalesReport(startDate: any, endDate: any) {
    const filter = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD')
    }

    console.log(filter)
    return await this.reportsService.getSalesReport(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data
      } else {
        return null
      }
    })
  }

  async getPaymentReport(startDate: any, endDate: any) {
    const filter = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD')
    }
    return await this.reportsService.getPaymentReport(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data
      } else {
        return null
      }
    })
  }

  async getBestSellerProductReport(startDate: any, endDate: any) {
    const filter = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD')
    }
    return await this.reportsService.getBestSellerProductReport(this.shopId, filter).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data
      } else {
        return null
      }
    })
  }

  async getOrderReport(startDate: any, endDate: any, limit?: number) {
    const filter: any = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD'),
      offset: 0
    }

    if (limit) {
      filter.limit = limit
    }
    return await this.reportsService.getOrderReport(this.shopId, filter).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { data: response.data, count: response.count }
      } else {
        return null
      }
    })
  }

  async getReturnReport(startDate: any, endDate: any, limit?: number) {
    const filter: any = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD'),
      offset: 0
    }

    if (limit) {
      filter.limit = limit
    }
    return await this.reportsService.getReturnReport(this.shopId, filter).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { data: response.data, count: response.count }
      } else {
        return null
      }
    })
  }

  async getDiscountReport(startDate: any, endDate: any) {
    const filter = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD')
    }
    return await this.reportsService.getDiscountReport(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data ? response.data : {}
      } else {
        return null
      }
    })
  }

  async getOrderStatusReport(startDate: any, endDate: any) {
    const filter = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD')
    }
    return await this.reportsService.getOrderStatusReport(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data
      } else {
        return null
      }
    })
  }

  async getNewCustomerReport(startDate: any, endDate: any) {
    const filter = {
      start_date: this.utilService.dayjs(startDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(endDate).format('YYYY-MM-DD')
    }
    return await this.reportsService.getNewCustomerReport(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data
      } else {
        return null
      }
    })
  }

  async getPaymentConfig() {
    return await this.reportsService.getPaymentConfig().then((response: any) => {
      if (response.success) {
        return response.data.filter((item: any) => item.type === 'sale')
      } else {
        return []
      }
    })
  }

  setPaymentChart(paymentReport: any, paymentConfig: any) {
    console.log(paymentReport)
    console.log(paymentConfig)
    const labels: Array<string> = []
    const dataQty: Array<number> = []
    const dataValue: Array<number> = []
    for (let i = 0; i < paymentConfig.length; i++) {
      labels[i] = paymentConfig[i].name || 'ไม่พบข้อมูล'
      const payment = paymentReport?.find((item: any) => item.payment_method_id === paymentConfig[i].id)
      if (payment) {
        dataQty[i] = payment.orders || 0
        dataValue[i] = payment.sales || 0
        this.paymentMethodQtyChartShow = true;
        this.paymentMethodValueChartShow = true;
      } else {
        dataQty[i] = 0
        dataValue[i] = 0
      }
    }

    this.paymentMethodQtyChart = this.setDataDoughnut('payment-method-qty', 'จำนวน', labels, dataQty)

    this.paymentMethodValueChart = this.setDataDoughnut('payment-method-value', 'มูลค่า', labels, dataValue)

    console.log(this.paymentMethodQtyChart)
  }

  setDataDoughnut(id: string, label: string, labels: any, data: any) {
    return {
      id: id,
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: data,
            backgroundColor: this.bgTemplate,
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  }

  sumArrayNumber(arr: number[]): number {
    let result: number = 0;
    for (const value of arr) {
      result += value;
    }
    return result;
  }

  calculateDiscountPercent(discount: number, sales: number) {
    return discount > 0 || sales > 0 ? parseFloat(((discount / sales) * 100).toFixed(2)) : 0
  }

  cancelOrderStatus(orderStatusReport: any) {
    return orderStatusReport.find((item: any) => item.status === 'canceled') ? orderStatusReport.find((item: any) => item.status === 'canceled').orders : 0
  }

  calculateCancelOrderStatusPercent(orderStatusReport: any) {
    const cancelOrder = orderStatusReport.find((item: any) => item.status === 'canceled') ? orderStatusReport.find((item: any) => item.status === 'canceled').orders : 0
    const orderAll = orderStatusReport.filter((item: any) => item.status === 'canceled' || item.status === 'completed').reduce((cuurentValue: number, report: any) => cuurentValue + report.orders, 0)
    const result = ((cancelOrder > 0) || (orderAll > 0)) ? parseFloat(((cancelOrder / orderAll) * 100).toFixed(2)) : 0
    return result
  }

  displayStatusOrder(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_for_payment') {
      return 'รอชำระเงิน'
    } else if (status === 'completed') {
      return 'สำเร็จ'
    } else if (status === 'invalid_amount') {
      return 'ยอดชำระเงินไม่ตรง'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayStatusReturn(status: string) {
    if (status === 'ongoing') {
      return 'กำลังดำเนินการ'
    } else if (status === 'waiting_refund') {
      return 'รอคืนเงิน'
    } else if (status === 'waiting_exchange') {
      return 'รอเปลี่ยนสินค้า'
    } else if (status === 'refunded') {
      return 'คืนเงินแล้ว'
    } else if (status === 'completed') {
      return 'เปลี่ยนสินค้าแล้ว'
    } else if (status === 'canceled') {
      return 'ยกเลิก'
    } else {
      return status
    }
  }

  displayStatusReturnColor(status: string) {
    if (status === 'ongoing') {
      return 'text-yellow-500'
    } else if (status === 'waiting_refund' || status === 'waiting_exchange') {
      return 'text-yellow-500'
    } else if (status === 'completed' || status === 'refunded') {
      return 'text-lime-600'
    } else if (status === 'canceled') {
      return 'text-red-500'
    } else {
      return 'text-yellow-500'
    }
  }

  displayStatusColor(status: string) {
    if (status === 'ongoing') {
      return 'text-yellow-500'
    } else if (status === 'waiting_for_payment') {
      return 'text-yellow-500'
    } else if (status === 'completed') {
      return 'text-lime-600'
    } else if (status === 'invalid_amount') {
      return 'text-red-500'
    } else if (status === 'canceled') {
      return 'text-red-500'
    } else {
      return 'text-yellow-500'
    }
  }

  displayPayment(payment: string) {
    if (payment === 'bank_transfer') {
      return 'โอนผ่านบัญชีธนาคาร'
    } else if (payment === 'cash') {
      return 'เงินสด'
    } else {
      return payment
    }
  }

  loopAnimatePulse(loop: number) {
    return Array(loop).fill(0);
  }

  selecetedCouponInfo(discounts: any, totalDiscount: any) {
    if (discounts.length > 0) {
      const dialogRef = this.dialog.open(CouponDetailComponent, {
        data: { discounts, totalDiscount }
      });
    }
  }

  selecetedPaymentInfo(payment: any, paymentMethod: any) {
    if (payment?.note || payment?.image) {
      const dialogRef = this.dialog.open(PaymentDetailComponent, {
        data: { payment, paymentMethod }
      });
    }
  }

  async seeAllOrder() {
    this.orderReport = await this.getOrderReport(this.selectedStartDate, this.selectedEndDate)
  }

  async seeAllReturn() {
    this.returnReport = await this.getReturnReport(this.selectedStartDate, this.selectedEndDate)
  }

  onDateRangeSelected() {
    if (this.selectedEndDate && this.selectedStartDate) {
      console.log(this.utilService.dayjs(this.selectedStartDate).format('DD-MM-YYYY'), this.utilService.dayjs(this.selectedEndDate).format('DD-MM-YYYY'));
      console.log('get data');
      this.getDataReport(this.selectedStartDate, this.selectedEndDate)
    }
  }

  onDateSelected(date: any) {
    return this.utilService.dayjs(date).format('DD-MM-YYYY')
  }

  getUserDetail(user: string) {
    return this.users.find(item => item.user_id === user)
  }

  canAccessExportExcel() {
    return this.permissionsExport.includes(this.authService.user.role)
  }

  async downloadReport() {
    const filter = {
      start_date: this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')
    }
    const orderReport: any = await this.reportsService.getExportReportOrder(this.shopId, filter)

    const productRows: any[] = [];
    const orderRows: any[] = [];
    for (const order of orderReport.data) {

      for (const product of order.products) {
        productRows.push({
          shop_name: this.shopName,
          date: this.utilService.dayjs(order.order_date).format('YYYY-MM-DD HH:mm'),
          payment: order.payment,
          payment_ref: order.payment_ref,
          sku: product.sku,
          name: product.name,
          category: product.category,
          serial_no: product.serial_no,
          order_no: order.order_no,
          cost: product.cost,
          price: product.unit_price,
          qty: 1,
          discount: !productRows.find(e => e.order_no === order.order_no) ? order.total_discount : 0,
          discount_point: !productRows.find(e => e.order_no === order.order_no) ? order.discount_point : 0,
          discount_normal: !productRows.find(e => e.order_no === order.order_no) ? order.discount_normal : 0,
          note: order.note,
        })
      }

      orderRows.push({
        shop_name: this.shopName,
        date: this.utilService.dayjs(order.order_date).format('YYYY-MM-DD HH:mm'),
        payment: order.payment,
        payment_ref: order.payment_ref,
        note: order.note,
        order_no: order.order_no,
        cost: order.total_cost,
        price: order.price,
        discount: order.total_discount,
        total_price: order.total_price,
        saler: this.getUserDetail(order.saler)?.name,
      })

    }
    this.reportsService.generateOrderReport(orderRows, productRows, `ยอดขายสาขา ${this.shopName}`)
  }

  async downloadReturnReport() {
    const filter = {
      start_date: this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD'),
      end_date: this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')
    }
    const orderReport: any = await this.reportsService.getExportReportReturn(this.shopId, filter)
    console.log(orderReport);

    const productRows: any[] = [];
    for (const order of orderReport.data) {

      for (const product of order.products) {
        productRows.push({
          shop_name: this.shopName,
          date: this.utilService.dayjs(order.return_date).format('YYYY-MM-DD HH:mm'),
          payment: this.displayPayment(order.payment),
          sku: product.sku,
          name: product.name,
          category: product.category,
          serial_no: product.serial_no,
          return_no: order.return_no,
          price: product.unit_price,
          qty: 1,
          discount: order.total_discount,
          total_price: order.total_price,
          note: order.note,
          saler: this.getUserDetail(order.saler)?.name
        })
      }


    }
    this.reportsService.generateReturnReport(productRows, `รายการรับคืนสาขา ${this.shopName}`)
  }
}
