import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { MerchantService } from '../../services/merchant/merchant.service';
import { ModalService } from '../../../../core/services/modal/modal.service';

@Component({
  selector: 'app-merchant-setting-printer',
  templateUrl: './merchant-setting-printer.component.html',
  styleUrl: './merchant-setting-printer.component.css'
})
export class MerchantSettingPrinterComponent implements OnInit {
  faScrewdriverWrench = faScrewdriverWrench;

  form: FormGroup;
  formChanged = false
  shopDetials: any = {}
  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopName: string = this.authService.selectedShop?.name || '';

  loading = false
  isPrinter: boolean = false;
  submitted: boolean = false;


  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private modalService: ModalService
  ) {

    this.form = this.fb.group({
      title: ['', [Validators.required]],
      shopName: ['', [Validators.required]],
      branchName: [{ value: this.shopName, disabled: true }, [Validators.required]],
      message: ['', [Validators.required]]
    });

  }

  async ngOnInit(): Promise<void> {
    this.loadShopDetails();
    this.form.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  loadShopDetails() {
    this.loading = true;
    this.merchantService.getOneShop(this.shopId).then((response: any) => {
      if (response.success) {
        this.shopDetials = response.data
        this.isPrinter = response.data.is_printer;
        this.form.patchValue({
          title: response?.data?.printer?.title || '',
          shopName: response?.data?.printer?.shop_name || '',
          message: response?.data?.printer?.message || ''
        });
        this.formChanged = false
      }
      this.loading = false;
    })
  }

  callbackActivePrinter = (value: any): void => {
    this.isPrinter = value
    this.formChanged = true;

    if (value) {
      this.form.controls['title'].setValidators(Validators.required);
      this.form.controls['shopName'].setValidators(Validators.required);
      this.form.controls['message'].setValidators(Validators.required);

    } else {
      this.form.controls['title'].clearValidators();
      this.form.controls['shopName'].clearValidators();
      this.form.controls['message'].clearValidators();
    }

    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].updateValueAndValidity();
    });

  }

  onCancel() {
    this.loadShopDetails()
  }

  onSubmit() {
    this.submitted = true;
    if (!this.form.valid) return
    const data = this.form.getRawValue()
    let updateData: any = {
      is_printer: this.isPrinter
    }
    if (this.isPrinter) {
      updateData['printer'] = {
        title: data.title,
        shop_name: data.shopName,
        message: data.message
      }
    }
    this.merchantService.updateshop(this.shopId, updateData).then(response => {
      this.formChanged = false;
      if (response.success) {
        this.loadShopDetails()
        this.modalService.openModal('success', 'อัพเดตข้อมูลสำเร็จ', '').then(ok => {
          console.log('ok')
        })
      } else {
        this.modalService.openModal('success', 'เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

}
