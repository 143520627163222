<div class="p-4 w-full space-y-2 text-sm lg:text-base relative" id="product-list-content">
    <form [formGroup]="filters" class="hidden lg:flex flex-row justify-between items-center">
        <div class="font-bold text-base lg:text-lg">รายการสินค้า</div>
        <div class="flex space-x-2">
            <div class="w-fit">
                <div class="flex flex-row justify-between items-center border bg-white rounded-lg h-[46px]">
                    <input type="text" id="keyword" class="py-2 px-4 !pr-8 w-full outline-none rounded-l-lg"
                        placeholder="ค้นหาชื่อ,SKU" formControlName="keyword" autocomplete="off">
                    <button
                        class="flex-none h-full aspect-square bg-yellow-500 rounded-r-lg flex items-center justify-center"
                        (click)="filterChange()">
                        <fa-icon [icon]="faMagnifyingGlass" class="text-xl"></fa-icon></button>
                </div>
            </div>
            <div class="w-fit">
                <button class="btn-light !border flex space-x-2 items-center" (click)="openProductFilterDialog()">
                    <fa-icon [icon]="faFilter" class=""></fa-icon>
                    <div class="">ตัวกรอง</div>
                    @if(countFilter>0){
                    <div class="bg-yellow-500 text-white w-4 h-4 rounded aspect-square text-xs">
                        {{countFilter}}
                    </div>}
                </button>
            </div>
            <div class="w-fit">
                <button class="btn-light !border flex space-x-2" (click)="downloadProduct()">
                    <fa-icon [icon]="faDownload" class=""></fa-icon>
                    <div class="">Export</div>
                </button>
            </div>
            @if(canAccessSettingPrice()){
            <div class="w-fit">
                <button class="btn-light !border flex space-x-2" routerLink="/products/product-setting">
                    <fa-icon [icon]="faScrewdriverWrench" class=""></fa-icon>
                    <div class="">ตั้งค่า</div>
                </button>
            </div>
            }
        </div>
    </form>
    <form [formGroup]="filters" class="lg:hidden">
        <div class="flex flex-row justify-between items-center border bg-white rounded-lg h-[46px]">
            <input type="text" id="keyword" class="py-2 px-4 !pr-8 w-full outline-none" placeholder="ค้นหาชื่อ,SKU"
                formControlName="keyword" autocomplete="off">
            <button class="flex-none h-full aspect-square bg-yellow-500 rounded-r-lg flex items-center justify-center"
                (click)="filterChange()">
                <fa-icon [icon]="faMagnifyingGlass" class="text-xl"></fa-icon></button>
        </div>
    </form>

    <div class="space-y-2">
        <div class="lg:hidden px-2 flex justify-between items-center">
            <div class="font-semibold">สินค้า {{countDataSource}} รายการ</div>
            <div class="flex flex-row space-x-2">
                <button class="flex items-center space-x-1 relative px-2 py-1 rounded border bg-white"
                    (click)="openProductFilterDialog()">
                    <fa-icon [icon]="faFilter" class="text-base"></fa-icon>
                    <div class="hidden md:block">ตัวกรอง</div>
                    @if(countFilter>0){
                    <div class="bg-yellow-500 text-white w-4 h-4 rounded aspect-square text-xs">
                        {{countFilter}}
                    </div>}
                </button>
                <button class="flex items-center space-x-1 relative px-2 py-1 rounded border bg-white"
                    routerLink="/products/product-setting">
                    <fa-icon [icon]="faScrewdriverWrench" class="text-base"></fa-icon>
                    <div class="hidden md:block">ตั้งค่า</div>
                </button>
            </div>
        </div>

        @if (isLoaded) {
        <div class="lg:hidden grid gap-1">
            @if(!filteredData || filteredData.length === 0){
            <div class="text-center py-10">
                <div class="flex flex-col justify-center items-center w-full">
                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                    <div class="text-center pt-4">ไม่พบสินค้า</div>
                </div>
            </div>
            }
            @else {
            @for (product of filteredData; track $index) {
            <button class="border bg-white rounded-lg p-3 grid grid-cols-5 md:grid-cols-8 gap-2 text-left"
                routerLink="/products/product-detail" [queryParams]="{sku: product.sku}">
                <div class="col-span-1">
                    <img src="https://storage.remaxthailand.co.th/img/product/{{product.sku}}/1.jpg" alt=""
                        class="w-full aspect-square rounded">
                </div>
                <div class="col-span-3 md:col-span-6">
                    <div class="line-clamp-2">{{product.name}}</div>
                    <div class="text-primary-500">{{product.sku}}</div>
                </div>
                <div class="col-span-1 flex flex-col items-end">
                    @if(product.stock){
                    <div class="font-medium">x {{product.stock | number}}</div>
                    }@else {
                    <div class="flex font-semibold items-center text-red-500">
                        <div class="bg-red-500 w-2 h-2 rounded-full mr-1"></div>
                        <div class="">หมด</div>
                    </div>
                    }
                    @if (priceConfigs.length > 0) {
                    <div class="font-semibold" [ngClass]="{'text-red-500':!getProductPrice(product.prices,
                    priceConfigs[0].price_config_id)}">
                        {{getProductPrice(product.prices,
                        priceConfigs[0].price_config_id)| number}}฿
                    </div>
                    }
                </div>
            </button>
            }

            @if(loadingMore) {
            <div class="flex justify-center pt-10">
                <app-loader></app-loader>
            </div>
            }
            @if(filteredData.length > 0 && ((countDataSource - filteredData.length) > 0)){
            <div class="flex items-center justify-center w-full pt-4 space-x-4">
                <button (click)="loadMore()"
                    class="btn-light !py-1 w-full lg:w-fit flex justify-between md:justify-center md:space-x-6 xl:space-x-8 items-center">
                    <div class="flex space-x-1 justify-start items-center">
                        <div>แสดง</div>
                        <div class="text-primary-500 font-medium">{{filteredData.length}}</div>
                        <div>/</div>
                        <div>{{countDataSource}}</div>
                    </div>
                    <div class="flex space-x-2 justify-end items-center font-medium">
                        <div>แสดงเพิ่มเติม</div>
                        <div><fa-icon [icon]="faArrowDown"></fa-icon></div>
                    </div>
                </button>
            </div>
            }
            @if(filteredData.length > 0 && (countDataSource === filteredData.length)){
            <div class="flex items-center justify-center w-full pt-4 space-x-4">
                - โหลดข้อมูลครบแล้ว -
            </div>
            }
            }
        </div>


        <div class="hidden lg:grid grid-cols-1 gap-2">
            <div>
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    สินค้า
                                </th>
                                <th class="text-left">
                                    สถานะสินค้า
                                </th>
                                <th class="text-center">
                                    สต๊อก
                                </th>
                                <th class="text-right">
                                    ต้นทุน
                                </th>
                                @for (config of priceConfigs; track $index) {
                                <th class="text-right">
                                    {{config.name}}
                                </th>
                                }
                                <th class="text-center">
                                    จัดการ
                                </th>
                            </tr>
                        </thead>
                        @if(!dataSource || dataSource.length === 0){
                        <tbody>
                            <tr class="tbody-tr">
                                <td colspan="100" class="text-center py-10">
                                    <div class="flex flex-col justify-center items-center w-full">
                                        <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                        <div class="text-center pt-4">ไม่พบสินค้า</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        }
                        @else {
                        <tbody>
                            @for (product of dataSource; track $index) {
                            <tr class="tbody-tr">
                                <td>
                                    <div class="flex space-x-2">
                                        <div class="flex-none">
                                            <img src="https://storage.remaxthailand.co.th/img/product/{{product.sku}}/1.jpg"
                                                alt="" class="w-12 h-12 object-cover rounded-md">
                                        </div>
                                        <div class="">
                                            <div class="line-clamp-2">{{product.name}}</div>
                                            <div class="text-primary-500">{{product.sku}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="product.stock <= 0 || !product.stock"
                                        class="flex font-semibold items-center text-red-500">
                                        <div class="bg-red-500 w-2 h-2 rounded-full mr-2"></div>
                                        <div class="">หมด</div>
                                    </div>
                                    <div *ngIf="product.stock > 0 && product.stock < product.min_stock"
                                        class="flex font-semibold items-center text-yellow-500">
                                        <div class="bg-yellow-500 w-2 h-2 rounded-full mr-2"></div>
                                        <div class="">ใกล้หมด</div>
                                    </div>
                                    <div *ngIf="product.stock > 0 && product.stock >= product.min_stock"
                                        class="flex font-semibold items-center text-green-500">
                                        <div class="bg-green-500 w-2 h-2 rounded-full mr-2"></div>
                                        <div class="">พร้อมขาย</div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <p class="font-semibold"
                                        [ngClass]="{'text-red-500': product.stock == 0 || !product.stock}">
                                        {{product.stock | number}}</p>
                                </td>
                                <td class="text-right">
                                    <p class="font-semibold"
                                        [ngClass]="{'text-sky-600':product.cost > 0, 'text-red-500': product.cost == 0 || !product.cost}">
                                        {{product.cost | number}}฿</p>
                                </td>
                                @for (config of priceConfigs; track $index) {
                                <td class="text-right w-28">
                                    <button *ngIf="!isEditing[product.sku + '' + config.price_config_id]"
                                        [disabled]="!canEditPrice()" class="font-semibold"
                                        [ngClass]="{'text-black': getProductPrice(product.prices, config.price_config_id) > 0, 'text-red-500': getProductPrice(product.prices, config.price_config_id) == 0 || !getProductPrice(product.prices, config.price_config_id)}"
                                        (click)="toggleEditPrice(product.sku, config.price_config_id)">
                                        {{ getProductPrice(product.prices, config.price_config_id) | number }}฿
                                    </button>

                                    <input *ngIf="isEditing[product.sku + '' + config.price_config_id]" type="number"
                                        id="price-add" class="input text-right" autocomplete="off" [(ngModel)]="price"
                                        [placeholder]="getProductPrice(product.prices, config.price_config_id) | number"
                                        (focusout)="toggleEditPrice(product.sku, config.price_config_id)"
                                        (keyup.enter)="updatePrice(product.sku, config.price_config_id)" />

                                </td>
                                }
                                <td class="text-center">
                                    <div class="flex justify-center">
                                        <button
                                            class="w-7 aspect-square rounded text-black  flex items-center justify-center transition hover:bg-yellow-500 "
                                            routerLink="/products/product-detail" [queryParams]="{sku: product.sku}">
                                            <fa-icon [icon]="faPencil" class=""></fa-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            }
                        </tbody>
                        }
                    </table>
                </div>
                <app-pagination *ngIf="countDataSource" [callBackPageChange]="callBackPageChange" [pageSize]="pageSize"
                    [pageIndex]="pageIndex" [length]="countDataSource"></app-pagination>
            </div>
        </div>
        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }
    </div>

</div>

<div>
    <app-product-filter-dialog [open]="openFilterProduct" [brands]="brandOptions" [categories]="categoryOptions"
        [stock_status_options]="stockStatusOptions" [category_id]="filters.value.category_id"
        [brand_id]="filters.value.brand_id" [stock_status]="filters.value.stock_status"
        (callBackSubmit)="callBackSubmit($event)"></app-product-filter-dialog>
</div>