import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
  styleUrl: './loader-dialog.component.css'
})
export class LoaderDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<LoaderDialogComponent>,
  ) { }

  close() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }
}
