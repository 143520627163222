import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaffsService {

  system_id = environment.system_id

  roleOptions = [
    { id: 'admin', name: 'Admin' },
    { id: 'manager', name: 'Manager' },
    { id: 'supervisor', name: 'Supervisor' },
    { id: 'sale', name: 'Sale' }
  ]

  constructor(

  ) { }

}
