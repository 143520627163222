import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { ClaimService } from '../../services/claim/claim.service';
import { MatDialog } from '@angular/material/dialog';
import { ClaimCustomerDialogComponent } from '../../components/claim-customer-dialog/claim-customer-dialog.component';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrl: './claim.component.css'
})
export class ClaimComponent implements OnInit {
  liffUrlClaim = environment.liff_url_claim;

  items: any[] = []

  shopId: string = this.authService.selectedShop?.shop_id || '';

  showDetail = false;

  claimDetail: any = {}

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private claimService: ClaimService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    const filter = {
      fields: 'claim_id,product,response,created,is_receipt'
    }
    this.items = await this.getClaim(filter);
  }

  getClaim(filter: any) {
    return this.claimService.getClaim(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data
      } else {
        return []
      }
    })
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  async clickShowDetail(claim_id: string) {
    console.log(claim_id)
    const filter = {
      claim_id: claim_id
    }
    const fetchClaim = await this.getClaim(filter)
    this.claimDetail = fetchClaim[0]
    this.showDetail = true;
  }

  callBackClaimDetail = (data: any) => {
    console.log(data)

    if (data.followUp) {
      console.log(`${this.liffUrlClaim}/follow-up?id=${this.claimDetail.claim_id}&shop-id=${this.shopId}`)
      const dialogRef = this.dialog.open(ClaimCustomerDialogComponent, {
        data: { qrcode: `${this.liffUrlClaim}/follow-up?id=${this.claimDetail.claim_id}&shop-id=${this.shopId}` }
      });
    } else {
      this.showDetail = false;
    }
  }

  displayStatus(status: string, is_receipt: any) {
    /**
      S1 =	ส่ง SUP
      S2 =	ส่งช่าง
      S3 =	รอสินค้า (Remax)
      S4 =	รอสินค้า (ซัพนอก)
      S5 =	รอดำเนินการ
      S6 =	ปิดงาน
      S7 =	อื่นๆ
    */

    if (status === 'ปิดงาน') {
      if (is_receipt) {
        return 'เคลมสำเร็จ'
      } else {
        return 'รอร้านค้ารับเข้า'
      }
    }
    return status
  }

  colorStatus(status: string, is_receipt: any) {
    /**
      S1 =	ส่ง SUP
      S2 =	ส่งช่าง
      S3 =	รอสินค้า (Remax)
      S4 =	รอสินค้า (ซัพนอก)
      S5 =	รอดำเนินการ
      S6 =	ปิดงาน
      S7 =	อื่นๆ
    */

    if (status === 'ปิดงาน') {
      if (is_receipt) {
        return '!bg-lime-200'
      } else {
        return '!bg-orange-300'
      }
    }
    return status
  }

}
