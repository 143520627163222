import { Component, OnInit } from '@angular/core';
import { faPlus, faPencil, faTags, faPercent } from '@fortawesome/free-solid-svg-icons';
import { PromotionService } from '../../service/promotion/promotion.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { PromotionCreateDialogComponent } from '../../components/promotion-create-dialog/promotion-create-dialog.component';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrl: './promotion-list.component.css'
})
export class PromotionListComponent implements OnInit {
  faPlus = faPlus;
  faPencil = faPencil;
  faTags = faTags;
  faPercent = faPercent;

  promotionList: any = []

  isLoading = false;

  formCreatePromotion: any = {}

  shopAll: any = []

  shopList: any = []

  showDetail = false;
  promotionDetail: any;

  providerId: string = this.authService.selectedShop?.provider_id || '';

  constructor(
    private promotionService: PromotionService,
    public dialog: MatDialog,
    private modalService: ModalService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.fetchData()
  }

  async fetchData() {
    this.isLoading = true
    this.promotionList = await this.getAllPromotion()
    this.shopAll = await this.getAllShop()
    this.shopList = this.shopAll.filter((item: any) => item.active)
    this.isLoading = false
  }

  getAllPromotion() {
    const filters = { providers: this.providerId }
    return this.promotionService.getAllPromotion(filters).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data.sort((a: any, b: any) => b.created.date - a.created.date)
      } else {
        return []
      }
    })
  }

  getAllShop() {
    const filters = { providers: this.providerId, fields: 'shop_id,name,active' }
    return this.promotionService.getAllShop(filters).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data.sort((a: any, b: any) => a.shop_id - b.shop_id)
      } else {
        return []
      }
    })
  }

  clickCreatePromotion() {
    const dialogRef = this.dialog.open(PromotionCreateDialogComponent, {
      data: { formCreatePromotion: this.formCreatePromotion, shopList: this.shopList },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`create result: `, result);
      if (result.confirm) {
        const body = {
          ...result.data.formCreatePromotion,
          provider_id: this.providerId
        }
        console.log(body)
        this.promotionService.createPromotion(body).then((response: any) => {
          console.log(response)
          if (response.success) {
            this.promotionList.unshift(response.data)
            this.modalService.openModal('success', 'เพิ่มโปรโมชั่นสำเร็จ', '')
          } else {
            this.modalService.openModal('fail', 'เพิ่มโปรโมชั่นไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
          }
        })
      } else {
        this.formCreatePromotion = {}
      }
    });
  }

  clickUpdatePromotion(promotion: any) {
    const formUpdate: any = {
      shops: promotion.shops,
      name: promotion.name,
      unit: promotion.unit,
      value: promotion.value,
      minimum: promotion.minimum,
      maximum: promotion.maximum,
      active: promotion.active
    }

    const dialogRef = this.dialog.open(PromotionCreateDialogComponent, {
      data: { formCreatePromotion: formUpdate, shopList: this.shopList },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`update result: `, result);
      if (result.confirm) {
        this.promotionService.updatePromotion(promotion.promotion_id, formUpdate).then((response: any) => {
          console.log(response)
          if (response.success) {
            const index = this.promotionList.findIndex((item: any) => item.promotion_id === promotion.promotion_id)
            if (index > -1) {
              this.promotionList[index] = response.data
              this.modalService.openModal('success', 'แก้ไขโปรโมชั่นสำเร็จ', '')
            }
          } else {
            this.modalService.openModal('fail', 'แก้ไขโปรโมชั่นไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
          }
        })
      } else {

      }
    });
  }

  clickDetailPromotion(promotion: any) {
    this.showDetail = true;
    this.promotionDetail = promotion
  }

  callBackPromotionDetail = (data: any) => {
    console.log(data)

    if (data.edit) {
      this.showDetail = false;
      this.clickUpdatePromotion(this.promotionDetail)
    } else {
      this.showDetail = false;
    }
  }
}
